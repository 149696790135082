import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";

import { getAllActuators } from "../../redux/actions/adminActions";
import { startCase } from "lodash";
import { ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_MODAL } from "../../redux/constants";
import { AddActuatorsFromCsvStringModal } from "../../components/admin/modals/AddActuatorsFromCsvStringModal";
import { toast } from "react-toastify";

const AdminActuators = () => {
  const dispatch = useDispatch();

  const actuators = useSelector((state) => state.admin.database.actuators);
  const { allActuators } = actuators;

  const addActuatorsFromCsvString = useSelector(
    (state) => state.admin.database.actuators.addActuatorsFromCsvString
  );

  useEffect(() => {
    dispatch(getAllActuators());
  }, []);

  useEffect(() => {
    if (addActuatorsFromCsvString.success) {
      dispatch({ type: ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_MODAL });
      dispatch(getAllActuators());
      toast.success(addActuatorsFromCsvString.message);
    }
  }, [addActuatorsFromCsvString.success]);

  return (
    <>
      <AddActuatorsFromCsvStringModal />
      <CRow>
        <CCol xs={12} className="mb-4">
          <CButton
            className="mr-2"
            color="primary"
            // onClick={() => dispatch({ type: ADMIN_ADD_VALVE_MODAL })}
          >
            Add Actuator
          </CButton>
          <CButton
            color="primary"
            onClick={() => dispatch({ type: ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_MODAL })}
          >
            Paste CSV Text
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={12}></CCol>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>ISO 5211 Standard Actuators</strong>
            </CCardHeader>
            <CCardBody>
              <CTable hover>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col">Public</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Size</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Drive Type</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Drive Size</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {allActuators.map(
                    (actuators) =>
                      actuators.type === "ISO 5211 Standard Actuator" && (
                        <CTableRow>
                          <CTableDataCell>
                            {actuators.allow === true ? "Yes" : actuators.allow === false && "No"}
                          </CTableDataCell>
                          <CTableDataCell>{actuators.isoFlange}</CTableDataCell>
                          <CTableDataCell>{actuators.driveType}</CTableDataCell>
                          <CTableDataCell>{actuators.driveSize}</CTableDataCell>
                          <CTableDataCell>
                            <CButton
                              color="light"
                              size="sm"
                              className="mx-1"
                              // onClick={() => {
                              //   setValveDetails(valve);
                              //   dispatch({
                              //     type: ADMIN_EDIT_VALVE_MODAL,
                              //   });
                              // }}
                            >
                              <i className="fas fa-edit"></i>
                            </CButton>
                            <CButton
                              color="light"
                              size="sm"
                              className="mx-1"
                              // onClick={() => {
                              //   setValveDetails(valve);
                              //   setConfirmDeleteValveModal(true);
                              // }}
                            >
                              <i className="fas fa-trash"></i>
                            </CButton>
                          </CTableDataCell>
                        </CTableRow>
                      )
                  )}
                </CTableBody>
              </CTable>
            </CCardBody>
          </CCard>

          <CCard className="mb-4">
            <CCardHeader>
              <strong>Actuators</strong>
            </CCardHeader>
            <CCardBody>
              <CTable hover>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col">Public</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Brand</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Series</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Model</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Options</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {allActuators.map(
                    (actuators) =>
                      actuators.type !== "ISO 5211 Standard Actuator" && (
                        <CTableRow>
                          <CTableDataCell>
                            {actuators.allow === true ? (
                              <CBadge shape="rounded-pill" color="success">
                                Yes
                              </CBadge>
                            ) : (
                              actuators.allow === false && (
                                <CBadge shape="rounded-pill" color="danger">
                                  No
                                </CBadge>
                              )
                            )}
                          </CTableDataCell>
                          <CTableDataCell>{actuators.brand}</CTableDataCell>
                          <CTableDataCell>{actuators.series}</CTableDataCell>
                          <CTableDataCell>{actuators.model}</CTableDataCell>
                          <CTableDataCell>
                            {actuators.options.map(
                              (option) =>
                                `${startCase(Object.keys(option)[0])}: ${
                                  Object.values(option)[0]
                                }, `
                            )}
                          </CTableDataCell>
                          <CTableDataCell>
                            <CButton
                              color="light"
                              size="sm"
                              className="mx-1"
                              // onClick={() => {
                              //   setValveDetails(valve);
                              //   dispatch({
                              //     type: ADMIN_EDIT_VALVE_MODAL,
                              //   });
                              // }}
                            >
                              <i className="fas fa-edit"></i>
                            </CButton>
                            <CButton
                              color="light"
                              size="sm"
                              className="mx-1"
                              // onClick={() => {
                              //   setValveDetails(valve);
                              //   setConfirmDeleteValveModal(true);
                              // }}
                            >
                              <i className="fas fa-trash"></i>
                            </CButton>
                          </CTableDataCell>
                        </CTableRow>
                      )
                  )}
                </CTableBody>
              </CTable>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default AdminActuators;
