import React from "react";
import { Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { SLKBD_SELECT_VALVE_STEM_TYPE } from "../../redux/constants";

const ValveStemType = () => {
  const dispatch = useDispatch();

  // bring linkage kit selections in from redux state
  const selectLinkageKitByDimensions = useSelector((state) => state.selectLinkageKitByDimensions);
  const { loading, valve } = selectLinkageKitByDimensions;

  const valveStemTypes = [
    {
      value: "doubleD",
      label: `Double D`,
    },
    {
      value: "square",
      label: `Square`,
    },
    {
      value: "roundWithKeyway",
      label: "Round With Key",
    },
  ];

  return (
    <>
      <Card.Body className="slkbd p-4 w-100">
        <div className="d-flex align-items-center" style={{ minHeight: "360px" }}>
          <div className="product-form__row w-100">
            <div className="product-form__title">Stem Type</div>
            <div className="product-form__control">
              <div className="input-radio-label">
                <div
                  className="input-radio-label__list d-flex flex-col"
                  style={{ columnGap: "1rem" }}
                >
                  {valveStemTypes.map((x) => (
                    <label
                      key={x.value}
                      className="input-radio-label__item"
                      style={{ flexGrow: 1, flexBasis: 1 }}
                    >
                      {`${x.label}`}
                      <input
                        type="radio"
                        name={x.label}
                        value={x.value}
                        onClick={(e) =>
                          dispatch({
                            type: SLKBD_SELECT_VALVE_STEM_TYPE,
                            payload: e.target.value,
                          })
                        }
                        checked={valve.stem.type === x.value}
                        className="input-radio-label__input"
                        disabled={loading === true}
                      />
                      <span
                        className="input-radio-label__title"
                        style={{
                          background: `url('/stem-${x.value}.png')`,
                          backgroundSize: "calc(100% - 20px)",
                          border: "none",
                          width: "calc(100%)",
                          height: "auto",
                          paddingBottom: "100%",
                          textAlign: "center",
                          backgroundPositionX: "10px",
                          backgroundPositionY: "10px",
                          backgroundRepeat: "no-repeat",
                        }}
                      ></span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </>
  );
};

export default ValveStemType;
