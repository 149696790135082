import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { numberWithCommas } from "../../utils/helpers";
import {
  fetchBracketHeights,
  fetchPrice,
  fetchPriceByDimensions,
  getKitWeight,
  selectBracketHeight,
  selectComponents,
  selectLockout,
  selectUseCustomDataSheet,
  selectQuantity,
  setSku,
} from "../../redux/actions/optionsActions";
import {
  optionsSelector,
  summaryQtySelector,
} from "../../styles/selectorStyles";
import {
  ADDED_TO_CART_MODAL_OPEN,
  OPTIONS_COMPONENTS,
  OPTIONS_LOCKOUT,
  OPTIONS_QUANTITY,
} from "../../redux/constants";
import {
  useAddToCartMutation,
  useCreateNewCartMutation,
  useGetCartByTokenQuery,
  useGetUserCartQuery,
} from "../../redux/api/cartApi";
import { setCart, setCartToken } from "../../redux/slices/cart";
import { useAuthStatusQuery } from "../../redux/api/authApi";

export const ItemInfoBlock = ({
  loading,
  item,
  sku,
  price,
  qty,
  qtyOnePrice,
  selectedValve,
  selectedActuator,
  components,
  lockout,
  bracketHeights,
  selectedBracketHeight,
}) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userDetails);
  const cart = useSelector((state) => state.cart);

  const { useCustomDataSheets } = useSelector(
    (state) => state.selectLinkageKit
  );

  const [addToCart, addToCartResult] = useAddToCartMutation();
  const [createNewCart, createNewCartResult] = useCreateNewCartMutation();

  // Get Auth status
  const authStatus = useAuthStatusQuery();

  // Wait to fetch cart until addToCart is successfull
  const getCart = useGetCartByTokenQuery(
    { id: cart.token },
    {
      skip:
        authStatus?.data?.isAuthenticated !== true &&
        addToCartResult.isSuccess !== true,
    }
  );

  // define list of component options
  const componentsList = [
    { value: "Bracket and Coupler", label: "Bracket and Coupler" },
    { value: "Bracket Only", label: "Bracket Only" },
    { value: "Coupler Only", label: "Coupler Only" },
  ];

  let arr = [];

  for (let i = 1; i <= 100; i++) {
    arr.push(i);
  }

  // for react-select options
  const qtys = arr.map((x) => ({ value: x, label: `Qty: ${x}` }));

  const selectLockoutHandler = async (value) => {
    if (item.productType === "mountingKit") {
      dispatch(selectLockout(value));

      dispatch(
        setSku(
          selectedValve,
          selectedActuator,
          value,
          components,
          selectedBracketHeight
        )
      );

      dispatch(fetchBracketHeights(item));
    } else if (item.productType === "customMountingKit") {
      dispatch({
        type: OPTIONS_LOCKOUT,
        payload: value,
      });
    }
  };

  const selectComponentsHandler = async (value) => {
    if (item.productType === "mountingKit") {
      dispatch(selectComponents(value));
      if (value !== "Bracket and Components") {
        dispatch(selectLockout(false));

        dispatch(fetchBracketHeights(item));
      }
    } else if (item.productType === "customMountingKit") {
      dispatch({
        type: OPTIONS_COMPONENTS,
        payload: value,
      });
    }
  };

  const selectBracketHeightHandler = async (value) => {
    dispatch(selectBracketHeight(value));
  };

  const updateQtyHandler = async (value) => {
    if (item.productType === "mountingKit") {
      dispatch(selectQuantity(value.value));
      dispatch(fetchPrice(item.productType, sku, value.value));
    } else if (item.productType === "customMountingKit") {
      dispatch({
        type: OPTIONS_QUANTITY,
        payload: value.value,
      });

      dispatch(fetchPriceByDimensions(item, value.value));
    }
  };

  const addToCartHandler = async (e) => {
    e.preventDefault();

    if (cart.token) {
      addToCart({ id: cart.token, item: item })
        .unwrap()
        .then((res) => {
          dispatch(setCart(res));
          dispatch({ type: ADDED_TO_CART_MODAL_OPEN });
        });
    } else {
      createNewCart()
        .unwrap()
        .then((res) => {
          if (authStatus?.data?.isAuthenticated === false) {
            dispatch(setCartToken({ token: res._id }));
          }

          addToCart({ id: res._id, item: item })
            .unwrap()
            .then((res) => {
              dispatch(setCart(res));
              dispatch({ type: ADDED_TO_CART_MODAL_OPEN });
            });
        });
    }
  };

  // useEffect(() => {
  //   if (addToCartResult.isSuccess) {
  //     dispatch({ type: ADDED_TO_CART_MODAL_OPEN });
  //   }
  // }, [addToCartResult]);

  return (
    <div className="product__info">
      <div className="product__info-card">
        <div className="product__info-body">
          <div
            className="product__prices-stock"
            style={{ position: "relative" }}
          >
            {(!price || price === null || price === "" || isNaN(price)) && (
              <div
                className="animate__animated animate_slower"
                style={{
                  position: "absolute",
                  display: "block",
                  zIndex: 10,
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "#fff",
                }}
              >
                <Spinner
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  style={{
                    color: "#ccc",
                    position: "absolute",
                    top: "calc(50% - 1rem)",
                    left: "calc(50% - 1rem)",
                  }}
                />
              </div>
            )}
            <div className="product__prices">
              {qty > 1 && qtyOnePrice && qtyOnePrice !== price && (
                <div className="product__price product__price--old">{`$${numberWithCommas(
                  Number(qtyOnePrice).toFixed(2)
                )} ea.`}</div>
              )}
              <div className="product__price product__price--new">{`$${numberWithCommas(
                Number(price).toFixed(2)
              )} ea.`}</div>

              {qty > 1 && qtyOnePrice && qtyOnePrice !== price && (
                <div className="status-badge status-badge--style--success product__stock status-badge--has-text mt-2">
                  <div className="status-badge__body">
                    <div className="status-badge__text">
                      <>Qty&nbsp;Discount:&nbsp;</>
                      <strong>{`-$${numberWithCommas(
                        Number(Number(qtyOnePrice) - Number(price)).toFixed(2)
                      )} ea.`}</strong>
                    </div>
                    <div
                      className="status-badge__tooltip"
                      tabindex="0"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="In Stock"
                    ></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="product-form product__form">
          <label
            className="product-form__title"
            id="aria-label-components"
            htmlFor="aria-input-components"
          >
            Components
          </label>
          <div className="product-form__body">
            <div className="product-form__row">
              <Select
                inputId="aria-input-components"
                aria-labelledby="aria-label-components"
                value={componentsList.filter(
                  ({ value }) => components === value
                )}
                options={componentsList}
                onChange={(value) => selectComponentsHandler(value.value)}
                styles={optionsSelector}
                placeholder="Select Components"
                disabled={loading === true}
              />
            </div>
            <div className="product-form__row">
              <div className="product-form__title">Lock-out Kit</div>
              {components !== "Bracket and Coupler" ? (
                <div
                  className="alert alert-xs alert-info mb-2"
                  style={{ fontSize: ".75rem", fontWeight: "500" }}
                >
                  <i className="fas fa-info-circle mr-1"></i>
                  Lock-out not available for <strong>{components}</strong>
                </div>
              ) : (
                <div className="product-form__control">
                  <div className="input-radio-label">
                    <div className="input-radio-label__list">
                      <label className="input-radio-label__item">
                        <input
                          type="radio"
                          name="lockout"
                          value="true"
                          onChange={() => selectLockoutHandler(true)}
                          checked={lockout === true}
                          className="input-radio-label__input"
                          disabled={
                            components !== "Bracket and Coupler" ||
                            loading === true
                          }
                        />
                        <span className="input-radio-label__title">Yes</span>
                      </label>
                      <label className="input-radio-label__item">
                        <input
                          type="radio"
                          name="lockout"
                          value="false"
                          onChange={() => selectLockoutHandler(false)}
                          checked={lockout === false}
                          className="input-radio-label__input"
                          disabled={
                            components !== "Bracket and Coupler" ||
                            loading === true
                          }
                        />
                        <span className="input-radio-label__title">No</span>
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="product-form__row">
              <div className="product-form__title">Bracket Height</div>
              <div className="product-form__control">
                <div className="input-radio-label">
                  <div className="input-radio-label__list">
                    {bracketHeights.map((x) => (
                      <label key={x} className="input-radio-label__item">
                        <input
                          type="radio"
                          name="bracketHeight"
                          value={x}
                          onChange={() => selectBracketHeightHandler(x)}
                          checked={selectedBracketHeight === x}
                          className="input-radio-label__input"
                          disabled={loading === true}
                        />
                        <span className="input-radio-label__title">{`${x}"`}</span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {user?.canUploadCustomDataSheets ? (
              <div className="product-form__row">
                <div className="product-form__title">Dimensions</div>
                <div className="checkout__payment-methods payment-methods">
                  <ul className="payment-methods__list">
                    <li
                      className={`payment-methods__item ${
                        useCustomDataSheets === false
                          ? "payment-methods__item--active bg-white"
                          : ""
                      }`}
                    >
                      <label className="payment-methods__item-header">
                        <span className="payment-methods__item-radio input-radio">
                          <span className="input-radio__body">
                            <input
                              className="input-radio__input"
                              name="paymentMethod"
                              value={false}
                              type="radio"
                              checked={useCustomDataSheets === false}
                              onChange={() =>
                                dispatch(selectUseCustomDataSheet(false))
                              }
                            />
                            <span className="input-radio__circle"></span>
                          </span>
                        </span>
                        <span
                          style={{
                            width: "100%",
                          }}
                          className={`payment-methods__item-title ${
                            useCustomDataSheets === false
                              ? "font-weight-bold"
                              : ""
                          }`}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>Default Datasheets</span>
                            <span>FREE</span>
                          </div>
                        </span>
                      </label>
                      <div className="payment-methods__item-container">
                        <div className="payment-methods__item-details text-muted">
                          Kits will be manufactured based on the dimensions
                          found in the valve and actuator manufacturer's
                          datasheets up to 2020.
                        </div>
                      </div>
                    </li>
                    <li
                      className={`payment-methods__item ${
                        useCustomDataSheets === true
                          ? "payment-methods__item--active bg-white"
                          : ""
                      }`}
                    >
                      <label className="payment-methods__item-header">
                        <span className="payment-methods__item-radio input-radio">
                          <span className="input-radio__body">
                            <input
                              className="input-radio__input"
                              name="paymentMethod"
                              value={true}
                              type="radio"
                              checked={useCustomDataSheets === true}
                              onChange={() =>
                                dispatch(selectUseCustomDataSheet(true))
                              }
                            />
                            <span className="input-radio__circle"></span>
                          </span>
                        </span>
                        <span
                          style={{
                            width: "100%",
                          }}
                          className={`payment-methods__item-title ${
                            useCustomDataSheets === true
                              ? "font-weight-bold"
                              : ""
                          }`}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>Upload Datasheets</span>
                            <span>$20.00</span>
                          </div>
                        </span>
                      </label>
                      <div className="payment-methods__item-container">
                        <div className="payment-methods__item-details text-muted">
                          Upload your valve and/or actuator data sheets to
                          ensure the exact manufacturing dimensions.
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="product__actions">
          {/* <div className="alert alert-success alert-xs">
                        Quantity Discounts at 2, 10, and 30 or more.
                      </div> */}

          <div className="d-flex product__actions-item product__actions-item--quantity">
            <Select
              aria-label="Select Quantity"
              options={qtys}
              onChange={(value) => updateQtyHandler(value)}
              styles={summaryQtySelector}
              singleValue={`Qty: ${qty}`}
              placeholder={`Qty: ${qty}`}
              disabled={loading === true}
            />
          </div>
          <div className="product__actions-item product__actions-item--addtocart">
            <button
              type="submit"
              className="btn btn-primary btn-lg w-100"
              onClick={addToCartHandler}
              disabled={loading}
            >
              {addToCartResult.isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="ml-2"
                  />
                </>
              ) : (
                <>Add To Cart</>
              )}
            </button>
          </div>
          <div className="product__actions-divider"></div>
          {/* {cart.addToCart.error && (
            <div className="d-block alert alert-danger mt-2">{cart.addToCart.error}</div>
          )} */}
        </div>
      </div>
    </div>
  );
};
