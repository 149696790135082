import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  CAlert,
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { toast } from "react-toastify";
import {
  changeOrderStatus,
  fetchDrawing,
  fetchDrawingPreview,
  getInvoice,
  getOrderDetails,
  getShippingTicket,
  updateTrackingInfo,
} from "../../redux/actions/adminActions";
import LoadingWidget from "../../components/LoadingWidget";
import DrawingPreview from "../../components/admin/DrawingPreview";
import LinkageKitDescription from "../../components/LinkageKitDescription";
import { Formik } from "formik";
import { ADMIN_CHANGE_ORDER_STATUS_RESET } from "../../redux/constants";

const AdminOrderDetails = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const admin = useSelector((state) => state.admin);
  const { loading, success, message, error, status } = admin;

  const drawingPreview = useSelector((state) => state.selectLinkageKit.drawingPreview);

  const order = useSelector((state) => state.admin.orderDetails);

  const [showDrawingPreview, setShowDrawingPreview] = useState(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [showAddTrackingNumberModal, setShowAddTrackingNumberModal] = useState(false);

  useEffect(() => {
    dispatch(getOrderDetails(id));
  }, []);

  useEffect(() => {
    if (admin.changeOrderStatus.success) {
      dispatch(getOrderDetails(id));
      setShowChangeStatusModal(false);
      toast.success(admin.changeOrderStatus.message);
    }
  }, [admin.changeOrderStatus]);

  useEffect(() => {
    if (admin.updateShippingInfo.success) {
      dispatch(getOrderDetails(id));
      setShowAddTrackingNumberModal(false);
      toast.success(admin.updateShippingInfo.message);
    }
  }, [admin.updateShippingInfo]);

  return (
    <>
      {loading ? (
        <LoadingWidget />
      ) : (
        <>
          <CModal visible={showDrawingPreview} fullscreen>
            <CModalHeader>
              <CModalTitle>Drawing Preview</CModalTitle>
              <CButton color="close" onClick={() => setShowDrawingPreview(false)}></CButton>
            </CModalHeader>

            <CModalBody style={{ background: "#000" }} className="p-3">
              <DrawingPreview drawing={drawingPreview} />
            </CModalBody>
          </CModal>

          <CModal
            visible={showChangeStatusModal}
            onClose={() => setShowChangeStatusModal(false)}
            size="sm"
            alignment="center"
          >
            <Formik
              onSubmit={(values) => {
                dispatch(changeOrderStatus(order.orderNumber, values.status));
              }}
              initialValues={{
                status: order.status,
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                isSubmitting,
              }) => (
                <CForm noValidate onSubmit={handleSubmit}>
                  <CModalHeader>
                    <CModalTitle>Change Order Status</CModalTitle>
                  </CModalHeader>
                  <CModalBody>
                    {admin.changeOrderStatus.error && (
                      <CAlert color="danger" size="sm">
                        {admin.changeOrderStatus.error}
                      </CAlert>
                    )}
                    <CFormSelect name="status" value={values.status} onChange={handleChange}>
                      <option key="Pending" value="Pending">
                        Pending
                      </option>
                      <option key="In Production" value="In Production">
                        In Production
                      </option>
                      <option key="Awaiting Shipment" value="Awaiting Shipment">
                        Awaiting Shipment
                      </option>
                      <option key="Awaiting Pickup" value="Awaiting Pickup">
                        Awaiting Pickup
                      </option>
                      <option key="Partially Shipped" value="Partially Shipped">
                        Partially Shipped
                      </option>
                      <option key="Shipped" value="Shipped">
                        Shipped
                      </option>
                      <option key="Cancelled" value="Cancelled">
                        Cancelled
                      </option>
                      <option key="Partially Refunded" value="Partially Refunded">
                        Partially Refunded
                      </option>
                      <option key="Refunded" value="Refunded">
                        Refunded
                      </option>
                      <option key="Delivered" value="Delivered">
                        Delivered
                      </option>
                    </CFormSelect>
                  </CModalBody>
                  <CModalFooter>
                    <CButton
                      type="button"
                      color="light"
                      onClick={() => setShowChangeStatusModal(false)}
                    >
                      Cancel
                    </CButton>
                    <CButton type="submit">Submit</CButton>
                  </CModalFooter>
                </CForm>
              )}
            </Formik>
          </CModal>

          <CModal
            visible={showAddTrackingNumberModal}
            // onClose={() => setShowAddTrackingNumberModal(false)}
            size="sm"
            alignment="center"
          >
            <Formik
              onSubmit={(values) => {
                //dispatch({ type: ADMIN_CHANGE_ORDER_STATUS_RESET });
                dispatch(updateTrackingInfo(order.orderNumber, values));
              }}
              initialValues={{
                carrier:
                  order.shippingInfo && order.shippingInfo.carrier
                    ? order.shippingInfo.carrier
                    : "UPS",
                service:
                  order.shippingInfo && order.shippingInfo.service
                    ? order.shippingInfo.service
                    : "UPS Ground",
                trackingNumber:
                  order.shippingInfo && order.shippingInfo.trackingNumber
                    ? order.shippingInfo.trackingNumber
                    : "",
                trackingUrl:
                  order.shippingInfo && order.shippingInfo.trackingUrl
                    ? order.shippingInfo.trackingUrl
                    : "",
                sendEmailToCustomer: false,
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                isSubmitting,
              }) => (
                <CForm noValidate onSubmit={handleSubmit}>
                  <CModalHeader>
                    <CModalTitle>Add Tracking Info</CModalTitle>
                  </CModalHeader>
                  <CModalBody>
                    {admin.changeOrderStatus.error && (
                      <CAlert color="danger" size="sm">
                        {admin.changeOrderStatus.error}
                      </CAlert>
                    )}
                    <div className="form-group">
                      <CFormLabel>Carrier</CFormLabel>
                      <CFormSelect name="carrier" value={values.carrier} onChange={handleChange}>
                        <option key="UPS" value="UPS">
                          UPS
                        </option>
                        <option key="FedEx" value="FedEx">
                          FedEx
                        </option>
                        <option key="USPS" value="USPS">
                          USPS
                        </option>
                      </CFormSelect>
                    </div>
                    <div className="form-group">
                      <CFormLabel>Service</CFormLabel>
                      <CFormSelect name="service" value={values.service} onChange={handleChange}>
                        <option key="UPS Ground" value="UPS Ground">
                          UPS Ground
                        </option>
                      </CFormSelect>
                    </div>
                    <div className="form-group">
                      <CFormLabel>Tracking Number</CFormLabel>
                      <CFormInput
                        type="text"
                        name="trackingNumber"
                        value={values.trackingNumber}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <CFormLabel>Tracking URL</CFormLabel>
                      <CFormInput
                        type="text"
                        name="trackingUrl"
                        value={values.trackingUrl}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <CFormCheck
                        name="sendEmailToCustomer"
                        label="Email Customer"
                        onChange={handleChange}
                      />
                    </div>
                  </CModalBody>
                  <CModalFooter>
                    <CButton
                      type="button"
                      color="light"
                      onClick={() => setShowAddTrackingNumberModal(false)}
                    >
                      Cancel
                    </CButton>
                    <CButton type="submit">Submit</CButton>
                  </CModalFooter>
                </CForm>
              )}
            </Formik>
          </CModal>

          <a href="/admin/orders" className="btn btn-primary mb-3">
            <i className="fas fa-angle-left mr-2"></i>Orders
          </a>
          <CRow>
            <CCol md={8}>
              <CCard className="mb-4 p-2">
                <CCardBody>
                  <div className="d-flex">
                    <div className="mr-auto">
                      <p>
                        <span className="fs-2 mr-3">#{order.orderNumber}</span>
                        <span className="text-uppercase alert alert-primary p-2 round-0">
                          {order.status}
                        </span>
                      </p>

                      <p>
                        <span className="fs-5">
                          {new Date(order.createdAt).toLocaleDateString()}{" "}
                        </span>
                        <span className="text-secondary">
                          {new Date(order.createdAt).toLocaleTimeString("en-US")}
                        </span>
                      </p>
                    </div>
                    <div className="ml-auto">
                      <CDropdown variant="btn-group" alignment="end">
                        <CDropdownToggle color="light">Actions</CDropdownToggle>
                        <CDropdownMenu>
                          <CDropdownItem
                            component="button"
                            onClick={() => setShowChangeStatusModal(true)}
                          >
                            Change Order Status
                          </CDropdownItem>
                          <CDropdownItem
                            component="button"
                            onClick={() => dispatch(getShippingTicket(order.orderNumber))}
                          >
                            Print Shipping Ticket
                          </CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </div>
                  </div>
                  <hr />
                  <p className="fs-5">Order Details</p>
                  <CTable borderless>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell scope="col" colSpan="1">
                          Item
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="text-center">
                          Price
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="text-center">
                          Qty
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="text-right">
                          Total
                        </CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {order.orderItems.map((item) => (
                        <CTableRow>
                          <CTableDataCell>
                            <CRow>
                              <CCol>
                                <LinkageKitDescription item={item} />
                                <br />
                                <span className="small">SKU: {item.sku}</span>
                                <br />
                                <span className="small">{item.components}</span>
                                <br />
                                <span className="small">
                                  {item.lockout ? "Lockout Kit" : "Non-Lockout Kit"}
                                </span>
                                <br />
                                <span className="small">Bracket Height: {item.bracketHeight}"</span>
                              </CCol>
                              <CCol>
                                <CDropdown variant="btn-group" alignment="end" size="sm">
                                  <CButton
                                    type="button"
                                    color="light"
                                    onClick={() => {
                                      dispatch(fetchDrawingPreview(item.sku));
                                      setShowDrawingPreview(true);
                                    }}
                                  >
                                    Preview
                                  </CButton>
                                  <CDropdownToggle color="light" split />
                                  <CDropdownMenu>
                                    <CDropdownItem
                                      component="button"
                                      onClick={() => {
                                        dispatch(fetchDrawing(item.sku, "dxf"));
                                      }}
                                    >
                                      Download DXF
                                    </CDropdownItem>
                                  </CDropdownMenu>
                                </CDropdown>
                              </CCol>
                            </CRow>
                          </CTableDataCell>
                          <CTableDataCell className="text-center">${item.price}</CTableDataCell>
                          <CTableDataCell className="text-center">{item.qty}</CTableDataCell>
                          <CTableDataCell className="text-right">
                            ${Number(item.price * item.qty).toFixed(2)}
                          </CTableDataCell>
                        </CTableRow>
                      ))}
                    </CTableBody>
                  </CTable>
                  <hr />
                  <CRow className="text-right mb-2">
                    <CCol>
                      <strong>Subtotal</strong>
                    </CCol>
                    <CCol xs={2}>${order.amounts.subtotal}</CCol>
                  </CRow>
                  <CRow className="text-right mb-2">
                    <CCol>
                      <strong>Shipping</strong>
                    </CCol>
                    <CCol xs={2}>${order.amounts.shipping}</CCol>
                  </CRow>
                  <CRow className="text-right mb-2">
                    <CCol>
                      <strong>Tax</strong>
                    </CCol>
                    <CCol xs={2}>${order.amounts.tax}</CCol>
                  </CRow>
                  <CRow className="text-right mb-2">
                    <CCol>
                      <strong>Total</strong>
                    </CCol>
                    <CCol xs={2}>${order.amounts.total}</CCol>
                  </CRow>
                </CCardBody>
                <CCardBody>
                  <hr />
                  <CRow className="mb-2">
                    <p className="fs-5">Payment Details</p>
                    {order.paymentMethod === "card" ? (
                      <>
                        <CCol>
                          <span className="text-muted">Payment Method</span>
                          <br />
                          Credit/Debit Card
                          <br />
                          {`${String(
                            order.stripe.charge.payment_method_details.card.brand
                          ).toUpperCase()} ****${
                            order.stripe.charge.payment_method_details.card.last4
                          }`}
                        </CCol>
                      </>
                    ) : (
                      order.paymentMethod === "invoice" && (
                        <>
                          <CCol md={6} sm={12}>
                            <span className="text-muted">Payment Method</span>
                            <br />
                            Invoice
                          </CCol>
                          <CCol md={6} sm={12} className="ml-auto text-right">
                            <CDropdown variant="btn-group" alignment="end" size="sm">
                              <CButton
                                type="button"
                                color="light"
                                // onClick={() => {
                                // }}
                              >
                                Change Invoice Status
                              </CButton>
                              <CDropdownToggle color="light" split />
                              <CDropdownMenu>
                                <CDropdownItem
                                  component="button"
                                  onClick={() => dispatch(getInvoice(order.orderNumber))}
                                >
                                  Download Invoice
                                </CDropdownItem>
                              </CDropdownMenu>
                            </CDropdown>
                          </CCol>
                        </>
                      )
                    )}
                  </CRow>
                </CCardBody>

                <CCardBody>
                  <hr />
                  <CRow className="mb-3">
                    <p className="fs-5">Shipping Details</p>

                    {order.shippingInfo &&
                    order.shippingInfo.trackingNumber &&
                    order.shippingInfo.trackingNumber ? (
                      <>
                        <CCol md={4} sm={12}>
                          <span className="text-muted">Carrier</span>
                          <br />
                          {order.shippingInfo.carrier}
                        </CCol>
                        <CCol md={4} sm={12}>
                          <span className="text-muted">Tracking Number</span>
                          <br />
                          {order.shippingInfo.trackingNumber}
                        </CCol>
                      </>
                    ) : (
                      <>
                        <CCol md={4} sm={12}>
                          <span className="text-muted">Carrier</span>
                          <br />
                          N/A
                        </CCol>
                        <CCol md={4} sm={12}>
                          <span className="text-muted">Tracking Number</span>
                          <br />
                          N/A
                        </CCol>
                      </>
                    )}

                    <CCol md={4} sm={12} className="align-self-end text-right">
                      <CButton
                        component="button"
                        onClick={() => setShowAddTrackingNumberModal(true)}
                      >
                        {order.shippingInfo &&
                        order.shippingInfo.trackingNumber &&
                        order.shippingInfo.trackingNumber ? (
                          <>Edit Shipping Info</>
                        ) : (
                          <>Add Shipping Info</>
                        )}
                      </CButton>
                    </CCol>
                  </CRow>
                  {order.upsAccountNo && (
                    <CRow>
                      <CCol>
                        <span className="text-muted">Customer UPS Account Number</span>
                        <br />
                        {order.upsAccountNo}
                      </CCol>
                    </CRow>
                  )}
                </CCardBody>

                <CCardBody>
                  <hr />
                  <CRow>
                    <p className="fs-5">Order Info</p>

                    <CCol md={6} sm={12}>
                      <span className="text-muted">Order Notes</span>
                      <br />
                      {order.orderNotes ? order.orderNotes : "None"}
                    </CCol>
                    <CCol md={6} sm={12}>
                      <span className="text-muted">Customer PO Number</span>
                      <br />
                      {order.customerPO ? order.customerPO : "None"}
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol md={4}>
              <CCard className="mb-4  p-2">
                <CCardBody>
                  <p className="fs-5">Shipping Address</p>
                  <hr />
                  <div className="address-card__name">{`${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`}</div>
                  <div className="address-card__row">
                    {order.shippingAddress.company && (
                      <>
                        {order.shippingAddress.company} <br />
                      </>
                    )}
                    {order.shippingAddress.street2 ? (
                      <>
                        {`${order.shippingAddress.street} ${order.shippingAddress.street2}`} <br />
                      </>
                    ) : (
                      <>
                        {order.shippingAddress.street} <br />
                      </>
                    )}
                    <>
                      {`${order.shippingAddress.city}, ${order.shippingAddress.state} ${order.shippingAddress.postalCode} `}
                      <br />
                      {order.shippingAddress.country}
                    </>
                  </div>
                  <div className="address-card__row">
                    <div className="address-card__row-title">Phone Number</div>
                    <div className="address-card__row-content">{order.shippingAddress.phone}</div>
                  </div>
                </CCardBody>
              </CCard>
              <CCard className="mb-4  p-2">
                <CCardBody>
                  <p className="fs-5">Customer</p>
                  <hr />
                  <div className="address-card__name">{`${order.billingDetails.firstName} ${order.billingDetails.lastName}`}</div>
                  <div className="address-card__row">
                    {order.billingDetails.company && (
                      <>
                        {order.billingDetails.company} <br />
                      </>
                    )}
                    {order.billingDetails.street2 ? (
                      <>
                        {`${order.billingDetails.street} ${order.billingDetails.street2}`} <br />
                      </>
                    ) : (
                      <>
                        {order.billingDetails.street} <br />
                      </>
                    )}
                    <>
                      {`${order.billingDetails.city}, ${order.billingDetails.state} ${order.billingDetails.postalCode} `}
                      <br />
                      {order.billingDetails.country}
                    </>
                  </div>
                  <div className="address-card__row">
                    <div className="address-card__row-title">Phone Number</div>
                    <div className="address-card__row-content">{order.billingDetails.phone}</div>
                  </div>
                  <div className="address-card__row">
                    <div className="address-card__row-title">Email Address</div>
                    <div className="address-card__row-content">{order.billingDetails.email}</div>
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </>
      )}
    </>
  );
};

export default AdminOrderDetails;
