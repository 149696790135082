import React from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { SLKBD_VALVE_CUSTOM_NAME } from "../../redux/constants";

const ValveCustomName = () => {
  const dispatch = useDispatch();

  const valve = useSelector((state) => state.selectLinkageKitByDimensions.valve);

  return (
    <div className="slkbd w-100">
      <Form.Group>
        <Form.Label>Enter the name of your valve</Form.Label>
        <div className="d-flex">
          <Form.Control
            type="text"
            className="input"
            value={valve.customName}
            onChange={(e) =>
              dispatch({
                type: SLKBD_VALVE_CUSTOM_NAME,
                payload: e.target.value,
              })
            }
            placeholder={"Enter name of valve"}
          />
        </div>
      </Form.Group>
    </div>
  );
};

export default ValveCustomName;
