import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler, CNavItem } from "@coreui/react";

import { ReactComponent as LogoWhite32Svg } from "../../svg/logo-white-32.svg";
import { ReactComponent as LogoWhiteSquare32Svg } from "../../svg/logo-white-square-32.svg";

import SimpleBar from "simplebar-react";
import "../../../node_modules/simplebar/dist/simplebar.min.css";

// sidebar nav config
import navigation from "../../_nav";
import { ADMIN_SIDEBAR, ADMIN_SIDEBAR_UNFOLDABLE } from "../../redux/constants";
import CIcon from "@coreui/icons-react";
import { AppSidebarNav } from "./AppSidebarNav";

const AppSidebar = () => {
  const dispatch = useDispatch();
  const unfoldable = useSelector((state) => state.admin.application.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.admin.application.sidebarShow);

  return (
    <CSidebar
      position="fixed"
      selfHiding="md"
      unfoldable={unfoldable}
      show={sidebarShow}
      onHide={() => {
        dispatch({ type: ADMIN_SIDEBAR });
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <LogoWhite32Svg width="144" height="32" className="sidebar-brand-full" />
        <LogoWhiteSquare32Svg width="32" height="32" className="sidebar-brand-narrow" />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: ADMIN_SIDEBAR_UNFOLDABLE })}
      />
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
