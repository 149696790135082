import React from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { customStyles5 } from "../../styles/selectorStyles";
import {
  SLKBD_ACTUATOR_MOUNTING_PCD_UNIT,
  SLKBD_ACTUATOR_MOUNTING_PCD_VALUE,
  SLKBD_ACTUATOR_MOUNTING_BOLTS,
  SLKBD_ACTUATOR_MOUNTING_BOLTS_COUNT,
} from "../../redux/constants";
import { unitOptions } from "../../utils/helpers";

const ActuatorMountingDimensions = () => {
  const dispatch = useDispatch();

  // bring linkage kit selections in from redux state
  const actuator = useSelector((state) => state.selectLinkageKitByDimensions.actuator);

  return (
    <>
      <div className="slkbd p-4">
        <div className="d-flex flex-column">
          <Form.Group className="ml-auto">
            <div className="d-flex">
              <Form.Label>Mounting PCD</Form.Label>
              <Form.Control
                type="text"
                className="split-input"
                value={actuator.mounting.pcd.value}
                onChange={(e) =>
                  dispatch({
                    type: SLKBD_ACTUATOR_MOUNTING_PCD_VALUE,
                    payload: e.target.value,
                  })
                }
                placeholder={"Enter Mounting PCD"}
              />
              <Select
                options={unitOptions}
                isSearchable={false}
                styles={customStyles5}
                placeholder={actuator.mounting.pcd.unit}
                value={actuator.mounting.pcd.unit}
                onChange={(value) =>
                  dispatch({
                    type: SLKBD_ACTUATOR_MOUNTING_PCD_UNIT,
                    payload: value.value,
                  })
                }
              />
            </div>
          </Form.Group>

          <Form.Group className="ml-auto">
            <div className="d-flex">
              <Form.Label>Mounting Bolts</Form.Label>
              <Form.Control
                type="text"
                className="short-input"
                value={actuator.mounting.bolts}
                onChange={(e) =>
                  dispatch({
                    type: SLKBD_ACTUATOR_MOUNTING_BOLTS,
                    payload: e.target.value,
                  })
                }
                placeholder={"Enter Mounting Holes Diameter"}
              />
            </div>
          </Form.Group>

          <Form.Group className="ml-auto">
            <div className="d-flex">
              <Form.Label>Number of Mounting Bolts</Form.Label>
              <Form.Control
                type="text"
                className="short-input"
                value={actuator.mounting.boltCount}
                onChange={(e) =>
                  dispatch({
                    type: SLKBD_ACTUATOR_MOUNTING_BOLTS_COUNT,
                    payload: e.target.value,
                  })
                }
                placeholder={"Enter Number of Mounting Bolts"}
              />
            </div>
          </Form.Group>
        </div>
      </div>
    </>
  );
};

export default ActuatorMountingDimensions;
