import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Spinner } from "react-bootstrap";
import { useGetUserDetailsQuery, useResendConfirmationEmailMutation } from "../redux/api/userApi";

const VerifyAccount = (props) => {
  const dispatch = useDispatch();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [resendConfirmationEmail, resendConfirmationEmailResult] =
    useResendConfirmationEmailMutation();

  const user = useGetUserDetailsQuery();

  const handleReCaptchaVerify = async () => {
    try {
      const token = await executeRecaptcha();
      await resendConfirmationEmail({ email: user?.data?.email, reCaptchaToken: token });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {resendConfirmationEmailResult.isSuccess ? (
        <div className="alert alert-success w-100">
          <i className="fas fa-check-circle mr-2"></i>
          {resendConfirmationEmailResult?.data?.message}
        </div>
      ) : resendConfirmationEmailResult.isError ? (
        <div className="alert alert-danger w-100">
          {resendConfirmationEmailResult?.error?.data?.message}
        </div>
      ) : (
        <div className="alert alert-info">
          <i className="fas fa-info-circle mr-2"></i>
          {props.message ? props.message : "Your email address has not been verified."}{" "}
          <button
            className="btn btn-link btn-sm text-dark p-0 m-0"
            onClick={() => handleReCaptchaVerify()}
          >
            {resendConfirmationEmailResult?.isLoading ? (
              <>
                Resend Confirmation Email
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="ml-2"
                />
              </>
            ) : (
              <>Resend Confirmation Email</>
            )}
          </button>
        </div>
      )}
    </>
  );
};

export default VerifyAccount;
