import {
  SELECTED_VALVE_REQUEST,
  SELECTED_VALVE_SUCCESS,
  SELECTED_VALVE_FAIL,
  VALVE_SELECTIONS_RESET,
  SELECTED_VALVE_BRAND,
  SELECTED_VALVE_SERIES,
  SELECTED_VALVE_CLASS,
  SELECTED_VALVE_SIZE,
  SELECTED_VALVE_RESET,
  VALVE_OPTIONS_REQUEST,
  VALVE_OPTIONS_SUCCESS,
  VALVE_OPTIONS_FAIL,
  SELECTED_VALVE_OPTIONS,
} from "../constants";

import axios from "axios";

export const fetchValveOptions =
  (selectedValveBrand, selectedValveSeries, selectedValveSize) =>
  async (dispatch) => {
    try {
      dispatch({
        type: VALVE_OPTIONS_REQUEST,
      });

      const { data } = await axios.get(
        `/api/v1/valves/${encodeURIComponent(
          selectedValveBrand
        )}/${encodeURIComponent(selectedValveSeries)}/${encodeURIComponent(
          selectedValveSize
        )}/options`
      );
      dispatch({
        type: VALVE_OPTIONS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: VALVE_OPTIONS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const fetchSelectedValve =
  (
    selectedValveBrand,
    selectedValveSeries,
    selectedValveSize,
    selectedValveOptions
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SELECTED_VALVE_REQUEST,
      });

      const queryString = (options) => {
        return `?${options
          .map((entry) => {
            return `${Object.keys(entry)[0]}=${Object.values(entry)[0]}`;
          })
          .join("&")}`;
      };

      const res = await axios.get(
        `/api/v1/valves/${encodeURIComponent(
          selectedValveBrand
        )}/${encodeURIComponent(selectedValveSeries)}/${encodeURIComponent(
          selectedValveSize
        )}/details${
          selectedValveOptions.length > 0
            ? queryString(selectedValveOptions)
            : ""
        }`
      );

      dispatch({
        type: SELECTED_VALVE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        dispatch({
          type: VALVE_OPTIONS_SUCCESS,
          payload: error.response.data,
        });
      } else {
        dispatch({
          type: SELECTED_VALVE_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    }
  };

export const setSelectedValveBrand = (brand) => (dispatch) => {
  dispatch({
    type: SELECTED_VALVE_BRAND,
    payload: brand,
  });
};

export const setSelectedValveSeries = (series) => (dispatch) => {
  dispatch({
    type: SELECTED_VALVE_SERIES,
    payload: series,
  });
};

export const setSelectedValveSize = (size) => (dispatch) => {
  dispatch({
    type: SELECTED_VALVE_SIZE,
    payload: size,
  });
};

export const setSelectedValveOptions = (key, value) => (dispatch) => {
  dispatch({
    type: SELECTED_VALVE_OPTIONS,
    payload: { [key]: value },
  });
};

export const resetSelectedValve = () => (dispatch) => {
  dispatch({
    type: SELECTED_VALVE_RESET,
  });
};

export const resetValveSelections = () => (dispatch) => {
  dispatch({
    type: VALVE_SELECTIONS_RESET,
  });
};
