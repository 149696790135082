import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { getCustomerMessageDetails } from "../../redux/actions/adminActions";
import LoadingWidget from "../../components/LoadingWidget";

const AdminCustomerMessageDetails = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const admin = useSelector((state) => state.admin);
  const { loading, message, error, status } = admin;

  const customerMessages = useSelector((state) => state.admin.customerMessages);
  const { customerMessageDetails } = customerMessages;

  useEffect(() => {
    dispatch(getCustomerMessageDetails(id));
  }, []);

  return (
    <>
      {loading ? (
        <LoadingWidget />
      ) : (
        <>
          <CButton href="/admin/customer-messages" color="primary" className="mb-3">
            <i className="fas fa-angle-left mr-2"></i>Customer Messages
          </CButton>
          <CRow>
            <CCol md={8}>
              <CCard className="mb-4 p-2">
                <CCardBody>
                  <p>
                    Sent on {new Date(customerMessageDetails.createdAt).toLocaleDateString([])} at{" "}
                    {new Date(customerMessageDetails.createdAt).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </p>
                  <hr />
                  <CTable>
                    <CTableBody>
                      <CTableRow>
                        <CTableHeaderCell scope="row">Name</CTableHeaderCell>
                        <CTableDataCell>{customerMessageDetails.name}</CTableDataCell>
                      </CTableRow>
                      {customerMessageDetails.company && (
                        <CTableRow>
                          <CTableHeaderCell scope="row">Company</CTableHeaderCell>
                          <CTableDataCell>{customerMessageDetails.company}</CTableDataCell>
                        </CTableRow>
                      )}
                      <CTableRow>
                        <CTableHeaderCell scope="row">Email</CTableHeaderCell>
                        <CTableDataCell>{customerMessageDetails.email}</CTableDataCell>
                      </CTableRow>
                      {customerMessageDetails.phone && (
                        <CTableRow>
                          <CTableHeaderCell scope="row">Phone</CTableHeaderCell>
                          <CTableDataCell>{customerMessageDetails.phone}</CTableDataCell>
                        </CTableRow>
                      )}
                      <CTableRow>
                        <CTableHeaderCell scope="row">Subject</CTableHeaderCell>
                        <CTableDataCell>{customerMessageDetails.subject}</CTableDataCell>
                      </CTableRow>
                      <CTableRow>
                        <CTableHeaderCell scope="row">Message</CTableHeaderCell>
                        <CTableDataCell>{customerMessageDetails.message}</CTableDataCell>
                      </CTableRow>
                    </CTableBody>
                  </CTable>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </>
      )}
    </>
  );
};

export default AdminCustomerMessageDetails;
