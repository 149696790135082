import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import {
  SLKBD_SELECT_VALVE_MOUNTING_HOLES_COUNT,
  SLKBD_SELECT_VALVE_MOUNTING_HOLES_DIA_UNIT,
  SLKBD_SELECT_VALVE_MOUNTING_HOLES_DIA_VALUE,
  SLKBD_SELECT_VALVE_MOUNTING_PCD_UNIT,
  SLKBD_SELECT_VALVE_MOUNTING_PCD_VALUE,
  SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_X_UNIT,
  SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_X_VALUE,
  SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_Y_UNIT,
  SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_Y_VALUE,
} from "../../redux/constants";
import { customStyles5 } from "../../styles/selectorStyles";
import { unitOptions } from "../../utils/helpers";

const ValveMountingDimensions = () => {
  const dispatch = useDispatch();

  // bring linkage kit selections in from redux state
  const selectLinkageKitByDimensions = useSelector((state) => state.selectLinkageKitByDimensions);
  const { valve } = selectLinkageKitByDimensions;

  return (
    <div className="slkbd p-4 w-100">
      {valve.mounting.type === "boltCircle" ? (
        <>
          <div className="d-flex flex-column">
            <Form.Group className="ml-auto">
              <div className="d-flex">
                <Form.Label>Mounting PCD</Form.Label>
                <Form.Control
                  type="text"
                  className="split-input"
                  value={valve.mounting.pcd.value}
                  onChange={(e) =>
                    dispatch({
                      type: SLKBD_SELECT_VALVE_MOUNTING_PCD_VALUE,
                      payload: e.target.value,
                    })
                  }
                  placeholder={"Enter Mounting PCD"}
                />
                <Select
                  options={unitOptions}
                  isSearchable={false}
                  styles={customStyles5}
                  placeholder={valve.mounting.pcd.unit}
                  value={valve.mounting.pcd.unit}
                  onChange={(value) =>
                    dispatch({
                      type: SLKBD_SELECT_VALVE_MOUNTING_PCD_UNIT,
                      payload: value.value,
                    })
                  }
                />
              </div>
            </Form.Group>

            <Form.Group className="ml-auto">
              <div className="d-flex">
                <Form.Label>Mounting Holes Diameter</Form.Label>
                <Form.Control
                  type="text"
                  className="split-input"
                  value={valve.mounting.holes.diameter.value}
                  onChange={(e) =>
                    dispatch({
                      type: SLKBD_SELECT_VALVE_MOUNTING_HOLES_DIA_VALUE,
                      payload: e.target.value,
                    })
                  }
                  placeholder={"Enter Mounting Holes Diameter"}
                />
                <Select
                  options={unitOptions}
                  isSearchable={false}
                  styles={customStyles5}
                  placeholder={valve.mounting.holes.diameter.unit}
                  value={valve.mounting.holes.diameter.unit}
                  onChange={(value) =>
                    dispatch({
                      type: SLKBD_SELECT_VALVE_MOUNTING_HOLES_DIA_UNIT,
                      payload: value.value,
                    })
                  }
                />
              </div>
            </Form.Group>

            <Form.Group className="ml-auto">
              <div className="d-flex">
                <Form.Label>Number of Mounting Holes</Form.Label>
                <Form.Control
                  type="text"
                  className="short-input"
                  value={valve.mounting.holes.count}
                  onChange={(e) =>
                    dispatch({
                      type: SLKBD_SELECT_VALVE_MOUNTING_HOLES_COUNT,
                      payload: e.target.value,
                    })
                  }
                  placeholder={"Enter Number of Mounting Holes"}
                />
              </div>
            </Form.Group>
          </div>
        </>
      ) : valve.mounting.type === "rectangle" ? (
        <div className="d-flex flex-column">
          <Form.Group className="ml-auto">
            <div className="d-flex">
              <Form.Label>Mounting X</Form.Label>
              <Form.Control
                type="text"
                className="split-input"
                value={valve.mounting.rectangle.x.value}
                onChange={(e) =>
                  dispatch({
                    type: SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_X_VALUE,
                    payload: e.target.value,
                  })
                }
                placeholder={"Enter Mounting PCD"}
              />
              <Select
                options={unitOptions}
                isSearchable={false}
                styles={customStyles5}
                placeholder={valve.mounting.rectangle.x.unit}
                value={valve.mounting.rectangle.x.unit}
                onChange={(value) =>
                  dispatch({
                    type: SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_X_UNIT,
                    payload: value.value,
                  })
                }
              />
            </div>
          </Form.Group>

          <Form.Group className="ml-auto">
            <div className="d-flex">
              <Form.Label>Mounting Y</Form.Label>
              <Form.Control
                type="text"
                className="split-input"
                value={valve.mounting.rectangle.y.value}
                onChange={(e) =>
                  dispatch({
                    type: SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_Y_VALUE,
                    payload: e.target.value,
                  })
                }
                placeholder={"Enter Mounting PCD"}
              />
              <Select
                options={unitOptions}
                isSearchable={false}
                styles={customStyles5}
                placeholder={valve.mounting.rectangle.y.unit}
                value={valve.mounting.rectangle.y.unit}
                onChange={(value) =>
                  dispatch({
                    type: SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_Y_UNIT,
                    payload: value.value,
                  })
                }
              />
            </div>
          </Form.Group>

          <Form.Group className="ml-auto">
            <div className="d-flex">
              <Form.Label>Mounting Holes Diameter</Form.Label>
              <Form.Control
                type="text"
                className="split-input"
                value={valve.mounting.holes.diameter.value}
                onChange={(e) =>
                  dispatch({
                    type: SLKBD_SELECT_VALVE_MOUNTING_HOLES_DIA_VALUE,
                    payload: e.target.value,
                  })
                }
                placeholder={"Enter Mounting Holes Diameter"}
              />
              <Select
                options={unitOptions}
                isSearchable={false}
                styles={customStyles5}
                placeholder={valve.mounting.holes.diameter.unit}
                value={valve.mounting.holes.diameter.unit}
                onChange={(value) =>
                  dispatch({
                    type: SLKBD_SELECT_VALVE_MOUNTING_HOLES_DIA_UNIT,
                    payload: value.value,
                  })
                }
              />
            </div>
          </Form.Group>

          <Form.Group className="ml-auto">
            <div className="d-flex">
              <Form.Label>Number of Mounting Holes</Form.Label>
              <Form.Control
                type="text"
                className="short-input"
                value={valve.mounting.holes.count}
                onChange={(e) =>
                  dispatch({
                    type: SLKBD_SELECT_VALVE_MOUNTING_HOLES_COUNT,
                    payload: e.target.value,
                  })
                }
                placeholder={"Enter Number of Mounting Holes"}
              />
            </div>
          </Form.Group>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ValveMountingDimensions;
