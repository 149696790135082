import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingWidget from "../../components/LoadingWidget";
import * as Antd from "antd";

import AccountNavigation from "../../components/account/AccountNavigation";

import BillingAddressCard from "../../components/account/BillingAddressCard";
import ShippingAddressCard from "../../components/account/ShippingAddressCard";

import { numberWithCommas } from "../../utils/helpers";

import { isEmpty } from "lodash";
import { Helmet } from "react-helmet";
import { variables } from "../../config";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import LinkageKitDescription from "../../components/LinkageKitDescription";
import {
  useGetOrderDetailsQuery,
  useReOrderItemMutation,
} from "../../redux/api/orderApi";
import {
  useAddToCartMutation,
  useGetUserCartQuery,
} from "../../redux/api/cartApi";
import { setCart } from "../../redux/slices/cart";
import { CART_DROPDOWN_OPEN } from "../../redux/constants";
import { CheckCircleFilled, PaperClipOutlined } from "@ant-design/icons";

const AccountOrderDetailsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const [reOrderItem, reOrderItemResult] = useReOrderItemMutation();

  const order = useGetOrderDetailsQuery(id, { skip: !id });

  const [clickedReorderButton, setClickedReorderButton] = useState(null);

  const reOrderHandler = async (e, item) => {
    e.preventDefault();

    try {
      const { data } = await axios.get("/api/v1/auth");

      if (data?.isAuthenticated === true) {
        setClickedReorderButton(item._id);

        reOrderItem({ order: order?.data?._id, item: item._id })
          .unwrap()
          .then((data) => {
            dispatch(setCart(data?.cart));
            dispatch({
              type: CART_DROPDOWN_OPEN,
            });
          });
      } else if (data?.isAuthenticated === false) {
        // dispatch(softLogout());

        navigate(
          `/login?redirect=${window.location.pathname}&msg=session-timeout`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (order.isError && order?.error?.status === 404) {
      navigate("/account/order-history");
    }
  }, [order]);

  return (
    <>
      <Helmet>
        <title>Account | {variables.WEBSITE_NAME}</title>
        <link
          rel="canonical"
          href={`${variables.FULL_URL}/account/order-details`}
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {order.isLoading ? (
        <LoadingWidget />
      ) : order.isSuccess ? (
        <>
          <div className="block-space block-space--layout--after-header"></div>
          <div className="block">
            <div className="container container--max--xl">
              <div className="row">
                <div className="col-12 col-lg-3">
                  <AccountNavigation active="orderDetails" />
                </div>
                <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                  <div className="card">
                    <div className="order-header">
                      <div className="order-header__actions">
                        <Link
                          to="/account/order-history"
                          className="btn btn-xs btn-secondary"
                        >
                          Back to list
                        </Link>
                      </div>
                      <h5 className="mb-0">Order Details</h5>
                    </div>
                    <div className="card-divider"></div>
                    <div class="order-success__meta mb-0">
                      <ul class="order-success__meta-list">
                        <li class="order-success__meta-item">
                          <span class="order-success__meta-title">
                            Order number:
                          </span>
                          <span class="order-success__meta-value">
                            #{order?.data?.orderNumber}
                          </span>
                        </li>
                        <li class="order-success__meta-item">
                          <span class="order-success__meta-title">
                            Order Placed:
                          </span>
                          <span class="order-success__meta-value">
                            {new Date(
                              order?.data?.createdAt
                            ).toLocaleDateString([])}{" "}
                            {new Date(
                              order?.data?.createdAt
                            ).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </span>
                        </li>
                        <li class="order-success__meta-item">
                          <span class="order-success__meta-title">
                            Order Status:
                          </span>
                          <span class="order-success__meta-value">
                            {order?.data?.status}
                          </span>
                        </li>
                        <li class="order-success__meta-item">
                          <span class="order-success__meta-title">
                            Payment Method:
                          </span>
                          <span class="order-success__meta-value">
                            {order?.data?.paymentMethod === "invoice" && (
                              <>Invoice</>
                            )}
                            {order?.data?.paymentMethod === "card" && (
                              <>{`${String(
                                order?.data?.creditCardPayment?.brand
                              ).toUpperCase()} ****${String(
                                order?.data?.creditCardPayment?.last4
                              )}`}</>
                            )}
                          </span>
                        </li>
                        {order?.data?.customerPO && (
                          <li class="order-success__meta-item">
                            <span class="order-success__meta-title">
                              Customer PO:
                            </span>
                            <span class="order-success__meta-value">
                              {order?.data?.customerPO}
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="card-divider"></div>
                    <div className="card-table">
                      <div className="table-responsive-sm">
                        <table>
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Price</th>
                              <th className="text-center">Qty</th>
                              <th className="text-right">Total</th>
                            </tr>
                          </thead>
                          <tbody className="card-table__body">
                            {order?.data?.items.map((item) => (
                              <tr key={item.sku}>
                                <td>
                                  {item.product.productType ===
                                    "mountingKit" && (
                                    <strong>
                                      Stainless Steel Mounting Kit
                                      <br />
                                    </strong>
                                  )}
                                  <Link
                                    className="d-block text-dark pt-2"
                                    to={`/build?sku=${item.product.sku}`}
                                  >
                                    <h6>
                                      <LinkageKitDescription
                                        item={item.product}
                                      />
                                    </h6>
                                  </Link>
                                  <div className="mb-3">
                                    <span className="text-muted order-success__meta-value py-1">
                                      SKU: {item.product.sku}
                                    </span>
                                    <span className="text-muted">
                                      {item.product.components} /{" "}
                                      {item.product.bracketHeight}" Bracket
                                      Height /{" "}
                                      {item.product.lockout ? (
                                        <>Lockout Kit</>
                                      ) : (
                                        <>Non-Lockout Kit</>
                                      )}
                                    </span>
                                  </div>
                                  {item.useCustomDataSheets === true ? (
                                    <div className="mb-3">
                                      {item.customValveDataSheet ? (
                                        <>
                                          <div>
                                            <div className="font-weight-bold mb-1">
                                              <span className="mr-2">
                                                Customer-Supplied Valve
                                                Datasheet
                                              </span>
                                            </div>
                                            <div>
                                              <Antd.Typography.Paragraph
                                                ellipsis={{ rows: 1 }}
                                              >
                                                <a
                                                  href={
                                                    item.customValveDataSheet
                                                      ?.url
                                                  }
                                                  target="_blank"
                                                  rel="noreferrer"
                                                  className="link"
                                                >
                                                  <PaperClipOutlined />{" "}
                                                  {
                                                    item.customValveDataSheet
                                                      ?.name
                                                  }
                                                </a>
                                              </Antd.Typography.Paragraph>
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                      {item.customActuatorDataSheet ? (
                                        <>
                                          <div>
                                            <div className="font-weight-bold mb-1">
                                              <span className="mr-2">
                                                Customer-Supplied Actuator
                                                Datasheet
                                              </span>
                                            </div>
                                          </div>
                                          <div>
                                            <Antd.Typography.Paragraph
                                              ellipsis={{ rows: 1 }}
                                            >
                                              <a
                                                href={
                                                  item.customActuatorDataSheet
                                                    ?.url
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                                className="link"
                                              >
                                                <PaperClipOutlined />{" "}
                                                {
                                                  item.customActuatorDataSheet
                                                    ?.name
                                                }
                                              </a>
                                            </Antd.Typography.Paragraph>
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                  ) : null}

                                  <Antd.Button
                                    id={item.product.sku}
                                    onClick={(e) => {
                                      reOrderHandler(e, item);
                                    }}
                                    loading={
                                      reOrderItemResult.isLoading &&
                                      clickedReorderButton === item._id
                                    }
                                  >
                                    Order Again
                                  </Antd.Button>
                                  {reOrderItemResult.isSuccess &&
                                  clickedReorderButton === item._id ? (
                                    <span className="text-success ml-2">
                                      <CheckCircleFilled /> Added to Cart
                                    </span>
                                  ) : null}
                                </td>
                                <td>${Number(item.price).toFixed(2)}</td>
                                <td className="text-center">{item.quantity}</td>
                                <td className="text-right">
                                  $
                                  {numberWithCommas(
                                    (
                                      Number(item.price) * Number(item.quantity)
                                    ).toFixed(2)
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tbody className="card-table__body card-table__body--merge-rows">
                            <tr>
                              <th colSpan="3">Subtotal</th>
                              <td className="text-right">
                                ${numberWithCommas(order?.data?.subtotal)}
                              </td>
                            </tr>
                            {order?.data?.discounts &&
                            order?.data?.preferredCustomerDiscount?.discount ? (
                              <tr>
                                <th colSpan="3">Preferred Customer Discount</th>
                                <td className="text-right">
                                  -$
                                  {numberWithCommas(
                                    order?.data?.preferredCustomerDiscount
                                      .discount
                                  )}
                                </td>
                              </tr>
                            ) : null}
                            {order?.data?.coupon?.coupon ? (
                              <tr>
                                <th colSpan="3">Coupon</th>
                                <td className="text-right">
                                  -$
                                  {numberWithCommas(
                                    order?.data?.coupon.discount
                                  )}
                                </td>
                              </tr>
                            ) : null}
                            <tr>
                              <th colSpan="3">Shipping</th>
                              <td className="text-right">
                                {order?.data?.shipping === 0 ? (
                                  <>Free</>
                                ) : (
                                  <>
                                    ${numberWithCommas(order?.data?.shipping)}
                                  </>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="3">Tax</th>
                              <td className="text-right">
                                ${numberWithCommas(order?.data?.tax)}
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <th colSpan="3">Total</th>
                              <td className="text-right">
                                ${numberWithCommas(order?.data?.total)}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3 no-gutters mx-n2">
                    <div className="col-12 px-2">
                      <div className="card">
                        <div className="order-header pl-4">
                          <h5 className="mb-0">Shipping Details</h5>
                        </div>
                        <div className="card-divider"></div>
                        <div className="card-body d-flex pb-0 align-items-start">
                          <div className="mr-5 pb-4">
                            <span class="order-success__meta-title">
                              Shipping Method:
                            </span>
                            <span className="order-success__meta-value">
                              {order?.data?.shippingMethod === "upsAccount"
                                ? "UPS Account"
                                : order?.data?.shippingMethod === "flatRate"
                                ? "Flat Rate"
                                : order?.data?.shippingMethod === "localPickup"
                                ? "Local Pickup"
                                : null}
                            </span>
                          </div>

                          {order?.data?.shippingMethod === "upsAccount" ? (
                            <>
                              <div className="mr-5 pb-4">
                                <span className="order-success__meta-title">
                                  UPS Account Number:
                                </span>
                                <span className="order-success__meta-value">
                                  {order?.data?.upsAccountNo}
                                </span>
                              </div>

                              <div className="mr-5 pb-4">
                                <span className="order-success__meta-title">
                                  Shipping Service:
                                </span>
                                <span className="order-success__meta-value">
                                  {order?.data?.shippingMethod === "upsAccount"
                                    ? order?.data?.shippingService ===
                                      "upsGround"
                                      ? "UPS Ground"
                                      : order?.data?.shippingService ===
                                        "ups3DaySelect"
                                      ? "UPS 3 Day Select"
                                      : order?.data?.shippingService ===
                                          "upsNextDayAir" && "UPS Next Day Air"
                                    : order?.data?.shippingMethod ===
                                        "flatRate" && "UPS Ground"}
                                </span>
                              </div>
                            </>
                          ) : null}

                          {order?.data?.shippingMethod !== "localPickup" ? (
                            <div className="mr-5 pb-4">
                              <span className="order-success__meta-title">
                                Tracking Number:
                              </span>
                              {isEmpty(order?.data?.shippingInfo) ? (
                                <span
                                  className="order-success__meta-value"
                                  style={{
                                    fontWeight: "300",
                                    fontStyle: "italic",
                                    fontSize: "14px",
                                  }}
                                >
                                  Tracking Number not yet available.
                                </span>
                              ) : (
                                <span className="order-success__meta-value">
                                  {order?.data?.shippingInfo.trackingNumber}
                                </span>
                              )}
                            </div>
                          ) : order?.data?.shippingMethod === "localPickup" ? (
                            <div div className="mr-5">
                              <span className="order-success__meta-title">
                                Location for Pickup:
                              </span>
                              <span className="order-success__meta-value">
                                <address>
                                  250 Industrial Dr. Hampshire, IL 60140
                                </address>
                              </span>
                            </div>
                          ) : null}
                        </div>
                        {order?.data?.shippingMethod === "localPickup" ? (
                          <div className="p-3">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2960.2508577463836!2d-88.52295852321276!3d42.102097951358836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880f3cfc3561939b%3A0x996bcd4870d9c0e3!2s250%20Industrial%20Dr%2C%20Hampshire%2C%20IL%2060140!5e0!3m2!1sen!2sus!4v1692920125866!5m2!1sen!2sus"
                              width="100%"
                              height="450"
                              style={{ border: "none" }}
                              allowfullscreen=""
                              loading="lazy"
                              referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3 no-gutters mx-n2">
                    <div className="col-sm-6 col-12 px-2">
                      <BillingAddressCard
                        billingDetails={order?.data?.billingDetails}
                      />
                    </div>
                    <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
                      <ShippingAddressCard
                        shippingAddress={order?.data?.shippingAddress}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-space block-space--layout--before-footer"></div>
        </>
      ) : null}
    </>
  );
};

export default AccountOrderDetailsScreen;
