import { api } from "../slices/api";

// Define a service using a base URL and expected endpoints
export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getCartByToken: builder.query({
      query: ({ id }) => `v1/cart/${id}`,
    }),
    getUserCart: builder.query({
      query: () => "v1/cart",
    }),
    createNewCart: builder.mutation({
      query: () => ({
        url: "v1/cart",
        method: "POST",
      }),
    }),
    updateCart: builder.mutation({
      query: ({ id, body }) => ({
        url: `v1/cart/${id}`,
        method: "PATCH",
        body: body,
      }),
    }),
    mergeCart: builder.mutation({
      query: (values) => ({
        url: "v1/cart/merge",
        method: "POST",
        body: values,
      }),
    }),
    addToCart: builder.mutation({
      query: ({ id, item }) => ({
        url: `v1/cart/${id}/add`,
        method: "POST",
        body: { item: item },
      }),
    }),
    removeFromCart: builder.mutation({
      query: ({ cartId, cartItemId }) => ({
        url: `v1/cart/${cartId}/item/${cartItemId}/remove`,
        method: "POST",
      }),
    }),
    updateCartItemQty: builder.mutation({
      query: ({ cartId, cartItemId, qty }) => ({
        url: `v1/cart/${cartId}/item/${cartItemId}/update`,
        method: "POST",
        body: { qty: qty },
      }),
    }),
    addCustomDataSheetToCartItem: builder.mutation({
      query: ({ cartId, cartItemId, body, type }) => ({
        url: `v1/cart/${cartId}/item/${cartItemId}/datasheet?type=${type}`,
        method: "POST",
        body: body,
      }),
    }),
    removeCustomDataSheetFromCartItem: builder.mutation({
      query: ({ cartId, cartItemId, type }) => ({
        url: `v1/cart/${cartId}/item/${cartItemId}/datasheet?type=${type}`,
        method: "DELETE",
      }),
    }),
    moveToSaveItems: builder.mutation({
      query: ({ cartId, cartItemId }) => ({
        url: `v1/cart/${cartId}/item/${cartItemId}/move-to-saved-items`,
        method: "POST",
      }),
    }),
    addCoupon: builder.mutation({
      query: ({ id, couponCode }) => ({
        url: `v1/cart/${id}/coupon/add`,
        method: "POST",
        body: { couponCode: couponCode },
      }),
    }),
    removeCoupon: builder.mutation({
      query: ({ id }) => ({
        url: `v1/cart/${id}/coupon/remove`,
        method: "POST",
      }),
    }),
    getTaxRates: builder.query({
      query: () => "v1/taxRates",
    }),
    calculateShippingCost: builder.mutation({
      query: (values) => ({
        url: "v1/calculate/shipping",
        method: "POST",
        body: values,
      }),
    }),
    sumbmitOrder: builder.mutation({
      query: ({ id, body }) => ({
        url: `v1/cart/${id}/submit`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetCartByTokenQuery,
  useCreateNewCartMutation,
  useGetUserCartQuery,
  useUpdateCartMutation,
  useAddCustomDataSheetToCartItemMutation,
  useRemoveCustomDataSheetFromCartItemMutation,
  useMergeCartMutation,
  useAddToCartMutation,
  useRemoveFromCartMutation,
  useUpdateCartItemQtyMutation,
  useMoveToSaveItemsMutation,
  useAddCouponMutation,
  useRemoveCouponMutation,
  useGetTaxRatesQuery,
  useCalculateShippingCostMutation,
  useSumbmitOrderMutation,
} = extendedApiSlice;
