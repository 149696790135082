import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import App from "./App";

// coreui
import { icons } from "./assets/icons";

import { Provider } from "react-redux";
import store from "./redux/store.js";
import { BrowserRouter } from "react-router-dom";

console.log(process.env.REACT_APP_RECAPTCHA_SITE_KEY);

// coreui
React.icons = icons;

ReactDOM.render(
  <Provider store={store}>
    <GoogleReCaptchaProvider
      reCaptchaKey="6LeDlXYgAAAAALjso4m5sJWFlXSDZURj0Y9iL_dk"
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: "head", // optional, default to "head", can be "head" or "body",
        nonce: undefined, // optional, default undefined
      }}
    >
      <BrowserRouter>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "Roboto",
              borderRadius: "2px",
            },
          }}
        >
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </GoogleReCaptchaProvider>
  </Provider>,
  document.getElementById("root")
);
