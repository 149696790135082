import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { Form } from "react-bootstrap";
import {
  SLKBD_SELECT_VALVE_STEM_ACROSS_FLATS_UNIT,
  SLKBD_SELECT_VALVE_STEM_ACROSS_FLATS_VALUE,
  SLKBD_SELECT_VALVE_STEM_DIAMETER_UNIT,
  SLKBD_SELECT_VALVE_STEM_DIAMETER_VALUE,
  SLKBD_SELECT_VALVE_STEM_FLATS_HEIGHT_UNIT,
  SLKBD_SELECT_VALVE_STEM_FLATS_HEIGHT_VALUE,
  SLKBD_SELECT_VALVE_STEM_HEIGHT_UNIT,
  SLKBD_SELECT_VALVE_STEM_HEIGHT_VALUE,
  SLKBD_SELECT_VALVE_STEM_KEY_HEIGHT_UNIT,
  SLKBD_SELECT_VALVE_STEM_KEY_HEIGHT_VALUE,
  SLKBD_SELECT_VALVE_STEM_KEY_LENGTH_UNIT,
  SLKBD_SELECT_VALVE_STEM_KEY_LENGTH_VALUE,
  SLKBD_SELECT_VALVE_STEM_KEY_WIDTH_UNIT,
  SLKBD_SELECT_VALVE_STEM_KEY_WIDTH_VALUE,
  SLKBD_SELECT_VALVE_STEM_SQUARE_UNIT,
  SLKBD_SELECT_VALVE_STEM_SQUARE_VALUE,
} from "../../redux/constants";
import { unitOptions } from "../../utils/helpers";
import { customStyles5 } from "../../styles/selectorStyles";

import { CustomLinkageKitSchema } from "../../validation";
import { Formik } from "formik";

const ValveStemDimensions = () => {
  const dispatch = useDispatch();

  const valve = useSelector((state) => state.selectLinkageKitByDimensions.valve);

  //   useEffect(() => {
  //     CustomLinkageKitSchema.validate(valve)
  //       .then(function (value) {
  //         console.log(value);
  //       })
  //       .catch(function (err) {
  //         console.log(err);
  //         setError(err);
  //       });
  //   }, [valve.stem.diameter]);

  return (
    // <Formik
    //   validationSchema={CustomLinkageKitSchema}
    //   onSubmit={{}}
    //   initialValues={
    //     {
    //       // email: "",
    //       // password: "",
    //     }
    //   }
    // >
    //   {({
    //     handleSubmit,
    //     handleChange,
    //     handleBlur,
    //     values,
    //     touched,
    //     isValid,
    //     errors,
    //     isSubmitting,
    //   }) => (
    <>
      <div className="col-md-7">
        <img
          src={`/stem-${valve.stem.type}.png`}
          className="noselect"
          style={{
            pointerEvents: "none",
            margin: "0 auto",
            width: "100%",
            maxWidth: "300px",
            display: "block",
          }}
          draggable="false"
        />
      </div>
      <div className="col-md-5 justify-content-center slkbd">
        <div className="d-flex flex-column ml-auto">
          {valve.stem.type === "doubleD" ? (
            <>
              <Form.Group className="ml-auto">
                <div className="d-flex">
                  <Form.Label>Valve Stem Diameter</Form.Label>
                  <Form.Control
                    type="text"
                    className="split-input"
                    value={valve.stem.diameter.value}
                    onChange={(e) =>
                      dispatch({
                        type: SLKBD_SELECT_VALVE_STEM_DIAMETER_VALUE,
                        payload: e.target.value,
                      })
                    }
                  />
                  <Select
                    options={unitOptions}
                    isSearchable={false}
                    styles={customStyles5}
                    placeholder={valve.stem.diameter.unit}
                    value={valve.stem.diameter.unit}
                    onChange={(value) =>
                      dispatch({
                        type: SLKBD_SELECT_VALVE_STEM_DIAMETER_UNIT,
                        payload: value.value,
                      })
                    }
                  />
                </div>
              </Form.Group>

              <Form.Group className="ml-auto">
                <div className="d-flex">
                  <Form.Label>Stem Height</Form.Label>
                  <Form.Control
                    type="text"
                    value={valve.stem.height.value}
                    className="split-input"
                    onChange={(e) =>
                      dispatch({
                        type: SLKBD_SELECT_VALVE_STEM_HEIGHT_VALUE,
                        payload: e.target.value,
                      })
                    }
                  />
                  <Select
                    options={unitOptions}
                    isSearchable={false}
                    styles={customStyles5}
                    placeholder={valve.stem.height.unit}
                    value={valve.stem.height.unit}
                    onChange={(value) =>
                      dispatch({
                        type: SLKBD_SELECT_VALVE_STEM_HEIGHT_UNIT,
                        payload: value.value,
                      })
                    }
                  />
                </div>
              </Form.Group>

              <Form.Group className="ml-auto">
                <div className="d-flex">
                  <Form.Label>Across Flats</Form.Label>
                  <Form.Control
                    type="text"
                    value={valve.stem.flats.across.value}
                    className="split-input"
                    onChange={(e) =>
                      dispatch({
                        type: SLKBD_SELECT_VALVE_STEM_ACROSS_FLATS_VALUE,
                        payload: e.target.value,
                      })
                    }
                  />
                  <Select
                    options={unitOptions}
                    isSearchable={false}
                    styles={customStyles5}
                    placeholder={valve.stem.flats.across.unit}
                    value={valve.stem.flats.across.unit}
                    onChange={(value) =>
                      dispatch({
                        type: SLKBD_SELECT_VALVE_STEM_ACROSS_FLATS_UNIT,
                        payload: value.value,
                      })
                    }
                  />
                </div>
              </Form.Group>

              <Form.Group className="ml-auto">
                <div className="d-flex">
                  <Form.Label>Height of Flats</Form.Label>
                  <Form.Control
                    type="text"
                    className="split-input"
                    value={valve.stem.flats.height.value}
                    onChange={(e) =>
                      dispatch({
                        type: SLKBD_SELECT_VALVE_STEM_FLATS_HEIGHT_VALUE,
                        payload: e.target.value,
                      })
                    }
                  />
                  <Select
                    options={unitOptions}
                    isSearchable={false}
                    styles={customStyles5}
                    placeholder={valve.stem.flats.height.unit}
                    value={valve.stem.flats.height.unit}
                    onChange={(value) =>
                      dispatch({
                        type: SLKBD_SELECT_VALVE_STEM_FLATS_HEIGHT_UNIT,
                        payload: value.value,
                      })
                    }
                  />
                </div>
              </Form.Group>
            </>
          ) : valve.stem.type === "square" ? (
            <>
              <Form.Group className="ml-auto">
                <div className="d-flex">
                  <Form.Label>Square</Form.Label>
                  <Form.Control
                    type="text"
                    className="split-input"
                    value={valve.stem.square.value}
                    onChange={(e) =>
                      dispatch({
                        type: SLKBD_SELECT_VALVE_STEM_SQUARE_VALUE,
                        payload: e.target.value,
                      })
                    }
                  />
                  <Select
                    options={unitOptions}
                    isSearchable={false}
                    styles={customStyles5}
                    placeholder={valve.stem.square.unit}
                    value={valve.stem.square.unit}
                    onChange={(value) =>
                      dispatch({
                        type: SLKBD_SELECT_VALVE_STEM_ACROSS_FLATS_UNIT,
                        payload: value.value,
                      })
                    }
                  />
                </div>
              </Form.Group>

              <Form.Group className="ml-auto">
                <div className="d-flex">
                  <Form.Label>Stem Height</Form.Label>
                  <Form.Control
                    type="text"
                    className="split-input"
                    placeholder={valve.stem.height.value}
                    value={valve.stem.height.value}
                    onChange={(e) =>
                      dispatch({
                        type: SLKBD_SELECT_VALVE_STEM_HEIGHT_VALUE,
                        payload: e.target.value,
                      })
                    }
                  />
                  <Select
                    options={unitOptions}
                    isSearchable={false}
                    styles={customStyles5}
                    placeholder={valve.stem.height.unit}
                    value={valve.stem.height.unit}
                    onChange={(value) =>
                      dispatch({
                        type: SLKBD_SELECT_VALVE_STEM_HEIGHT_UNIT,
                        payload: value.value,
                      })
                    }
                  />
                </div>
              </Form.Group>

              <Form.Group className="ml-auto">
                <div className="d-flex">
                  <Form.Label>Height of Flats</Form.Label>
                  <Form.Control
                    type="text"
                    className="split-input"
                    value={valve.stem.flats.height.value}
                    onChange={(e) =>
                      dispatch({
                        type: SLKBD_SELECT_VALVE_STEM_FLATS_HEIGHT_VALUE,
                        payload: e.target.value,
                      })
                    }
                  />
                  <Select
                    options={unitOptions}
                    isSearchable={false}
                    styles={customStyles5}
                    placeholder={valve.stem.flats.height.unit}
                    value={valve.stem.flats.height.unit}
                    onChange={(value) =>
                      dispatch({
                        type: SLKBD_SELECT_VALVE_STEM_FLATS_HEIGHT_UNIT,
                        payload: value.value,
                      })
                    }
                  />
                </div>
              </Form.Group>
            </>
          ) : (
            valve.stem.type === "roundWithKeyway" && (
              <>
                <Form.Group className="ml-auto">
                  <div className="d-flex">
                    <Form.Label>Stem Diameter</Form.Label>
                    <Form.Control
                      type="text"
                      className="split-input"
                      value={valve.stem.diameter.value}
                      onChange={(e) =>
                        dispatch({
                          type: SLKBD_SELECT_VALVE_STEM_DIAMETER_VALUE,
                          payload: e.target.value,
                        })
                      }
                    />
                    <Select
                      options={unitOptions}
                      isSearchable={false}
                      styles={customStyles5}
                      placeholder={valve.stem.diameter.unit}
                      value={valve.stem.diameter.unit}
                      onChange={(value) =>
                        dispatch({
                          type: SLKBD_SELECT_VALVE_STEM_DIAMETER_UNIT,
                          payload: value.value,
                        })
                      }
                    />
                  </div>
                </Form.Group>

                <Form.Group className="ml-auto">
                  <div className="d-flex">
                    <Form.Label>Stem Height</Form.Label>
                    <Form.Control
                      type="text"
                      className="split-input"
                      placeholder={valve.stem.height.value}
                      value={valve.stem.height.value}
                      onChange={(e) =>
                        dispatch({
                          type: SLKBD_SELECT_VALVE_STEM_HEIGHT_VALUE,
                          payload: e.target.value,
                        })
                      }
                    />
                    <Select
                      options={unitOptions}
                      isSearchable={false}
                      styles={customStyles5}
                      placeholder={valve.stem.height.unit}
                      value={valve.stem.height.unit}
                      onChange={(value) =>
                        dispatch({
                          type: SLKBD_SELECT_VALVE_STEM_HEIGHT_UNIT,
                          payload: value.value,
                        })
                      }
                    />
                  </div>
                </Form.Group>

                <Form.Group className="ml-auto">
                  <div className="d-flex">
                    <Form.Label>Key Length</Form.Label>
                    <Form.Control
                      type="text"
                      className="split-input"
                      value={valve.stem.key.length.value}
                      onChange={(e) =>
                        dispatch({
                          type: SLKBD_SELECT_VALVE_STEM_KEY_LENGTH_VALUE,
                          payload: e.target.value,
                        })
                      }
                    />
                    <Select
                      options={unitOptions}
                      isSearchable={false}
                      styles={customStyles5}
                      placeholder={valve.stem.key.length.unit}
                      value={valve.stem.key.length.unit}
                      onChange={(value) =>
                        dispatch({
                          type: SLKBD_SELECT_VALVE_STEM_KEY_LENGTH_UNIT,
                          payload: value.value,
                        })
                      }
                    />
                  </div>
                </Form.Group>

                <Form.Group className="ml-auto">
                  <div className="d-flex">
                    <Form.Label>Key Width</Form.Label>
                    <Form.Control
                      type="text"
                      className="split-input"
                      value={valve.stem.key.width.value}
                      onChange={(e) =>
                        dispatch({
                          type: SLKBD_SELECT_VALVE_STEM_KEY_WIDTH_VALUE,
                          payload: e.target.value,
                        })
                      }
                    />
                    <Select
                      options={unitOptions}
                      isSearchable={false}
                      styles={customStyles5}
                      placeholder={valve.stem.key.width.unit}
                      value={valve.stem.key.width.unit}
                      onChange={(value) =>
                        dispatch({
                          type: SLKBD_SELECT_VALVE_STEM_KEY_WIDTH_UNIT,
                          payload: value.value,
                        })
                      }
                    />
                  </div>
                </Form.Group>

                <Form.Group className="ml-auto">
                  <div className="d-flex">
                    <Form.Label>Key Height</Form.Label>
                    <Form.Control
                      type="text"
                      className="split-input"
                      value={valve.stem.key.height.value}
                      onChange={(e) =>
                        dispatch({
                          type: SLKBD_SELECT_VALVE_STEM_KEY_HEIGHT_VALUE,
                          payload: e.target.value,
                        })
                      }
                    />
                    <Select
                      options={unitOptions}
                      isSearchable={false}
                      styles={customStyles5}
                      placeholder={valve.stem.key.height.unit}
                      value={valve.stem.key.height.unit}
                      onChange={(value) =>
                        dispatch({
                          type: SLKBD_SELECT_VALVE_STEM_KEY_HEIGHT_UNIT,
                          payload: value.value,
                        })
                      }
                    />
                  </div>
                </Form.Group>
              </>
            )
          )}
        </div>
      </div>
    </>
    //   )}
    // </Formik>
  );
};

export default ValveStemDimensions;
