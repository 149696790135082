import { Modal } from "react-bootstrap";
import React from "react";

import { ReactComponent as Check75Svg } from "../../svg/check-75.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  ADDED_TO_CART_MODAL_CLOSE,
  LINKAGE_KIT_SELECTIONS_RESET,
} from "../../redux/constants";
import LinkageKitDescription from "../LinkageKitDescription";
import { Link } from "react-router-dom";

const AddedToCartModal = ({ item }) => {
  const dispatch = useDispatch();

  const addedToCartModal = useSelector(
    (state) => state.application.addedToCartModal
  );

  return (
    <Modal
      show={addedToCartModal}
      onHide={() => {
        dispatch({ type: ADDED_TO_CART_MODAL_CLOSE });
      }}
      centered
    >
      <Modal.Body className="show-grid">
        <div style={{ textAlign: "center" }}>
          <div style={{ fill: "#1a79ff", margin: "0 auto", display: "block" }}>
            <Check75Svg />
          </div>
          <span className="d-block py-3" style={{ fontSize: "2rem" }}>
            Item Added to Cart
          </span>
          <span className="d-block pb-3" style={{ fontSize: "1rem" }}>
            <LinkageKitDescription item={item} />
          </span>
        </div>
        <div
          className="row no-gutters"
          style={{ paddingTop: "1rem", borderTop: "1px solid #ccc" }}
        >
          <div className="col-sm-6 p-1">
            <button
              className="btn btn-block btn-secondary btn-lg"
              onClick={() => {
                dispatch({ type: ADDED_TO_CART_MODAL_CLOSE });
              }}
            >
              Keep Building
            </button>
          </div>
          <div className="col-sm-6 p-1">
            <Link
              to="/cart"
              className="btn btn-block btn-primary btn-lg"
              onClick={() => {
                dispatch({ type: LINKAGE_KIT_SELECTIONS_RESET });
                dispatch({ type: ADDED_TO_CART_MODAL_CLOSE });
              }}
            >
              View Cart
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddedToCartModal;
