export const SET_LOADING = "SET_LOADING";

export const SET_LINKAGE_KIT_SELECTOR_STEP = "SET_LINKAGE_KIT_SELECTOR_STEP";
export const SET_LINKAGE_KIT_SELECTOR_STEPS = "SET_LINKAGE_KIT_SELECTOR_STEPS";
export const SET_VALVE_SELECTION_TYPE = "SET_VALVE_SELECTION_TYPE";
export const SET_ACTUATOR_SELECTION_TYPE = "SET_ACTUATOR_SELECTION_TYPE";

export const VALVE_OPTIONS_REQUEST = "VALVE_OPTIONS_REQUEST";
export const VALVE_OPTIONS_SUCCESS = "VALVE_OPTIONS_SUCCESS";
export const VALVE_OPTIONS_FAIL = "VALVE_OPTIONS_FAIL";
export const VALVE_OPTIONS_RESET = "VALVE_OPTIONS_RESET";

export const SELECTED_VALVE_BRAND = "SELECTED_VALVE_BRAND";
export const SELECTED_VALVE_SERIES = "SELECTED_VALVE_SERIES";
export const SELECTED_VALVE_CLASS = "SELECTED_VALVE_CLASS";
export const SELECTED_VALVE_SIZE = "SELECTED_VALVE_SIZE";
export const SELECTED_VALVE_OPTIONS = "SELECTED_VALVE_OPTIONS";
export const SELECTED_VALVE_OPTIONS_RESET = "SELECTED_VALVE_OPTIONS_RESET";

export const SELECTED_ACTUATOR_ISO5211_FLANGE =
  "SELECTED_ACTUATOR_ISO5211_FLANGE";
export const SELECTED_ACTUATOR_ISO5211_DRIVE_TYPE =
  "SELECTED_ACTUATOR_ISO5211_DRIVE_TYPE";
export const SELECTED_ACTUATOR_ISO5211_DRIVE_SIZE =
  "SELECTED_ACTUATOR_ISO5211_DRIVE_SIZE";

export const SELECTED_ACTUATOR_BRAND = "SELECTED_ACTUATOR_BRAND";
export const SELECTED_ACTUATOR_SERIES = "SELECTED_ACTUATOR_SERIES";
export const SELECTED_ACTUATOR_MODEL = "SELECTED_ACTUATOR_MODEL";
export const SELECTED_ACTUATOR_OPTIONS = "SELECTED_ACTUATOR_OPTIONS";
export const SELECTED_ACTUATOR_OPTIONS_RESET =
  "SELECTED_ACTUATOR_OPTIONS_RESET";

export const SELECTED_VALVE_REQUEST = "SELECTED_VALVE_REQUEST";
export const SELECTED_VALVE_SUCCESS = "SELECTED_VALVE_SUCCESS";
export const SELECTED_VALVE_FAIL = "SELECTED_VALVE_FAIL";

export const ACTUATOR_SELECTION_TYPE = "ACTUATOR_SELECTION_TYPE";

export const ACTUATOR_OPTIONS_REQUEST = "ACTUATOR_OPTIONS_REQUEST";
export const ACTUATOR_OPTIONS_SUCCESS = "ACTUATOR_OPTIONS_SUCCESS";
export const ACTUATOR_OPTIONS_FAIL = "ACTUATOR_OPTIONS_FAIL";
export const ACTUATOR_OPTIONS_RESET = "ACTUATOR_OPTIONS_RESET";

export const SELECTED_ACTUATOR_REQUEST = "SELECTED_ACTUATOR_REQUEST";
export const SELECTED_ACTUATOR_SUCCESS = "SELECTED_ACTUATOR_SUCCESS";
export const SELECTED_ACTUATOR_FAIL = "SELECTED_ACTUATOR_FAIL";

export const SELECTED_VALVE_RESET = "SELECTED_VALVE_RESET";
export const SELECTED_ACTUATOR_RESET = "SELECTED_ACTUATOR_RESET";

export const LINKAGE_KIT_SELECTIONS_RESET = "LINKAGE_KIT_SELECTIONS_RESET";

export const VALVE_SELECTIONS_RESET = "VALVE_SELECTIONS_RESET";
export const ACTUATOR_SELECTIONS_RESET = "ACTUATOR_SELECTIONS_RESET";

export const OPTIONS_LOCKOUT = "OPTIONS_LOCKOUT";
export const OPTIONS_LOCKOUT_RESET = "OPTIONS_LOCKOUT_RESET";

export const OPTIONS_BRACKET_HEIGHT = "OPTIONS_BRACKET_HEIGHT";
export const OPTIONS_BRACKET_HEIGHT_RESET = "OPTIONS_BRACKET_HEIGHT_RESET";

export const OPTIONS_USE_CUSTOM_DATA_SHEETS = "OPTIONS_USE_CUSTOM_DATA_SHEETS";

export const OPTIONS_COMPONENTS = "OPTIONS_COMPONENTS";
export const OPTIONS_COMPONENTS_RESET = "OPTIONS_COMPONENTS_RESET";

export const OPTIONS_QUANTITY = "OPTIONS_QUANTITY";

export const CART_ADD_ITEM_REQUEST = "CART_ADD_ITEM_REQUEST";
export const CART_ADD_ITEM_SUCCESS = "CART_ADD_ITEM_SUCCESS";
export const CART_ADD_ITEM_FAIL = "CART_ADD_ITEM_FAIL";
export const CART_ADD_ITEM_RESET = "CART_ADD_ITEM_RESET";

export const CART_REMOVE_ITEM_REQUEST = "CART_REMOVE_ITEM_REQUEST";
export const CART_REMOVE_ITEM_SUCCESS = "CART_REMOVE_ITEM_SUCCESS";
export const CART_REMOVE_ITEM_FAIL = "CART_REMOVE_ITEM_FAIL";

export const CART_UPDATE_ITEM_QTY_REQUEST = "CART_UPDATE_ITEM_QTY_REQUEST";
export const CART_UPDATE_ITEM_QTY_SUCCESS = "CART_UPDATE_ITEM_QTY_SUCCESS";
export const CART_UPDATE_ITEM_QTY_FAIL = "CART_UPDATE_ITEM_QTY_FAIL";

export const CART_CLEAR_ITEMS = "CART_RESET";

export const GET_SAVED_ITEMS_REQUEST = "GET_SAVED_ITEMS_REQUEST";
export const GET_SAVED_ITEMS_SUCCESS = "GET_SAVED_ITEMS_SUCCESS";
export const GET_SAVED_ITEMS_FAIL = "GET_SAVED_ITEMS_FAIL";

export const ADD_TO_SAVED_ITEMS_REQUEST = "ADD_TO_SAVED_ITEMS_REQUEST";
export const ADD_TO_SAVED_ITEMS_SUCCESS = "ADD_TO_SAVED_ITEMS_SUCCESS";
export const ADD_TO_SAVED_ITEMS_FAIL = "ADD_TO_SAVED_ITEMS_FAIL";

export const MOVE_TO_CART_REQUEST = "MOVE_TO_CART_REQUEST";
export const MOVE_TO_CART_SUCCESS = "MOVE_TO_CART_SUCCESS";
export const MOVE_TO_CART_FAIL = "MOVE_TO_CART_FAIL";

export const REMOVE_FROM_SAVED_ITEMS_REQUEST =
  "REMOVE_FROM_SAVED_ITEMS_REQUEST";
export const REMOVE_FROM_SAVED_ITEMS_SUCCESS =
  "REMOVE_FROM_SAVED_ITEMS_SUCCESS";
export const REMOVE_FROM_SAVED_ITEMS_FAIL = "REMOVE_FROM_SAVED_ITEMS_FAIL";

export const MOVE_TO_SAVED_ITEMS_REQUEST = "MOVE_TO_SAVED_ITEMS_REQUEST";
export const MOVE_TO_SAVED_ITEMS_SUCCESS = "MOVE_TO_SAVED_ITEMS_SUCCESS";
export const MOVE_TO_SAVED_ITEMS_FAIL = "MOVE_TO_SAVED_ITEMS_FAIL";

export const LINKAGE_KIT_PRICE_REQUEST = "LINKAGE_KIT_PRICE_REQUEST";
export const LINKAGE_KIT_PRICE_SUCCESS = "LINKAGE_KIT_PRICE_SUCCESS";
export const LINKAGE_KIT_PRICE_FAIL = "LINKAGE_KIT_PRICE_FAIL";

export const LINKAGE_KIT_DIMENSIONS_REQUEST = "LINKAGE_KIT_DIMENSIONS_REQUEST";
export const LINKAGE_KIT_DIMENSIONS_SUCCESS = "LINKAGE_KIT_DIMENSIONS_SUCCESS";
export const LINKAGE_KIT_DIMENSIONS_FAIL = "LINKAGE_KIT_DIMENSIONS_FAIL";

export const BRACKET_HEIGHTS_REQUEST = "BRACKET_HEIGHTS_REQUEST";
export const BRACKET_HEIGHTS_SUCCESS = "BRACKET_HEIGHTS_SUCCESS";
export const BRACKET_HEIGHTS_FAIL = "BRACKET_HEIGHTS_FAIL";
export const BRACKET_HEIGHTS_RESET = "BRACKET_HEIGHTS_RESET";

export const SELECTED_KIT_SKU = "SELECTED_KIT_SKU";

export const SLKBD_SELECT_VALVE_STEM_TYPE = "SLKBD_SELECT_VALVE_STEM_TYPE";

export const SLKBD_SELECT_VALVE_STEM_DIAMETER_UNIT =
  "SLKBD_SELECT_VALVE_STEM_DIAMETER_UNIT";
export const SLKBD_SELECT_VALVE_STEM_DIAMETER_VALUE =
  "SLKBD_SELECT_VALVE_STEM_DIAMETER_VALUE";

export const SLKBD_SELECT_VALVE_STEM_HEIGHT_UNIT =
  "SLKBD_SELECT_VALVE_STEM_HEIGHT_UNIT";
export const SLKBD_SELECT_VALVE_STEM_HEIGHT_VALUE =
  "SLKBD_SELECT_VALVE_STEM_HEIGHT_VALUE";

export const SLKBD_SELECT_VALVE_STEM_ACROSS_FLATS_UNIT =
  "SLKBD_SELECT_VALVE_STEM_ACROSS_FLATS_UNIT";
export const SLKBD_SELECT_VALVE_STEM_ACROSS_FLATS_VALUE =
  "SLKBD_SELECT_VALVE_STEM_ACROSS_FLATS_VALUE";

export const SLKBD_SELECT_VALVE_STEM_FLATS_HEIGHT_UNIT =
  "SLKBD_SELECT_VALVE_STEM_FLATS_HEIGHT_UNIT";
export const SLKBD_SELECT_VALVE_STEM_FLATS_HEIGHT_VALUE =
  "SLKBD_SELECT_VALVE_STEM_FLATS_HEIGHT_VALUE";

export const SLKBD_SELECT_VALVE_STEM_SQUARE_UNIT =
  "SLKBD_SELECT_VALVE_STEM_SQUARE_UNIT";
export const SLKBD_SELECT_VALVE_STEM_SQUARE_VALUE =
  "SLKBD_SELECT_VALVE_STEM_SQUARE_VALUE";

export const SLKBD_SELECT_VALVE_STEM_KEY_WIDTH_UNIT =
  "SLKBD_SELECT_VALVE_STEM_KEY_WIDTH_UNIT";
export const SLKBD_SELECT_VALVE_STEM_KEY_WIDTH_VALUE =
  "SLKBD_SELECT_VALVE_STEM_KEY_WIDTH_VALUE";

export const SLKBD_SELECT_VALVE_STEM_KEY_HEIGHT_UNIT =
  "SLKBD_SELECT_VALVE_STEM_KEY_HEIGHT_UNIT";
export const SLKBD_SELECT_VALVE_STEM_KEY_HEIGHT_VALUE =
  "SLKBD_SELECT_VALVE_STEM_KEY_HEIGHT_VALUE";

export const SLKBD_SELECT_VALVE_STEM_KEY_LENGTH_UNIT =
  "SLKBD_SELECT_VALVE_STEM_KEY_LENGTH_UNIT";
export const SLKBD_SELECT_VALVE_STEM_KEY_LENGTH_VALUE =
  "SLKBD_SELECT_VALVE_STEM_KEY_LENGTH_VALUE";

export const SLKBD_SELECT_VALVE_STEM_KEY_PLUS_STEM_DIA_UNIT =
  "SLKBD_SELECT_VALVE_STEM_KEY_PLUS_STEM_DIA_UNIT";
export const SLKBD_SELECT_VALVE_STEM_KEY_PLUS_STEM_DIA_VALUE =
  "SLKBD_SELECT_VALVE_STEM_KEY_PLUS_STEM_DIA_VALUE";

export const SLKBD_SELECT_VALVE_MOUNTING_TYPE =
  "SLKBD_SELECT_VALVE_MOUNTING_TYPE";

export const SLKBD_SELECT_VALVE_MOUNTING_PCD_UNIT =
  "SLKBD_SELECT_VALVE_MOUNTING_PCD_UNIT";
export const SLKBD_SELECT_VALVE_MOUNTING_PCD_VALUE =
  "SLKBD_SELECT_VALVE_MOUNTING_PCD_VALUE";

export const SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_X_UNIT =
  "SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_X_UNIT";
export const SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_X_VALUE =
  "SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_X_VALUE";

export const SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_Y_UNIT =
  "SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_Y_UNIT";
export const SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_Y_VALUE =
  "SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_Y_VALUE";

export const SLKBD_SELECT_VALVE_MOUNTING_HOLES_DIA_UNIT =
  "SLKBD_SELECT_VALVE_MOUNTING_HOLES_DIA_UNIT";
export const SLKBD_SELECT_VALVE_MOUNTING_HOLES_DIA_VALUE =
  "SLKBD_SELECT_VALVE_MOUNTING_HOLES_DIA_VALUE";

export const SLKBD_SELECT_VALVE_MOUNTING_HOLES_COUNT =
  "SLKBD_SELECT_VALVE_MOUNTING_HOLES_COUNT";

export const SLKBD_VALVE_CUSTOM_NAME = "SLKBD_VALVE_CUSTOM_NAME";

export const SLKBD_SELECT_ACTUATOR_DRIVE_TYPE =
  "SLKBD_SELECT_ACTUATOR_DRIVE_TYPE";

export const SLKBD_ACTUATOR_DRIVE_SQUARE_VALUE =
  "SLKBD_ACTUATOR_DRIVE_SQUARE_VALUE";
export const SLKBD_ACTUATOR_DRIVE_SQUARE_UNIT =
  "SLKBD_ACTUATOR_DRIVE_SQUARE_UNIT";

export const SLKBD_ACTUATOR_DRIVE_DEPTH_VALUE =
  "SLKBD_ACTUATOR_DRIVE_DEPTH_VALUE";
export const SLKBD_ACTUATOR_DRIVE_DEPTH_UNIT =
  "SLKBD_ACTUATOR_DRIVE_DEPTH_UNIT";

export const SLKBD_ACTUATOR_DRIVE_DIAMETER_VALUE =
  "SLKBD_ACTUATOR_DRIVE_DIAMETER_VALUE";
export const SLKBD_ACTUATOR_DRIVE_DIAMETER_UNIT =
  "SLKBD_ACTUATOR_DRIVE_DIAMETER_UNIT";

export const SLKBD_ACTUATOR_DRIVE_WIDTH_VALUE =
  "SLKBD_ACTUATOR_DRIVE_WIDTH_VALUE";
export const SLKBD_ACTUATOR_DRIVE_WIDTH_UNIT =
  "SLKBD_ACTUATOR_DRIVE_WIDTH_UNIT";

export const SLKBD_ACTUATOR_DRIVE_KEY_WIDTH_VALUE =
  "SLKBD_ACTUATOR_DRIVE_KEY_WIDTH_VALUE";
export const SLKBD_ACTUATOR_DRIVE_KEY_WIDTH_UNIT =
  "SLKBD_ACTUATOR_DRIVE_KEY_WIDTH_UNIT";

export const SLKBD_ACTUATOR_DRIVE_KEY_HEIGHT_VALUE =
  "SLKBD_ACTUATOR_DRIVE_KEY_HEIGHT_VALUE";
export const SLKBD_ACTUATOR_DRIVE_KEY_HEIGHT_UNIT =
  "SLKBD_ACTUATOR_DRIVE_KEY_HEIGHT_UNIT";

export const SLKBD_SELECT_ACTUATOR_MOUNTING_TYPE =
  "SLKBD_SELECT_ACTUATOR_MOUNTING_TYPE";

export const SLKBD_ACTUATOR_MOUNTING_PCD_VALUE =
  "SLKBD_ACTUATOR_MOUNTING_PCD_VALUE";
export const SLKBD_ACTUATOR_MOUNTING_PCD_UNIT =
  "SLKBD_ACTUATOR_MOUNTING_PCD_UNIT";

export const SLKBD_ACTUATOR_MOUNTING_BOLTS = "SLKBD_ACTUATOR_MOUNTING_BOLTS";
export const SLKBD_ACTUATOR_MOUNTING_BOLTS_COUNT =
  "SLKBD_ACTUATOR_MOUNTING_BOLTS_COUNT";

export const SLKBD_ACTUATOR_CUSTOM_NAME = "SLKBD_ACTUATOR_CUSTOM_NAME";

export const SLKBD_SELECT_LOCKOUT = "SLKBD_SELECT_LOCKOUT";
export const SLKBD_SELECT_BRACKET_HEIGHT = "SLKBD_SELECT_BRACKET_HEIGHT";
export const SLKBD_SELECT_COMPONENTS = "SLKBD_SELECT_COMPONENTS";
export const SLKBD_SELECT_QTY = "SLKBD_SELECT_QTY";

export const SLKBD_FETCH_BRACKET_HEIGHTS_REQUEST =
  "SLKBD_FETCH_BRACKET_HEIGHTS_REQUEST";
export const SLKBD_FETCH_BRACKET_HEIGHTS_SUCCESS =
  "SLKBD_FETCH_BRACKET_HEIGHTS_SUCCESS";
export const SLKBD_FETCH_BRACKET_HEIGHTS_FAIL =
  "SLKBD_FETCH_BRACKET_HEIGHTS_FAIL";

export const SLKBD_FETCH_PRICE_REQUEST = "SLKBD_FETCH_PRICE_REQUEST";
export const SLKBD_FETCH_PRICE_SUCCESS = "SLKBD_FETCH_PRICE_SUCCESS";
export const SLKBD_FETCH_PRICE_FAIL = "SLKBD_FETCH_PRICE_FAIL";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_HARD_LOGOUT_REQUEST = "USER_HARD_LOGOUT_REQUEST";
export const USER_HARD_LOGOUT_SUCCESS = "USER_HARD_LOGOUT_SUCCESS";
export const USER_HARD_LOGOUT_FAIL = "USER_HARD_LOGOUT_FAIL";
export const USER_HARD_LOGOUT_RESET = "USER_HARD_LOGOUT_RESET";

export const USER_SOFT_LOGOUT = "USER_SOFT_LOGOUT";

export const USER_PASSWORD_FORGOT_REQUEST = "USER_PASSWORD_FORGOT_REQUEST";
export const USER_PASSWORD_FORGOT_SUCCESS = "USER_PASSWORD_FORGOT_SUCCESS";
export const USER_PASSWORD_FORGOT_FAIL = "USER_PASSWORD_FORGOT_FAIL";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_REGISTER_CONFIRM_TOKEN_REQUEST =
  "USER_REGISTER_CONFIRM_TOKEN_REQUEST";
export const USER_REGISTER_CONFIRM_TOKEN_SUCCESS =
  "USER_REGISTER_CONFIRM_TOKEN_SUCCESS";
export const USER_REGISTER_CONFIRM_TOKEN_FAIL =
  "USER_REGISTER_CONFIRM_TOKEN_FAIL";

export const USER_RESEND_CONFIRMATION_EMAIL_REQUEST =
  "USER_RESEND_CONFIRMATION_EMAIL_REQUEST";
export const USER_RESEND_CONFIRMATION_EMAIL_SUCCESS =
  "USER_RESEND_CONFIRMATION_EMAIL_SUCCESS";
export const USER_RESEND_CONFIRMATION_EMAIL_FAIL =
  "USER_RESEND_CONFIRMATION_EMAIL_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

export const USER_UPDATE_PASSWORD_REQUEST = "USER_UPDATE_PASSWORD_REQUEST";
export const USER_UPDATE_PASSWORD_SUCCESS = "USER_UPDATE_PASSWORD_SUCCESS";
export const USER_UPDATE_PASSWORD_FAIL = "USER_UPDATE_PASSWORD_FAIL";

export const USER_CART_REQUEST = "USER_CART_REQUEST";
export const USER_CART_SUCCESS = "USER_CART_SUCCESS";
export const USER_CART_FAIL = "USER_CART_FAIL";

export const USER_GET_SHIPPING_ADDRESS_REQUEST =
  "USER_GET_SHIPPING_ADDRESS_REQUEST";
export const USER_GET_SHIPPING_ADDRESS_SUCCESS =
  "USER_GET_SHIPPING_ADDRESS_SUCCESS";
export const USER_GET_SHIPPING_ADDRESS_FAIL = "USER_GET_SHIPPING_ADDRESS_FAIL";

export const USER_GET_DEFAULT_SHIPPING_ADDRESS_REQUEST =
  "USER_GET_DEFAULT_SHIPPING_ADDRESS_REQUEST";
export const USER_GET_DEFAULT_SHIPPING_ADDRESS_SUCCESS =
  "USER_GET_DEFAULT_SHIPPING_ADDRESS_SUCCESS";
export const USER_GET_DEFAULT_SHIPPING_ADDRESS_FAIL =
  "USER_GET_DEFAULT_SHIPPING_ADDRESS_FAIL";

export const USER_GET_SHIPPING_ADDRESSES_REQUEST =
  "USER_GET_SHIPPING_ADDRESSES_REQUEST";
export const USER_GET_SHIPPING_ADDRESSES_SUCCESS =
  "USER_GET_SHIPPING_ADDRESSES_SUCCESS";
export const USER_GET_SHIPPING_ADDRESSES_FAIL =
  "USER_GET_SHIPPING_ADDRESSES_FAIL";

export const USER_ADD_SHIPPING_ADDRESS_REQUEST =
  "USER_ADD_SHIPPING_ADDRESS_REQUEST";
export const USER_ADD_SHIPPING_ADDRESS_SUCCESS =
  "USER_ADD_SHIPPING_ADDRESS_SUCCESS";
export const USER_ADD_SHIPPING_ADDRESS_FAIL = "USER_ADD_SHIPPING_ADDRESS_FAIL";
export const USER_ADD_SHIPPING_ADDRESS_RESET =
  "USER_ADD_SHIPPING_ADDRESS_RESET";

export const USER_UPDATE_SHIPPING_ADDRESS_REQUEST =
  "USER_UPDATE_SHIPPING_ADDRESS_REQUEST";
export const USER_UPDATE_SHIPPING_ADDRESS_SUCCESS =
  "USER_UPDATE_SHIPPING_ADDRESS_SUCCESS";
export const USER_UPDATE_SHIPPING_ADDRESS_FAIL =
  "USER_UPDATE_SHIPPING_ADDRESS_FAIL";
export const USER_UPDATE_SHIPPING_ADDRESS_RESET =
  "USER_UPDATE_SHIPPING_ADDRESS_RESET";

export const USER_DELETE_SHIPPING_ADDRESS_REQUEST =
  "USER_DELETE_SHIPPING_ADDRESS_REQUEST";
export const USER_DELETE_SHIPPING_ADDRESS_SUCCESS =
  "USER_DELETE_SHIPPING_ADDRESS_SUCCESS";
export const USER_DELETE_SHIPPING_ADDRESS_FAIL =
  "USER_DELETE_SHIPPING_ADDRESS_FAIL";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const USER_GET_ORDER_REQUEST = "USER_GET_ORDER_REQUEST";
export const USER_GET_ORDER_SUCCESS = "USER_GET_ORDER_SUCCESS";
export const USER_GET_ORDER_FAIL = "USER_GET_ORDER_FAIL";
export const USER_GET_ORDER_RESET = "USER_GET_ORDER_RESET";

export const USER_GET_ORDERS_REQUEST = "USER_GET_ORDERS_REQUEST";
export const USER_GET_ORDERS_SUCCESS = "USER_GET_ORDERS_SUCCESS";
export const USER_GET_ORDERS_FAIL = "USER_GET_ORDERS_FAIL";
export const USER_GET_ORDERS_RESET = "USER_GET_ORDERS_RESET";

export const USER_GET_AUTH_STATUS_REQUEST = "USER_GET_AUTH_STATUS_REQUEST";
export const USER_GET_AUTH_STATUS_SUCCESS = "USER_GET_AUTH_STATUS_SUCCESS";
export const USER_GET_AUTH_STATUS_FAIL = "USER_GET_AUTH_STATUS_FAIL";
export const USER_GET_AUTH_STATUS_RESET = "USER_GET_AUTH_STATUS_RESET";

export const ADD_COUPON_REQUEST = "ADD_COUPON_REQUEST";
export const ADD_COUPON_SUCCESS = "ADD_COUPON_SUCCESS";
export const ADD_COUPON_FAIL = "ADD_COUPON_FAIL";
export const ADD_COUPON_RESET = "ADD_COUPON_RESET";

export const CART_ADD_COUPON_REQUEST = "CART_ADD_COUPON_REQUEST";
export const CART_ADD_COUPON_SUCCESS = "CART_ADD_COUPON_SUCCESS";
export const CART_ADD_COUPON_FAIL = "CART_ADD_COUPON_FAIL";

export const CART_MERGE_REQUEST = "CART_MERGE_REQUEST";
export const CART_MERGE_SUCCESS = "CART_MERGE_SUCCESS";
export const CART_MERGE_FAIL = "CART_MERGE_FAIL";

export const CART_UPDATE_REQUEST = "CART_UPDATE_REQUEST";
export const CART_UPDATE_SUCCESS = "CART_UPDATE_SUCCESS";
export const CART_UPDATE_FAIL = "CART_UPDATE_FAIL";

export const CART_REMOVE_COUPON_REQUEST = "CART_REMOVE_COUPON_REQUEST";
export const CART_REMOVE_COUPON_SUCCESS = "CART_REMOVE_COUPON_SUCCESS";
export const CART_REMOVE_COUPON_FAIL = "CART_REMOVE_COUPON_FAIL";

export const GET_KIT_WEIGHT_REQUEST = "GET_KIT_WEIGHT_REQUEST";
export const GET_KIT_WEIGHT_SUCCESS = "GET_KIT_WEIGHT_SUCCESS";
export const GET_KIT_WEIGHT_FAIL = "GET_KIT_WEIGHT_FAIL";

export const UPS_SHIPPING_RATES_REQUEST = "UPS_SHIPPING_RATES_REQUEST";
export const UPS_SHIPPING_RATES_SUCCESS = "UPS_SHIPPING_RATES_SUCCESS";
export const UPS_SHIPPING_RATES_FAIL = "UPS_SHIPPING_RATES_FAIL";
export const UPS_SHIPPING_RATES_RESET = "UPS_SHIPPING_RATES_RESET";

export const VALIDATE_COUPON_REQUEST = "VALIDATE_COUPON_REQUEST";
export const VALIDATE_COUPON_SUCCESS = "VALIDATE_COUPON_SUCCESS";
export const VALIDATE_COUPON_FAIL = "VALIDATE_COUPON_FAIL";
export const VALIDATE_COUPON_RESET = "VALIDATE_COUPON_RESET";
export const VALIDATE_COUPON_HARD_RESET = "VALIDATE_COUPON_HARD_RESET";

export const VALIDATE_PC_REQUEST = "VALIDATE_PC_REQUEST";
export const VALIDATE_PC_SUCCESS = "VALIDATE_PC_SUCCESS";
export const VALIDATE_PC_FAIL = "VALIDATE_PC_FAIL";
export const VALIDATE_PC_RESET = "VALIDATE_PC_RESET";
export const VALIDATE_PC_HARD_RESET = "VALIDATE_PC_HARD_RESET";

export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";
export const ORDER_CREATE_RESET = "ORDER_CREATE_RESET";
export const ORDER_CREATE_ERROR_RESET = "ORDER_CREATE_ERROR_RESET";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";

export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";

export const SUBMIT_RFQ_REQUEST = "SUBMIT_RFQ_REQUEST";
export const SUBMIT_RFQ_SUCCESS = "SUBMIT_RFQ_SUCCESS";
export const SUBMIT_RFQ_FAIL = "SUBMIT_RFQ_FAIL";

export const USER_SEND_MESSAGE_REQUEST = "USER_SEND_MESSAGE_REQUEST";
export const USER_SEND_MESSAGE_SUCCESS = "USER_SEND_MESSAGE_SUCCESS";
export const USER_SEND_MESSAGE_FAIL = "USER_SEND_MESSAGE_FAIL";

// Application state
export const CART_DROPDOWN_OPEN = "CART_DROPDOWN_OPEN";
export const CART_DROPDOWN_CLOSE = "CART_DROPDOWN_CLOSE";

export const ACCOUNT_DROPDOWN_OPEN = "ACCOUNT_DROPDOWN_OPEN";
export const ACCOUNT_DROPDOWN_CLOSE = "ACCOUNT_DROPDOWN_CLOSE";

export const MOBILE_MENU_OPEN = "MOBILE_MENU_OPEN";
export const MOBILE_MENU_CLOSE = "MOBILE_MENU_CLOSE";

export const CHECKOUT_EDIT_ADDRESS_MODAL_OPEN =
  "CHECKOUT_EDIT_ADDRESS_MODAL_OPEN";
export const CHECKOUT_EDIT_ADDRESS_MODAL_CLOSE =
  "CHECKOUT_EDIT_ADDRESS_MODAL_CLOSE";

export const CHECKOUT_ADD_ADDRESS_MODAL_OPEN =
  "CHECKOUT_ADD_ADDRESS_MODAL_OPEN";
export const CHECKOUT_ADD_ADDRESS_MODAL_CLOSE =
  "CHECKOUT_ADD_ADDRESS_MODAL_CLOSE";

export const ADDED_TO_CART_MODAL_OPEN = "ADDED_TO_CART_MODAL_OPEN";
export const ADDED_TO_CART_MODAL_CLOSE = "ADDED_TO_CART_MODAL_CLOSE";

export const EMAIL_SIGNUP_MODAL_OPEN = "EMAIL_SIGNUP_MODAL_OPEN";
export const EMAIL_SIGNUP_MODAL_CLOSE = "EMAIL_SIGNUP_MODAL_CLOSE";

// Check Tax Exempt status of user
export const IS_TAX_EXEMPT = "IS_TAX_EXEMPT";

// Get tax rates
export const TAX_RATES_REQUEST = "TAX_RATES_REQUEST";
export const TAX_RATES_SUCCESS = "TAX_RATES_SUCCESS";
export const TAX_RATES_FAIL = "TAX_RATES_FAIL";

// Submit reseller certificate form
export const SUBMIT_RESALE_CERT_REQUEST = "SUBMIT_RESALE_CERT_REQUEST";
export const SUBMIT_RESALE_CERT_SUCCESS = "SUBMIT_RESALE_CERT_SUCCESS";
export const SUBMIT_RESALE_CERT_FAIL = "SUBMIT_RESALE_CERT_FAIL";

// Get shipping price
export const GET_SHIPPING_PRICE_REQUEST = "GET_SHIPPING_PRICE_REQUEST";
export const GET_SHIPPING_PRICE_SUCCESS = "GET_SHIPPING_PRICE_SUCCESS";
export const GET_SHIPPING_PRICE_FAIL = "GET_SHIPPING_PRICE_FAIL";

// Change shipping method
export const CHANGE_SHIPPING_METHOD = "CHANGE_SHIPPING_METHOD";

export const CHANGE_SHIPPING_ADDRESS_SHOW = "CHANGE_SHIPPING_ADDRESS_SHOW";
export const CHANGE_SHIPPING_ADDRESS_HIDE = "CHANGE_SHIPPING_ADDRESS_HIDE";

// Sign up for email marketing
export const EMAIL_SIGNUP_REQUEST = "EMAIL_SIGNUP_REQUEST";
export const EMAIL_SIGNUP_SUCCESS = "EMAIL_SIGNUP_SUCCESS";
export const EMAIL_SIGNUP_FAIL = "EMAIL_SIGNUP_FAIL";

// Check if user is an admin
export const CHECK_ADMIN_STATUS_REQUEST = "CHECK_ADMIN_STATUS_REQUEST";
export const CHECK_ADMIN_STATUS_SUCCESS = "CHECK_ADMIN_STATUS_SUCCESS";
export const CHECK_ADMIN_STATUS_FAIL = "CHECK_ADMIN_STATUS_FAIL";

// Admin sidebar
export const ADMIN_SIDEBAR = "ADMIN_SIDEBAR";
export const ADMIN_SIDEBAR_UNFOLDABLE = "ADMIN_SIDEBAR_UNFOLDABLE";

// Admin Modals
export const ADMIN_ADD_COUPON_MODAL = "ADMIN_ADD_COUPON_MODAL";
export const ADMIN_EDIT_COUPON_MODAL = "ADMIN_EDIT_COUPON_MODAL";
export const ADMIN_ADD_VALVE_MODAL = "ADMIN_ADD_VALVE_MODAL";
export const ADMIN_ADD_VALVES_FROM_CSV_STRING_MODAL =
  "ADMIN_ADD_VALVES_FROM_CSV_STRING_MODAL";
export const ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_MODAL =
  "ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_MODAL";
export const ADMIN_EDIT_VALVE_MODAL = "ADMIN_EDIT_VALVE_MODAL";
export const ADMIN_ADD_PREFERRED_CUSTOMER_MODAL =
  "ADMIN_ADD_PREFERRED_CUSTOMER_MODAL";
export const ADMIN_EDIT_PREFERRED_CUSTOMER_MODAL =
  "ADMIN_EDIT_PREFERRED_CUSTOMER_MODAL";
export const ADMIN_ADD_EARLY_ACCESS_USER_MODAL =
  "ADMIN_ADD_EARLY_ACCESS_USER_MODAL";

// Admin: Get all users
export const ADMIN_GET_ALL_USERS_REQUEST = "ADMIN_GET_ALL_USERS_REQUEST";
export const ADMIN_GET_ALL_USERS_SUCCESS = "ADMIN_GET_ALL_USERS_SUCCESS";
export const ADMIN_GET_ALL_USERS_FAIL = "ADMIN_GET_ALL_USERS_FAIL";
export const ADMIN_GET_ALL_USERS_RESET = "ADMIN_GET_ALL_USERS_RESET";

// Admin: Get single user by id
export const ADMIN_GET_SINGLE_USER_REQUEST = "ADMIN_GET_SINGLE_USER_REQUEST";
export const ADMIN_GET_SINGLE_USER_SUCCESS = "ADMIN_GET_SINGLE_USER_SUCCESS";
export const ADMIN_GET_SINGLE_USER_FAIL = "ADMIN_GET_SINGLE_USER_FAIL";
export const ADMIN_GET_SINGLE_USER_RESET = "ADMIN_GET_SINGLE_USER_RESET";

// Admin: Get all preferred customers
export const ADMIN_GET_PREFERRED_CUSTOMERS_REQUEST =
  "ADMIN_GET_PREFERRED_CUSTOMERS_REQUEST";
export const ADMIN_GET_PREFERRED_CUSTOMERS_SUCCESS =
  "ADMIN_GET_PREFERRED_CUSTOMERS_SUCCESS";
export const ADMIN_GET_PREFERRED_CUSTOMERS_FAIL =
  "ADMIN_GET_PREFERRED_CUSTOMERS_FAIL";

// Admin: Add preferred customer
export const ADMIN_ADD_PREFERRED_CUSTOMER_REQUEST =
  "ADMIN_ADD_PREFERRED_CUSTOMER_REQUEST";
export const ADMIN_ADD_PREFERRED_CUSTOMER_SUCCESS =
  "ADMIN_ADD_PREFERRED_CUSTOMER_SUCCESS";
export const ADMIN_ADD_PREFERRED_CUSTOMER_FAIL =
  "ADMIN_ADD_PREFERRED_CUSTOMER_FAIL";

// Admin: Get preferred customer status
export const ADMIN_GET_PREFERRED_CUSTOMER_STATUS_REQUEST =
  "ADMIN_GET_PREFERRED_CUSTOMER_STATUS_REQUEST";
export const ADMIN_GET_PREFERRED_CUSTOMER_STATUS_SUCCESS =
  "ADMIN_GET_PREFERRED_CUSTOMER_STATUS_SUCCESS";
export const ADMIN_GET_PREFERRED_CUSTOMER_STATUS_FAIL =
  "ADMIN_GET_PREFERRED_CUSTOMER_STATUS_FAIL";

// Admin: Get all early access users
export const ADMIN_GET_ALL_EARLY_ACCESS_USERS_REQUEST =
  "ADMIN_GET_ALL_EARLY_ACCESS_USERS_REQUEST";
export const ADMIN_GET_ALL_EARLY_ACCESS_USERS_SUCCESS =
  "ADMIN_GET_ALL_EARLY_ACCESS_USERS_SUCCESS";
export const ADMIN_GET_ALL_EARLY_ACCESS_USERS_FAIL =
  "ADMIN_GET_ALL_EARLY_ACCESS_USERS_FAIL";

// Admin: Add early access user
export const ADMIN_ADD_EARLY_ACCESS_USER_REQUEST =
  "ADMIN_ADD_EARLY_ACCESS_USER_REQUEST";
export const ADMIN_ADD_EARLY_ACCESS_USER_SUCCESS =
  "ADMIN_ADD_EARLY_ACCESS_USER_SUCCESS";
export const ADMIN_ADD_EARLY_ACCESS_USER_FAIL =
  "ADMIN_GET_ALL_EARLY_ACCESS_USERS_FAIL";

// Admin: Delete early access user
export const ADMIN_DELETE_EARLY_ACCESS_USER_REQUEST =
  "ADMIN_DELETE_EARLY_ACCESS_USER_REQUEST";
export const ADMIN_DELETE_EARLY_ACCESS_USER_SUCCESS =
  "ADMIN_DELETE_EARLY_ACCESS_USER_SUCCESS";
export const ADMIN_DELETE_EARLY_ACCESS_USER_FAIL =
  "ADMIN_GET_ALL_EARLY_ACCESS_USERS_FAIL";

// Admin: Get all valves in database
export const ADMIN_GET_ALL_VALVES_REQUEST = "ADMIN_GET_ALL_VALVES_REQUEST";
export const ADMIN_GET_ALL_VALVES_SUCCESS = "ADMIN_GET_ALL_VALVES_SUCCESS";
export const ADMIN_GET_ALL_VALVES_FAIL = "ADMIN_GET_ALL_VALVES_FAIL";

// Admin: Add a valve to database
export const ADMIN_ADD_VALVE_REQUEST = "ADMIN_ADD_VALVE_REQUEST";
export const ADMIN_ADD_VALVE_SUCCESS = "ADMIN_ADD_VALVE_SUCCESS";
export const ADMIN_ADD_VALVE_FAIL = "ADMIN_ADD_VALVE_FAIL";

// Admin: Add valves to database via CSV text string
export const ADMIN_ADD_VALVES_FROM_CSV_STRING_REQUEST =
  "ADMIN_ADD_VALVES_FROM_CSV_STRING_REQUEST";
export const ADMIN_ADD_VALVES_FROM_CSV_STRING_SUCCESS =
  "ADMIN_ADD_VALVES_FROM_CSV_STRING_SUCCESS";
export const ADMIN_ADD_VALVES_FROM_CSV_STRING_FAIL =
  "ADMIN_ADD_VALVES_FROM_CSV_STRING_FAIL";

// Admin: Edit a valve in the database
export const ADMIN_EDIT_VALVE_REQUEST = "ADMIN_EDIT_VALVE_REQUEST";
export const ADMIN_EDIT_VALVE_SUCCESS = "ADMIN_EDIT_VALVE_SUCCESS";
export const ADMIN_EDIT_VALVE_FAIL = "ADMIN_EDIT_VALVE_FAIL";

// Admin: Delete a valve in the database
export const ADMIN_DELETE_VALVE_REQUEST = "ADMIN_DELETE_VALVE_REQUEST";
export const ADMIN_DELETE_VALVE_SUCCESS = "ADMIN_DELETE_VALVE_SUCCESS";
export const ADMIN_DELETE_VALVE_FAIL = "ADMIN_DELETE_VALVE_FAIL";

// Admin: Get all actuators in database
export const ADMIN_GET_ALL_ACTUATORS_REQUEST =
  "ADMIN_GET_ALL_ACTUATORS_REQUEST";
export const ADMIN_GET_ALL_ACTUATORS_SUCCESS =
  "ADMIN_GET_ALL_ACTUATORS_SUCCESS";
export const ADMIN_GET_ALL_ACTUATORS_FAIL = "ADMIN_GET_ALL_ACTUATORS_FAIL";

// Admin: Add actuators to database via CSV text string
export const ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_REQUEST =
  "ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_REQUEST";
export const ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_SUCCESS =
  "ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_SUCCESS";
export const ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_FAIL =
  "ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_FAIL";

export const ADMIN_GET_ALL_COUPONS_REQUEST = "ADMIN_GET_ALL_COUPONS_REQUEST";
export const ADMIN_GET_ALL_COUPONS_SUCCESS = "ADMIN_GET_ALL_COUPONS_SUCCESS";
export const ADMIN_GET_ALL_COUPONS_FAIL = "ADMIN_GET_ALL_COUPONS_FAIL";
export const ADMIN_GET_ALL_COUPONS_RESET = "ADMIN_GET_ALL_COUPONS_RESET";

// Admin: Add a new coupon to database
export const ADMIN_ADD_COUPON_REQUEST = "ADMIN_ADD_COUPON_REQUEST";
export const ADMIN_ADD_COUPON_SUCCESS = "ADMIN_ADD_COUPON_SUCCESS";
export const ADMIN_ADD_COUPON_FAIL = "ADMIN_ADD_COUPON_FAIL";
export const ADMIN_ADD_COUPON_RESET = "ADMIN_ADD_COUPON_RESET";

// Admin: Edit coupon
export const ADMIN_EDIT_COUPON_REQUEST = "ADMIN_EDIT_COUPON_REQUEST";
export const ADMIN_EDIT_COUPON_SUCCESS = "ADMIN_EDIT_COUPON_SUCCESS";
export const ADMIN_EDIT_COUPON_FAIL = "ADMIN_EDIT_COUPON_FAIL";
export const ADMIN_EDIT_COUPON_RESET = "ADMIN_EDIT_COUPON_RESET";

// Admin: Delete coupon
export const ADMIN_DELETE_COUPON_REQUEST = "ADMIN_DELETE_COUPON_REQUEST";
export const ADMIN_DELETE_COUPON_SUCCESS = "ADMIN_DELETE_COUPON_SUCCESS";
export const ADMIN_DELETE_COUPON_FAIL = "ADMIN_DELETE_COUPON_FAIL";
export const ADMIN_DELETE_COUPON_RESET = "ADMIN_DELETE_COUPON_RESET";

// Admin: Change order status
export const ADMIN_CHANGE_ORDER_STATUS_REQUEST =
  "ADMIN_CHANGE_ORDER_STATUS_REQUEST";
export const ADMIN_CHANGE_ORDER_STATUS_SUCCESS =
  "ADMIN_CHANGE_ORDER_STATUS_SUCCESS";
export const ADMIN_CHANGE_ORDER_STATUS_FAIL = "ADMIN_CHANGE_ORDER_STATUS_FAIL";
export const ADMIN_CHANGE_ORDER_STATUS_RESET =
  "ADMIN_CHANGE_ORDER_STATUS_RESET";

// Admin: Change order status
export const ADMIN_UPDATE_SHIPPING_INFO_REQUEST =
  "ADMIN_UPDATE_SHIPPING_INFO_REQUEST";
export const ADMIN_UPDATE_SHIPPING_INFO_SUCCESS =
  "ADMIN_UPDATE_SHIPPING_INFO_SUCCESS";
export const ADMIN_UPDATE_SHIPPING_INFO_FAIL =
  "ADMIN_UPDATE_SHIPPING_INFO_FAIL";
export const ADMIN_UPDATE_SHIPPING_INFO_RESET =
  "ADMIN_UPDATE_SHIPPING_INFO_RESET";

export const ADMIN_DELETE_PC_REQUEST = "ADMIN_DELETE_PC_REQUEST";
export const ADMIN_DELETE_PC_SUCCESS = "ADMIN_DELETE_PC_SUCCESS";
export const ADMIN_DELETE_PC_FAIL = "ADMIN_DELETE_PC_FAIL";
export const ADMIN_DELETE_PC_RESET = "ADMIN_DELETE_PC_RESET";

export const ADMIN_GET_ALL_ORDERS_REQUEST = "ADMIN_GET_ALL_ORDERS_REQUEST";
export const ADMIN_GET_ALL_ORDERS_SUCCESS = "ADMIN_GET_ALL_ORDERS_SUCCESS";
export const ADMIN_GET_ALL_ORDERS_FAIL = "ADMIN_GET_ALL_ORDERS_FAIL";
export const ADMIN_GET_ALL_ORDERS_RESET = "ADMIN_GET_ALL_ORDERS_RESET";

export const ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_REQUEST =
  "ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_REQUEST";
export const ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_SUCCESS =
  "ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_SUCCESS";
export const ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_FAIL =
  "ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_FAIL";
export const ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_RESET =
  "ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_RESET";

export const ADMIN_GET_ORDER_DETAILS_REQUEST =
  "ADMIN_GET_ORDER_DETAILS_REQUEST";
export const ADMIN_GET_ORDER_DETAILS_SUCCESS =
  "ADMIN_GET_ORDER_DETAILS_SUCCESS";
export const ADMIN_GET_ORDER_DETAILS_FAIL = "ADMIN_GET_ORDER_DETAILS_FAIL";
export const ADMIN_GET_ORDER_DETAILS_RESET = "ADMIN_GET_ORDER_DETAILS_RESET";

// Admin: Get all quote requests
export const ADMIN_GET_ALL_QUOTE_REQUESTS_REQUEST =
  "ADMIN_GET_ALL_QUOTE_REQUESTS_REQUEST";
export const ADMIN_GET_ALL_QUOTE_REQUESTS_SUCCESS =
  "ADMIN_GET_ALL_QUOTE_REQUESTS_SUCCESS";
export const ADMIN_GET_ALL_QUOTE_REQUESTS_FAIL =
  "ADMIN_GET_ALL_QUOTE_REQUESTS_FAIL";
export const ADMIN_GET_ALL_QUOTE_REQUESTS_RESET =
  "ADMIN_GET_ALL_QUOTE_REQUESTS_RESET";

// Admin: Get quote request details
export const ADMIN_GET_QUOTE_REQUEST_DETAILS_REQUEST =
  "ADMIN_GET_QUOTE_REQUEST_DETAILS_REQUEST";
export const ADMIN_GET_QUOTE_REQUEST_DETAILS_SUCCESS =
  "ADMIN_GET_QUOTE_REQUEST_DETAILS_SUCCESS";
export const ADMIN_GET_QUOTE_REQUEST_DETAILS_FAIL =
  "ADMIN_GET_QUOTE_REQUEST_DETAILS_FAIL";
export const ADMIN_GET_QUOTE_REQUEST_DETAILS_RESET =
  "ADMIN_GET_QUOTE_REQUEST_DETAILS_RESET";

// Admin: Get all customer messages
export const ADMIN_GET_ALL_CUSTOMER_MESSAGES_REQUEST =
  "ADMIN_GET_ALL_CUSTOMER_MESSAGES_REQUEST";
export const ADMIN_GET_ALL_CUSTOMER_MESSAGES_SUCCESS =
  "ADMIN_GET_ALL_CUSTOMER_MESSAGES_SUCCESS";
export const ADMIN_GET_ALL_CUSTOMER_MESSAGES_FAIL =
  "ADMIN_GET_ALL_CUSTOMER_MESSAGES_FAIL";
export const ADMIN_GET_ALL_CUSTOMER_MESSAGES_RESET =
  "ADMIN_GET_ALL_CUSTOMER_MESSAGES_RESET";

// Admin: Get customer message details
export const ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_REQUEST =
  "ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_REQUEST";
export const ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_SUCCESS =
  "ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_SUCCESS";
export const ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_FAIL =
  "ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_FAIL";
export const ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_RESET =
  "ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_RESET";

// Admin: Get all manufacture rates
export const ADMIN_GET_ALL_MANUFACTURE_RATES_REQUEST =
  "ADMIN_GET_ALL_MANUFACTURE_RATES_REQUEST";
export const ADMIN_GET_ALL_MANUFACTURE_RATES_SUCCESS =
  "ADMIN_GET_ALL_MANUFACTURE_RATES_SUCCESS";
export const ADMIN_GET_ALL_MANUFACTURE_RATES_FAIL =
  "ADMIN_GET_ALL_MANUFACTURE_RATES_FAIL";
export const ADMIN_GET_ALL_MANUFACTURE_RATES_RESET =
  "ADMIN_GET_ALL_MANUFACTURE_RATES_RESET";

// Admin: Get all manufacture rates
export const ADMIN_UPDATE_MANUFACTURE_RATE_REQUEST =
  "ADMIN_UPDATE_MANUFACTURE_RATE_REQUEST";
export const ADMIN_UPDATE_MANUFACTURE_RATE_SUCCESS =
  "ADMIN_UPDATE_MANUFACTURE_RATE_SUCCESS";
export const ADMIN_UPDATE_MANUFACTURE_RATE_FAIL =
  "ADMIN_UPDATE_MANUFACTURE_RATE_FAIL";
export const ADMIN_UPDATE_MANUFACTURE_RATE_RESET =
  "ADMIN_UPDATE_MANUFACTURE_RATE_RESET";

// Admin: Get all material pricing
export const ADMIN_GET_ALL_MATERIAL_PRICING_REQUEST =
  "ADMIN_GET_ALL_MATERIAL_PRICING_REQUEST";
export const ADMIN_GET_ALL_MATERIAL_PRICING_SUCCESS =
  "ADMIN_GET_ALL_MATERIAL_PRICING_SUCCESS";
export const ADMIN_GET_ALL_MATERIAL_PRICING_FAIL =
  "ADMIN_GET_ALL_MATERIAL_PRICING_FAIL";
export const ADMIN_GET_ALL_MATERIAL_PRICING_RESET =
  "ADMIN_GET_ALL_MATERIAL_PRICING_RESET";

// Admin: Update material pricing
export const ADMIN_UPDATE_MATERIAL_PRICING_REQUEST =
  "ADMIN_UPDATE_MATERIAL_PRICING_REQUEST";
export const ADMIN_UPDATE_MATERIAL_PRICING_SUCCESS =
  "ADMIN_UPDATE_MATERIAL_PRICING_SUCCESS";
export const ADMIN_UPDATE_MATERIAL_PRICING_FAIL =
  "ADMIN_UPDATE_MATERIAL_PRICING_FAIL";
export const ADMIN_UPDATE_MATERIAL_PRICING_RESET =
  "ADMIN_UPDATE_MATERIAL_PRICING_RESET";

// Admin: Get all setting
export const ADMIN_GET_ALL_SETTINGS_REQUEST = "ADMIN_GET_ALL_SETTINGS_REQUEST";
export const ADMIN_GET_ALL_SETTINGS_SUCCESS = "ADMIN_GET_ALL_SETTINGS_SUCCESS";
export const ADMIN_GET_ALL_SETTINGS_FAIL = "ADMIN_GET_ALL_SETTINGS_FAIL";
export const ADMIN_GET_ALL_SETTINGS_RESET = "ADMIN_GET_ALL_SETTINGS_RESET";

// Admin: Get svg preview of linkage kit drawing
export const LINKAGE_KIT_DRAWING_PREVIEW_REQUEST =
  "LINKAGE_KIT_DRAWING_PREVIEW_REQUEST";
export const LINKAGE_KIT_DRAWING_PREVIEW_SUCCESS =
  "LINKAGE_KIT_DRAWING_PREVIEW_SUCCESS";
export const LINKAGE_KIT_DRAWING_PREVIEW_FAIL =
  "LINKAGE_KIT_DRAWING_PREVIEW_FAIL";
export const LINKAGE_KIT_DRAWING_PREVIEW_RESET =
  "LINKAGE_KIT_DRAWING_PREVIEW_RESET";

// Admin: Download file of linkage kit drawing
export const LINKAGE_KIT_DRAWING_REQUEST = "LINKAGE_KIT_DRAWING_REQUEST";
export const LINKAGE_KIT_DRAWING_SUCCESS = "LINKAGE_KIT_DRAWING_SUCCESS";
export const LINKAGE_KIT_DRAWING_FAIL = "LINKAGE_KIT_DRAWING_FAIL";
export const LINKAGE_KIT_DRAWING_RESET = "LINKAGE_KIT_DRAWING_RESET";

// Admin: Download Shipping Ticket for order
export const ADMIN_GET_ORDER_SHIPPING_TICKET_REQUEST =
  "ADMIN_GET_ORDER_SHIPPING_TICKET_REQUEST";
export const ADMIN_GET_ORDER_SHIPPING_TICKET_SUCCESS =
  "ADMIN_GET_ORDER_SHIPPING_TICKET_SUCCESS";
export const ADMIN_GET_ORDER_SHIPPING_TICKET_FAIL =
  "ADMIN_GET_ORDER_SHIPPING_TICKET_FAIL";
export const ADMIN_GET_ORDER_SHIPPING_TICKET_RESET =
  "ADMIN_GET_ORDER_SHIPPING_TICKET_RESET";

// Admin: Download Invoice for order
export const ADMIN_GET_ORDER_INVOICE_REQUEST =
  "ADMIN_GET_ORDER_INVOICE_REQUEST";
export const ADMIN_GET_ORDER_INVOICE_SUCCESS =
  "ADMIN_GET_ORDER_INVOICE_SUCCESS";
export const ADMIN_GET_ORDER_INVOICE_FAIL = "ADMIN_GET_ORDER_INVOICE_FAIL";
export const ADMIN_GET_ORDER_INVOICE_RESET = "ADMIN_GET_ORDER_INVOICE_RESET";

// Admin: Change reseller status
export const ADMIN_UPDATE_RESELLER_STATUS_REQUEST =
  "ADMIN_UPDATE_RESELLER_STATUS_REQUEST";
export const ADMIN_UPDATE_RESELLER_STATUS_SUCCESS =
  "ADMIN_UPDATE_RESELLER_STATUS_SUCCESS";
export const ADMIN_UPDATE_RESELLER_STATUS_FAIL =
  "ADMIN_UPDATE_RESELLER_STATUS_FAIL";
export const ADMIN_UPDATE_RESELLER_STATUS_RESET =
  "ADMIN_UPDATE_RESELLER_STATUS_RESET";

// Admin: Get manufacture rate by kit sku
export const ADMIN_GET_MANUFACTURE_RATE_REQUEST =
  "ADMIN_GET_MANUFACTURE_RATE_REQUEST";
export const ADMIN_GET_MANUFACTURE_RATE_SUCCESS =
  "ADMIN_GET_MANUFACTURE_RATE_SUCCESS";
export const ADMIN_GET_MANUFACTURE_RATE_FAIL =
  "ADMIN_GET_MANUFACTURE_RATE_FAIL";
export const ADMIN_GET_MANUFACTURE_RATE_RESET =
  "ADMIN_GET_MANUFACTURE_RATE_RESET";

// Admin: Reset Message
export const ADMIN_MESSAGE_RESET = "ADMIN_MESSAGE_RESET";
