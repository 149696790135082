import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as Search20Svg } from "../svg/search-20.svg";

const SearchBox = ({ refetch, queryString, setQueryString }) => {
  const [input, setInput] = useState("");

  const navigate = useNavigate();

  const onSubmitHandler = async (e, input) => {
    e.preventDefault();
    // setQueryString();
    // navigate(`/build?sku=${encodeURIComponent(input.trim())}`);
    // refetch();
  };

  return (
    <>
      {/* <h5 class="mb-3">Find a Linkage Kit its SKU/Part Number</h5> */}
      <div className="search mb-3">
        <form
          onSubmit={(e) => onSubmitHandler(e, queryString)}
          className="search__body"
          style={{ maxWidth: "none" }}
        >
          <input
            className="search__input"
            onChange={(e) => setQueryString(e.target.value)}
            type="text"
            placeholder={"Start typing the linkage kit SKU"}
          />
          {/* <button className="btn btn-primary" type="submit" aria-label="Search Button">
            <span className="search__button-icon d-flex">
              <Search20Svg width="20" height="20" className="mr-1" />
              Search
            </span>
          </button> */}
          <div className="search__box"></div>
          <div className="search__decor">
            <div className="search__decor-start"></div>
            <div className="search__decor-end"></div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SearchBox;
