import React, {
  useEffect,
  //useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { startCase } from "lodash";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  // CForm,
  // CFormControl,
  // CFormFeedback,
  // CFormLabel,
  // CModal,
  // CModalBody,
  // CModalFooter,
  // CModalHeader,
  // CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import {
  getAllSettings,
  // updateManufactureRate,
} from "../../redux/actions/adminActions";
import LoadingWidget from "../../components/LoadingWidget";
// import { Formik } from "formik";
// import * as Yup from "yup";
// import { ADMIN_MESSAGE_RESET } from "../../redux/constants";

const AdminSettings = () => {
  const dispatch = useDispatch();

  const admin = useSelector((state) => state.admin);
  const { loading, success, message, error, status } = admin;

  const allSettings = useSelector((state) => state.admin.settings.settings);

  useEffect(() => {
    dispatch(getAllSettings());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      dispatch(getAllSettings());
    }
  }, [dispatch, success]);

  return (
    <>
      {loading ? (
        <LoadingWidget />
      ) : (
        <>
          {/* {showEditRateModal && (
            <CModal visible={showEditRateModal} alignment="center">
              <Formik
                validationSchema={Yup.object().shape({
                  multiplier: Yup.string().required(
                    'Please enter a multiplier'
                  ),
                })}
                onSubmit={(values) => {
                  dispatch(
                    updateManufactureRate(rateDetails._id, {
                      multiplier: values.multiplier,
                    })
                  );
                }}
                initialValues={{
                  multiplier: rateDetails.multiplier,
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  setValues,
                  setFieldValue,
                  touched,
                  isValid,
                  errors,
                  isSubmitting,
                }) => (
                  <CForm noValidate onSubmit={handleSubmit}>
                    <CModalHeader>
                      <CModalTitle>Update Manufacture Rate</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                      {error && (
                        <div className="alert alert-danger">{error}</div>
                      )}
                      <CFormLabel>Multiplier</CFormLabel>
                      <CFormControl
                        type="text"
                        name="multiplier"
                        value={values.multiplier}
                        onChange={handleChange}
                        isInvalid={!!errors.multiplier && touched.multiplier}
                      />
                      <CFormFeedback>{errors.multiplier}</CFormFeedback>
                    </CModalBody>
                    <CModalFooter>
                      <CButton
                        type="button"
                        color="light"
                        onClick={() => {
                          setRateDetails({});
                          setShowEditRateModal(false);
                        }}
                      >
                        Cancel
                      </CButton>
                      <CButton
                        type="submit"
                        color="primary"
                        disabled={rateDetails.multiplier === values.multiplier}
                      >
                        Update
                      </CButton>
                    </CModalFooter>
                  </CForm>
                )}
              </Formik>
            </CModal>
          )} */}
          {success && message && <div className="alert alert-success">{message}</div>}
          <CRow>
            <CCol xs={12}>
              <CCard className="mb-4">
                <CCardHeader>
                  <strong>Settings</strong>
                </CCardHeader>
                <CCardBody>
                  <CTable>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Value</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {allSettings.map((setting) => (
                        <CTableRow>
                          <CTableDataCell>{startCase(setting.key)}</CTableDataCell>
                          <CTableDataCell>{setting.value}</CTableDataCell>
                          <CTableDataCell>
                            <CButton
                              type="button"
                              color="light"
                              size="sm"
                              // onClick={() => {
                              //   dispatch({ type: ADMIN_MESSAGE_RESET });
                              //   setSettingDetails(setting);
                              //   setShowEditRateModal(true);
                              // }}
                            >
                              <i className="fas fa-edit"></i>
                            </CButton>
                          </CTableDataCell>
                        </CTableRow>
                      ))}
                    </CTableBody>
                  </CTable>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </>
      )}
    </>
  );
};

export default AdminSettings;
