import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAdminStatusQuery } from "../../redux/api/authApi";
import {
  SET_ACTUATOR_SELECTION_TYPE,
  SET_LINKAGE_KIT_SELECTOR_STEP,
  SET_LINKAGE_KIT_SELECTOR_STEPS,
} from "../../redux/constants";

const SelectActuatorChoices = () => {
  const dispatch = useDispatch();

  const adminStatus = useAdminStatusQuery();

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const linkageKitSelections = useSelector((state) => state.selectLinkageKit);
  const { steps } = linkageKitSelections;

  return (
    <>
      <div className="actuator-selection-type-btns-container d-block p-1">
        <button
          className="btn btn-lg btn-dark"
          onClick={() => {
            dispatch({ type: SET_ACTUATOR_SELECTION_TYPE, payload: "byModel" });
            dispatch({
              type: SET_LINKAGE_KIT_SELECTOR_STEPS,
              payload: [
                ...steps,
                {
                  value: "selectActuatorByModel",
                  label: "Select Actuator By Model",
                },
                {
                  value: "summary",
                  label: "Summary",
                },
              ],
            });
            dispatch({
              type: SET_LINKAGE_KIT_SELECTOR_STEP,
              payload: {
                value: "selectActuatorByModel",
                label: "Select Actuator By Model",
              },
            });
          }}
        >
          By Model
        </button>
        <button
          className="btn btn-lg btn-dark"
          onClick={() => {
            dispatch({ type: SET_ACTUATOR_SELECTION_TYPE, payload: "byISO5211" });
            dispatch({
              type: SET_LINKAGE_KIT_SELECTOR_STEPS,
              payload: [
                ...steps,
                {
                  value: "selectActuatorByISO5211",
                  label: "Select Actuator By ISO 5211 Standard",
                },
                {
                  value: "summary",
                  label: "Summary",
                },
              ],
            });
            dispatch({
              type: SET_LINKAGE_KIT_SELECTOR_STEP,
              payload: {
                value: "selectActuatorByISO5211",
                label: "Select Actuator By ISO 5211 Standard",
              },
            });
          }}
        >
          By ISO 5211 Standard
        </button>
        {adminStatus?.data === true ? (
          <button
            className="btn btn-lg btn-dark"
            onClick={() => {
              dispatch({ type: SET_ACTUATOR_SELECTION_TYPE, payload: "byDimensions" });
              dispatch({
                type: SET_LINKAGE_KIT_SELECTOR_STEPS,
                payload: [
                  ...steps,
                  { value: "actuatorDriveType", label: "Select Actuator Drive Type" },
                  { value: "actuatorDriveDimensions", label: "Enter Actuator Drive Dimensions" },
                  { value: "actuatorMountingType", label: "Enter Actuator Mounting Type" },
                  {
                    value: "actuatorMountingDimensions",
                    label: "Enter Actuator Mounting Dimensions",
                  },
                  { value: "actuatorCustomName", label: "Name Your Actuator" },
                  { value: "summary", label: "Summary" },
                ],
              });
              dispatch({
                type: SET_LINKAGE_KIT_SELECTOR_STEP,
                payload: { value: "actuatorDriveType", label: "Select Actuator Drive Type" },
              });
            }}
          >
            <i className="fas fa-lock mr-2"></i> By Dimensions
          </button>
        ) : null}
      </div>
    </>
  );
};

export default SelectActuatorChoices;
