import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { ADDED_TO_CART_MODAL_OPEN } from "../../redux/constants";
import {
  fetchBracketHeights,
  fetchPrice,
  getKitWeight,
  selectBracketHeight,
  selectComponents,
  selectLockout,
  selectQuantity,
  setSku,
} from "../../redux/actions/optionsActions";
import ItemDetails from "./ItemDetails";
import { getManufactureRateOfKitBySku } from "../../redux/actions/adminActions";
import { useGetLinkageKitPriceMutation } from "../../redux/api/linkageKitApi";

const SkuSearchResults = ({ linkagekit }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  const user = useSelector((state) => state.userDetails.user);
  const cart = useSelector((state) => state.cart);
  const admin = useSelector((state) => state.admin);

  // bring linkage kit selections in from redux state
  const linkageKitSelections = useSelector((state) => state.selectLinkageKit);
  const {
    loading,
    bracketHeights,
    lockout,
    selectedBracketHeight,
    components,
    useCustomDataSheets,
    qtyOnePrice,
    price,
    sku,
    qty,
  } = linkageKitSelections;

  const { selectedValve, selectedActuator } = linkagekit;

  const initialBracketHeight = linkagekit.selectedBracketHeight;
  const initialLockout = linkagekit.lockout;
  const initialComponents = linkagekit.components;
  const initialQty = linkagekit.qty;

  const item = {
    productType: "mountingKit",
    sku: sku,
    valve: selectedValve,
    actuator: selectedActuator,
    lockout: lockout,
    bracketHeight: selectedBracketHeight,
    components: components,
    useCustomDataSheets: useCustomDataSheets,
    qty: Number(qty),
    price: price,
    qtyOnePrice: qtyOnePrice,
  };

  const [getPriceQuery, getPriceQueryResult] = useGetLinkageKitPriceMutation();

  async function getPrice() {
    await getPriceQuery({
      productType: item.productType,
      sku: sku,
      qty: qty,
    });
  }

  useEffect(() => {
    if (sku !== null && sku !== "" && sku !== undefined && qty !== null) {
      getPrice();

      if (user && user.isAdmin) {
        dispatch(getManufactureRateOfKitBySku(sku));
      }
    }
  }, [dispatch, sku]);

  useEffect(() => {
    dispatch(fetchBracketHeights(item));

    //dispatch(getKitWeight({ items: [{ sku: initialSku, qty: 1 }] }));
  }, [dispatch]);

  useEffect(() => {
    if (!bracketHeights.includes(selectedBracketHeight)) {
      dispatch(selectBracketHeight(bracketHeights[0]));
    }
  }, [bracketHeights]);

  useEffect(() => {
    dispatch(selectBracketHeight(initialBracketHeight));
    dispatch(selectLockout(initialLockout));
    dispatch(selectComponents(initialComponents));
    dispatch(selectQuantity(initialQty));
  }, [dispatch]);

  useEffect(() => {
    if (
      selectedBracketHeight !== null &&
      lockout !== null &&
      components !== null
    ) {
      dispatch(
        setSku(
          selectedValve,
          selectedActuator,
          lockout,
          components,
          selectedBracketHeight
        )
      );
    }
  }, [dispatch, lockout, components, selectedBracketHeight]);

  useEffect(() => {
    if (
      sku !== "" &&
      selectedBracketHeight !== null &&
      lockout !== null &&
      components !== null &&
      qty !== null
    ) {
      navigate(`/build?sku=${sku}&qty=${qty}`);
    }
  }, [dispatch, sku, qty]);

  // useEffect(() => {
  //   if (cart.addToCart.success) {
  //     dispatch({ type: ADDED_TO_CART_MODAL_OPEN });
  //   }
  // }, [cart.addToCart]);

  return (
    <>
      <ItemDetails
        loading={loading}
        item={item}
        sku={sku}
        price={getPriceQueryResult?.data?.price}
        qty={qty}
        qtyOnePrice={qtyOnePrice}
        selectedValve={selectedValve}
        selectedActuator={selectedActuator}
        components={components}
        lockout={lockout}
        bracketHeights={bracketHeights}
        selectedBracketHeight={selectedBracketHeight}
        user={user}
        admin={admin}
      />
    </>
  );
};

export default SkuSearchResults;
