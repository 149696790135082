import { isEmpty } from "ramda";
import {
  SELECTED_VALVE_BRAND,
  SELECTED_VALVE_SERIES,
  SELECTED_VALVE_SIZE,
  SELECTED_VALVE_REQUEST,
  SELECTED_VALVE_SUCCESS,
  SELECTED_VALVE_FAIL,
  VALVE_SELECTIONS_RESET,
  ACTUATOR_SELECT_BY_ISO,
  ACTUATOR_OPTIONS_REQUEST,
  ACTUATOR_OPTIONS_SUCCESS,
  ACTUATOR_OPTIONS_FAIL,
  SELECTED_ACTUATOR_BRAND,
  SELECTED_ACTUATOR_SERIES,
  SELECTED_ACTUATOR_MODEL,
  SELECTED_ACTUATOR_REQUEST,
  SELECTED_ACTUATOR_SUCCESS,
  SELECTED_ACTUATOR_FAIL,
  SELECTED_ACTUATOR_RESET,
  ACTUATOR_SELECTIONS_RESET,
  OPTIONS_LOCKOUT,
  OPTIONS_BRACKET_HEIGHT,
  OPTIONS_COMPONENTS,
  OPTIONS_USE_CUSTOM_DATA_SHEETS,
  OPTIONS_QUANTITY,
  SELECTED_KIT_SKU,
  LINKAGE_KIT_PRICE_REQUEST,
  LINKAGE_KIT_PRICE_SUCCESS,
  LINKAGE_KIT_PRICE_FAIL,
  LINKAGE_KIT_DIMENSIONS_REQUEST,
  LINKAGE_KIT_DIMENSIONS_SUCCESS,
  LINKAGE_KIT_DIMENSIONS_FAIL,
  BRACKET_HEIGHTS_REQUEST,
  BRACKET_HEIGHTS_SUCCESS,
  BRACKET_HEIGHTS_FAIL,
  LINKAGE_KIT_SELECTIONS_RESET,
  SELECTED_VALVE_RESET,
  LINKAGE_KIT_DRAWING_PREVIEW_REQUEST,
  LINKAGE_KIT_DRAWING_PREVIEW_SUCCESS,
  LINKAGE_KIT_DRAWING_PREVIEW_FAIL,
  OPTIONS_LOCKOUT_RESET,
  BRACKET_HEIGHTS_RESET,
  OPTIONS_BRACKET_HEIGHT_RESET,
  OPTIONS_COMPONENTS_RESET,
  VALVE_OPTIONS_REQUEST,
  VALVE_OPTIONS_SUCCESS,
  VALVE_OPTIONS_FAIL,
  SELECTED_VALVE_OPTIONS,
  SELECTED_VALVE_OPTIONS_RESET,
  SELECTED_ACTUATOR_OPTIONS,
  SELECTED_ACTUATOR_ISO5211_FLANGE,
  SELECTED_ACTUATOR_ISO5211_DRIVE_TYPE,
  SELECTED_ACTUATOR_ISO5211_DRIVE_SIZE,
  ACTUATOR_SELECTION_TYPE,
  SELECTED_ACTUATOR_OPTIONS_RESET,
  ACTUATOR_OPTIONS_RESET,
  GET_KIT_WEIGHT_REQUEST,
  GET_KIT_WEIGHT_SUCCESS,
  GET_KIT_WEIGHT_FAIL,
  SET_LOADING,
  SET_LINKAGE_KIT_SELECTOR_STEP,
  SET_VALVE_SELECTION_TYPE,
  SET_ACTUATOR_SELECTION_TYPE,
  SET_LINKAGE_KIT_SELECTOR_STEPS,
} from "../constants";

export const selectLinkageKitReducer = (
  state = {
    step: {
      value: "",
      label: "",
    },
    steps: [
      {
        value: "selectValveByModel",
        label: "Select Valve By Model",
      },
      {
        value: "chooseActuatorSelectionType",
        label: "How Would You Like To Select Your Actuator?",
      },
    ],
    valveSelectionType: "byModel",
    actuatorSelectionType: "byModel",
    loading: false,
    valveOptions: [],
    actuatorOptions: [],
    selectedValveBrand: "",
    selectedValveSeries: "",
    selectedValveSize: "",
    selectedValveOptions: [],
    selectedActuatorISO5211Flange: "",
    selectedActuatorISO5211DriveType: "",
    selectedActuatorISO5211DriveSize: "",
    selectedActuatorBrand: "",
    selectedActuatorSeries: "",
    selectedActuatorModel: "",
    selectedActuatorOptions: [],
    selectedValve: {},
    selectedActuator: {},
    lockout: false,
    bracketHeights: [],
    selectedBracketHeight: null,
    components: "Bracket and Coupler",
    useCustomDataSheets: false,
    sku: "",
    price: "",
    qtyOnePrice: "",
    qty: 1,
    weight: null,
    dimensions: {},
    drawingPreview: "",
    message: null,
    error: null,
    status: null,
  },
  action
) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_LINKAGE_KIT_SELECTOR_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case SET_LINKAGE_KIT_SELECTOR_STEPS:
      return {
        ...state,
        steps: action.payload,
      };
    case SET_VALVE_SELECTION_TYPE:
      return {
        ...state,
        valveSelectionType: action.payload,
      };
    case SET_ACTUATOR_SELECTION_TYPE:
      return {
        ...state,
        actuatorSelectionType: action.payload,
      };
    case VALVE_OPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VALVE_OPTIONS_SUCCESS:
      let valveOptions = [...state.valveOptions];

      const matchingValveOptions = state.valveOptions.filter(
        (valveOption) =>
          Object.keys(valveOption)[0] === Object.keys(action.payload[0])[0]
      );

      if (isEmpty(matchingValveOptions)) {
        valveOptions.push(action.payload[0]);
      }

      return {
        ...state,
        loading: false,
        valveOptions: valveOptions,
      };
    case VALVE_OPTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SELECTED_VALVE_BRAND:
      return {
        ...state,
        selectedValveBrand: action.payload,
        valveOptions: [],
      };
    case SELECTED_VALVE_SERIES:
      return {
        ...state,
        selectedValveSeries: action.payload,
        valveOptions: [],
      };
    case SELECTED_VALVE_SIZE:
      return {
        ...state,
        selectedValveSize: action.payload,
        valveOptions: [],
      };
    case SELECTED_VALVE_OPTIONS:
      const v_index = state.selectedValveOptions.findIndex(
        (option) => Object.keys(option)[0] === Object.keys(action.payload)[0]
      );

      if (state.selectedValveOptions.length > 0 && v_index !== -1) {
        state.selectedValveOptions[v_index] = action.payload;
        // When an option is selected, reset all the following selections by setting the array length to the v_index plus 1
        state.selectedValveOptions.length = v_index + 1;
      } else if (v_index === -1) {
        state.selectedValveOptions.push(action.payload);
      }

      return {
        ...state,
        // Spread mutated state into empty array so useEffect will still trigger
        selectedValveOptions: [...state.selectedValveOptions],
      };
    case SELECTED_VALVE_OPTIONS_RESET:
      return {
        ...state,
        selectedValveOptions: [],
      };
    case SELECTED_VALVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SELECTED_VALVE_SUCCESS:
      let v_arr = [];

      // Push all option keys of selected valve to empty array
      action.payload.options.map((op) => v_arr.push(Object.keys(op)[0]));

      // Filter valveOptions based on options of selected valve to eliminate unrelated options from state
      const updatedOptions = state.valveOptions.filter((option) =>
        v_arr.includes(Object.keys(option)[0])
      );

      return {
        ...state,
        loading: false,
        selectedValve: action.payload,
        valveOptions: updatedOptions,
      };
    case SELECTED_VALVE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SELECTED_VALVE_RESET:
      return {
        ...state,
        selectedValve: {},
      };
    case VALVE_SELECTIONS_RESET:
      return {
        ...state,
        valveBrands: [],
        valveSeries: [],
        valveSizes: [],
        valveOptions: [],
        selectedValveBrand: "",
        selectedValveSeries: "",
        selectedValvesSize: "",
        selectedValveOptions: {},
        selectedValve: {},
        lockout: false,
        selectedBracketHeight: null,
        components: "",
      };
    case ACTUATOR_SELECTION_TYPE:
      return {
        ...state,
        actuatorSelectionType: action.payload,
      };
    case ACTUATOR_OPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTUATOR_OPTIONS_SUCCESS:
      let actuatorOptions = [...state.actuatorOptions];

      const matchingActuatorOptions = state.actuatorOptions.filter(
        (actuatorOption) =>
          Object.keys(actuatorOption)[0] === Object.keys(action.payload[0])[0]
      );

      if (isEmpty(matchingActuatorOptions)) {
        actuatorOptions.push(action.payload[0]);
      }

      return {
        ...state,
        loading: false,
        actuatorOptions: actuatorOptions,
      };
    case ACTUATOR_OPTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ACTUATOR_OPTIONS_RESET:
      return {
        ...state,
        loading: false,
        actuatorOptions: [],
        error: null,
      };
    case SELECTED_ACTUATOR_ISO5211_FLANGE:
      return {
        ...state,
        selectedActuatorISO5211Flange: action.payload,
      };
    case SELECTED_ACTUATOR_ISO5211_DRIVE_TYPE:
      return {
        ...state,
        selectedActuatorISO5211DriveType: action.payload,
      };
    case SELECTED_ACTUATOR_ISO5211_DRIVE_SIZE:
      return {
        ...state,
        selectedActuatorISO5211DriveSize: action.payload,
      };
    case SELECTED_ACTUATOR_BRAND:
      return {
        ...state,
        selectedActuatorBrand: action.payload,
        actuatorOptions: [],
      };
    case SELECTED_ACTUATOR_SERIES:
      return {
        ...state,
        selectedActuatorSeries: action.payload,
        actuatorOptions: [],
      };
    case SELECTED_ACTUATOR_MODEL:
      return {
        ...state,
        selectedActuatorModel: action.payload,
        actuatorOptions: [],
      };
    case SELECTED_ACTUATOR_OPTIONS:
      const a_index = state.selectedActuatorOptions.findIndex(
        (option) => Object.keys(option)[0] === Object.keys(action.payload)[0]
      );

      if (state.selectedActuatorOptions.length > 0 && a_index !== -1) {
        state.selectedActuatorOptions[a_index] = action.payload;
        // When an option is selected, reset all the following selections by setting the array length to the a_index plus 1
        state.selectedActuatorOptions.length = a_index + 1;
      } else if (a_index === -1) {
        state.selectedActuatorOptions.push(action.payload);
      }

      return {
        ...state,
        // Spread mutated state into empty array so useEffect will still trigger
        selectedActuatorOptions: [...state.selectedActuatorOptions],
      };
    case SELECTED_ACTUATOR_OPTIONS_RESET:
      return {
        ...state,
        selectedActuatorOptions: [],
      };
    case ACTUATOR_SELECTIONS_RESET:
      return {
        ...state,
        actuatorBrands: [],
        actuatorSeries: [],
        actuatorModels: [],
        actuatorOptions: [],
        actuatorProtectionLevels: [],
        selectedActuatorBrand: "",
        selectedActuatorSeries: "",
        selectedActuatorModel: "",
        selectedActuatorProtectionLevel: "",
        selectedActuator: {},
        lockout: false,
        selectedBracketHeight: null,
        components: "",
      };
    case SELECTED_ACTUATOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SELECTED_ACTUATOR_SUCCESS:
      let a_arr = [];
      let updatedActuatorOptions;

      if (action.payload.options) {
        // Push all option keys of selected actuator to empty array
        action.payload.options.map((op) => a_arr.push(Object.keys(op)[0]));

        // Filter actuatorOptions based on options of selected actuator to eliminate unrelated options from state
        updatedActuatorOptions = state.actuatorOptions.filter((option) =>
          a_arr.includes(Object.keys(option)[0])
        );
      }

      return {
        ...state,
        loading: false,
        selectedActuator: action.payload,
        actuatorOptions: action.payload.options ? updatedActuatorOptions : [],
      };
    case SELECTED_ACTUATOR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SELECTED_ACTUATOR_RESET:
      return {
        ...state,
        loading: false,
        selectedActuator: {},
      };
    case OPTIONS_LOCKOUT:
      return {
        ...state,
        lockout: action.payload,
      };
    case OPTIONS_LOCKOUT_RESET:
      return {
        ...state,
        lockout: false,
      };
    case BRACKET_HEIGHTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case BRACKET_HEIGHTS_SUCCESS:
      return {
        ...state,
        loading: false,
        bracketHeights: action.payload,
      };
    case BRACKET_HEIGHTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case BRACKET_HEIGHTS_RESET:
      return {
        ...state,
        bracketHeights: [],
      };
    case OPTIONS_BRACKET_HEIGHT:
      return {
        ...state,
        selectedBracketHeight: action.payload,
      };
    case OPTIONS_BRACKET_HEIGHT_RESET:
      return {
        ...state,
        selectedBracketHeight: null,
      };
    case OPTIONS_COMPONENTS:
      return {
        ...state,
        components: action.payload,
        // lockout: action.payload !== "Bracket and Coupler" && false,
      };
    case OPTIONS_COMPONENTS_RESET:
      return {
        ...state,
        components: "",
      };
    case OPTIONS_USE_CUSTOM_DATA_SHEETS:
      return {
        ...state,
        useCustomDataSheets: action.payload,
      };
    case OPTIONS_QUANTITY:
      return {
        ...state,
        qty: action.payload,
      };
    case SELECTED_KIT_SKU:
      return {
        ...state,
        sku: action.payload,
      };
    case LINKAGE_KIT_PRICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LINKAGE_KIT_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        price: action.payload.price,
        qtyOnePrice: action.payload.qtyOnePrice,
      };
    case LINKAGE_KIT_PRICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LINKAGE_KIT_DIMENSIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LINKAGE_KIT_DIMENSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        dimensions: action.payload.data,
      };
    case LINKAGE_KIT_DIMENSIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.data.message,
      };
    case LINKAGE_KIT_DRAWING_PREVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LINKAGE_KIT_DRAWING_PREVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        drawingPreview: action.payload.data,
      };
    case LINKAGE_KIT_DRAWING_PREVIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.data.message,
      };
    case GET_KIT_WEIGHT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_KIT_WEIGHT_SUCCESS:
      return {
        ...state,
        loading: false,
        weight: action.payload.totalWeight,
      };
    case GET_KIT_WEIGHT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.data.message,
      };
    case LINKAGE_KIT_SELECTIONS_RESET:
      return {
        ...state,
        step: action?.payload?.step
          ? action.payload.step
          : {
              value: "selectValveByModel",
              label: "Select Valve By Model",
            },
        steps: action?.payload?.steps
          ? action.payload.steps
          : [
              {
                value: "selectValveByModel",
                label: "Select Valve By Model",
              },
              {
                value: "chooseActuatorSelectionType",
                label: "How Would You Like To Select Your Actuator?",
              },
            ],
        valveSelectionType: "byModel",
        actuatorSelectionType: "byModel",
        loading: false,
        valveOptions: [],
        actuatorOptions: [],
        selectedValveBrand: "",
        selectedValveSeries: "",
        selectedValveSize: "",
        selectedValveOptions: [],
        selectedActuatorISO5211Flange: "",
        selectedActuatorISO5211DriveType: "",
        selectedActuatorISO5211DriveSize: "",
        selectedActuatorBrand: "",
        selectedActuatorSeries: "",
        selectedActuatorModel: "",
        selectedActuatorOptions: [],
        selectedValve: {},
        selectedActuator: {},
        lockout: false,
        bracketHeights: [],
        selectedBracketHeight: null,
        components: "Bracket and Coupler",
        useCustomDataSheets: false,
        sku: "",
        price: "",
        qtyOnePrice: "",
        qty: 1,
        weight: null,
        dimensions: {},
        drawingPreview: "",
        message: null,
        error: null,
        status: null,
      };
    default:
      return state;
  }
};
