import React from "react";
import { TailSpin } from "react-loading-icons";

const LoadingWidget = ({ opacity }) => {
  return (
    <>
      <div
        className="animate__animated animate__fadeIn"
        style={{ height: `calc(100vh)`, background: `rgba(255,255,255,${opacity})` }}
      >
        <TailSpin
          stroke="#1a79ff"
          speed={1}
          style={{
            position: "absolute",
            top: "50vh",
            left: "50vw",
            display: "block",
          }}
        />
      </div>
    </>
  );
};

export default LoadingWidget;
