import { configureStore } from "@reduxjs/toolkit";
import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension";
import rootReducer from "./reducers";
import { api } from "./slices/api";
import { persistToLocalStorage } from "./middleware/persistToLocalStorage";


const composeEnhancers = composeWithDevToolsDevelopmentOnly({
  trace: true,
  traceLimit: 25,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(api.middleware)
      .concat(persistToLocalStorage),
  devTools: true,
  // preloadedState: initialState,
  composeEnhancers: composeEnhancers,
});

export default store;
