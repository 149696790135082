import { api } from "../slices/api";

// Define a service using a base URL and expected endpoints
export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getValveBrands: builder.query({
      query: () => "v1/valves/brands",
    }),
    getValveSeries: builder.query({
      query: ({ brand }) => `v1/valves/${brand}/series`,
    }),
    getValveSizes: builder.query({
      query: ({ brand, series }) => `v1/valves/${brand}/${series}/sizes`,
    }),
    getValveOptions: builder.query({
      query: ({ brand, series, size }) =>
        `v1/valves/${brand}/${series}/${size}/options`,
    }),
  }),
});

export const {
  useGetValveBrandsQuery,
  useGetValveSeriesQuery,
  useGetValveSizesQuery,
  useGetValveOptionsQuery,
} = extendedApiSlice;
