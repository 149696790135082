import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { deleteCoupon, getAllCoupons, getSingleUser } from "../../redux/actions/adminActions";
import { AddCouponModal } from "../../components/admin/modals/AddCouponModal";
import { EditCouponModal } from "../../components/admin/modals/EditCouponModal";
import {
  ADMIN_ADD_COUPON_MODAL,
  ADMIN_EDIT_COUPON_MODAL,
  ADMIN_MESSAGE_RESET,
} from "../../redux/constants";

const AdminCoupons = () => {
  const dispatch = useDispatch();

  const [couponDetails, setCouponDetails] = useState({});
  const [confirmDeleteCouponModal, setConfirmDeleteCouponModal] = useState(false);

  const admin = useSelector((state) => state.admin);
  const { loading, success, coupons, error, message, status } = admin;

  useEffect(() => {
    dispatch(getAllCoupons());
  }, [dispatch]);

  useEffect(() => {
    if (message === "Coupon added successfully") {
      dispatch({ type: ADMIN_ADD_COUPON_MODAL });
      dispatch(getAllCoupons());
      toast.success(message);
      dispatch({ type: ADMIN_MESSAGE_RESET });
    }
  }, [dispatch, message]);

  useEffect(() => {
    if (message === "Coupon updated successfully") {
      dispatch({ type: ADMIN_EDIT_COUPON_MODAL });
      dispatch(getAllCoupons());
      toast.success(message);
      dispatch({ type: ADMIN_MESSAGE_RESET });
    }
  }, [dispatch, message]);

  useEffect(() => {
    if (message === "Coupon deleted successfully") {
      setConfirmDeleteCouponModal(false);
      setCouponDetails({});
      dispatch(getAllCoupons());
      toast.success(message);
      dispatch({ type: ADMIN_MESSAGE_RESET });
    }
  }, [dispatch, message]);

  return (
    <>
      {confirmDeleteCouponModal && (
        <CModal visible={confirmDeleteCouponModal} centered>
          <CModalHeader>
            <CModalTitle>Delete Coupon?</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <strong>Coupon Code:</strong> {`${couponDetails.couponCode}`}
          </CModalBody>
          <CModalFooter>
            <CButton
              color="light"
              onClick={() => {
                setConfirmDeleteCouponModal(false);
                setCouponDetails({});
              }}
            >
              Cancel
            </CButton>
            <CButton
              color="danger"
              onClick={() => {
                dispatch(deleteCoupon(couponDetails.couponCode));
              }}
            >
              Delete
            </CButton>
          </CModalFooter>
        </CModal>
      )}
      <AddCouponModal />
      <EditCouponModal coupon={couponDetails} />
      <CRow>
        <CCol xs={12} className="mb-4">
          <CButton color="primary" onClick={() => dispatch({ type: ADMIN_ADD_COUPON_MODAL })}>
            Add Coupon
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Coupons</strong>
            </CCardHeader>
            <CCardBody>
              <CTable>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col">Coupon</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Discount</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Expires</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {coupons.allCoupons.map((coupon) => {
                    let date = new Date(coupon.expiresAt);
                    let formatted = `${
                      date.getMonth() + 1
                    }/${date.getDate()}/${date.getFullYear()}`;
                    return (
                      <CTableRow key={coupon.couponCode}>
                        <CTableDataCell>{coupon.couponCode}</CTableDataCell>
                        <CTableDataCell>
                          {coupon.discountType === "percentage" ? (
                            <>{coupon.discountAmount}%</>
                          ) : (
                            coupon.discountType === "dollar" && <>${coupon.discountAmount}</>
                          )}
                        </CTableDataCell>
                        <CTableDataCell>{formatted}</CTableDataCell>
                        <CTableDataCell>
                          <CButton
                            color="light"
                            size="sm"
                            className="mx-1"
                            onClick={() => {
                              setCouponDetails(coupon);

                              dispatch({
                                type: ADMIN_EDIT_COUPON_MODAL,
                              });
                            }}
                          >
                            <i className="fas fa-edit"></i>
                          </CButton>
                          <CButton
                            color="light"
                            size="sm"
                            className="mx-1"
                            onClick={() => {
                              setConfirmDeleteCouponModal(true);
                              setCouponDetails(coupon);
                            }}
                          >
                            <i className="fas fa-trash"></i>
                          </CButton>
                        </CTableDataCell>
                      </CTableRow>
                    );
                  })}
                </CTableBody>
              </CTable>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default AdminCoupons;
