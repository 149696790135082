// Redparts Theme Styles
import "./styles/main/scss/index.scss";
import "./styles/main/scss/style.header-classic-variant-three.scss";
import "./styles/main/scss/style.mobile-header-variant-one.scss";

import React, { lazy, Suspense, useEffect } from "react";
// import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import LinkedInTag from "react-linkedin-insight";
import ReactPixel from "react-facebook-pixel";
// import { isEmpty } from "lodash";
// import CookieConsent from "react-cookie-consent";

import AccountDashboardScreen from "./screens/account/AccountDashboardScreen";
import AccountProfileScreen from "./screens/account/AccountProfileScreen";
import AccountPasswordScreen from "./screens/account/AccountPasswordScreen";
import AccountOrderHistoryScreen from "./screens/account/AccountOrderHistoryScreen";
import AccountOrderDetailsScreen from "./screens/account/AccountOrderDetailsScreen";
import AccountAddressesScreen from "./screens/account/AccountAddressesScreen";
import AccountEditAddressScreen from "./screens/account/AccountEditAddressScreen";
import AccountAddAddressScreen from "./screens/account/AccountAddAddressScreen";
import AccountResellerScreen from "./screens/account/AccountResellerScreen";
import AccountSavedItemsScreen from "./screens/account/AccountSavedItemsScreen";

import FrequentlyAskedQuestionsScreen from "./screens/FrequentlyAskedQuestionsScreen";

// import EmailSignupModal from "./components/modals/EmailSignupModal";
// import { ComingSoonScreen } from './screens/ComingSoonScreen';

import LoadingWidget from "./components/LoadingWidget";
import { useAuthStatusQuery } from "./redux/api/authApi";
import { useDispatch, useSelector } from "react-redux";

const Header = lazy(() => import("./components/Header"));
const Footer = lazy(() => import("./components/Footer"));
const MobileMenu = lazy(() => import("./components/MobileMenu"));

const HomeScreen = lazy(() => import("./screens/HomeScreen"));
const BuildYourKitScreen = lazy(() => import("./screens/BuildYourKitScreen"));
const LoginScreen = lazy(() => import("./screens/LoginScreen"));
const RegisterScreen = lazy(() => import("./screens/RegisterScreen"));
const LoginOrRegisterScreen = lazy(() => import("./screens/LoginOrRegisterScreen"));
const ForgotPasswordScreen = lazy(() => import("./screens/ForgotPasswordScreen"));
const ResetPasswordScreen = lazy(() => import("./screens/ResetPasswordScreen"));
const ContactUsScreen = lazy(() => import("./screens/ContactUsScreen2"));

const CartScreen = lazy(() => import("./screens/CartScreen"));
const CheckoutScreen = lazy(() => import("./screens/CheckoutScreen"));
const OrderSuccessScreen = lazy(() => import("./screens/OrderSuccessScreen"));
const TermsAndConditionsScreen = lazy(() => import("./screens/TermsAndConditionsScreen"));
const PrivacyPolicyScreen = lazy(() => import("./screens/PrivacyPolicyScreen"));
const ReturnPolicyScreen = lazy(() => import("./screens/ReturnPolicyScreen"));
const AboutUsScreen = lazy(() => import("./screens/AboutUsScreen"));
const RegistrationConfirmationScreen = lazy(() =>
  import("./screens/RegistrationConfirmationScreen")
);
const NotFound = lazy(() => import("./screens/NotFound"));

const ResendConfirmationEmailScreen = lazy(() => import("./screens/ResendConfirmationEmailScreen"));
const RequestQuoteScreen = lazy(() => import("./screens/RequestQuoteScreen"));

// Google Analytics
if (process.env.NODE_ENV === "production") {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);

  LinkedInTag.init(process.env.REACT_APP_LINKEDIN_PARTNER_ID, "dc");
  // LinkedInTag.track(conversionId);

  // Facebook Tracking Pixel
  ReactPixel.init(
    process.env.REACT_APP_FB_PIXEL_ID,
    {},
    {
      autoConfig: true,
      debug: false,
    }
  );
}

function RequireAuth({ children }) {
  const location = useLocation();
  const { isAuthenticated } = useSelector((state) => state.userDetails);

  return isAuthenticated === true ? (
    <Routes>{children}</Routes>
  ) : isAuthenticated === false ? (
    <Navigate to={`/login?redirect=${location.pathname}`} state={{ from: location }} replace />
  ) : null;
}

const Main = () => {
  // const userDetails = useSelector((state) => state.userDetails);
  // const { user } = userDetails;

  useEffect(() => {
    ReactPixel.pageView();
  }, []);

  useEffect(() => {
    const body = document.getElementsByTagName("BODY")[0];

    body.removeAttribute("id", "admin");
    body.setAttribute("id", "main");
  }, []);

  return (
    <>
      <Suspense fallback={<LoadingWidget />}>
        <div className="site site--desktop-header--classic-three site--mobile-header--mobile-one">
          <div className="site__container">
            <Header />
            <div className="site__body">
              <Routes>
                <Route path="/cart" element={<CartScreen />} />

                <Route path="/login" element={<LoginScreen />} />
                <Route path="/register" element={<RegisterScreen />} />
                <Route
                  path="/cart/checkout/login-or-register"
                  element={<LoginOrRegisterScreen />}
                />
                <Route path="/contact-us" element={<ContactUsScreen />} />
                <Route path="/about-us" element={<AboutUsScreen />} />

                <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
                <Route path="/reset-password/:token" element={<ResetPasswordScreen />} />

                <Route path="/confirm-registration" element={<RegistrationConfirmationScreen />} />
                <Route
                  path="/resend-confirmation-email"
                  element={<ResendConfirmationEmailScreen />}
                />
                <Route path="/request-quote" element={<RequestQuoteScreen />} />

                <Route path="/terms-and-conditions" element={<TermsAndConditionsScreen />} />

                <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />

                <Route path="/return-policy" element={<ReturnPolicyScreen />} />

                <Route path="/faq" element={<FrequentlyAskedQuestionsScreen />} />

                <Route
                  path="/cart/*"
                  element={
                    <RequireAuth>
                      <Route path="checkout" element={<CheckoutScreen />} />
                      <Route path="checkout/order-success" element={<OrderSuccessScreen />} />
                    </RequireAuth>
                  }
                />

                {/* ACCOUNT ROUTES */}
                <Route
                  path="/account/*"
                  element={
                    <RequireAuth>
                      <Route path="dashboard" element={<AccountDashboardScreen />} />
                      <Route path="profile" element={<AccountProfileScreen />} />
                      <Route path="order-history" element={<AccountOrderHistoryScreen />} />
                      <Route path="saved-items" element={<AccountSavedItemsScreen />} />
                      <Route path="order-details/:id" element={<AccountOrderDetailsScreen />} />
                      <Route path="addresses" element={<AccountAddressesScreen />} />
                      <Route path="addresses/add" element={<AccountAddAddressScreen />} />
                      <Route path="addresses/edit/:id" element={<AccountEditAddressScreen />} />
                      <Route path="password" element={<AccountPasswordScreen />} />
                      <Route path="reseller" element={<AccountResellerScreen />} />
                      <Route path="*" element={<AccountDashboardScreen />} />
                    </RequireAuth>
                  }
                />

                <Route path="/build" element={<BuildYourKitScreen />} />
                <Route path="/" element={<HomeScreen />} />

                {/* 404 Page */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
            <Footer />
            <MobileMenu />
          </div>
        </div>
      </Suspense>
      {/* Enable after proper styling */}
      {/* <CookieConsent
            location="bottom"
            buttonText="I Agree"
            cookieName="cookieConsent"
            style={{ background: '#262626' }}
            buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
            expires={150}
          >
            This website uses cookies
          </CookieConsent> */}
    </>
    //   )}
    // </>
  );
};

export default Main;
