import { createSlice } from "@reduxjs/toolkit";

const cartTokenFromStorage = localStorage.getItem("cart_token")
  ? localStorage.getItem("cart_token")
  : null;

const initialState = {
  loading: false,
  token: cartTokenFromStorage,
  canProceedToCheckout: null,
  items: [],
  unavailableCartItems: [],
  subtotalBeforeDiscounts: 0.0,
  shippingMethod: null,
  paymentMethod: null,
  coupon: {
    coupon: null,
    discount: 0.0,
  },
  subtotal: 0.0,
  isTaxExempt: null,
  taxRates: {
    state: null,
    local: null,
    combined: null,
  },
  tax: 0.0,
  shipping: 0.0,
  total: 0.0,
  isPreferredCustomer: false,
  preferredCustomer: {
    discount: null,
  },
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartToken: (state, action) => {
      state.token = action.payload.token;
      localStorage.setItem("cart_token", action.payload.token);
    },
    setCartLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCart: (state, action) => {
      state.token = action.payload._id;
      state.canProceedToCheckout = action.payload.canProceedToCheckout;
      state.items = action.payload.items;
      state.subtotalBeforeDiscounts = action.payload.subtotalBeforeDiscounts;
      state.coupon = action.payload.coupon;
      state.isPreferredCustomer = action.payload.isPreferredCustomer;

      if (action.payload.isPreferredCustomer) {
        state.preferredCustomer.discount =
          action.payload.preferredCustomer.discount;
      }

      state.shippingMethod = action.payload.shippingMethod;
      state.paymentMethod = action.payload.paymentMethod;
      state.subtotal = action.payload.subtotal;
      state.shipping = action.payload.shipping;
      state.isTaxExempt = action.payload.isTaxExempt;
      state.taxRates = action.payload.taxRates;
      state.tax = action.payload.tax;
      state.total = action.payload.total;
    },
    clearCart: (state) => {
      state.loading = false;
      state.token = null;
      state.canProceedToCheckout = null;
      state.items = [];
      state.unavailableCartItems = [];
      state.subtotalBeforeDiscounts = 0.0;
      state.shippingMethod = null;
      state.paymentMethod = null;
      state.coupon = {
        coupon: null,
        discount: 0.0,
      };
      state.subtotal = 0.0;
      state.isTaxExempt = null;
      state.taxRates = {
        state: null,
        local: null,
        combined: null,
      };
      state.tax = 0.0;
      state.shipping = 0.0;
      state.total = 0.0;
      state.isPreferredCustomer = false;
      state.preferredCustomer = {
        discount: null,
      };

      localStorage.removeItem("cart_token");
    },
    setShippingMethod: (state, action) => {
      state.shippingMethod = action.payload;
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    setUnavailableCartItems: (state, action) => {
      state.unavailableCartItems = action.payload;
      localStorage.setItem(
        "unavailableCartItems",
        JSON.stringify(action.payload)
      );
    },
    clearUnavailableCartItemsMessage: (state) => {
      state.unavailableCartItems = [];
      localStorage.removeItem("unavailableCartItems");
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCartToken,
  setCartLoading,
  setCart,
  clearCart,
  setShippingMethod,
  setPaymentMethod,
  setUnavailableCartItems,
  clearUnavailableCartItemsMessage,
} = cartSlice.actions;

export default cartSlice.reducer;
