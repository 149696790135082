import {
  ADMIN_SIDEBAR,
  ADMIN_SIDEBAR_UNFOLDABLE,
  ADMIN_GET_ALL_ORDERS_REQUEST,
  ADMIN_GET_ALL_ORDERS_SUCCESS,
  ADMIN_GET_ALL_ORDERS_FAIL,
  ADMIN_GET_ALL_ORDERS_RESET,
  ADMIN_GET_ALL_USERS_REQUEST,
  ADMIN_GET_ALL_USERS_SUCCESS,
  ADMIN_GET_ALL_USERS_FAIL,
  ADMIN_GET_ALL_USERS_RESET,
  ADMIN_GET_PREFERRED_CUSTOMERS_REQUEST,
  ADMIN_GET_PREFERRED_CUSTOMERS_SUCCESS,
  ADMIN_GET_PREFERRED_CUSTOMERS_FAIL,
  ADMIN_CHANGE_ORDER_STATUS_REQUEST,
  ADMIN_CHANGE_ORDER_STATUS_SUCCESS,
  ADMIN_CHANGE_ORDER_STATUS_FAIL,
  ADMIN_CHANGE_ORDER_STATUS_RESET,
  ADMIN_GET_ALL_COUPONS_REQUEST,
  ADMIN_GET_ALL_COUPONS_SUCCESS,
  ADMIN_GET_ALL_COUPONS_FAIL,
  ADMIN_GET_ALL_COUPONS_RESET,
  ADMIN_ADD_COUPON_REQUEST,
  ADMIN_ADD_COUPON_SUCCESS,
  ADMIN_ADD_COUPON_FAIL,
  ADMIN_ADD_COUPON_RESET,
  ADMIN_EDIT_COUPON_REQUEST,
  ADMIN_EDIT_COUPON_SUCCESS,
  ADMIN_EDIT_COUPON_FAIL,
  ADMIN_EDIT_COUPON_RESET,
  ADMIN_DELETE_COUPON_REQUEST,
  ADMIN_DELETE_COUPON_SUCCESS,
  ADMIN_DELETE_COUPON_FAIL,
  ADMIN_DELETE_COUPON_RESET,
  ADMIN_GET_ALL_VALVES_REQUEST,
  ADMIN_GET_ALL_VALVES_SUCCESS,
  ADMIN_GET_ALL_VALVES_FAIL,
  ADMIN_GET_ALL_ACTUATORS_REQUEST,
  ADMIN_GET_ALL_ACTUATORS_SUCCESS,
  ADMIN_GET_ALL_ACTUATORS_FAIL,
  ADMIN_ADD_COUPON_MODAL,
  ADMIN_EDIT_COUPON_MODAL,
  ADMIN_ADD_VALVE_MODAL,
  ADMIN_EDIT_VALVE_MODAL,
  ADMIN_ADD_PREFERRED_CUSTOMER_MODAL,
  ADMIN_DELETE_VALVE_REQUEST,
  ADMIN_DELETE_VALVE_SUCCESS,
  ADMIN_DELETE_VALVE_FAIL,
  ADMIN_GET_ORDER_DETAILS_REQUEST,
  ADMIN_GET_ORDER_DETAILS_SUCCESS,
  ADMIN_GET_ORDER_DETAILS_FAIL,
  ADMIN_GET_ALL_EARLY_ACCESS_USERS_REQUEST,
  ADMIN_GET_ALL_EARLY_ACCESS_USERS_SUCCESS,
  ADMIN_GET_ALL_EARLY_ACCESS_USERS_FAIL,
  ADMIN_ADD_EARLY_ACCESS_USER_MODAL,
  ADMIN_ADD_EARLY_ACCESS_USER_REQUEST,
  ADMIN_ADD_EARLY_ACCESS_USER_SUCCESS,
  ADMIN_ADD_EARLY_ACCESS_USER_FAIL,
  ADMIN_GET_ALL_QUOTE_REQUESTS_REQUEST,
  ADMIN_GET_ALL_QUOTE_REQUESTS_SUCCESS,
  ADMIN_GET_ALL_QUOTE_REQUESTS_FAIL,
  ADMIN_GET_QUOTE_REQUEST_DETAILS_REQUEST,
  ADMIN_GET_QUOTE_REQUEST_DETAILS_SUCCESS,
  ADMIN_GET_QUOTE_REQUEST_DETAILS_FAIL,
  ADMIN_GET_ALL_MANUFACTURE_RATES_REQUEST,
  ADMIN_GET_ALL_MANUFACTURE_RATES_SUCCESS,
  ADMIN_GET_ALL_MANUFACTURE_RATES_FAIL,
  ADMIN_UPDATE_MANUFACTURE_RATE_REQUEST,
  ADMIN_UPDATE_MANUFACTURE_RATE_SUCCESS,
  ADMIN_UPDATE_MANUFACTURE_RATE_FAIL,
  ADMIN_MESSAGE_RESET,
  ADMIN_GET_ORDER_SHIPPING_TICKET_REQUEST,
  ADMIN_GET_ORDER_SHIPPING_TICKET_SUCCESS,
  ADMIN_GET_ORDER_SHIPPING_TICKET_FAIL,
  ADMIN_GET_ORDER_SHIPPING_TICKET_RESET,
  ADMIN_GET_ALL_MATERIAL_PRICING_REQUEST,
  ADMIN_GET_ALL_MATERIAL_PRICING_SUCCESS,
  ADMIN_GET_ALL_MATERIAL_PRICING_FAIL,
  ADMIN_UPDATE_MATERIAL_PRICING_REQUEST,
  ADMIN_UPDATE_MATERIAL_PRICING_SUCCESS,
  ADMIN_UPDATE_MATERIAL_PRICING_FAIL,
  ADMIN_GET_ALL_CUSTOMER_MESSAGES_REQUEST,
  ADMIN_GET_ALL_CUSTOMER_MESSAGES_SUCCESS,
  ADMIN_GET_ALL_CUSTOMER_MESSAGES_FAIL,
  ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_REQUEST,
  ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_SUCCESS,
  ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_FAIL,
  ADMIN_GET_SINGLE_USER_REQUEST,
  ADMIN_GET_SINGLE_USER_SUCCESS,
  ADMIN_GET_SINGLE_USER_FAIL,
  ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_REQUEST,
  ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_SUCCESS,
  ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_FAIL,
  ADMIN_ADD_PREFERRED_CUSTOMER_REQUEST,
  ADMIN_ADD_PREFERRED_CUSTOMER_SUCCESS,
  ADMIN_ADD_PREFERRED_CUSTOMER_FAIL,
  ADMIN_GET_PREFERRED_CUSTOMER_STATUS_REQUEST,
  ADMIN_GET_PREFERRED_CUSTOMER_STATUS_SUCCESS,
  ADMIN_GET_PREFERRED_CUSTOMER_STATUS_FAIL,
  ADMIN_GET_ALL_SETTINGS_REQUEST,
  ADMIN_GET_ALL_SETTINGS_SUCCESS,
  ADMIN_GET_ALL_SETTINGS_FAIL,
  ADMIN_UPDATE_RESELLER_STATUS_SUCCESS,
  ADMIN_UPDATE_RESELLER_STATUS_FAIL,
  ADMIN_UPDATE_RESELLER_STATUS_RESET,
  ADMIN_UPDATE_RESELLER_STATUS_REQUEST,
  ADMIN_GET_MANUFACTURE_RATE_REQUEST,
  ADMIN_GET_MANUFACTURE_RATE_SUCCESS,
  ADMIN_GET_MANUFACTURE_RATE_FAIL,
  ADMIN_UPDATE_SHIPPING_INFO_REQUEST,
  ADMIN_UPDATE_SHIPPING_INFO_SUCCESS,
  ADMIN_UPDATE_SHIPPING_INFO_FAIL,
  ADMIN_UPDATE_SHIPPING_INFO_RESET,
  ADMIN_ADD_VALVES_FROM_CSV_STRING_MODAL,
  ADMIN_ADD_VALVES_FROM_CSV_STRING_REQUEST,
  ADMIN_ADD_VALVES_FROM_CSV_STRING_SUCCESS,
  ADMIN_ADD_VALVES_FROM_CSV_STRING_FAIL,
  ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_MODAL,
  ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_REQUEST,
  ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_SUCCESS,
  ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_FAIL,
} from "../constants";

export const adminReducer = (
  state = {
    loading: true,
    application: {
      sidebarShow: false,
      sidebarUnfoldable: false,
      addCouponModal: false,
      editCouponModal: false,
      addValveModal: false,
      addValvesFromCsvStringModal: false,
      editValveModal: false,
      addActuatorsFromCsvStringModal: false,
    },
    allUsers: [],
    userDetails: {
      orders: [],
      preferredCustomerStatus: {},
    },
    updateResellerStatus: {
      loading: false,
      succes: null,
      message: null,
      error: null,
      status: null,
    },
    allOrders: [],
    orderDetails: {},
    getShippingTicket: {
      loading: false,
      success: null,
      message: null,
      error: null,
      status: null,
    },
    getInvoice: {
      loading: false,
      success: null,
      message: null,
      error: null,
      status: null,
    },
    changeOrderStatus: {
      loading: false,
      success: null,
      message: null,
      error: null,
      status: null,
    },
    updateShippingInfo: {
      loading: false,
      success: null,
      message: null,
      error: null,
      status: null,
    },
    quoteRequests: {
      allQuoteRequests: [],
      quoteRequestDetails: {},
    },
    customerMessages: {
      allCustomerMessages: [],
      customerMessageDetails: {},
    },
    coupons: {
      allCoupons: [],
    },
    preferredCustomers: [],
    earlyAccess: {
      allEarlyAccessUsers: [],
    },
    database: {
      valves: {
        allValves: [],
        addValvesFromCsvString: {
          loading: false,
          success: null,
          message: null,
          details: {},
          error: null,
          status: null,
        },
      },
      actuators: {
        allActuators: [],
        addActuatorsFromCsvString: {
          loading: false,
          success: null,
          message: null,
          details: {},
          error: null,
          status: null,
        },
      },
    },
    settings: {
      settings: [],
      manufactureRates: {
        allManufactureRates: [],
        manufactureRate: {},
      },
      materialPricing: {
        allMaterialPricing: [],
      },
    },
    success: null,
    message: null,
    error: null,
    status: null,
  },
  action
) => {
  switch (action.type) {
    case ADMIN_SIDEBAR:
      return {
        ...state,
        application: { sidebarShow: !state.application.sidebarShow },
      };
    case ADMIN_SIDEBAR_UNFOLDABLE:
      return {
        ...state,
        application: {
          sidebarUnfoldable: !state.application.sidebarUnfoldable,
        },
      };
    case ADMIN_ADD_COUPON_MODAL:
      return {
        ...state,
        application: {
          addCouponModal: !state.application.addCouponModal,
        },
      };
    case ADMIN_EDIT_COUPON_MODAL:
      return {
        ...state,
        application: {
          editCouponModal: !state.application.editCouponModal,
        },
      };
    case ADMIN_ADD_VALVE_MODAL:
      return {
        ...state,
        application: {
          addValveModal: !state.application.addValveModal,
        },
      };
    case ADMIN_ADD_VALVES_FROM_CSV_STRING_MODAL:
      return {
        ...state,
        application: {
          addValvesFromCsvStringModal: !state.application.addValvesFromCsvStringModal,
        },
      };
    case ADMIN_EDIT_VALVE_MODAL:
      return {
        ...state,
        application: {
          editValveModal: !state.application.editValveModal,
        },
      };
    case ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_MODAL:
      return {
        ...state,
        application: {
          addActuatorsFromCsvStringModal: !state.application.addActuatorsFromCsvStringModal,
        },
      };
    case ADMIN_ADD_PREFERRED_CUSTOMER_MODAL:
      return {
        ...state,
        application: {
          addPreferredCustomerModal: !state.application.addPreferredCustomerModal,
        },
      };
    case ADMIN_ADD_EARLY_ACCESS_USER_MODAL:
      return {
        ...state,
        application: {
          addEarlyAccessUserModal: !state.application.addEarlyAccessUserModal,
        },
      };
    case ADMIN_GET_ALL_USERS_REQUEST:
      return { ...state, loading: true };
    case ADMIN_GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        allUsers: action.payload.data,
        status: action.payload.status,
      };
    case ADMIN_GET_ALL_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.message,
        status: action.payload.response.status,
      };
    case ADMIN_GET_SINGLE_USER_REQUEST:
      return { ...state, loading: true };
    case ADMIN_GET_SINGLE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: {
          ...state.userDetails,
          ...action.payload.data,
        },
        status: action.payload.status,
      };
    case ADMIN_GET_SINGLE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.message,
        status: action.payload.response.status,
      };
    case ADMIN_GET_PREFERRED_CUSTOMERS_REQUEST:
      return { ...state, loading: true };
    case ADMIN_GET_PREFERRED_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        preferredCustomers: action.payload.data,
        status: action.payload.status,
      };
    case ADMIN_GET_PREFERRED_CUSTOMERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.message,
        status: action.payload.response.status,
      };
    case ADMIN_GET_PREFERRED_CUSTOMER_STATUS_REQUEST:
      return { ...state, loading: true };
    case ADMIN_GET_PREFERRED_CUSTOMER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: {
          ...state.userDetails,
          preferredCustomerStatus: action.payload.data,
        },
        status: action.payload.status,
      };
    case ADMIN_GET_PREFERRED_CUSTOMER_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.message,
        status: action.payload.response.status,
      };
    case ADMIN_ADD_PREFERRED_CUSTOMER_REQUEST:
      return { ...state, loading: true };
    case ADMIN_ADD_PREFERRED_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: action.payload.data.message,
      };
    case ADMIN_ADD_PREFERRED_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.message,
        status: action.payload.response.status,
      };
    case ADMIN_GET_ALL_USERS_RESET:
      return {
        ...state,
        users: [],
      };
    case ADMIN_GET_ALL_ORDERS_REQUEST:
      return { ...state, loading: true };
    case ADMIN_GET_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        allOrders: action.payload.data,
        status: action.payload.status,
      };
    case ADMIN_GET_ALL_ORDERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.message,
        status: action.payload.response.status,
      };
    case ADMIN_GET_ALL_ORDERS_RESET:
      return {
        ...state,
        orders: [],
      };
    case ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_REQUEST:
      return { ...state, loading: true };
    case ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: {
          ...state.userDetails,
          orders: action.payload.data,
        },
        status: action.payload.status,
      };
    case ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.message,
        status: action.payload.response.status,
      };
    case ADMIN_GET_ORDER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ADMIN_GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        orderDetails: action.payload.data,
        status: action.payload.status,
      };
    case ADMIN_GET_ORDER_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.message,
        status: action.payload.response.status,
      };
    case ADMIN_GET_ALL_COUPONS_REQUEST:
      return { ...state, loading: true };
    case ADMIN_GET_ALL_COUPONS_SUCCESS:
      return {
        ...state,
        loading: false,
        coupons: { allCoupons: action.payload.data },
        status: action.payload.status,
      };
    case ADMIN_GET_ALL_COUPONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.message,
        status: action.payload.response.status,
      };
    case ADMIN_GET_ALL_COUPONS_RESET:
      return {
        ...state,
        coupons: { allCoupons: [] },
      };
    case ADMIN_ADD_COUPON_REQUEST:
      return { ...state, loading: true };
    case ADMIN_ADD_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: action.payload.data.message,
        status: action.payload.status,
      };
    case ADMIN_ADD_COUPON_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.response.data.message,
        status: action.payload.response.status,
      };
    case ADMIN_ADD_COUPON_RESET:
      return {};
    case ADMIN_EDIT_COUPON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADMIN_EDIT_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: action.payload.data.message,
        status: action.payload.status,
      };
    case ADMIN_EDIT_COUPON_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.response.data.message,
        status: action.payload.response.status,
      };
    case ADMIN_DELETE_COUPON_REQUEST:
      return { ...state, loading: true };
    case ADMIN_DELETE_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.data.message,
        status: action.payload.status,
      };
    case ADMIN_DELETE_COUPON_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.data.message,
      };
    case ADMIN_GET_ALL_VALVES_REQUEST:
      return { ...state, loading: true };
    case ADMIN_GET_ALL_VALVES_SUCCESS:
      return {
        ...state,
        loading: false,
        database: {
          ...state.database,
          valves: { ...state.database.valves, allValves: action.payload.data },
        },
        status: action.payload.status,
      };
    case ADMIN_GET_ALL_VALVES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.message,
        status: action.payload.response.status,
      };
    case ADMIN_ADD_VALVES_FROM_CSV_STRING_REQUEST:
      return {
        ...state,
        database: {
          ...state.database,
          valves: {
            ...state.database.valves,
            addValvesFromCsvString: {
              ...state.database.valves.addValvesFromCsvString,
              loading: true,
            },
          },
        },
      };
    case ADMIN_ADD_VALVES_FROM_CSV_STRING_SUCCESS:
      return {
        ...state,
        database: {
          ...state.database,
          valves: {
            ...state.database.valves,
            addValvesFromCsvString: {
              ...state.database.valves.addValvesFromCsvString,
              loading: false,
              success: true,
              message: action.payload.data.message,
              details: action.payload.data.details,
              error: null,
              status: action.payload.status,
            },
          },
        },
      };
    case ADMIN_ADD_VALVES_FROM_CSV_STRING_FAIL:
      return {
        ...state,
        database: {
          ...state.database,
          valves: {
            ...state.database.valves,
            addValvesFromCsvString: {
              ...state.database.valves.addValvesFromCsvString,
              loading: false,
              success: false,
              message: null,
              details: null,
              error: action.payload.response.data.message,
              status: action.payload.response.status,
            },
          },
        },
      };
    case ADMIN_DELETE_VALVE_REQUEST:
      return { ...state, loading: true };
    case ADMIN_DELETE_VALVE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.data.message,
        status: action.payload.status,
      };
    case ADMIN_DELETE_VALVE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.data.message,
      };
    case ADMIN_GET_ALL_ACTUATORS_REQUEST:
      return { ...state, loading: true };
    case ADMIN_GET_ALL_ACTUATORS_SUCCESS:
      return {
        ...state,
        loading: false,
        database: {
          ...state.database,
          actuators: {
            ...state.database.actuators,
            allActuators: action.payload.data,
          },
        },
        status: action.payload.status,
      };
    case ADMIN_GET_ALL_ACTUATORS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.data.message,
        status: action.payload.response.status,
      };
    case ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_REQUEST:
      return {
        ...state,
        database: {
          ...state.database,
          actuators: {
            ...state.database.actuators,
            addActuatorsFromCsvString: {
              ...state.database.actuators.addActuatorsFromCsvString,
              loading: true,
            },
          },
        },
      };
    case ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_SUCCESS:
      return {
        ...state,
        database: {
          ...state.database,
          actuators: {
            ...state.database.actuators,
            addActuatorsFromCsvString: {
              ...state.database.actuators.addActuatorsFromCsvString,
              loading: false,
              success: true,
              message: action.payload.data.message,
              details: action.payload.data.details,
              error: null,
              status: action.payload.status,
            },
          },
        },
      };
    case ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_FAIL:
      return {
        ...state,
        database: {
          ...state.database,
          actuators: {
            ...state.database.actuators,
            addActuatorsFromCsvString: {
              ...state.database.actuators.addActuatorsFromCsvString,
              loading: false,
              success: false,
              message: null,
              details: null,
              error: action.payload.response.data.message,
              status: action.payload.response.status,
            },
          },
        },
      };
    case ADMIN_GET_ALL_EARLY_ACCESS_USERS_REQUEST:
      return { ...state, loading: true };
    case ADMIN_GET_ALL_EARLY_ACCESS_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        earlyAccess: {
          ...state.earlyAccess,
          allEarlyAccessUsers: action.payload.data,
        },
        status: action.payload.status,
      };
    case ADMIN_GET_ALL_EARLY_ACCESS_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.data.message,
        status: action.payload.response.status,
      };
    case ADMIN_ADD_EARLY_ACCESS_USER_REQUEST:
      return { ...state, loading: true };
    case ADMIN_ADD_EARLY_ACCESS_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.data.message,
        status: action.payload.status,
      };
    case ADMIN_ADD_EARLY_ACCESS_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.data.message,
        status: action.payload.response.status,
      };
    case ADMIN_GET_ALL_QUOTE_REQUESTS_REQUEST:
      return { ...state, loading: true };
    case ADMIN_GET_ALL_QUOTE_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        quoteRequests: {
          ...state.quoteRequests,
          allQuoteRequests: action.payload.data,
        },
        status: action.payload.status,
      };
    case ADMIN_GET_ALL_QUOTE_REQUESTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.data.message,
        status: action.payload.response.status,
      };
    case ADMIN_GET_ALL_CUSTOMER_MESSAGES_REQUEST:
      return { ...state, loading: true };
    case ADMIN_GET_ALL_CUSTOMER_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        customerMessages: {
          ...state.customerMessages,
          allCustomerMessages: action.payload.data,
        },
        status: action.payload.status,
      };
    case ADMIN_GET_ALL_CUSTOMER_MESSAGES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.data.message,
        status: action.payload.response.status,
      };
    case ADMIN_GET_QUOTE_REQUEST_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ADMIN_GET_QUOTE_REQUEST_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        quoteRequests: {
          ...state.quoteRequests,
          quoteRequestDetails: action.payload.data,
        },
        status: action.payload.status,
      };
    case ADMIN_GET_QUOTE_REQUEST_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.data.message,
        status: action.payload.response.status,
      };
    case ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        customerMessages: {
          ...state.customerMessages,
          customerMessageDetails: action.payload.data,
        },
        status: action.payload.status,
      };
    case ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.data.message,
        status: action.payload.response.status,
      };
    case ADMIN_GET_ALL_MANUFACTURE_RATES_REQUEST:
      return { ...state, loading: true };
    case ADMIN_GET_ALL_MANUFACTURE_RATES_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: {
          ...state.settings,
          manufactureRates: {
            ...state.manufactureRates,
            allManufactureRates: action.payload.data,
          },
        },
        status: action.payload.status,
      };
    case ADMIN_GET_ALL_MANUFACTURE_RATES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.data.message,
        status: action.payload.response.status,
      };
    case ADMIN_GET_MANUFACTURE_RATE_REQUEST:
      return { ...state, loading: true };
    case ADMIN_GET_MANUFACTURE_RATE_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: {
          ...state.settings,
          manufactureRates: {
            ...state.manufactureRates,
            manufactureRate: action.payload.data,
          },
        },
        status: action.payload.status,
      };
    case ADMIN_GET_MANUFACTURE_RATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.data.message,
        status: action.payload.response.status,
      };
    case ADMIN_UPDATE_MANUFACTURE_RATE_REQUEST:
      return { ...state, loading: true, message: null };
    case ADMIN_UPDATE_MANUFACTURE_RATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: action.payload.data.message,
        status: action.payload.status,
      };
    case ADMIN_UPDATE_MANUFACTURE_RATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.response.data.message,
        status: action.payload.response.status,
      };
    case ADMIN_GET_ALL_MATERIAL_PRICING_REQUEST:
      return { ...state, loading: true };
    case ADMIN_GET_ALL_MATERIAL_PRICING_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: {
          ...state.settings,
          materialPricing: {
            ...state.materialPricing,
            allMaterialPricing: action.payload.data,
          },
        },
        status: action.payload.status,
      };
    case ADMIN_GET_ALL_MATERIAL_PRICING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.data.message,
        status: action.payload.response.status,
      };
    case ADMIN_UPDATE_MATERIAL_PRICING_REQUEST:
      return { ...state, loading: true };
    case ADMIN_UPDATE_MATERIAL_PRICING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: action.payload.data.message,
        status: action.payload.status,
      };
    case ADMIN_UPDATE_MATERIAL_PRICING_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.response.data.message,
        status: action.payload.response.status,
      };
    case ADMIN_GET_ALL_SETTINGS_REQUEST:
      return { ...state, loading: true };
    case ADMIN_GET_ALL_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: {
          ...state.settings,
          settings: action.payload.data,
        },
        status: action.payload.status,
      };
    case ADMIN_GET_ALL_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.response.data.message,
        status: action.payload.response.status,
      };
    case ADMIN_CHANGE_ORDER_STATUS_REQUEST:
      return {
        ...state,
        changeOrderStatus: {
          ...state.changeOrderStatus,
          loading: true,
          message: null,
        },
      };
    case ADMIN_CHANGE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        changeOrderStatus: {
          loading: false,
          success: true,
          message: action.payload.data.message,
          status: action.payload.status,
        },
      };
    case ADMIN_CHANGE_ORDER_STATUS_FAIL:
      return {
        ...state,
        changeOrderStatus: {
          loading: false,
          success: false,
          error: action.payload.response.data.message,
          status: action.payload.response.status,
        },
      };
    case ADMIN_CHANGE_ORDER_STATUS_RESET:
      return {
        ...state,
        changeOrderStatus: {
          loading: false,
          message: null,
          success: null,
          error: null,
          status: null,
        },
      };
    case ADMIN_UPDATE_SHIPPING_INFO_REQUEST:
      return {
        ...state,
        updateShippingInfo: {
          ...state.updateShippingInfo,
          loading: true,
          message: null,
        },
      };
    case ADMIN_UPDATE_SHIPPING_INFO_SUCCESS:
      return {
        ...state,
        updateShippingInfo: {
          ...state.updateShippingInfo,
          loading: false,
          success: true,
          message: action.payload.data.message,
          status: action.payload.status,
        },
      };
    case ADMIN_UPDATE_SHIPPING_INFO_FAIL:
      return {
        ...state,
        updateShippingInfo: {
          ...state.updateShippingInfo,
          loading: false,
          success: false,
          error: action.payload.response.data.message,
          status: action.payload.response.status,
        },
      };
    case ADMIN_UPDATE_SHIPPING_INFO_RESET:
      return {
        ...state,
        updateShippingInfo: {
          loading: false,
          message: null,
          success: null,
          error: null,
          status: null,
        },
      };
    case ADMIN_GET_ORDER_SHIPPING_TICKET_REQUEST:
      return {
        ...state,
        getShippingTicket: {
          ...state.getShippingTicket,
          loading: true,
        },
      };
    case ADMIN_GET_ORDER_SHIPPING_TICKET_SUCCESS:
      return {
        ...state,
        getShippingTicket: {
          ...state.getShippingTicket,
          loading: false,
          success: true,
          message: action.payload.data.message,
          status: action.payload.status,
        },
      };
    case ADMIN_GET_ORDER_SHIPPING_TICKET_FAIL:
      return {
        ...state,
        getShippingTicket: {
          ...state.getShippingTicket,
          loading: false,
          success: false,
          message: action.payload.response.data.message,
          status: action.payload.response.status,
        },
      };
    case ADMIN_GET_ORDER_SHIPPING_TICKET_RESET:
      return {
        ...state,
        getShippingTicket: {
          loading: false,
          success: null,
          message: null,
          status: null,
        },
      };
    case ADMIN_UPDATE_RESELLER_STATUS_REQUEST:
      return {
        ...state,
        updateResellerStatus: {
          ...state.updateResellerStatus,
          loading: true,
        },
      };
    case ADMIN_UPDATE_RESELLER_STATUS_SUCCESS:
      return {
        ...state,
        updateResellerStatus: {
          ...state.updateResellerStatus,
          loading: false,
          success: true,
          message: action.payload.data.message,
          status: action.payload.status,
        },
      };
    case ADMIN_UPDATE_RESELLER_STATUS_FAIL:
      return {
        ...state,
        updateResellerStatus: {
          loading: false,
          success: false,
          message: action.payload.response.data.message,
          status: action.payload.response.status,
        },
      };
    case ADMIN_MESSAGE_RESET:
      return {
        ...state,
        success: null,
        message: null,
        error: null,
        status: null,
      };
    default:
      return state;
  }
};
