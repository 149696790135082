import React from "react";
import { Helmet } from "react-helmet";
import { variables } from "../config";

const FrequentlyAskedQuestionsScreen = () => {
  return (
    <>
      <Helmet>
        <title>FAQ | {variables.WEBSITE_NAME}</title>
        <link rel="canonical" href={`${variables.FULL_URL}/faq`} />
      </Helmet>
      <div className="block-space block-space--layout--spaceship-ledge-height"></div>
      <div className="block faq">
        <div className="container container--max--xl">
          <div className="faq__header">
            <h1 className="faq__header-title">Frequently Asked Questions</h1>
          </div>
          <div className="faq__section">
            <h3 className="faq__section-title">Mounting Kits</h3>
            <div className="faq__section-body">
              <div className="faq__question">
                <h5 className="faq__question-title">
                  What is the typical lead time on mounting kits?
                </h5>
                <div className="faq__question-answer">
                  <div className="typography">
                    <p>
                      Most orders for mounting kits ship within 10 business days or less. If you are
                      purchasing in large quantities (more than 50 kits), lead times may increase to
                      3-4 weeks. If you would like to check the lead time of a current order, please{" "}
                      <a href="/contact-us">contact us</a>.
                    </p>
                  </div>
                </div>
              </div>

              <div className="faq__question">
                <h5 className="faq__question-title">
                  What if my valve or actuator is not on your website?
                </h5>
                <div className="faq__question-answer">
                  <div className="typography">
                    <p>
                      If a particular valve or actuator has not been added to our website yet, you
                      may <a href="/contact-us">contact us</a> to request it be added, and one of
                      our team members will assist you.{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="faq__question">
                <h5 className="faq__question-title">Where are your mounting kits made?</h5>
                <div className="faq__question-answer">
                  <div className="typography">
                    <p>All our mounting kits are designed and manufactured in the United States.</p>
                  </div>
                </div>
              </div>

              <div className="faq__question">
                <h5 className="faq__question-title">What material do you use for mounting kits?</h5>
                <div className="faq__question-answer">
                  <div className="typography">
                    <p>
                      Our mounting kits are manufactured from 300-series stainless steel for maximum
                      strength and corrosion resistance
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="faq__section">
            <h3 className="faq__section-title">Shipping Information</h3>
            <div className="faq__section-body">
              <div className="faq__question">
                <h5 className="faq__question-title">What shipping methods do you offer?</h5>
                <div className="faq__question-answer">
                  <div className="typography">
                    <p>
                      We offer the ability to have shipping costs billed directly to your UPS
                      account by entering your UPS account number at checkout. Alternatively, you
                      may choose a Flat-Rate shipping option.
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq__question">
                <h5 className="faq__question-title">Do you ship internationally?</h5>
                <div className="faq__question-answer">
                  <div className="typography">
                    <p>We do not presently ship to locations outside of the United States.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="faq__section">
            <h3 className="faq__section-title">Payment Information</h3>
            <div className="faq__section-body">
              <div className="faq__question">
                <h5 className="faq__question-title">What methods of payment do you offer?</h5>
                <div className="faq__question-answer">
                  <div className="typography">
                    <p>
                      We accept payments by credit or debit card at checkout. Alternatively, you may
                      pay later by invoice. Invoice terms are NET 10 from the date of delivery.
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq__question">
                <h5 className="faq__question-title">
                  Do I pay sales tax if I have a Certificate of Resale?
                </h5>
                <div className="faq__question-answer">
                  <div className="typography">
                    <p>
                      If your business holds a valid Certificate of Resale (CR), you are eligible to
                      place orders free of sales tax upon the submission and subsequent approval of
                      a copy of your CR. To submit your CR for approval, upload a copy to{" "}
                      <a href="/account/reseller">your account</a> for review by one of our team
                      members.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="faq__section">
            <h3 className="faq__section-title">Orders and Returns</h3>
            <div className="faq__section-body">
              <div className="faq__question">
                <h5 className="faq__question-title">What is your Return Policy?</h5>
                <div className="faq__question-answer">
                  <div className="typography">
                    <p>
                      Our Return Policy can be found <a href="/return-policy">here</a>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="faq__footer">
            <div className="faq__footer-title">Still Have Questions?</div>
            <div className="faq__footer-subtitle">
              We are happy to answer any questions you may have.
            </div>
            <a href="/contact-us" className="btn btn-primary">
              Contact Us
            </a>
          </div>
        </div>
      </div>

      <div className="block-space block-space--layout--before-footer"></div>
    </>
  );
};

export default FrequentlyAskedQuestionsScreen;
