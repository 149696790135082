import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CButton,
  CForm,
  CFormCheck,
  CFormInput,
  CFormFeedback,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import Flatpickr from "react-flatpickr";
import "../../../../node_modules/flatpickr/dist/themes/light.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { addCoupon } from "../../../redux/actions/adminActions";
import { ADMIN_ADD_COUPON_MODAL, ADMIN_MESSAGE_RESET } from "../../../redux/constants";

export const AddCouponModal = () => {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);
  const { loading, message, error, status } = admin;

  return (
    <CModal alignment="center" visible={admin.application.addCouponModal}>
      <CModalHeader onDismiss={() => dispatch({ type: ADMIN_ADD_COUPON_MODAL })}>
        <CModalTitle>Add Coupon</CModalTitle>
      </CModalHeader>
      <Formik
        validationSchema={Yup.object().shape({
          couponCode: Yup.string().required("Required"),
          discountType: Yup.string().required("Required"),
          discountAmount: Yup.string().required("Required"),
          minimumDollarAmount: Yup.string().required("Required"),
          minimumItems: Yup.string().required("Required"),
          expiresAt: Yup.string().required("Required"),
          email: Yup.string().email(),
        })}
        onSubmit={(values) => {
          dispatch({ type: ADMIN_MESSAGE_RESET });
          dispatch(
            addCoupon(
              values.couponCode,
              values.discountType,
              values.discountAmount,
              values.minimumDollarAmount,
              values.minimumItems,
              values.expiresAt,
              values.email
            )
          );
        }}
        initialValues={{
          couponCode: "",
          discountType: "percentage",
          discountAmount: "",
          minimumDollarAmount: "10",
          minimumItems: "1",
          expiresAt: new Date(),
          email: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          touched,
          isValid,
          errors,
          isSubmitting,
        }) => (
          <CForm noValidate onSubmit={handleSubmit}>
            <CModalBody>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="form-row mb-3">
                <div className="col-md-6">
                  <CFormLabel htmlFor="couponCode">Coupon Code</CFormLabel>
                  <CFormInput
                    type="text"
                    name="couponCode"
                    value={values.couponCode}
                    onChange={handleChange}
                    isInvalid={!!errors.couponCode}
                    id="couponCode"
                  />
                  <CFormFeedback invalid>{errors.couponCode}</CFormFeedback>
                </div>
                <div className="col-md-6">
                  <CFormLabel htmlFor="discountAmount">Discount Amount</CFormLabel>
                  <CFormInput
                    type="text"
                    name="discountAmount"
                    value={values.discountAmount}
                    onChange={handleChange}
                    id="discountAmount"
                  />
                  <CFormFeedback invalid>{errors.discountAmount}</CFormFeedback>
                </div>
              </div>
              <div className="form-row mb-3">
                <div className="col-md-6">
                  <CFormLabel htmlFor="minimumDollarAmount">Minimum Dollar Amount</CFormLabel>
                  <CFormInput
                    type="text"
                    name="minimumDollarAmount"
                    value={values.minimumDollarAmount}
                    onChange={handleChange}
                    isInvalid={!!errors.minimumDollarAmount}
                    id="minimumDollarAmount"
                  />
                  <CFormFeedback invalid>{errors.minimumDollarAmount}</CFormFeedback>
                </div>
                <div className="col-md-6">
                  <CFormLabel htmlFor="minimumItems">Minimum Items</CFormLabel>
                  <CFormInput
                    type="text"
                    name="minimumItems"
                    value={values.minimumItems}
                    onChange={handleChange}
                    id="minimumItems"
                  />
                  <CFormFeedback invalid>{errors.minimumItems}</CFormFeedback>
                </div>
              </div>
              <div className="form-row mb-3">
                <div className="col">
                  <CFormLabel htmlFor="email">Email</CFormLabel>
                  <CFormInput
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                    id="email"
                  />
                  <CFormFeedback invalid>{errors.email}</CFormFeedback>
                </div>
              </div>
              <div className="form-row">
                <div className="col">
                  <CFormLabel htmlFor="discountType">Discount Type</CFormLabel>
                  <CFormCheck
                    type="radio"
                    name="discountType"
                    value="percentage"
                    onChange={handleChange}
                    id="discountTypePercentage"
                    label="Percentage"
                    checked={values.discountType === "percentage"}
                  />
                  <CFormCheck
                    type="radio"
                    name="discountType"
                    value="dollar"
                    onChange={handleChange}
                    id="discountTypeDollar"
                    label="Dollar"
                    checked={values.discountType === "dollar"}
                  />
                </div>
                <div className="col">
                  <CFormLabel htmlFor="discountType">Expires</CFormLabel>
                  <Flatpickr
                    data-enable-time
                    value={values.expiresAt}
                    onChange={(date) => {
                      setFieldValue("expiresAt", date[0]);
                    }}
                  />
                </div>
              </div>
            </CModalBody>
            <CModalFooter>
              <CButton
                type="button"
                color="light"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({ type: ADMIN_ADD_COUPON_MODAL });
                }}
              >
                Close
              </CButton>
              <CButton type="submit" color="primary">
                Save
              </CButton>
            </CModalFooter>
          </CForm>
        )}
      </Formik>
    </CModal>
  );
};
