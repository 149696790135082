import Decimal from "decimal.js";

export const vowels = ["a", "e", "i", "o", "u"];

export const unitOptions = [
  { label: "inches", value: "inches" },
  { label: "mm", value: "mm" },
];

export const capitalize = (s) => {
  if (typeof s !== "string") {
    return "";
  }
  const t = s.toLowerCase();
  return t.charAt(0).toUpperCase() + t.slice(1);
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const percentage = (subtotal, discount) => {
  return (discount / 100) * subtotal;
};

export const CartItemsCount = (cartItems) => {
  return cartItems.length > 0
    ? String(cartItems.map((x) => x.qty).reduce((acc, qty) => acc + qty, 0))
    : String(0);
};

export const Subtotal = (cartItems) => {
  return cartItems.length > 0
    ? new Decimal(cartItems.reduce((acc, item) => acc + item.qty * item.price, 0)).toFixed(2)
    : String(0);
};

export const PreferredCustomerDiscount = (isPreferredCustomer, preferredCustomer, subtotal) => {
  return isPreferredCustomer
    ? preferredCustomer.discount.type === "percentage"
      ? percentage(Number(subtotal), Number(preferredCustomer.discount.amount)).toFixed(2)
      : preferredCustomer.discount.type === "dollar" && Number(preferredCustomer.discount.amount)
    : Number(0).toFixed(2);
};

export const CouponDiscount = (
  isPreferredCustomer,
  preferredCustomerDiscount,
  coupon,
  subtotal
) => {
  return coupon.isValid
    ? coupon.discount.type === "percentage"
      ? new Decimal(
          percentage(
            isPreferredCustomer
              ? Number(subtotal) - Number(preferredCustomerDiscount)
              : Number(subtotal),
            Number(coupon.discount.amount)
          )
        ).toFixed(2)
      : coupon.discount.type === "dollar" && new Decimal(coupon.discount.amount).toFixed(2)
    : Number(0).toFixed(2);
};

export const Discounts = (preferredCustomerDiscount, couponDiscount) => {
  return new Decimal(Number(preferredCustomerDiscount) + Number(couponDiscount));
};

export const Tax = (subtotal, discounts, shipping, combinedTaxRates) => {
  return new Decimal(
    ((Number(subtotal) - Number(discounts) + Number(shipping)) * Number(combinedTaxRates)) / 100
  ).toFixed(2);
};

export const Total = (subtotal, discounts, shipping, tax) => {
  return new Decimal(Number(subtotal) - Number(discounts) + Number(shipping) + Number(tax)).toFixed(
    2
  );
};
