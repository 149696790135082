import React from 'react';
import { useSelector } from 'react-redux';
import LoadingWidget from '../../components/LoadingWidget';

const DrawingPreview = ({ drawing }) => {
  const selectLinkageKit = useSelector((state) => state.selectLinkageKit);
  const { loading } = selectLinkageKit;

  return loading ? (
    <LoadingWidget />
  ) : (
    <div dangerouslySetInnerHTML={{ __html: drawing }}></div>
  );
};

export default DrawingPreview;
