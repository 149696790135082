import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { getAllOrders } from "../../redux/actions/adminActions";

const AdminOrders = () => {
  const dispatch = useDispatch();

  const allOrders = useSelector((state) => state.admin.allOrders);

  useEffect(() => {
    dispatch(getAllOrders());
  }, []);

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Orders</strong>
          </CCardHeader>
          <CCardBody>
            <CTable>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope="col">Order Number</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Date</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Customer</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Items</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Total</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {allOrders.map((order) => (
                  <CTableRow>
                    <CTableDataCell>{order.orderNumber}</CTableDataCell>
                    <CTableDataCell>
                      {`${new Date(order.createdAt).toLocaleDateString([])} ${new Date(
                        order.createdAt
                      ).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}`}
                    </CTableDataCell>
                    <CTableDataCell>{order.customer}</CTableDataCell>
                    <CTableDataCell>{order.items}</CTableDataCell>
                    <CTableDataCell>${order.total}</CTableDataCell>
                    <CTableDataCell>{order.status}</CTableDataCell>
                    <CTableDataCell>
                      <CButton size="sm" color="light">
                        <a href={`/admin/orders/${order.orderNumber}`}>
                          <i className="fas fa-eye"></i>
                        </a>
                      </CButton>
                    </CTableDataCell>
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default AdminOrders;
