import React, { useEffect } from "react";
import { Form, Spinner, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { updateUserPassword, softLogout } from "../../redux/actions/userActions";

//import account navigation
import AccountNavigation from "../../components/account/AccountNavigation";

// Import Validation Schemas
import { UpdatePasswordValidationSchema } from "../../validation";
import { Helmet } from "react-helmet";
import { variables } from "../../config";
import VerifyAccount from "../../components/VerifyAccount";
import { useNavigate } from "react-router-dom";
import { useGetUserDetailsQuery, useUpdatePasswordMutation } from "../../redux/api/userApi";

const AccountPasswordScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useGetUserDetailsQuery();

  const [updatePassword, updatePasswordResult] = useUpdatePasswordMutation();

  return (
    <>
      <Helmet>
        <title>Account | {variables.WEBSITE_NAME}</title>
        <link rel="canonical" href={`${variables.FULL_URL}/account/password`} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <>
        <div className="block-space block-space--layout--after-header"></div>
        <div className="block">
          <div className="container container--max--xl">
            {user.isSuccess && user?.data?.isVerified === false ? (
              <div className="row">
                <div className="col">
                  <VerifyAccount />
                </div>
              </div>
            ) : null}
            <div className="row">
              <div className="col-12 col-lg-3">
                <AccountNavigation active="password" />
              </div>
              <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                <div className="card">
                  <div className="card-header">
                    <h5>Change Password</h5>
                  </div>
                  <div className="card-divider"></div>
                  <div className="card-body card-body--padding--2">
                    <div className="row no-gutters">
                      <div className="col-12 col-lg-7 col-xl-6">
                        {updatePasswordResult.isSuccess && (
                          <Alert variant="success">Password Updated</Alert>
                        )}
                        {updatePasswordResult.isError && (
                          <Alert variant="danger">
                            {updatePasswordResult?.error?.data?.message}
                          </Alert>
                        )}
                        <Formik
                          validationSchema={UpdatePasswordValidationSchema}
                          onSubmit={async (values) => {
                            await updatePassword({
                              currentPassword: values.currentPassword,
                              newPassword: values.newPassword,
                            });
                          }}
                          initialValues={{
                            currentPassword: "",
                            newPassword: "",
                            confirmNewPassword: "",
                          }}
                        >
                          {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors,
                            isSubmitting,
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                              <Form.Group controlId="current-password">
                                <Form.Label>Current Password</Form.Label>
                                <Form.Control
                                  className="form-control"
                                  name="currentPassword"
                                  type="password"
                                  placeholder="Enter current password"
                                  value={values.currentPassword}
                                  onChange={handleChange}
                                  isInvalid={!!errors.currentPassword && touched.currentPassword}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.currentPassword}
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group controlId="new-password">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                  className="form-control"
                                  name="newPassword"
                                  type="password"
                                  placeholder="Enter new password"
                                  value={values.newPassword}
                                  onChange={handleChange}
                                  isInvalid={!!errors.newPassword && touched.newPassword}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.newPassword}
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group controlId="confirm-password">
                                <Form.Label>Confirm New Password</Form.Label>
                                <Form.Control
                                  className="form-control"
                                  name="confirmNewPassword"
                                  type="password"
                                  placeholder="Re-enter new password"
                                  value={values.confirmNewPassword}
                                  onChange={handleChange}
                                  isInvalid={
                                    !!errors.confirmNewPassword && touched.confirmNewPassword
                                  }
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.confirmNewPassword}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <div className="form-group mb-0">
                                <button
                                  className="btn btn-primary mt-3"
                                  disabled={updatePasswordResult?.isLoading}
                                >
                                  {updatePasswordResult?.isLoading ? (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                      className="ml-2"
                                    />
                                  ) : null}
                                  Update
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block-space block-space--layout--before-footer"></div>
      </>
    </>
  );
};

export default AccountPasswordScreen;
