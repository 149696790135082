import React from "react";

const ActuatorDescription = ({ actuator }) => {
  return (
    <>
      {actuator?.type === "ISO 5211 Standard Actuator" ? (
        <>{`${actuator?.isoFlange} ${actuator?.type}, ${actuator?.driveSize} ${actuator?.driveType} Drive`}</>
      ) : actuator?.type === "Custom Actuator" ? (
        <>{`${actuator?.customName}`}</>
      ) : (
        <>{`${actuator?.brand} Series ${actuator?.series} ${actuator?.model} ${
          actuator?.options && actuator?.options.length > 0
            ? actuator?.options
                .map((option) => {
                  return `${Object.values(option)[0]} `;
                })
                .join(" ")
            : ""
        } ${actuator?.type} `}</>
      )}
    </>
  );
};

export default ActuatorDescription;
