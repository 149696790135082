import { api } from "../slices/api";

// Define a service using a base URL and expected endpoints
export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getLinkageKitPrice: builder.mutation({
      query: (values) => ({
        url: "v1/calculate/price",
        method: "POST",
        body: values,
      }),
    }),
    getLinkageKitBySku: builder.query({
      query: (sku) => `v1/calculate/validateSku/${sku}`,
    }),
  }),
});

export const { useGetLinkageKitPriceMutation, useGetLinkageKitBySkuQuery } = extendedApiSlice;
