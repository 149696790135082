import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";

import { deleteEarlyAccessUser, getAllEarlyAccessUsers } from "../../redux/actions/adminActions";
import { ADMIN_ADD_EARLY_ACCESS_USER_MODAL, ADMIN_MESSAGE_RESET } from "../../redux/constants";
import { AddEarlyAccessUserModal } from "../../components/admin/modals/AddEarlyAccessUserModal";

const AdminEarlyAccessUsers = () => {
  const dispatch = useDispatch();

  const [confirmDeleteEarlyAccessUserModal, setConfirmDeleteEarlyAccessUserModal] = useState(false);

  const [earlyAccessUserDetails, setEarlyAccessUserDetails] = useState({});

  const admin = useSelector((state) => state.admin);
  const { loading, message, error, status } = admin;

  const earlyAccessUsers = useSelector((state) => state.admin.earlyAccess.allEarlyAccessUsers);

  useEffect(() => {
    dispatch(getAllEarlyAccessUsers());
  }, [dispatch]);

  useEffect(() => {
    if (message === "Early access user added successfully") {
      dispatch({ type: ADMIN_ADD_EARLY_ACCESS_USER_MODAL });
      dispatch(getAllEarlyAccessUsers());
    }
  }, [dispatch, message]);

  useEffect(() => {
    if (message === "Early access user deleted successfully") {
      setConfirmDeleteEarlyAccessUserModal(false);
      dispatch(getAllEarlyAccessUsers());
    }
  }, [dispatch, message]);

  return (
    <>
      {confirmDeleteEarlyAccessUserModal && (
        <CModal visible={confirmDeleteEarlyAccessUserModal} alignment="center">
          <CModalHeader>
            <CModalTitle>Delete Early Access User?</CModalTitle>
          </CModalHeader>
          <CModalBody>
            {error && <div className="alert alert-danger">{error}</div>}
            <strong>Early Access User:</strong> {`${earlyAccessUserDetails.email}`}
          </CModalBody>
          <CModalFooter>
            <CButton
              color="light"
              onClick={() => {
                setConfirmDeleteEarlyAccessUserModal(false);
                setEarlyAccessUserDetails({});
              }}
            >
              Cancel
            </CButton>
            <CButton
              color="danger"
              onClick={() => {
                dispatch({ type: ADMIN_MESSAGE_RESET });
                dispatch(deleteEarlyAccessUser(earlyAccessUserDetails.email));
              }}
            >
              Delete
            </CButton>
          </CModalFooter>
        </CModal>
      )}
      {message === "Early access user added successfully" && (
        <div className="alert alert-success">{message}</div>
      )}
      {message === "Early access user deleted successfully" && (
        <div className="alert alert-success">{message}</div>
      )}
      <AddEarlyAccessUserModal />
      <CRow>
        <CCol xs={12} className="mb-4">
          <CButton
            color="primary"
            onClick={() => dispatch({ type: ADMIN_ADD_EARLY_ACCESS_USER_MODAL })}
          >
            Add Early Access User
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Early Access Users</strong>
            </CCardHeader>
            <CCardBody>
              <CTable hover>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {earlyAccessUsers.map((earlyAccessUser) => (
                    <CTableRow>
                      <CTableDataCell>{earlyAccessUser.email}</CTableDataCell>
                      <CTableDataCell>
                        <CButton
                          color="light"
                          size="sm"
                          className="mx-1"
                          onClick={() => {
                            setConfirmDeleteEarlyAccessUserModal(true);
                            setEarlyAccessUserDetails(earlyAccessUser);
                          }}
                        >
                          <i className="fas fa-trash"></i>
                        </CButton>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default AdminEarlyAccessUsers;
