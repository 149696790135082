import {
  SELECTED_ACTUATOR_BRAND,
  SELECTED_ACTUATOR_SERIES,
  SELECTED_ACTUATOR_MODEL,
  SELECTED_ACTUATOR_OPTIONS,
  SELECTED_ACTUATOR_REQUEST,
  SELECTED_ACTUATOR_SUCCESS,
  SELECTED_ACTUATOR_FAIL,
  SELECTED_ACTUATOR_RESET,
  ACTUATOR_SELECTIONS_RESET,
  ACTUATOR_OPTIONS_REQUEST,
  ACTUATOR_OPTIONS_SUCCESS,
  ACTUATOR_OPTIONS_FAIL,
  SELECTED_ACTUATOR_ISO5211_FLANGE,
  SELECTED_ACTUATOR_ISO5211_DRIVE_TYPE,
  SELECTED_ACTUATOR_ISO5211_DRIVE_SIZE,
  ACTUATOR_SELECTION_TYPE,
} from "../constants";

import axios from "axios";

export const fetchActuatorOptions =
  (selectedActuatorBrand, selectedActuatorSeries, selectedActuatorModel) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTUATOR_OPTIONS_REQUEST,
      });

      const { data } = await axios.get(
        `/api/v1/actuators/${encodeURIComponent(
          selectedActuatorBrand
        )}/${encodeURIComponent(selectedActuatorSeries)}/${encodeURIComponent(
          selectedActuatorModel
        )}/options`
      );
      dispatch({
        type: ACTUATOR_OPTIONS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ACTUATOR_OPTIONS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const fetchSelectedActuator =
  (
    selectedActuatorBrand,
    selectedActuatorSeries,
    selectedActuatorModel,
    selectedActuatorOptions
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SELECTED_ACTUATOR_REQUEST,
      });

      const queryString = (options) => {
        return `?${options
          .map((entry) => {
            return `${Object.keys(entry)[0]}=${Object.values(entry)[0]}`;
          })
          .join("&")}`;
      };

      const res = await axios.get(
        `/api/v1/actuators/${encodeURIComponent(
          selectedActuatorBrand
        )}/${encodeURIComponent(selectedActuatorSeries)}/${encodeURIComponent(
          selectedActuatorModel
        )}/details${
          selectedActuatorOptions.length > 0
            ? queryString(selectedActuatorOptions)
            : ""
        }`
      );

      dispatch({
        type: SELECTED_ACTUATOR_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        dispatch({
          type: ACTUATOR_OPTIONS_SUCCESS,
          payload: error.response.data,
        });
      } else {
        dispatch({
          type: SELECTED_ACTUATOR_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    }
  };

export const fetchSelectedISO5211Actuator =
  (
    selectedActuatorFlange,
    selectedActuatorDriveType,
    selectedActuatorDriveSize
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SELECTED_ACTUATOR_REQUEST,
      });

      const { data } = await axios.get(
        `/api/v1/actuators/iso5211/${encodeURIComponent(
          selectedActuatorFlange
        )}/${encodeURIComponent(
          selectedActuatorDriveType
        )}/${encodeURIComponent(selectedActuatorDriveSize)}/details`
      );

      dispatch({
        type: SELECTED_ACTUATOR_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SELECTED_ACTUATOR_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const setActuatorSelectionType = (type) => (dispatch) => {
  dispatch({
    type: ACTUATOR_SELECTION_TYPE,
    payload: type,
  });
};

export const setSelectedActuatorISO5211Flange = (isoFlange) => (dispatch) => {
  dispatch({
    type: SELECTED_ACTUATOR_ISO5211_FLANGE,
    payload: isoFlange,
  });
};

export const setSelectedActuatorISO5211DriveType =
  (driveType) => (dispatch) => {
    dispatch({
      type: SELECTED_ACTUATOR_ISO5211_DRIVE_TYPE,
      payload: driveType,
    });
  };

export const setSelectedActuatorISO5211DriveSize =
  (driveSize) => (dispatch) => {
    dispatch({
      type: SELECTED_ACTUATOR_ISO5211_DRIVE_SIZE,
      payload: driveSize,
    });
  };

export const setSelectedActuatorBrand = (brand) => (dispatch) => {
  dispatch({
    type: SELECTED_ACTUATOR_BRAND,
    payload: brand,
  });
};

export const setSelectedActuatorSeries = (series) => (dispatch) => {
  dispatch({
    type: SELECTED_ACTUATOR_SERIES,
    payload: series,
  });
};

export const setSelectedActuatorModel = (model) => (dispatch) => {
  dispatch({
    type: SELECTED_ACTUATOR_MODEL,
    payload: model,
  });
};

export const setSelectedActuatorOptions = (key, value) => (dispatch) => {
  dispatch({
    type: SELECTED_ACTUATOR_OPTIONS,
    payload: { [key]: value },
  });
};

export const resetSelectedActuator = () => (dispatch) => {
  dispatch({
    type: SELECTED_ACTUATOR_RESET,
  });
};

export const resetActuatorSelections = () => (dispatch) => {
  dispatch({
    type: ACTUATOR_SELECTIONS_RESET,
  });
};
