import axios from "axios";
import slugify from "slugify";
import {
  ADMIN_GET_ALL_ORDERS_REQUEST,
  ADMIN_GET_ALL_ORDERS_SUCCESS,
  ADMIN_GET_ALL_ORDERS_FAIL,
  ADMIN_GET_ALL_USERS_REQUEST,
  ADMIN_GET_ALL_USERS_SUCCESS,
  ADMIN_GET_ALL_USERS_FAIL,
  ADMIN_GET_PREFERRED_CUSTOMERS_REQUEST,
  ADMIN_GET_PREFERRED_CUSTOMERS_SUCCESS,
  ADMIN_GET_PREFERRED_CUSTOMERS_FAIL,
  ADMIN_CHANGE_ORDER_STATUS_REQUEST,
  ADMIN_CHANGE_ORDER_STATUS_SUCCESS,
  ADMIN_CHANGE_ORDER_STATUS_FAIL,
  ADMIN_GET_ALL_COUPONS_REQUEST,
  ADMIN_GET_ALL_COUPONS_SUCCESS,
  ADMIN_GET_ALL_COUPONS_FAIL,
  ADMIN_GET_ALL_VALVES_REQUEST,
  ADMIN_GET_ALL_VALVES_SUCCESS,
  ADMIN_GET_ALL_VALVES_FAIL,
  ADMIN_GET_ALL_ACTUATORS_REQUEST,
  ADMIN_GET_ALL_ACTUATORS_SUCCESS,
  ADMIN_GET_ALL_ACTUATORS_FAIL,
  ADMIN_ADD_COUPON_REQUEST,
  ADMIN_ADD_COUPON_SUCCESS,
  ADMIN_ADD_COUPON_FAIL,
  ADMIN_EDIT_COUPON_REQUEST,
  ADMIN_EDIT_COUPON_SUCCESS,
  ADMIN_EDIT_COUPON_FAIL,
  ADMIN_DELETE_COUPON_REQUEST,
  ADMIN_DELETE_COUPON_SUCCESS,
  ADMIN_DELETE_COUPON_FAIL,
  ADMIN_DELETE_VALVE_REQUEST,
  ADMIN_DELETE_VALVE_SUCCESS,
  ADMIN_DELETE_VALVE_FAIL,
  ADMIN_ADD_VALVE_REQUEST,
  ADMIN_ADD_VALVE_SUCCESS,
  ADMIN_ADD_VALVE_FAIL,
  ADMIN_GET_ORDER_DETAILS_REQUEST,
  ADMIN_GET_ORDER_DETAILS_SUCCESS,
  ADMIN_GET_ORDER_DETAILS_FAIL,
  ADMIN_GET_ALL_EARLY_ACCESS_USERS_REQUEST,
  ADMIN_GET_ALL_EARLY_ACCESS_USERS_SUCCESS,
  ADMIN_GET_ALL_EARLY_ACCESS_USERS_FAIL,
  ADMIN_ADD_EARLY_ACCESS_USER_REQUEST,
  ADMIN_ADD_EARLY_ACCESS_USER_SUCCESS,
  ADMIN_ADD_EARLY_ACCESS_USER_FAIL,
  ADMIN_GET_ALL_QUOTE_REQUESTS_REQUEST,
  ADMIN_GET_ALL_QUOTE_REQUESTS_SUCCESS,
  ADMIN_GET_ALL_QUOTE_REQUESTS_FAIL,
  ADMIN_GET_QUOTE_REQUEST_DETAILS_REQUEST,
  ADMIN_GET_QUOTE_REQUEST_DETAILS_SUCCESS,
  ADMIN_GET_ALL_MANUFACTURE_RATES_REQUEST,
  ADMIN_GET_ALL_MANUFACTURE_RATES_SUCCESS,
  ADMIN_UPDATE_MANUFACTURE_RATE_REQUEST,
  ADMIN_UPDATE_MANUFACTURE_RATE_SUCCESS,
  LINKAGE_KIT_DIMENSIONS_REQUEST,
  LINKAGE_KIT_DIMENSIONS_SUCCESS,
  LINKAGE_KIT_DIMENSIONS_FAIL,
  LINKAGE_KIT_DRAWING_PREVIEW_REQUEST,
  LINKAGE_KIT_DRAWING_PREVIEW_SUCCESS,
  LINKAGE_KIT_DRAWING_PREVIEW_FAIL,
  LINKAGE_KIT_DRAWING_REQUEST,
  LINKAGE_KIT_DRAWING_SUCCESS,
  LINKAGE_KIT_DRAWING_FAIL,
  ADMIN_GET_ORDER_SHIPPING_TICKET_REQUEST,
  ADMIN_GET_ORDER_SHIPPING_TICKET_SUCCESS,
  ADMIN_GET_ORDER_SHIPPING_TICKET_FAIL,
  ADMIN_GET_ALL_MATERIAL_PRICING_REQUEST,
  ADMIN_GET_ALL_MATERIAL_PRICING_SUCCESS,
  ADMIN_GET_ALL_MANUFACTURE_RATES_FAIL,
  ADMIN_GET_ALL_MATERIAL_PRICING_FAIL,
  ADMIN_UPDATE_MATERIAL_PRICING_REQUEST,
  ADMIN_UPDATE_MATERIAL_PRICING_SUCCESS,
  ADMIN_UPDATE_MATERIAL_PRICING_FAIL,
  ADMIN_UPDATE_MANUFACTURE_RATE_FAIL,
  ADMIN_GET_ALL_CUSTOMER_MESSAGES_REQUEST,
  ADMIN_GET_ALL_CUSTOMER_MESSAGES_SUCCESS,
  ADMIN_GET_ALL_CUSTOMER_MESSAGES_FAIL,
  ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_REQUEST,
  ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_SUCCESS,
  ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_FAIL,
  ADMIN_GET_SINGLE_USER_REQUEST,
  ADMIN_GET_SINGLE_USER_SUCCESS,
  ADMIN_GET_SINGLE_USER_FAIL,
  ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_REQUEST,
  ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_SUCCESS,
  ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_FAIL,
  ADMIN_ADD_PREFERRED_CUSTOMER_REQUEST,
  ADMIN_ADD_PREFERRED_CUSTOMER_SUCCESS,
  ADMIN_ADD_PREFERRED_CUSTOMER_FAIL,
  ADMIN_GET_PREFERRED_CUSTOMER_STATUS_REQUEST,
  ADMIN_GET_PREFERRED_CUSTOMER_STATUS_SUCCESS,
  ADMIN_GET_PREFERRED_CUSTOMER_STATUS_FAIL,
  ADMIN_GET_ALL_SETTINGS_REQUEST,
  ADMIN_GET_ALL_SETTINGS_SUCCESS,
  ADMIN_GET_ALL_SETTINGS_FAIL,
  ADMIN_UPDATE_RESELLER_STATUS_REQUEST,
  ADMIN_UPDATE_RESELLER_STATUS_SUCCESS,
  ADMIN_UPDATE_RESELLER_STATUS_FAIL,
  ADMIN_GET_MANUFACTURE_RATE_REQUEST,
  ADMIN_GET_MANUFACTURE_RATE_SUCCESS,
  ADMIN_GET_MANUFACTURE_RATE_FAIL,
  ADMIN_UPDATE_SHIPPING_INFO_REQUEST,
  ADMIN_UPDATE_SHIPPING_INFO_SUCCESS,
  ADMIN_UPDATE_SHIPPING_INFO_FAIL,
  ADMIN_ADD_VALVES_FROM_CSV_STRING_REQUEST,
  ADMIN_ADD_VALVES_FROM_CSV_STRING_SUCCESS,
  ADMIN_ADD_VALVES_FROM_CSV_STRING_FAIL,
  ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_REQUEST,
  ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_SUCCESS,
  ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_FAIL,
  ADMIN_GET_ORDER_INVOICE_REQUEST,
  ADMIN_GET_ORDER_INVOICE_SUCCESS,
  ADMIN_GET_ORDER_INVOICE_FAIL,
} from "../constants";

export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_ALL_USERS_REQUEST,
    });

    const res = await axios.get(`/api/v1/users/all`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: ADMIN_GET_ALL_USERS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_ALL_USERS_FAIL,
      payload: error,
    });
  }
};

export const getSingleUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_SINGLE_USER_REQUEST,
    });

    const res = await axios.get(`/api/v1/users/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: ADMIN_GET_SINGLE_USER_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_SINGLE_USER_FAIL,
      payload: error,
    });
  }
};

export const getAllOrders = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_ALL_ORDERS_REQUEST,
    });

    const res = await axios.get(`/api/v1/orders/all`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: ADMIN_GET_ALL_ORDERS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_ALL_ORDERS_FAIL,
      payload: error,
    });
  }
};

export const getAllOrdersByCustomer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_REQUEST,
    });

    const res = await axios.get(`/api/v1/orders/${id}/all`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_ALL_ORDERS_BY_CUSTOMER_FAIL,
      payload: error,
    });
  }
};

export const getOrderDetails = (orderNumber) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_ORDER_DETAILS_REQUEST,
    });

    const res = await axios.get(`/api/v1/orders/${orderNumber}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: ADMIN_GET_ORDER_DETAILS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_ORDER_DETAILS_FAIL,
      payload: error,
    });
  }
};

export const getPreferredCustomers = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_PREFERRED_CUSTOMERS_REQUEST,
    });

    const res = await axios.get(`/api/v1/preferredCustomers`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: ADMIN_GET_PREFERRED_CUSTOMERS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_PREFERRED_CUSTOMERS_FAIL,
      payload: error,
    });
  }
};

export const addPreferredCustomer = (values) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_ADD_PREFERRED_CUSTOMER_REQUEST,
    });

    const res = await axios.post(`/api/v1/preferredCustomers`, values, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: ADMIN_ADD_PREFERRED_CUSTOMER_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_ADD_PREFERRED_CUSTOMER_FAIL,
      payload: error,
    });
  }
};

export const getPreferredCustomerStatus = (email) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_PREFERRED_CUSTOMER_STATUS_REQUEST,
    });

    const res = await axios.post(
      `/api/v1/preferredCustomers/status`,
      { email: email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: ADMIN_GET_PREFERRED_CUSTOMER_STATUS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_PREFERRED_CUSTOMER_STATUS_FAIL,
      payload: error,
    });
  }
};

export const getAllCoupons = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_ALL_COUPONS_REQUEST,
    });

    const res = await axios.get(`/api/v1/coupons/all`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: ADMIN_GET_ALL_COUPONS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_ALL_COUPONS_FAIL,
      payload: error,
    });
  }
};

export const addCoupon =
  (couponCode, discountType, discountAmount, minimumDollarAmount, minimumItems, expiresAt, email) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ADMIN_ADD_COUPON_REQUEST,
      });

      const res = await axios.post(
        "/api/v1/coupons/add",
        {
          couponCode,
          discountType,
          discountAmount,
          minimumDollarAmount,
          minimumItems,
          expiresAt,
          email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: ADMIN_ADD_COUPON_SUCCESS,
        payload: res,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message ? error.response.data.message : error.message;

      dispatch({
        type: ADMIN_ADD_COUPON_FAIL,
        payload: error,
      });
    }
  };

export const editCoupon = (values) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_EDIT_COUPON_REQUEST,
    });

    const res = await axios.post(
      `/api/v1/coupons/${values.couponCode}`,
      {
        discountType: values.discountType,
        discountAmount: values.discountAmount,
        minimumDollarAmount: values.minimumDollarAmount,
        minimumItems: values.minimumItems,
        expiresAt: values.expiresAt,
        email: values.email,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: ADMIN_EDIT_COUPON_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_EDIT_COUPON_FAIL,
      payload: error,
    });
  }
};

export const deleteCoupon = (couponCode) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_DELETE_COUPON_REQUEST,
    });

    const res = await axios.delete(`/api/v1/coupons/${couponCode}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: ADMIN_DELETE_COUPON_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_DELETE_COUPON_FAIL,
      payload: error,
    });
  }
};

export const getAllValves = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_ALL_VALVES_REQUEST,
    });

    const res = await axios.get(`/api/v1/valves`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: ADMIN_GET_ALL_VALVES_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_ALL_VALVES_FAIL,
      payload: error,
    });
  }
};

export const addValve = (values) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_ADD_VALVE_REQUEST,
    });

    const res = await axios.post(
      `/api/v1/valves/add`,
      { values },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: ADMIN_ADD_VALVE_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_ADD_VALVE_FAIL,
      payload: error,
    });
  }
};

export const addValvesFromCsvString = (csvString) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_ADD_VALVES_FROM_CSV_STRING_REQUEST,
    });

    const res = await axios.post(
      `/api/v1/valves/add/csvString`,
      { csvString: csvString },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: ADMIN_ADD_VALVES_FROM_CSV_STRING_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_ADD_VALVES_FROM_CSV_STRING_FAIL,
      payload: error,
    });
  }
};

export const addActuatorsFromCsvString = (csvString) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_REQUEST,
    });

    const res = await axios.post(
      `/api/v1/actuators/add/csvString`,
      { csvString: csvString },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_ADD_ACTUATORS_FROM_CSV_STRING_FAIL,
      payload: error,
    });
  }
};

export const deleteValve = (sku) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_DELETE_VALVE_REQUEST,
    });

    const res = await axios.post(
      `/api/v1/valves/delete`,
      { sku },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: ADMIN_DELETE_VALVE_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_DELETE_VALVE_FAIL,
      payload: error,
    });
  }
};

export const getAllActuators = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_ALL_ACTUATORS_REQUEST,
    });

    const res = await axios.get(`/api/v1/actuators`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: ADMIN_GET_ALL_ACTUATORS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_ALL_ACTUATORS_FAIL,
      payload: error,
    });
  }
};

export const changeOrderStatus = (id, status) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_CHANGE_ORDER_STATUS_REQUEST,
    });

    const res = await axios.post(
      `/api/v1/orders/${id}/status`,
      { status },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: ADMIN_CHANGE_ORDER_STATUS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_CHANGE_ORDER_STATUS_FAIL,
      payload: error,
    });
  }
};

export const updateTrackingInfo = (id, values) => async (dispatch) => {
  console.log(values);
  try {
    dispatch({
      type: ADMIN_UPDATE_SHIPPING_INFO_REQUEST,
    });

    const res = await axios.post(`/api/v1/orders/${id}/shipping/tracking`, values, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: ADMIN_UPDATE_SHIPPING_INFO_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_UPDATE_SHIPPING_INFO_FAIL,
      payload: error,
    });
  }
};

export const getAllEarlyAccessUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_ALL_EARLY_ACCESS_USERS_REQUEST,
    });

    const res = await axios.get(`/api/v1/earlyAccess`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: ADMIN_GET_ALL_EARLY_ACCESS_USERS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_ALL_EARLY_ACCESS_USERS_FAIL,
      payload: error,
    });
  }
};

export const addEarlyAccessUser = (email) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_ADD_EARLY_ACCESS_USER_REQUEST,
    });

    const res = await axios.post(
      `/api/v1/earlyAccess`,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: ADMIN_ADD_EARLY_ACCESS_USER_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_ADD_EARLY_ACCESS_USER_FAIL,
      payload: error,
    });
  }
};

export const deleteEarlyAccessUser = (email) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_ADD_EARLY_ACCESS_USER_REQUEST,
    });

    const res = await axios.delete(`/api/v1/earlyAccess/${email}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: ADMIN_ADD_EARLY_ACCESS_USER_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_ADD_EARLY_ACCESS_USER_FAIL,
      payload: error,
    });
  }
};

export const getAllQuoteRequests = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_ALL_QUOTE_REQUESTS_REQUEST,
    });

    const res = await axios.get(`/api/v1/contact/rfq/all`);

    dispatch({
      type: ADMIN_GET_ALL_QUOTE_REQUESTS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_ALL_QUOTE_REQUESTS_FAIL,
      payload: error,
    });
  }
};

export const getQuoteRequestDetails = (rfqNumber) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_QUOTE_REQUEST_DETAILS_REQUEST,
    });

    const res = await axios.get(`/api/v1/contact/rfq/${rfqNumber}`);

    dispatch({
      type: ADMIN_GET_QUOTE_REQUEST_DETAILS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_QUOTE_REQUEST_DETAILS_SUCCESS,
      payload: error,
    });
  }
};

export const getAllCustomerMessages = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_ALL_CUSTOMER_MESSAGES_REQUEST,
    });

    const res = await axios.get(`/api/v1/contact/message/all`);

    dispatch({
      type: ADMIN_GET_ALL_CUSTOMER_MESSAGES_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_ALL_CUSTOMER_MESSAGES_FAIL,
      payload: error,
    });
  }
};

export const getCustomerMessageDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_REQUEST,
    });

    const res = await axios.get(`/api/v1/contact/message/${id}`);

    dispatch({
      type: ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_CUSTOMER_MESSAGE_DETAILS_FAIL,
      payload: error,
    });
  }
};

export const getAllManufactureRates = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_ALL_MANUFACTURE_RATES_REQUEST,
    });

    const res = await axios.get(`/api/v1/admin/settings/manufactureRates`);

    dispatch({
      type: ADMIN_GET_ALL_MANUFACTURE_RATES_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_ALL_MANUFACTURE_RATES_FAIL,
      payload: error,
    });
  }
};

export const updateManufactureRate = (id, rate) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_UPDATE_MANUFACTURE_RATE_REQUEST,
    });

    const res = await axios.post(`/api/v1/admin/settings/manufactureRates/${id}`, rate, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: ADMIN_UPDATE_MANUFACTURE_RATE_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_UPDATE_MANUFACTURE_RATE_FAIL,
      payload: error,
    });
  }
};

export const getAllMaterialPricing = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_ALL_MATERIAL_PRICING_REQUEST,
    });

    const res = await axios.get(`/api/v1/admin/settings/materialPricing`);

    dispatch({
      type: ADMIN_GET_ALL_MATERIAL_PRICING_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_ALL_MATERIAL_PRICING_FAIL,
      payload: error,
    });
  }
};

export const updateMaterialPricing = (id, updates) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_UPDATE_MATERIAL_PRICING_REQUEST,
    });

    const res = await axios.post(`/api/v1/admin/settings/materialPricing/${id}`, updates, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: ADMIN_UPDATE_MATERIAL_PRICING_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_UPDATE_MATERIAL_PRICING_FAIL,
      payload: error,
    });
  }
};

export const getAllSettings = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_ALL_SETTINGS_REQUEST,
    });

    const res = await axios.get(`/api/v1/admin/settings`);

    dispatch({
      type: ADMIN_GET_ALL_SETTINGS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_ALL_SETTINGS_FAIL,
      payload: error,
    });
  }
};

export const updateResellerStatus = (id, status) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_UPDATE_RESELLER_STATUS_REQUEST,
    });

    const res = await axios.post(
      `/api/v1/users/${id}/resellerStatus`,
      { status: status },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: ADMIN_UPDATE_RESELLER_STATUS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_UPDATE_RESELLER_STATUS_FAIL,
      payload: error,
    });
  }
};

export const fetchDimensions = (kit) => async (dispatch) => {
  try {
    dispatch({
      type: LINKAGE_KIT_DIMENSIONS_REQUEST,
    });

    const res = await axios.post(
      "/api/v1/calculate/dimensions",
      {
        valveSku: kit.valveSku,
        actuatorSku: kit.actuatorSku,
        lockout: kit.lockout,
        components: kit.components,
        bracketHeight: kit.bracketHeight,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: LINKAGE_KIT_DIMENSIONS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: LINKAGE_KIT_DIMENSIONS_FAIL,
      payload: error,
    });
  }
};

export const fetchDrawingPreview = (sku) => async (dispatch) => {
  try {
    dispatch({
      type: LINKAGE_KIT_DRAWING_PREVIEW_REQUEST,
    });

    const res = await axios.get(`/api/v1/calculate/drawing/preview/bySku/${sku}`);

    dispatch({
      type: LINKAGE_KIT_DRAWING_PREVIEW_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: LINKAGE_KIT_DRAWING_PREVIEW_FAIL,
      payload: error,
    });
  }
};

export const fetchDrawingPreviewByDimensions = (item) => async (dispatch) => {
  try {
    dispatch({
      type: LINKAGE_KIT_DRAWING_PREVIEW_REQUEST,
    });

    const res = await axios.post(`/api/v1/calculate/drawing/preview/byDimensions`, item, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: LINKAGE_KIT_DRAWING_PREVIEW_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: LINKAGE_KIT_DRAWING_PREVIEW_FAIL,
      payload: error,
    });
  }
};

export const getManufactureRateOfKitBySku = (sku) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_MANUFACTURE_RATE_REQUEST,
    });

    const res = await axios.get(`/api/v1/admin/manufactureRate/bySku/${sku}`);

    dispatch({
      type: ADMIN_GET_MANUFACTURE_RATE_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_MANUFACTURE_RATE_FAIL,
      payload: error,
    });
  }
};

export const getManufactureRateOfKitByDimensions = (item) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_MANUFACTURE_RATE_REQUEST,
    });

    const res = await axios.post(`/api/v1/admin/manufactureRate/byDimensions`, item, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: ADMIN_GET_MANUFACTURE_RATE_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_MANUFACTURE_RATE_FAIL,
      payload: error,
    });
  }
};

export const fetchDrawingByDimensions = (item, fileType) => async (dispatch) => {
  try {
    dispatch({
      type: LINKAGE_KIT_DRAWING_REQUEST,
    });

    await axios.post(`/api/v1/calculate/drawing/byDimensions/${fileType}`, item, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const valveName =
      item.valve.type === "Custom Valve"
        ? slugify(item.valve.customName, {
            replacement: "-",
            remove: undefined,
            lower: true,
          })
        : item.valve.sku;

    const actuatorName =
      item.actuator.type === "Custom Actuator"
        ? slugify(item.actuator.customName, {
            replacement: "-",
            remove: undefined,
            lower: true,
          })
        : item.actuator.sku;

    if (process.env.NODE_ENV === "development") {
      window.open(
        `http://localhost:5000/downloads/drawings/${valveName}-${actuatorName}.${fileType}`
      );
    } else {
      window.open(`/downloads/drawings/${valveName}-${actuatorName}.${fileType}`);
    }

    dispatch({
      type: LINKAGE_KIT_DRAWING_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: LINKAGE_KIT_DRAWING_FAIL,
      payload: error,
    });
  }
};

export const fetchDrawing = (sku, fileType) => async (dispatch) => {
  try {
    dispatch({
      type: LINKAGE_KIT_DRAWING_REQUEST,
    });

    await axios.get(`/api/v1/calculate/drawing/bySku/${sku}/${fileType}`);

    if (process.env.NODE_ENV === "development") {
      window.open(`http://localhost:5000/downloads/drawings/${sku}.${fileType}`);
    } else {
      window.open(`/downloads/drawings/${sku}.${fileType}`);
    }

    dispatch({
      type: LINKAGE_KIT_DRAWING_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: LINKAGE_KIT_DRAWING_FAIL,
      payload: error,
    });
  }
};

export const getShippingTicket = (orderNumber) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_ORDER_SHIPPING_TICKET_REQUEST,
    });

    const res = await axios.get(`/api/v1/orders/${orderNumber}/shippingTicket/`);

    if (process.env.NODE_ENV === "development") {
      window.open(`http://localhost:5000/downloads/shippingTickets/${orderNumber}-ST.pdf`);
    } else {
      window.open(`/downloads/shippingTickets/${orderNumber}-ST.pdf`);
    }

    dispatch({
      type: ADMIN_GET_ORDER_SHIPPING_TICKET_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_ORDER_SHIPPING_TICKET_FAIL,
      payload: error,
    });
  }
};

export const getInvoice = (orderNumber) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_ORDER_INVOICE_REQUEST,
    });

    const res = await axios.get(`/api/v1/orders/${orderNumber}/invoice/`);

    if (process.env.NODE_ENV === "development") {
      window.open(`http://localhost:5000/downloads/invoices/${orderNumber}-INV.pdf`);
    } else {
      window.open(`/downloads/invoices/${orderNumber}-INV.pdf`);
    }

    dispatch({
      type: ADMIN_GET_ORDER_INVOICE_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_ORDER_INVOICE_FAIL,
      payload: error,
    });
  }
};
