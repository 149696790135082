import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CButton,
  CForm,
  CFormInput,
  CFormFeedback,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import { ADMIN_EDIT_VALVE_MODAL } from "../../../redux/constants";

export const EditValveModal = ({ valve }) => {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);
  const { loading, message, error, status } = admin;

  return (
    <CModal alignment="center" size="lg" visible={admin.application.editValveModal}>
      <CModalHeader onDismiss={() => dispatch({ type: ADMIN_EDIT_VALVE_MODAL })}>
        <CModalTitle>Edit Valve</CModalTitle>
      </CModalHeader>
      <Formik
        validationSchema={Yup.object().shape({
          brand: Yup.string().required("Required"),
          series: Yup.string().required("Required"),
          asmeClass: Yup.string().required("Required"),
          size: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          //dispatch(addValve(values.brand));
        }}
        initialValues={{
          brand: valve.brand,
          series: valve.series,
          asmeClass: valve.asmeClass,
          size: valve.size,
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          isSubmitting,
        }) => (
          <CForm noValidate onSubmit={handleSubmit}>
            <CModalBody>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="row">
                <div className="col-md-6">
                  <CFormLabel htmlFor="brand">Brand</CFormLabel>
                  <CFormInput
                    type="text"
                    name="brand"
                    value={values.brand}
                    onChange={handleChange}
                    isInvalid={!!errors.brand}
                    id="brand"
                  />
                  <CFormFeedback invalid>{errors.brand}</CFormFeedback>
                </div>
                <div className="col-md-6">
                  <CFormLabel htmlFor="series">Series</CFormLabel>
                  <CFormInput
                    type="text"
                    name="series"
                    value={values.series}
                    onChange={handleChange}
                    isInvalid={!!errors.series}
                    id="series"
                  />
                  <CFormFeedback invalid>{errors.series}</CFormFeedback>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <CFormLabel htmlFor="series">ASME Class</CFormLabel>
                  <CFormInput
                    type="text"
                    name="asmeClass"
                    value={values.asmeClass}
                    onChange={handleChange}
                    isInvalid={!!errors.asmeClass}
                    id="asmeClass"
                  />
                  <CFormFeedback invalid>{errors.asmeClass}</CFormFeedback>
                </div>
                <div className="col-md-6">
                  <CFormLabel htmlFor="size">Size</CFormLabel>
                  <CFormInput
                    type="text"
                    name="size"
                    value={values.size}
                    onChange={handleChange}
                    isInvalid={!!errors.size}
                    id="size"
                  />
                  <CFormFeedback invalid>{errors.size}</CFormFeedback>
                </div>
              </div>
            </CModalBody>
            <CModalFooter>
              <CButton
                color="light"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({ type: ADMIN_EDIT_VALVE_MODAL });
                }}
              >
                Close
              </CButton>
              <CButton type="submit" color="primary">
                Save
              </CButton>
            </CModalFooter>
          </CForm>
        )}
      </Formik>
    </CModal>
  );
};
