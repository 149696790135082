import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { ADDED_TO_CART_MODAL_OPEN } from "../../redux/constants";

import {
  fetchBracketHeights,
  fetchPrice,
  fetchPriceByDimensions,
  getKitWeight,
  selectBracketHeight,
  setSku,
} from "../../redux/actions/optionsActions";
import ItemDetails from "./ItemDetails";
import {
  getManufactureRateOfKitByDimensions,
  getManufactureRateOfKitBySku,
} from "../../redux/actions/adminActions";
import { useGetLinkageKitPriceMutation } from "../../redux/api/linkageKitApi";

const SkuSearchResults = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  const [getPriceQuery, getPriceQueryResult] = useGetLinkageKitPriceMutation();

  async function getPrice() {
    await getPriceQuery({
      productType: item.productType,
      sku: sku,
      qty: qty,
    });
  }

  const user = useSelector((state) => state.userDetails.user);

  const cart = useSelector((state) => state.cart);

  // bring linkage kit selections in from redux state
  const slkbd = useSelector((state) => state.selectLinkageKitByDimensions);

  const admin = useSelector((state) => state.admin);

  const linkageKitSelections = useSelector((state) => state.selectLinkageKit);
  const {
    step,
    loading,
    valveSelectionType,
    actuatorSelectionType,
    selectedValve,
    selectedActuator,
    bracketHeights,
    lockout,
    selectedBracketHeight,
    components,
    useCustomDataSheets,
    qtyOnePrice,
    price,
    sku,
    qty,
  } = linkageKitSelections;

  const productType =
    valveSelectionType === "byDimensions" ||
    actuatorSelectionType === "byDimensions"
      ? "customMountingKit"
      : "mountingKit";

  const item = {
    productType: productType,
    ...(valveSelectionType !== "byDimensions" &&
      actuatorSelectionType !== "byDimensions" && { sku: sku }),
    valve:
      valveSelectionType === "byDimensions"
        ? { ...{ type: "Custom Valve" }, ...slkbd.valve }
        : selectedValve,
    actuator:
      actuatorSelectionType === "byDimensions"
        ? { ...{ type: "Custom Actuator" }, ...slkbd.actuator }
        : actuatorSelectionType === "byISO5211"
        ? { ...{ type: "ISO 5211 Standard Actuator" }, ...selectedActuator }
        : selectedActuator,
    lockout: lockout,
    bracketHeight: selectedBracketHeight,
    components: components,
    useCustomDataSheets: useCustomDataSheets,
    qty: qty,
  };

  useEffect(() => {
    dispatch(fetchBracketHeights(item));

    //dispatch(getKitWeight({ items: [{ sku: sku, qty: 1 }] }));
  }, [dispatch]);

  useEffect(() => {
    if (!bracketHeights.includes(selectedBracketHeight)) {
      dispatch(selectBracketHeight(bracketHeights[0]));
    }
  }, [bracketHeights]);

  useEffect(() => {
    if (
      item.productType === "mountingKit" &&
      selectedBracketHeight !== null &&
      selectedBracketHeight !== undefined &&
      lockout !== null &&
      components !== null
    ) {
      dispatch(
        setSku(
          selectedValve,
          selectedActuator,
          lockout,
          components,
          selectedBracketHeight
        )
      );
    }
  }, [dispatch, lockout, components, selectedBracketHeight]);

  useEffect(() => {
    if (
      step.value === "summary" &&
      productType !== "customMountingKit" &&
      sku !== "" &&
      selectedBracketHeight !== null &&
      lockout !== null &&
      components !== null &&
      qty !== null
    ) {
      navigate(`/build?sku=${sku}&qty=${qty}`);
    }
  }, [dispatch, sku, qty]);

  useEffect(() => {
    if (item.productType === "customMountingKit") {
      getPrice();

      if (user && user.isAdmin) {
        dispatch(getManufactureRateOfKitByDimensions(item));
      }
    }
  }, [dispatch, components, lockout, selectedBracketHeight]);

  useEffect(() => {
    if (item.productType === "mountingKit") {
      if (sku !== null && sku !== "" && sku !== undefined && qty !== null) {
        dispatch(fetchPrice(productType, sku, qty));

        if (user && user.isAdmin) {
          dispatch(getManufactureRateOfKitBySku(item.sku));
        }
      }
    }
  }, [dispatch, sku]);

  // useEffect(() => {
  //   if (cart.addToCart.success) {
  //     dispatch({ type: ADDED_TO_CART_MODAL_OPEN });
  //   }
  // }, [cart.addToCart]);

  return (
    <>
      <ItemDetails
        loading={loading}
        item={item}
        sku={sku}
        price={price}
        qty={qty}
        qtyOnePrice={qtyOnePrice}
        selectedValve={selectedValve}
        selectedActuator={selectedActuator}
        components={components}
        lockout={lockout}
        bracketHeights={bracketHeights}
        selectedBracketHeight={selectedBracketHeight}
        user={user}
        admin={admin}
      />
    </>
  );
};

export default SkuSearchResults;
