import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingWidget from "../../components/LoadingWidget";

import AccountNavigation from "../../components/account/AccountNavigation";

import { capitalize, numberWithCommas } from "../../utils/helpers";
import { Helmet } from "react-helmet";
import { variables } from "../../config";
import VerifyAccount from "../../components/VerifyAccount";
import { Link, useNavigate } from "react-router-dom";
import { useGetOrdersQuery } from "../../redux/api/orderApi";

const AccountOrdersScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage, setOrderPerPage] = useState(5);

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;

  const orders = useGetOrdersQuery();

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const totalOrders = orders?.data && orders?.data?.length;

  const currentOrders = orders?.data && orders?.data?.slice(indexOfFirstOrder, indexOfLastOrder);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalOrders / ordersPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <Helmet>
        <title>Account | {variables.WEBSITE_NAME}</title>
        <link rel="canonical" href={`${variables.FULL_URL}/account/order-history`} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {orders.isLoading ? (
        <LoadingWidget />
      ) : orders.isSuccess ? (
        <>
          <div className="block-space block-space--layout--after-header"></div>
          <div className="block">
            <div className="container container--max--xl">
              {user && user.isVerified === false && (
                <div className="row">
                  <div className="col">
                    <VerifyAccount />
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-12 col-lg-3">
                  <AccountNavigation active="orderHistory" />
                </div>
                <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                  {orders.isLoading ? (
                    <LoadingWidget />
                  ) : (
                    <div className="card">
                      <div className="card-header">
                        <h5>Order History</h5>
                      </div>
                      <div className="card-divider"></div>
                      <div className="card-table">
                        {orders?.data?.length < 1 ? (
                          <div className="card-body profile-card__body">No Orders</div>
                        ) : (
                          <div className="table-responsive-sm">
                            <table>
                              <thead>
                                <tr>
                                  <th>Order</th>
                                  <th>Date</th>
                                  <th>Status</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {currentOrders.map((order) => (
                                  <React.Fragment key={order._id}>
                                    <tr>
                                      <td>
                                        <Link to={`/account/order-details/${order._id}`}>
                                          #{order.orderNumber}
                                        </Link>
                                      </td>
                                      <td>{new Date(order.submittedAt).toLocaleDateString([])}</td>
                                      <td>{capitalize(order.status)}</td>
                                      <td>${numberWithCommas(order.total)}</td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                      <div className="card-divider"></div>
                      <div className="card-footer">
                        {orders.length > ordersPerPage && (
                          <ul className="pagination">
                            <li className={`page-item ${currentPage === 1 && "disabled"}`}>
                              <button
                                className="page-link page-link--with-arrow"
                                aria-label="Previous"
                                onClick={() => setCurrentPage(currentPage - 1)}
                              >
                                <span
                                  className="page-link__arrow page-link__arrow--left"
                                  aria-hidden="true"
                                >
                                  <svg width="7" height="11">
                                    <path d="M6.7,0.3L6.7,0.3c-0.4-0.4-0.9-0.4-1.3,0L0,5.5l5.4,5.2c0.4,0.4,0.9,0.3,1.3,0l0,0c0.4-0.4,0.4-1,0-1.3l-4-3.9l4-3.9C7.1,1.2,7.1,0.6,6.7,0.3z" />
                                  </svg>
                                </span>
                              </button>
                            </li>
                            {pageNumbers.map((page) => (
                              <li
                                key={page}
                                className={`page-item ${page === currentPage && "active"}`}
                              >
                                <button className="page-link" onClick={() => setCurrentPage(page)}>
                                  {page}
                                </button>
                              </li>
                            ))}
                            <li
                              className={`page-item ${
                                currentPage === pageNumbers.length && "disabled"
                              }`}
                            >
                              <button
                                className="page-link page-link--with-arrow"
                                aria-label="Next"
                                onClick={() => setCurrentPage(currentPage + 1)}
                              >
                                <span
                                  className="page-link__arrow page-link__arrow--right"
                                  aria-hidden="true"
                                >
                                  <svg width="7" height="11">
                                    <path
                                      d="M0.3,10.7L0.3,10.7c0.4,0.4,0.9,0.4,1.3,0L7,5.5L1.6,0.3C1.2-0.1,0.7,0,0.3,0.3l0,0c-0.4,0.4-0.4,1,0,1.3l4,3.9l-4,3.9
  C-0.1,9.8-0.1,10.4,0.3,10.7z"
                                    />
                                  </svg>
                                </span>
                              </button>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="block-space block-space--layout--before-footer"></div>
        </>
      ) : null}
    </>
  );
};

export default AccountOrdersScreen;
