import React from "react";
import ValveDescription from "../ValveDescription";
import ActuatorDescription from "../ActuatorDescription";
import LinkageKitDescription from "../LinkageKitDescription";

import { isEmpty } from "lodash";
import LinkageKitLongDescription from "./LinkageKitLongDescription";

const ItemTitleBlock = ({ item, sku }) => {
  return (
    <>
      <div className="product__header">
        <h1 className="product__title mb-3">
          <span
            style={{
              fontWeight: "100",
              fontSize: "1.15rem",
              //textDecoration: "underline",
              //fontStyle: "italic",
            }}
          >
            Stainless Steel Mounting Kit
          </span>
          <br />
          <LinkageKitDescription item={item} />
        </h1>

        {item.valve.type !== "Custom Valve" && (
          <div className="status-badge status-badge--style--success product__fit status-badge--has-icon status-badge--has-text">
            <div className="status-badge__body">
              <div className="status-badge__icon">
                <svg width="13" height="13">
                  <path d="M12,4.4L5.5,11L1,6.5l1.4-1.4l3.1,3.1L10.6,3L12,4.4z"></path>
                </svg>
              </div>
              <div className="status-badge__text">
                Part Fit for <ValveDescription valve={item.valve} />
              </div>
              <div
                className="status-badge__tooltip"
                tabindex="0"
                data-toggle="tooltip"
                title=""
                data-original-title="Part Fit for 2011 Ford Focus S"
              ></div>
            </div>
          </div>
        )}

        {item.actuator.type !== "Custom Actuator" && (
          <div className="status-badge status-badge--style--success product__fit status-badge--has-icon status-badge--has-text">
            <div className="status-badge__body">
              <div className="status-badge__icon">
                <svg width="13" height="13">
                  <path d="M12,4.4L5.5,11L1,6.5l1.4-1.4l3.1,3.1L10.6,3L12,4.4z"></path>
                </svg>
              </div>
              <div className="status-badge__text">
                Part Fit for <ActuatorDescription actuator={item.actuator} />
              </div>
              <div
                className="status-badge__tooltip"
                tabindex="0"
                data-toggle="tooltip"
                title=""
                data-original-title="Part Fit for 2011 Ford Focus S"
              ></div>
            </div>
          </div>
        )}

        {item.productType === "mountingKit" && (
          <h6 className="small text-muted pt-2">SKU: {sku}</h6>
        )}
      </div>

      {!isEmpty(item.valve) && !isEmpty(item.actuator) && (
        <div className="product__main">
          <div className="product__excerpt">
            <LinkageKitLongDescription item={item} />
          </div>
        </div>
      )}
    </>
  );
};

export default ItemTitleBlock;
