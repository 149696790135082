import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import AccountNavigation from "../../components/account/AccountNavigation";
import { getUserDetails, resendConfirmationEmail } from "../../redux/actions/userActions";

import LoadingWidget from "../../components/LoadingWidget";
import { submitResaleCert } from "../../redux/actions/contactActions";
import { Helmet } from "react-helmet";
import { variables } from "../../config";
import { useNavigate } from "react-router-dom";
import { useGetUserDetailsQuery } from "../../redux/api/userApi";
import VerifyAccount from "../../components/VerifyAccount";
import { useSubmitResellerCertificateMutation } from "../../redux/api/contactApi";

const AccountResellerScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const user = useGetUserDetailsQuery();

  const [submitResellerCertificate, submitResellerCertificateResult] =
    useSubmitResellerCertificateMutation();

  const fileInput = useRef();

  const [fileName, setFileName] = useState("No file selected");
  const [fileSelected, setFileSelected] = useState(false);

  const handleSelectFile = (e) => {
    e.preventDefault();
    setFileSelected(true);
    setFileName(fileInput.current.files[0].name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const file = fileInput.current.files[0];

    const formData = new FormData();
    formData.append("resaleCert", file);

    await submitResellerCertificate(formData);
  };

  useEffect(() => {
    if (submitResellerCertificateResult.isSuccess) {
      user.refetch();
    }
  }, [submitResellerCertificateResult]);

  return (
    <>
      <Helmet>
        <title>Account | {variables.WEBSITE_NAME}</title>
        <link rel="canonical" href={`${variables.FULL_URL}/account/reseller`} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      {user.isLoading ? (
        <LoadingWidget />
      ) : (
        <>
          <div className="block-space block-space--layout--after-header"></div>
          <div className="block">
            <div className="container container--max--xl">
              <div className="row">
                <div className="col-12 col-lg-3">
                  <AccountNavigation active="reseller" />
                </div>
                <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                  <div className="card">
                    <div className="card-header">
                      <h5>Reseller Status</h5>
                    </div>
                    <div className="card-divider"></div>
                    <div className="card-body card-body--padding--2">
                      <div className="row no-gutters">
                        {/* {contact.submitResaleCert.success && (
                        <div className="alert alert-success alert-lg">
                          Thank you! Your request has been submitted
                          successfully.
                        </div>
                      )} */}
                        {user?.data?.verificationRequiredFor?.taxExemption === true &&
                        user?.data?.isVerified === false ? (
                          <>
                            <div className="row">
                              <div className="col">
                                <VerifyAccount message="You must verify your account before submitting a Certificate of Resale." />
                              </div>
                            </div>

                            <p>
                              If you are certified as a reseller in your state, you may submit a
                              copy of your reseller certificate to make tax-free purchases on
                              linkagekits.com.
                            </p>
                          </>
                        ) : user?.data?.verificationRequiredFor?.taxExemption === false ||
                          user?.data?.isVerified === true ? (
                          <>
                            {user?.data?.resellerStatus === "Pending" ? (
                              <div className="alert alert-primary py-3">
                                <i className="fas fa-info-circle mr-2"></i>
                                <span>
                                  Your certificate of resale submission has been recieved and is
                                  currently under review.
                                </span>
                              </div>
                            ) : null}

                            {user?.data?.resellerStatus === "Verified" ? (
                              <>
                                <span className="h4 text-success">
                                  <i className="far fa-check-circle mr-2"></i>
                                  Verified Reseller
                                </span>
                                <p className="pt-3">
                                  As a verified reseller, you are eligible for tax-free purchases on
                                  linkagekits.com.
                                </p>
                              </>
                            ) : null}

                            {user?.data?.resellerStatus === "Denied" ? (
                              <>
                                <div className="alert alert-warning py-3">
                                  <span className="h6">
                                    <i className="fas fa-exclamation-triangle mr-2"></i>
                                    Your certificate of resale submission has been denied.
                                  </span>
                                  <br />
                                </div>
                                <p>
                                  Please email <strong>support@linkagekits.com</strong> to learn
                                  more about why your request was denied.
                                </p>
                              </>
                            ) : null}

                            {user?.data?.resellerStatus === "Unverified" ? (
                              <>
                                <div className="alert alert-info">
                                  <i className="fas fa-info-circle mr-2"></i>
                                  <span className="font-italic">
                                    If you are certified as a reseller in your state, you are
                                    eligible for tax-free purchases on linkagekits.com.
                                  </span>
                                </div>

                                <div className="col-12">
                                  <p>
                                    To apply for verified reseller status, please upload a PDF or
                                    image file of your Certificate of Resale and submit the form. We
                                    will review the document and upgrade your account upon
                                    verification.
                                  </p>
                                  {submitResellerCertificateResult.isError ? (
                                    <div className="alert alert-danger">
                                      {submitResellerCertificateResult?.error?.data?.message}
                                    </div>
                                  ) : null}
                                  <form onSubmit={handleSubmit}>
                                    <div className="form-row">
                                      <div className="col mb-3">
                                        <div className="file-input-btn-wrap">
                                          <label
                                            htmlFor="upload-file-input"
                                            className="btn btn-secondary"
                                          >
                                            <i class="fas fa-upload mr-2"></i>
                                            Upload Document
                                          </label>
                                          <input
                                            type="file"
                                            ref={fileInput}
                                            onChange={handleSelectFile}
                                            name="resaleCert"
                                            id="upload-file-input"
                                          />
                                          <span className="ml-2">{fileName}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      disabled={!fileSelected}
                                    >
                                      {submitResellerCertificateResult.isLoading ? (
                                        <>
                                          Submit
                                          <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="ml-2"
                                          />
                                        </>
                                      ) : (
                                        <>Submit</>
                                      )}
                                    </button>
                                  </form>
                                </div>
                              </>
                            ) : null}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-space block-space--layout--before-footer"></div>
        </>
      )}
    </>
  );
};

export default AccountResellerScreen;
