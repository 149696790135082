import React, { useState, useEffect } from "react";
import { Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchDrawing,
  fetchDrawingByDimensions,
  fetchDrawingPreview,
  fetchDrawingPreviewByDimensions,
} from "../../redux/actions/adminActions";
import DrawingPreview from "../admin/DrawingPreview";

const AdminButtons = () => {
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  // bring linkage kit selections in from redux state
  const slkbd = useSelector((state) => state.selectLinkageKitByDimensions);

  const linkageKitSelections = useSelector((state) => state.selectLinkageKit);
  const {
    valveSelectionType,
    actuatorSelectionType,
    selectedValve,
    selectedActuator,
    lockout,
    selectedBracketHeight,
    components,
    sku,
    qty,
    drawingPreview,
  } = linkageKitSelections;

  const [showDrawingPreview, setShowDrawingPreview] = useState(false);

  const productType =
    valveSelectionType === "byDimensions" || actuatorSelectionType === "byDimensions"
      ? "customMountingKit"
      : "mountingKit";

  const item = {
    productType: productType,
    ...(valveSelectionType !== "byDimensions" &&
      actuatorSelectionType !== "byDimensions" && { sku: sku }),
    valve:
      valveSelectionType === "byDimensions"
        ? { ...{ type: "Custom Valve" }, ...slkbd.valve }
        : selectedValve,
    actuator:
      actuatorSelectionType === "byDimensions"
        ? { ...{ type: "Custom Actuator" }, ...slkbd.actuator }
        : actuatorSelectionType === "byISO5211"
        ? { ...{ type: "ISO 5211 Standard Actuator" }, ...selectedActuator }
        : selectedActuator,
    lockout: lockout,
    bracketHeight: selectedBracketHeight,
    components: components,
    qty: qty,
  };

  return (
    <>
      <Modal
        show={showDrawingPreview}
        onHide={() => setShowDrawingPreview(false)}
        size="xl"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Drawing Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#000" }}>
          <DrawingPreview drawing={drawingPreview} />
        </Modal.Body>
      </Modal>

      <DropdownButton title="Admin" className="mr-auto">
        <Dropdown.Item
          onClick={() => {
            if (valveSelectionType === "byDimensions" || actuatorSelectionType === "byDimensions") {
              dispatch(fetchDrawingPreviewByDimensions(item));
            } else {
              dispatch(fetchDrawingPreview(sku));
            }
            setShowDrawingPreview(true);
          }}
        >
          Preview Drawing
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            if (valveSelectionType === "byDimensions" || actuatorSelectionType === "byDimensions") {
              dispatch(fetchDrawingByDimensions(item, "dxf"));
            } else {
              dispatch(fetchDrawing(sku, "dxf"));
            }
          }}
        >
          Download DXF
        </Dropdown.Item>
      </DropdownButton>
    </>
  );
};

export default AdminButtons;
