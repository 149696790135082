import React from "react";
import { Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { SLKBD_SELECT_VALVE_MOUNTING_TYPE } from "../../redux/constants";

const ValveMountingType = () => {
  const dispatch = useDispatch();

  // bring linkage kit selections in from redux state
  const selectLinkageKitByDimensions = useSelector((state) => state.selectLinkageKitByDimensions);
  const { loading, valve } = selectLinkageKitByDimensions;

  const valveMountingTypes = [
    {
      value: "boltCircle",
      label: `Bolt Circle`,
    },
    {
      value: "rectangle",
      label: `Rectangle`,
    },
    {
      value: "iso",
      label: "ISO 5211",
    },
  ];

  return (
    <>
      <Card.Body className="slkbd p-4 w-100">
        <div className="product-form__row">
          <div className="product-form__title">Mounting Type</div>
          <div className="product-form__control">
            <div className="input-radio-label">
              <div
                className="input-radio-label__list d-flex flex-col"
                style={{ columnGap: "1rem" }}
              >
                {valveMountingTypes.map((x) => (
                  <label
                    key={x.value}
                    className="input-radio-label__item"
                    style={{ flexGrow: 1, flexBasis: 1 }}
                  >
                    {`${x.label}`}
                    <input
                      type="radio"
                      name={x.label}
                      value={x.value}
                      onClick={(e) =>
                        dispatch({
                          type: SLKBD_SELECT_VALVE_MOUNTING_TYPE,
                          payload: e.target.value,
                        })
                      }
                      checked={valve.mounting.type === x.value}
                      className="input-radio-label__input"
                      disabled={loading === true}
                    />
                    <span
                      className="input-radio-label__title"
                      style={{
                        // background: `url(${doubleDThumbnail})`,
                        // backgroundSize: "calc(100% - 20px)",
                        // border: "none",
                        // width: "calc(100%)",
                        // height: "auto",
                        // paddingBottom: "100%",
                        // backgroundPositionX: "10px",
                        // backgroundPositionY: "10px",
                        // backgroundRepeat: "no-repeat",
                        textAlign: "center",
                      }}
                    >
                      {x.label}
                    </span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </>
  );
};

export default ValveMountingType;
