import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Spinner } from "react-bootstrap";
import Select from "react-select";

// import custom styling for react-select
import { customStyles3 } from "../../styles/selectorStyles";

//import trademark information
import tm from "../../data/trademarks";

// import redux actions
import {
  fetchSelectedValve,
  setSelectedValveBrand,
  setSelectedValveSeries,
  setSelectedValveSize,
  resetSelectedValve,
  setSelectedValveOptions,
} from "../../redux/actions/valveActions";
import { isEmpty, startCase } from "lodash";
import { SELECTED_VALVE_OPTIONS_RESET } from "../../redux/constants";
import {
  useGetValveBrandsQuery,
  useGetValveSeriesQuery,
  useGetValveSizesQuery,
} from "../../redux/api/valveApi";

const SelectValveModel = ({ title }) => {
  const dispatch = useDispatch();

  // bring linkage kits selections in from redux state
  const linkageKitSelections = useSelector((state) => state.selectLinkageKit);
  const {
    loading,
    valveOptions,
    selectedValveBrand,
    selectedValveSeries,
    selectedValveSize,
    selectedValveOptions,
    selectedValve,
  } = linkageKitSelections;

  const getValveBrandsQuery = useGetValveBrandsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  const getValveSeriesQuery = useGetValveSeriesQuery(
    { brand: selectedValveBrand },
    { skip: !selectedValveBrand }
  );

  const getValveSizesQuery = useGetValveSizesQuery(
    { brand: selectedValveBrand, series: selectedValveSeries },
    { skip: !selectedValveBrand || !selectedValveSeries }
  );

  // convert arrays of brands, series, sizes fetched from DB to arrays of objects containing values and labels. (For use with React-Select)
  // ie; { value: 'XOMOX', label: 'XOMOX' }
  const brands = getValveBrandsQuery?.data?.map((x) => ({
    value: x,
    label: tm[x] === "r" ? `${x}\u00AE` : tm[x] === "tm" ? `${x}\u2122` : x,
  }));
  const series = getValveSeriesQuery?.data?.map((x) => ({
    value: x,
    label:
      tm[x] === "r"
        ? `${x}\u00AE`
        : tm[x] === "tm"
        ? `${x}\u2122`
        : x.includes("DynaCentric")
        ? `${x.split(" ")[0]}\u00AE ${x.split(" ")[1]}`
        : x,
  }));
  const sizes = getValveSizesQuery?.data?.map((x) => ({ value: x, label: x }));

  // when a brand is selected, dispatch action, set new state in redux, and reset series, asme class and size selections
  const selectValveBrandHandler = (value) => {
    dispatch(setSelectedValveBrand(value));
    dispatch(setSelectedValveSeries(""));
    dispatch(setSelectedValveSize(""));
    dispatch({ type: SELECTED_VALVE_OPTIONS_RESET });

    if (!isEmpty(selectedValve)) {
      dispatch(resetSelectedValve());
    }
  };

  // when a series is selected, dispatch action, set new state in redux, and reset asme class and size selections
  const selectValveSeriesHandler = (value) => {
    dispatch(setSelectedValveSeries(value));
    dispatch(setSelectedValveSize(""));
    dispatch({ type: SELECTED_VALVE_OPTIONS_RESET });

    if (!isEmpty(selectedValve)) {
      dispatch(resetSelectedValve());
    }
  };

  // when an asme class is selected, dispatch action, set new state in redux, and reset size selections
  const selectValveSizeHandler = (value) => {
    dispatch(setSelectedValveSize(value));
    dispatch({ type: SELECTED_VALVE_OPTIONS_RESET });

    if (!isEmpty(selectedValve)) {
      dispatch(resetSelectedValve());
    }
  };

  // when a size is selected, dispatch action, and set new state in redux
  const selectValveOptionsHandler = (key, value) => {
    dispatch(setSelectedValveOptions(key, value));

    if (!isEmpty(selectedValve)) {
      dispatch(resetSelectedValve());
    }
  };

  // fetch list of valve options when a size is selected
  useEffect(() => {
    if (isEmpty(selectedValve)) {
      if (selectedValveSize !== "" && selectedValveSize !== "Select Size") {
        dispatch(
          fetchSelectedValve(
            selectedValveBrand,
            selectedValveSeries,
            selectedValveSize,
            selectedValveOptions
          )
        );
      }
    }
  }, [
    dispatch,
    selectedValveBrand,
    selectedValveSeries,
    selectedValveSize,
    selectedValveOptions,
  ]);

  return (
    <>
      <div className="w-100">
        <div className="build-kit-dropdowns-container">
          <div className="primary-selector-1 flex-fill">
            <Form.Group>
              <Form.Label
                id="aria-label-valve-brand"
                htmlFor="aria-input-valve-brand"
              >
                Brand
              </Form.Label>
              <Select
                inputId="aria-input-valve-brand"
                aria-labelledby="aria-label-valve-brand"
                value={brands?.filter(
                  ({ value }) => selectedValveBrand === value
                )}
                options={brands}
                onChange={(value) => selectValveBrandHandler(value.value)}
                styles={customStyles3}
                placeholder={
                  loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="ml-2"
                    />
                  ) : (
                    "Select Brand"
                  )
                }
              />
            </Form.Group>
          </div>
          <div className="primary-selector-2 flex-fill">
            <Form.Group>
              <Form.Label
                id="aria-label-valve-series"
                htmlFor="aria-input-valve-series"
              >
                Series
              </Form.Label>
              <Select
                inputId="aria-input-valve-series"
                aria-labelledby="aria-label-valve-series"
                value={series?.filter(
                  ({ value }) => selectedValveSeries === value
                )}
                options={series}
                onChange={(value) => selectValveSeriesHandler(value.value)}
                styles={customStyles3}
                isDisabled={selectedValveBrand === ""}
                placeholder={
                  loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="ml-2"
                    />
                  ) : (
                    "Select Series"
                  )
                }
              />
            </Form.Group>
          </div>
          <div className="primary-selector-3 flex-fill">
            <Form.Group>
              <Form.Label
                id="aria-label-valve-size"
                htmlFor="aria-input-valve-size"
              >
                Size
              </Form.Label>
              <Select
                inputId="aria-input-valve-size"
                aria-labelledby="aria-label-valve-size"
                value={sizes?.filter(
                  ({ value }) => selectedValveSize === value
                )}
                options={sizes}
                onChange={(value) => selectValveSizeHandler(value.value)}
                styles={customStyles3}
                isDisabled={selectedValveSeries === ""}
                placeholder={
                  loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="ml-2"
                    />
                  ) : (
                    "Select Size"
                  )
                }
              />
            </Form.Group>
          </div>

          {!isEmpty(valveOptions) &&
            valveOptions.map((option, index) => {
              const options = Object.values(option)[0].map((x) => ({
                value: x,
                label: x,
              }));

              const optionName = Object.keys(option)[0];

              const label =
                Object.keys(option)[0] === "asmeClass"
                  ? "ASME Class"
                  : startCase(Object.keys(option)[0]);

              const disabled = index > selectedValveOptions.length;

              return (
                <React.Fragment key={Object.keys(option)[0]}>
                  <div className="flex-fill">
                    <Form.Group>
                      <Form.Label
                        id={`aria-label-valve-${optionName}`}
                        htmlFor={`aria-input-valve-${optionName}`}
                      >
                        {label}
                      </Form.Label>
                      <Select
                        inputId={`aria-input-valve-${optionName}`}
                        aria-optionledby={`aria-label-valve-${optionName}`}
                        value={options.filter(({ value }) =>
                          selectedValveOptions.find(
                            (option) => Object.values(option)[0] === value
                          )
                        )}
                        options={options}
                        onChange={(value) =>
                          selectValveOptionsHandler(
                            Object.keys(option)[0],
                            value.value
                          )
                        }
                        styles={customStyles3}
                        isDisabled={disabled}
                        placeholder={
                          loading ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="ml-2"
                            />
                          ) : (
                            // `Select ${label}`
                            "Select an Option"
                          )
                        }
                      />
                    </Form.Group>
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default SelectValveModel;
