import { api } from "../slices/api";

// Define a service using a base URL and expected endpoints
export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (values) => ({
        url: "v1/users/login",
        method: "POST",
        body: values,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "v1/users/logout",
        method: "POST",
        body: {},
      }),
    }),
    authStatus: builder.query({
      query: () => "v1/auth",
    }),
    adminStatus: builder.query({
      query: () => "v1/auth/admin",
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation, useAuthStatusQuery, useAdminStatusQuery } =
  extendedApiSlice;
