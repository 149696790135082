import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CButton,
  CForm,
  CFormCheck,
  CFormInput,
  CFormFeedback,
  CFormLabel,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import { ADMIN_ADD_VALVE_MODAL } from "../../../redux/constants";

export const AddValveModal = () => {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);
  const { loading, message, error, status } = admin;

  return (
    <CModal alignment="center" size="lg" visible={admin.application.addValveModal}>
      <CModalHeader onDismiss={() => dispatch({ type: ADMIN_ADD_VALVE_MODAL })}>
        <CModalTitle>Add Valve</CModalTitle>
      </CModalHeader>
      <Formik
        validationSchema={Yup.object().shape({
          valveType: Yup.string().required("Required"),
          brand: Yup.string().required("Required"),
          series: Yup.string().required("Required"),
          size: Yup.string().required("Required"),
          asmeClass: Yup.string(),
          iso_1: Yup.string().required("Required"),
          iso_2: Yup.string().required("Required"),
          iso_3: Yup.string().required("Required"),
          mountingUnit: Yup.string().required("Required"),
          mountingX: Yup.string().required("Required"),
          mountingY: Yup.string().required("Required"),
          boltSize: Yup.string().required("Required"),
          numberOfBolts: Yup.string().required("Required"),
          stemUnit: Yup.string().required("Required"),
          stemType: Yup.string().required("Required"),
          ddX: Yup.string().required("Required"),
          ddY: Yup.string().required("Required"),
          ddZ: Yup.string().required("Required"),
          ddFlats: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          //dispatch(addValve(values.brand));
        }}
        initialValues={{
          valveType: "",
          brand: "",
          series: "",
          asmeClass: "",
          size: "",
          iso_1: "",
          iso_2: "",
          iso_3: "",
          mountingUnit: "",
          mountingX: "",
          mountingY: "",
          boltSize: "",
          numberOfBolts: "",
          stemUnit: "",
          stemType: "",
          stemX: "",
          stemY: "",
          stemZ: "",
          stemFlats: "",
          stemRotation: "",
          hasBonnet: "false",
          bonnetUnit: "",
          bonnetX: "",
          bonnetY: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          isSubmitting,
        }) => (
          <CForm noValidate onSubmit={handleSubmit}>
            <CModalBody>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="form-row mb-3">
                <div className="col-md-4">
                  <CFormLabel htmlFor="series">Valve Type</CFormLabel>
                  <CFormSelect
                    name="valveType"
                    value={values.valveType}
                    onChange={handleChange}
                    isInvalid={!!errors.valveType}
                    id="valveType"
                  >
                    <option value="" disabled>
                      Please select an option
                    </option>
                    <option value="Ball Valve">Ball Valve</option>
                    <option value="Gate Valve">Gate Valve</option>
                    <option value="Butterfly Valve">Butterfly Valve</option>
                  </CFormSelect>
                  <CFormFeedback invalid>{errors.iso_1}</CFormFeedback>
                </div>
              </div>
              <div className="form-row mb-3">
                <div className="col-md-6">
                  <CFormLabel htmlFor="brand">Brand</CFormLabel>
                  <CFormInput
                    type="text"
                    name="brand"
                    value={values.brand}
                    onChange={handleChange}
                    isInvalid={!!errors.brand}
                    id="brand"
                  />
                  <CFormFeedback invalid>{errors.brand}</CFormFeedback>
                </div>
                <div className="col-md-6">
                  <CFormLabel htmlFor="series">Series</CFormLabel>
                  <CFormInput
                    type="text"
                    name="series"
                    value={values.series}
                    onChange={handleChange}
                    isInvalid={!!errors.series}
                    id="series"
                  />
                  <CFormFeedback invalid>{errors.series}</CFormFeedback>
                </div>
              </div>
              <div className="form-row mb-3">
                <div className="col-md-6">
                  <CFormLabel htmlFor="series">ASME Class</CFormLabel>
                  <CFormInput
                    type="text"
                    name="asmeClass"
                    value={values.asmeClass}
                    onChange={handleChange}
                    isInvalid={!!errors.asmeClass}
                    id="asmeClass"
                  />
                  <CFormFeedback invalid>{errors.asmeClass}</CFormFeedback>
                </div>
                <div className="col-md-6">
                  <CFormLabel htmlFor="size">Size</CFormLabel>
                  <CFormInput
                    type="text"
                    name="size"
                    value={values.size}
                    onChange={handleChange}
                    isInvalid={!!errors.size}
                    id="size"
                  />
                  <CFormFeedback invalid>{errors.size}</CFormFeedback>
                </div>
              </div>
              <div className="form-row mb-3">
                <div className="col-md-4">
                  <CFormLabel htmlFor="series">ISO 1</CFormLabel>
                  <CFormSelect
                    name="iso_1"
                    value={values.iso_1}
                    onChange={handleChange}
                    isInvalid={!!errors.iso_1}
                    id="iso_1"
                  >
                    <option value="" disabled>
                      Please select an option
                    </option>
                    <option value="F03">F03</option>
                    <option value="F04">F04</option>
                    <option value="F05">F05</option>
                    <option value="F07">F07</option>
                    <option value="F10">F10</option>
                    <option value="F12">F12</option>
                    <option value="F14">F14</option>
                    <option value="F16">F16</option>
                  </CFormSelect>
                  <CFormFeedback invalid>{errors.iso_1}</CFormFeedback>
                </div>
                <div className="col-md-4">
                  <CFormLabel htmlFor="series">ISO 2</CFormLabel>
                  <CFormSelect
                    name="iso_2"
                    value={values.iso_2}
                    onChange={handleChange}
                    isInvalid={!!errors.iso_2}
                    id="iso_2"
                  >
                    <option value="" disabled>
                      Please select an option
                    </option>
                    <option value="F03">F03</option>
                    <option value="F04">F04</option>
                    <option value="F05">F05</option>
                    <option value="F07">F07</option>
                    <option value="F10">F10</option>
                    <option value="F12">F12</option>
                    <option value="F14">F14</option>
                    <option value="F16">F16</option>
                  </CFormSelect>
                  <CFormFeedback invalid>{errors.iso_2}</CFormFeedback>
                </div>
                <div className="col-md-4">
                  <CFormLabel htmlFor="series">ISO 3</CFormLabel>
                  <CFormSelect
                    name="iso_3"
                    value={values.iso_3}
                    onChange={handleChange}
                    isInvalid={!!errors.iso_3}
                    id="iso_3"
                  >
                    <option value="" disabled>
                      Please select an option
                    </option>
                    <option value="F03">F03</option>
                    <option value="F04">F04</option>
                    <option value="F05">F05</option>
                    <option value="F07">F07</option>
                    <option value="F10">F10</option>
                    <option value="F12">F12</option>
                    <option value="F14">F14</option>
                    <option value="F16">F16</option>
                  </CFormSelect>
                  <CFormFeedback invalid>{errors.iso_3}</CFormFeedback>
                </div>
              </div>
              <h5>Mounting</h5>
              <hr></hr>
              <div className="form-row mb-3">
                <div className="col-md-6">
                  <CFormLabel htmlFor="mountingUnit">Unit</CFormLabel>
                  <CFormSelect
                    id="mountingUnit"
                    name="mountingUnit"
                    value={values.mountingUnit}
                    onChange={handleChange}
                  >
                    <option>MM</option>
                    <option>IN</option>
                  </CFormSelect>
                </div>
              </div>
              <div className="form-row mb-3">
                <div className="col-md-3">
                  <CFormLabel htmlFor="mountingX">Mounting X</CFormLabel>
                  <CFormInput
                    type="text"
                    name="mountingX"
                    value={values.mountingX}
                    onChange={handleChange}
                    isInvalid={!!errors.mountingX}
                    id="mountingX"
                  />
                  <CFormFeedback invalid>{errors.mountingX}</CFormFeedback>
                </div>
                <div className="col-md-3">
                  <CFormLabel htmlFor="mountingY">Mounting Y</CFormLabel>
                  <CFormInput
                    type="text"
                    name="mountingY"
                    value={values.mountingY}
                    onChange={handleChange}
                    isInvalid={!!errors.mountingY}
                    id="mountingY"
                  />
                  <CFormFeedback invalid>{errors.size}</CFormFeedback>
                </div>
                <div className="col-md-3">
                  <CFormLabel htmlFor="boltSize">Bolt Size</CFormLabel>
                  <CFormSelect
                    name="boltSize"
                    value={values.boltSize}
                    onChange={handleChange}
                    isInvalid={!!errors.boltSize}
                    id="boltSize"
                  >
                    <option value="0-80">0-80</option>
                    <option value="1-64">1-64</option>
                    <option value="1-72">1-72</option>
                    <option value="2-56">2-56</option>
                    <option value="2-64">2-64</option>
                    <option value="4-40">4-40</option>
                    <option value="4-48">4-48</option>
                    <option value="5-40">5-40</option>
                    <option value="5-44">5-44</option>
                    <option value="6-32">6-32</option>
                    <option value="6-40">6-40</option>
                    <option value="8-32">8-32</option>
                    <option value="8-36">8-36</option>
                    <option value="10-24">10-24</option>
                    <option value="10-32">10-32</option>
                    <option value="12-24">12-24</option>
                    <option value="12-28">12-28</option>
                    <option value="1/4">1/4</option>
                    <option value="1/4-20">1/4-20</option>
                    <option value="1/4-28">1/4-28</option>
                    <option value="5/16">5/16</option>
                    <option value="5/16-18">5/16-18</option>
                    <option value="5/16-24">5/16-24</option>
                    <option value="3/8">3/8</option>
                    <option value="3/8-16">3/8-16</option>
                    <option value="3/8-24">3/8-24</option>
                    <option value="1/2">1/2</option>
                    <option value="5/8">5/8</option>
                    <option value="3/4">3/4</option>
                    <option value="M1.4">M1.4</option>
                    <option value="M2">M2</option>
                    <option value="M2.5">M2.5</option>
                    <option value="M3">M3</option>
                    <option value="M4">M4</option>
                    <option value="M5">M5</option>
                    <option value="M6">M7</option>
                    <option value="M8">M8</option>
                    <option value="M10">M10</option>
                    <option value="M12">M12</option>
                    <option value="M14">M14</option>
                    <option value="M16">M16</option>
                    <option value="M18">M18</option>
                    <option value="M20">M20</option>
                    <option value="M22">M22</option>
                    <option value="M24">M24</option>
                    <option value="M30">M30</option>
                    <option value="M36">M36</option>
                  </CFormSelect>
                  <CFormFeedback invalid>{errors.boltSize}</CFormFeedback>
                </div>
                <div className="col-md-3">
                  <CFormLabel htmlFor="numberOfBolts">Number of Bolts</CFormLabel>
                  <CFormSelect
                    name="numberOfBolts"
                    value={values.numberOfBolts}
                    onChange={handleChange}
                    isInvalid={!!errors.numberOfBolts}
                    id="numberOfBolts"
                  >
                    <option value="2">2</option>
                    <option value="4">4</option>
                    <option value="6">6</option>
                    <option value="8">8</option>
                    <option value="10">10</option>
                    <option value="12">12</option>
                  </CFormSelect>
                  <CFormFeedback invalid>{errors.numberOfBolts}</CFormFeedback>
                </div>
              </div>
              <h5>Stem</h5>
              <hr></hr>
              <div className="form-row mb-3">
                <div className="col-md-6">
                  <CFormLabel htmlFor="stemUnit">Unit</CFormLabel>
                  <CFormSelect
                    id="stemUnit"
                    name="stemUnit"
                    value={values.stemUnit}
                    onChange={handleChange}
                  >
                    <option value="mm">MM</option>
                    <option value="in">IN</option>
                  </CFormSelect>
                </div>
                <div className="col-md-6">
                  <CFormLabel htmlFor="stemType">Stem Type</CFormLabel>
                  <CFormSelect
                    id="stemType"
                    name="stemType"
                    value={values.stemType}
                    onChange={handleChange}
                  >
                    <option value="dd">Double-D</option>
                    <option value="round">Round with Keyway</option>
                  </CFormSelect>
                </div>
              </div>
              <div className="form-row mb-3">
                <div className="col-md-4">
                  <CFormLabel htmlFor="mountingX">Stem X</CFormLabel>
                  <CFormInput
                    type="text"
                    name="stemX"
                    value={values.stemX}
                    onChange={handleChange}
                    isInvalid={!!errors.stemX}
                    id="stemX"
                  />
                  <CFormFeedback invalid>{errors.stemX}</CFormFeedback>
                </div>
                <div className="col-md-4">
                  <CFormLabel htmlFor="stemY">Stem Y</CFormLabel>
                  <CFormInput
                    type="text"
                    name="stemY"
                    value={values.stemY}
                    onChange={handleChange}
                    isInvalid={!!errors.stemY}
                    id="stemY"
                  />
                  <CFormFeedback invalid>{errors.stemY}</CFormFeedback>
                </div>
                <div className="col-md-4">
                  <CFormLabel htmlFor="stemY">Stem Z</CFormLabel>
                  <CFormInput
                    type="text"
                    name="stemZ"
                    value={values.stemZ}
                    onChange={handleChange}
                    isInvalid={!!errors.stemZ}
                    id="stemZ"
                  />
                  <CFormFeedback invalid>{errors.stemZ}</CFormFeedback>
                </div>
              </div>
              <div className="form-row mb-3">
                <div className="col-md-6">
                  <CFormLabel htmlFor="stemFlats">Stem Flats Height</CFormLabel>
                  <CFormInput
                    type="text"
                    name="stemFlats"
                    value={values.stemFlats}
                    onChange={handleChange}
                    isInvalid={!!errors.stemFlats}
                    id="stemFlats"
                  />
                  <CFormFeedback invalid>{errors.stemFlats}</CFormFeedback>
                </div>
                <div className="col-md-6">
                  <CFormLabel htmlFor="stemRotation">Stem Rotation</CFormLabel>
                  <CFormInput
                    type="text"
                    name="stemRotation"
                    value={values.stemRotation}
                    onChange={handleChange}
                    isInvalid={!!errors.stemRotation}
                    id="stemRotation"
                  />
                  <CFormFeedback invalid>{errors.stemRotation}</CFormFeedback>
                </div>
              </div>
              <h5>Bonnet</h5>
              <hr></hr>
              <div className="form-row mb-3">
                <div className="col">
                  <CFormCheck
                    type="radio"
                    name="hasBonnet"
                    value="true"
                    onChange={handleChange}
                    id="flexRadioDefault1"
                    label="Bonnet"
                  />
                  <CFormCheck
                    type="radio"
                    name="hasBonnet"
                    value="false"
                    onChange={handleChange}
                    id="flexRadioDefault2"
                    label="No Bonnet"
                    defaultChecked
                  />
                </div>
              </div>
              {values.hasBonnet === "true" && (
                <div className="form-row mb-3">
                  <div className="col-md-2">
                    <CFormLabel htmlFor="bonnetUnit">Unit</CFormLabel>
                    <CFormSelect
                      name="bonnetUnit"
                      value={values.bonnetUnit}
                      onChange={handleChange}
                      isInvalid={!!errors.bonnetUnit}
                      id="bonnetUnit"
                    >
                      <option value="mm">MM</option>
                      <option value="in">IN</option>
                    </CFormSelect>
                    <CFormFeedback invalid>{errors.bonnetUnit}</CFormFeedback>
                  </div>
                  <div className="col-md-5">
                    <CFormLabel htmlFor="bonnetX">Bonnet X</CFormLabel>
                    <CFormInput
                      type="text"
                      name="bonnetX"
                      value={values.bonnetX}
                      onChange={handleChange}
                      isInvalid={!!errors.bonnetX}
                      id="bonnetX"
                    />
                    <CFormFeedback invalid>{errors.bonnetX}</CFormFeedback>
                  </div>
                  <div className="col-md-5">
                    <CFormLabel htmlFor="bonnetY">Bonnet Y</CFormLabel>
                    <CFormInput
                      type="text"
                      name="bonnetY"
                      value={values.bonnetY}
                      onChange={handleChange}
                      isInvalid={!!errors.bonnetY}
                      id="bonnetY"
                    />
                    <CFormFeedback invalid>{errors.bonnetY}</CFormFeedback>
                  </div>
                </div>
              )}
              <pre>{JSON.stringify(values, null, 3)}</pre>
            </CModalBody>
            <CModalFooter>
              <CButton
                color="light"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({ type: ADMIN_ADD_VALVE_MODAL });
                }}
              >
                Close
              </CButton>
              <CButton type="submit" color="primary">
                Save
              </CButton>
            </CModalFooter>
          </CForm>
        )}
      </Formik>
    </CModal>
  );
};
