import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isAuthenticated: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.user = action.payload;
    },
    setAuthStatus: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    clearUserDetails: (state) => {
      state.user = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserLoading, setUserDetails, clearUserDetails, setAuthStatus } =
  userSlice.actions;

export default userSlice.reducer;
