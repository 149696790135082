export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    borderRadius: 0,
    color: "#222",
    textAlign: "left",
    backgroundColor: "&:hover" && {
      backgroundColor: "#f2f2f2",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    padding: ".375rem .1875rem",
    cursor: "pointer",
    borderRadius: "2px",
    border: state.isDisabled ? 0 : "1px solid #aaa",
    color: state.isDisabled && "#999",
    opacity: state.isDisabled ? 0.65 : 1,
  }),
};

export const customStyles2 = {
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    borderRadius: 0,
    color: "#222",
    textAlign: "left",
    backgroundColor: "&:hover" && {
      backgroundColor: "#f2f2f2",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    padding: ".375rem .1875rem",
    cursor: "pointer",
    borderRadius: "2px",
    border: 0,
    boxShadow: !state.isFocused && "1px 1px 3px rgba(0,0,0,0.5)",
    color: state.isDisabled && "#999",
    opacity: state.isDisabled && 0.65,
  }),
};

export const customStyles3 = {
  container: (provided, state) => ({
    ...provided,
    boxShadow: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "2px",
    padding: ".375rem .1875rem",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: state.isFocused ? "#2684FF" : "hsl(0, 0%, 60%)",
    boxShadow: "none",
    // borderWidth: '1px',
    // borderStyle: 'solid',
    // borderColor: '#aaa',
    "&:hover": {
      ...provided,
      boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1)",
      borderWidth: "2px",
      borderRadius: "2px",
      borderColor: "#d9d9d9",
      transition: "all 0.3s",
    },
    "&:focus": {
      ...provided,
      borderWidth: "2px",
      boxShadow: "none",
      borderRadius: "2px",
    },
    cursor: "pointer",
    color: state.isDisabled && "#999",
    opacity: state.isDisabled && 0.65,
  }),
};

export const customStyles4 = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "6px 2px",
  }),
  option: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
    fontSize: ".85rem",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "2px",
    fontSize: ".85rem",
    width: "6rem",
    minHeight: "auto",
    // boxShadow: '0 0 0 1px #aaa',
    // border: 'none',
    backgroundColor: state.isFocused ? "#ffffff" : "#ebebeb",
    borderColor: "#ebebeb",
    "&:hover": {
      borderColor: "#d9d9d9",
    },
    "&:focus": {
      borderColor: "#d9d9d9",
    },
    // borderWidth: '1px',
    // borderStyle: 'solid',
    // borderColor: '#aaa',
    // '&:hover': {
    //   borderColor: '#d9d9d9',
    // },
    // '&:focus': {
    //   borderColor: '#d9d9d9',
    // },
    cursor: "pointer",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: ".85rem",
    fontWeight: "500",
    color: "#333",
    opacity: state.isDisabled && 0.65,
  }),
};

export const cartQtySelector = {
  option: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "0",
    backgroundColor: state.isFocused ? "#ffffff" : "#ebebeb",
    boxShadow: state.isFocused && "none",
    borderColor: "#ebebeb",
    "&:hover": {
      borderColor: "#d9d9d9",
    },
    "&:focus": {
      borderColor: "#d9d9d9",
    },
    cursor: "pointer",
  }),
};

export const summaryQtySelector = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "6px 2px",
  }),
  valueContainer: (provided, state) => ({ padding: "6px 8px" }),
  option: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
    fontSize: ".9rem",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: ".9rem",
    fontWeight: "500",
    color: "#333",
  }),
  control: (provided, state) => ({
    ...provided,
    width: "100px",
    borderRadius: "2px",
    fontSize: ".9rem",
    minHeight: "auto",
    // boxShadow: '0 0 0 1px #aaa',
    // border: 'none',
    backgroundColor: state.isFocused ? "#ffffff" : "#ebebeb",
    borderColor: "#ebebeb",
    "&:hover": {
      borderColor: "#d9d9d9",
    },
    "&:focus": {
      borderColor: "#d9d9d9",
    },
    // borderWidth: '1px',
    // borderStyle: 'solid',
    // borderColor: '#aaa',
    // '&:hover': {
    //   borderColor: '#d9d9d9',
    // },
    // '&:focus': {
    //   borderColor: '#d9d9d9',
    // },
    cursor: "pointer",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: ".9rem",
    fontWeight: "500",
    color: "#333",
    opacity: state.isDisabled && 0.65,
  }),
};

export const optionsSelector = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "6px 2px",
  }),
  option: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
    fontSize: ".9rem",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "2px",
    fontSize: ".9rem",
    minHeight: "auto",
    // boxShadow: '0 0 0 1px #aaa',
    // border: 'none',
    backgroundColor: "#ffffff",
    borderColor: "#ebebeb",
    "&:hover": {
      borderColor: "#d9d9d9",
    },
    "&:focus": {
      borderColor: "#d9d9d9",
    },
    // borderWidth: '1px',
    // borderStyle: 'solid',
    // borderColor: '#aaa',
    // '&:hover': {
    //   borderColor: '#d9d9d9',
    // },
    // '&:focus': {
    //   borderColor: '#d9d9d9',
    // },
    cursor: "pointer",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: ".9rem",
    fontWeight: "500",
    color: "#333",
    opacity: state.isDisabled && 0.65,
  }),
};

export const customStyles5 = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "6px 2px",
  }),
  option: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "0 2px 2px 0",
    borderTop: "2px solid hsl(0, 0%, 75%)",
    borderBottom: "2px solid hsl(0, 0%, 75%)",
    borderRight: "2px solid hsl(0, 0%, 75%)",
    borderLeft: "none",
    padding: "0",
    minHeight: "2.5rem",
    height: "2.5rem",
    width: "80px",
    right: "0",
    boxShadow: "none",
    //boxShadow: "0 0 0 1px #aaa",
    // borderWidth: '1px',
    // borderStyle: 'solid',
    // borderColor: '#aaa',
    "&:hover": {
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
    cursor: "pointer",
    color: state.isDisabled && "#999",
    opacity: state.isDisabled && 0.65,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    margin: "0 6px",
    fontSize: ".9rem",
    fontWeight: "500",
    color: "#333",
    right: "0",
    opacity: state.isDisabled && 0.65,
  }),
};
