import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CAlert,
  CButton,
  CForm,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import { ADMIN_ADD_VALVES_FROM_CSV_STRING_MODAL } from "../../../redux/constants";
import { addValvesFromCsvString } from "../../../redux/actions/adminActions";
import { Spinner } from "react-bootstrap";

export const AddValvesFromCsvStringModal = () => {
  const dispatch = useDispatch();

  const application = useSelector((state) => state.admin.application);

  const state = useSelector((state) => state.admin.database.valves.addValvesFromCsvString);
  const { loading, success, message, details, error, status } = state;

  return (
    <CModal alignment="center" size="xl" visible={application.addValvesFromCsvStringModal}>
      <CModalHeader onDismiss={() => dispatch({ type: ADMIN_ADD_VALVES_FROM_CSV_STRING_MODAL })}>
        <CModalTitle>Add Valves From CSV Text</CModalTitle>
      </CModalHeader>

      <Formik
        validationSchema={Yup.object().shape({
          csvString: Yup.string().required("CSV Text Required"),
        })}
        onSubmit={(values) => {
          dispatch(addValvesFromCsvString(JSON.stringify(values.csvString)));
        }}
        initialValues={{
          csvString: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          isSubmitting,
        }) => (
          <CForm noValidate onSubmit={handleSubmit}>
            <CModalBody>
              {!success && error && (
                <CAlert color="danger">{JSON.stringify(error, null, 3)}</CAlert>
              )}
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <textarea
                      rows="10"
                      style={{ width: "100%" }}
                      name="csvString"
                      value={values.csvString}
                      onChange={handleChange}
                      isInvalid={!!errors.csvString}
                      id="csvString"
                    />
                    <div className="d-block invalid-feedback">{errors.csvString}</div>
                  </div>
                </div>
              </div>
            </CModalBody>
            <CModalFooter>
              <CButton
                type="button"
                color="light"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({ type: ADMIN_ADD_VALVES_FROM_CSV_STRING_MODAL });
                }}
              >
                Cancel
              </CButton>
              <CButton type="submit" color="primary">
                {loading ? (
                  <>
                    Submit to Database
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="ml-2"
                    />
                  </>
                ) : (
                  <>Submit to Database</>
                )}
              </CButton>
            </CModalFooter>
          </CForm>
        )}
      </Formik>
    </CModal>
  );
};
