import React from 'react';

const BillingAddressCard = ({ shippingAddress }) => {
  return (
    <>
      <div className="card address-card h-100">
        <div className="order-header pl-4">
          <h5 className="mb-0">Shipping Address</h5>
        </div>
        <div className="card-divider"></div>
        <div className="address-card__body">
          <div className="address-card__name">{`${shippingAddress.firstName} ${shippingAddress.lastName}`}</div>
          <div className="address-card__row">
            {shippingAddress.company && (
              <>
                {shippingAddress.company} <br />
              </>
            )}
            {shippingAddress.street2 ? (
              <>
                {`${shippingAddress.street} ${shippingAddress.street2}`} <br />
              </>
            ) : (
              <>
                {shippingAddress.street} <br />
              </>
            )}
            <>
              {`${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.postalCode} `}
              <br />
              {shippingAddress.country}
            </>
          </div>
          {shippingAddress.phone && (
            <div className="address-card__row">
              <div className="address-card__row-title">Phone Number</div>
              <div className="address-card__row-content">
                {shippingAddress.phone}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BillingAddressCard;
