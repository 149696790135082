import axios from "axios";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_HARD_LOGOUT_SUCCESS,
  USER_HARD_LOGOUT_REQUEST,
  USER_HARD_LOGOUT_FAIL,
  USER_SOFT_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PASSWORD_REQUEST,
  USER_UPDATE_PASSWORD_SUCCESS,
  USER_UPDATE_PASSWORD_FAIL,
  USER_GET_SHIPPING_ADDRESS_REQUEST,
  USER_GET_SHIPPING_ADDRESS_SUCCESS,
  USER_GET_SHIPPING_ADDRESS_FAIL,
  USER_GET_SHIPPING_ADDRESSES_REQUEST,
  USER_GET_SHIPPING_ADDRESSES_SUCCESS,
  USER_GET_SHIPPING_ADDRESSES_FAIL,
  USER_ADD_SHIPPING_ADDRESS_REQUEST,
  USER_ADD_SHIPPING_ADDRESS_SUCCESS,
  USER_ADD_SHIPPING_ADDRESS_FAIL,
  USER_UPDATE_SHIPPING_ADDRESS_REQUEST,
  USER_UPDATE_SHIPPING_ADDRESS_SUCCESS,
  USER_UPDATE_SHIPPING_ADDRESS_FAIL,
  USER_DELETE_SHIPPING_ADDRESS_REQUEST,
  USER_DELETE_SHIPPING_ADDRESS_SUCCESS,
  USER_DELETE_SHIPPING_ADDRESS_FAIL,
  USER_PASSWORD_FORGOT_REQUEST,
  USER_PASSWORD_FORGOT_SUCCESS,
  USER_PASSWORD_FORGOT_FAIL,
  USER_GET_ORDERS_REQUEST,
  USER_GET_ORDERS_SUCCESS,
  USER_GET_ORDERS_FAIL,
  USER_GET_ORDER_REQUEST,
  USER_GET_ORDER_SUCCESS,
  USER_GET_ORDER_FAIL,
  USER_REGISTER_CONFIRM_TOKEN_REQUEST,
  USER_REGISTER_CONFIRM_TOKEN_SUCCESS,
  USER_REGISTER_CONFIRM_TOKEN_FAIL,
  USER_GET_AUTH_STATUS,
  USER_RESEND_CONFIRMATION_EMAIL_REQUEST,
  USER_RESEND_CONFIRMATION_EMAIL_SUCCESS,
  USER_RESEND_CONFIRMATION_EMAIL_FAIL,
  USER_GET_DEFAULT_SHIPPING_ADDRESS_REQUEST,
  USER_GET_DEFAULT_SHIPPING_ADDRESS_SUCCESS,
  USER_GET_DEFAULT_SHIPPING_ADDRESS_FAIL,
  USER_GET_AUTH_STATUS_REQUEST,
  USER_GET_AUTH_STATUS_FAIL,
  USER_GET_AUTH_STATUS_SUCCESS,
} from "../constants";
import { getUserCart, mergeCart, validatePreferredCustomer } from "./cartActions";

import { getSavedItems } from "./savedItemsActions";

export const login = (email, password, reCaptchaToken) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const res = await axios.post(
      "/api/v1/users/login",
      { username: email, password, reCaptchaToken },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: res,
    });

    dispatch(getUserDetails());

    const localCart = JSON.parse(localStorage.getItem("cartItems"));

    if (localCart && localCart.length > 0) {
      dispatch(mergeCart(localCart));
    } else if (!localCart || localCart.length < 1) {
      dispatch(getUserCart());
      dispatch(getSavedItems());
    }

    dispatch(validatePreferredCustomer(res.data.email));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error,
    });
  }
};

// export const login = (email, password, reCaptchaToken) => async (dispatch) => {
//   try {
//     dispatch({
//       type: USER_LOGIN_REQUEST,
//     });

//     const userLoginRequest = await axios.post(
//       '/api/v1/users/login',
//       { username: email, password, reCaptchaToken },
//       {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       }
//     );

//     dispatch({
//       type: USER_LOGIN_SUCCESS,
//       payload: userLoginRequest,
//     });

//     dispatch({
//       type: USER_DETAILS_REQUEST,
//     });

//     const userDetailsRequest = await axios.get('/api/v1/users/profile');

//     dispatch({
//       type: USER_DETAILS_SUCCESS,
//       payload: userDetailsRequest,
//     });

//     localStorage.setItem(
//       'userDetails',
//       JSON.stringify({
//         loading: false,
//         user: userDetailsRequest.data,
//         status: userDetailsRequest.status,
//       })
//     );

//     const localCart = JSON.parse(localStorage.getItem('cartItems'));

//     if (localCart && localCart.length > 0) {
//       dispatch({ type: CART_MERGE_REQUEST });

//       const cartMergeRequest = await axios.post(
//         '/api/v1/cart/merge',
//         localCart,
//         {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         }
//       );

//       if (cartMergeRequest.data.coupon) {
//         dispatch(
//           addCoupon(
//             cartMergeRequest.data.coupon.code,
//             cartMergeRequest.data.cart.cartItems
//               .reduce((acc, item) => acc + item.qty * item.price, 0)
//               .toFixed(2),
//             cartMergeRequest.data.cart.cartItems.reduce(
//               (acc, item) => acc + item.qty,
//               0
//             )
//           )
//         );
//       }

//       dispatch({ type: CART_MERGE_SUCCESS, payload: cartMergeRequest });

//       localStorage.setItem(
//         'cartItems',
//         JSON.stringify(cartMergeRequest.data.cartItems)
//       );
//       localStorage.setItem(
//         'savedItems',
//         JSON.stringify(cartMergeRequest.data.savedItems)
//       );
//     } else if (!localCart || localCart.length < 1) {
//       dispatch({
//         type: USER_CART_REQUEST,
//       });

//       const userCartRequest = await axios.get('/api/v1/cart');

//       dispatch({
//         type: USER_CART_SUCCESS,
//         payload: userCartRequest,
//       });

//       localStorage.setItem(
//         'cartItems',
//         JSON.stringify(userCartRequest.data.cartItems)
//       );

//       if (userCartRequest.data.coupon) {
//         dispatch(
//           addCoupon(
//             userCartRequest.data.coupon.code,
//             userCartRequest.data.cartItems
//               .reduce((acc, item) => acc + item.qty * item.price, 0)
//               .toFixed(2),
//             userCartRequest.data.cartItems.reduce(
//               (acc, item) => acc + item.qty,
//               0
//             ),
//             true
//           )
//         );

//         localStorage.setItem(
//           'couponInfo',
//           JSON.stringify(userCartRequest.data.coupon)
//         );
//       }

//       dispatch({
//         type: GET_SAVED_ITEMS_REQUEST,
//       });

//       const savedItemsRequest = await axios.get(
//         '/api/v1/users/profile/savedItems'
//       );

//       dispatch({
//         type: GET_SAVED_ITEMS_SUCCESS,
//         payload: savedItemsRequest,
//       });

//       localStorage.setItem(
//         'savedItems',
//         JSON.stringify(savedItemsRequest.data)
//       );
//     }

//     dispatch(validatePreferredCustomer(userLoginRequest.data.email));
//   } catch (error) {
//     dispatch({
//       type: USER_LOGIN_FAIL,
//       payload: error,
//     });
//   }
// };

export const logout = () => async (dispatch) => {
  dispatch({ type: USER_HARD_LOGOUT_REQUEST });

  try {
    const res = await axios.post(
      "/api/v1/users/logout",
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    localStorage.removeItem("userLogin");
    localStorage.removeItem("userDetails");
    localStorage.removeItem("cartItems");
    localStorage.removeItem("savedItems");
    localStorage.removeItem("unavailableCartItems");
    localStorage.removeItem("preferredCustomer");
    localStorage.removeItem("couponInfo");

    dispatch({ type: USER_HARD_LOGOUT_SUCCESS, payload: res });
  } catch (error) {
    console.log(error);
    dispatch({ type: USER_HARD_LOGOUT_FAIL, payload: error });
  }
};

export const softLogout = () => async (dispatch) => {
  localStorage.setItem("userLogin", JSON.stringify({}));
  localStorage.removeItem("userDetails");
  localStorage.setItem("savedItems", JSON.stringify([]));
  localStorage.setItem("removedCartItems", JSON.stringify([]));
  localStorage.removeItem("preferredCustomer");
  localStorage.removeItem("couponInfo");

  dispatch({ type: USER_SOFT_LOGOUT });
};

export const forgotPasswordHandler = (values) => async (dispatch) => {
  try {
    dispatch({
      type: USER_PASSWORD_FORGOT_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/api/v1/users/login/forgot",
      { email: values.email, reCaptchaToken: values.token },
      config
    );
    dispatch({
      type: USER_PASSWORD_FORGOT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PASSWORD_FORGOT_FAIL,
      payload:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const ResetPasswordHandler = (token, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_PASSWORD_FORGOT_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post("/api/v1/users/login/reset/" + token, { password }, config);

    dispatch({
      type: USER_PASSWORD_FORGOT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PASSWORD_FORGOT_FAIL,
      payload:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const register = (user) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const { data } = await axios.post(
      "/api/v1/users",
      {
        firstName: user.firstName,
        lastName: user.lastName,
        company: user.company,
        jobTitle: user.jobTitle,
        email: user.email,
        password: user.password,
        promotionalEmails: user.promotionalEmails,
        termsAccepted: user.termsAccepted,
        localCartItems:
          localStorage.getItem("cartItems") &&
          JSON.parse(localStorage.getItem("cartItems")).length > 0
            ? JSON.parse(localStorage.getItem("cartItems"))
            : [],
        coupon: localStorage.getItem("couponInfo")
          ? JSON.parse(localStorage.getItem("couponInfo"))
          : {},
        reCaptchaToken: user.token,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const getRegistrationToken = (token) => async (dispatch) => {
  try {
    dispatch({ type: USER_REGISTER_CONFIRM_TOKEN_REQUEST });

    const { data } = await axios.get(`/api/v1/users/confirmation/${token}`);

    dispatch({ type: USER_REGISTER_CONFIRM_TOKEN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_REGISTER_CONFIRM_TOKEN_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const resendConfirmationEmail = (email, reCaptchaToken) => async (dispatch) => {
  try {
    dispatch({ type: USER_RESEND_CONFIRMATION_EMAIL_REQUEST });

    const res = await axios.post(
      `/api/v1/users/confirmation/resend`,
      {
        email: email,
        reCaptchaToken: reCaptchaToken,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({ type: USER_RESEND_CONFIRMATION_EMAIL_SUCCESS, payload: res });
  } catch (error) {
    dispatch({
      type: USER_RESEND_CONFIRMATION_EMAIL_FAIL,
      payload: error,
    });
  }
};

export const getUserDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const res = await axios.get("/api/v1/users/profile");

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: res,
    });

    localStorage.setItem(
      "userDetails",
      JSON.stringify({
        loading: false,
        user: res.data,
        status: res.status,
      })
    );
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: error,
    });
  }
};

export const updateUserProfile = (user) => async (dispatch) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    });

    const res = await axios.put(`/api/v1/users/profile`, user, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: error,
    });
  }
};

export const updateUserPassword = (passwords) => async (dispatch) => {
  try {
    dispatch({
      type: USER_UPDATE_PASSWORD_REQUEST,
    });

    const res = await axios.put(`/api/v1/users/updatePassword`, passwords, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: USER_UPDATE_PASSWORD_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PASSWORD_FAIL,
      payload: error,
    });
  }
};

export const getShippingAddress = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_GET_SHIPPING_ADDRESS_REQUEST,
    });

    const res = await axios.get(`/api/v1/users/profile/addresses/${id}`);

    dispatch({
      type: USER_GET_SHIPPING_ADDRESS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: USER_GET_SHIPPING_ADDRESS_FAIL,
      payload: error,
    });
  }
};

export const getDefaultShippingAddress = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_GET_DEFAULT_SHIPPING_ADDRESS_REQUEST,
    });

    const res = await axios.get(`/api/v1/users/profile/addresses/default`);

    dispatch({
      type: USER_GET_DEFAULT_SHIPPING_ADDRESS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: USER_GET_DEFAULT_SHIPPING_ADDRESS_FAIL,
      payload: error,
    });
  }
};

export const getShippingAddresses = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_GET_SHIPPING_ADDRESSES_REQUEST,
    });

    const res = await axios.get("/api/v1/users/profile/addresses");

    dispatch({
      type: USER_GET_SHIPPING_ADDRESSES_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: USER_GET_SHIPPING_ADDRESSES_FAIL,
      payload: error,
    });
  }
};

export const addShippingAddress = (address) => async (dispatch) => {
  try {
    dispatch({
      type: USER_ADD_SHIPPING_ADDRESS_REQUEST,
    });

    const res = await axios.post(`/api/v1/users/profile/addresses/add`, address, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: USER_ADD_SHIPPING_ADDRESS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: USER_ADD_SHIPPING_ADDRESS_FAIL,
      payload: error,
    });
  }
};

export const updateShippingAddress = (id, address) => async (dispatch) => {
  try {
    dispatch({
      type: USER_UPDATE_SHIPPING_ADDRESS_REQUEST,
    });

    const res = await axios.post(`/api/v1/users/profile/addresses/update/${id}`, address, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: USER_UPDATE_SHIPPING_ADDRESS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_SHIPPING_ADDRESS_FAIL,
      payload: error,
    });
  }
};

export const deleteShippingAddress = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_DELETE_SHIPPING_ADDRESS_REQUEST,
    });

    const res = await axios.delete(`/api/v1/users/profile/addresses/remove/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: USER_DELETE_SHIPPING_ADDRESS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: USER_DELETE_SHIPPING_ADDRESS_FAIL,
      payload: error,
    });
  }
};

export const getUserOrders = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_GET_ORDERS_REQUEST,
    });

    const res = await axios.get("/api/v1/users/profile/orders", {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: USER_GET_ORDERS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: USER_GET_ORDERS_FAIL,
      payload: error,
    });
  }
};

export const getUserOrder = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_GET_ORDER_REQUEST,
    });

    const res = await axios.get(`/api/v1/users/profile/order/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: USER_GET_ORDER_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: USER_GET_ORDER_FAIL,
      payload: error,
    });
  }
};

export const resetData = (key) => async (dispatch) => {
  dispatch({
    type: key,
  });
};

export const getUserAuthStatus = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_GET_AUTH_STATUS_REQUEST,
    });
    const res = await axios.get("/api/v1/auth");
    dispatch({
      type: USER_GET_AUTH_STATUS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: USER_GET_AUTH_STATUS_FAIL,
      payload: error,
    });
  }
};
