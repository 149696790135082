import produce from "immer";
import {
  SET_LOADING,
  SLKBD_ACTUATOR_CUSTOM_NAME,
  SLKBD_ACTUATOR_DRIVE_DEPTH_UNIT,
  SLKBD_ACTUATOR_DRIVE_DEPTH_VALUE,
  SLKBD_ACTUATOR_DRIVE_DIAMETER_UNIT,
  SLKBD_ACTUATOR_DRIVE_DIAMETER_VALUE,
  SLKBD_ACTUATOR_DRIVE_KEY_HEIGHT_UNIT,
  SLKBD_ACTUATOR_DRIVE_KEY_HEIGHT_VALUE,
  SLKBD_ACTUATOR_DRIVE_KEY_WIDTH_UNIT,
  SLKBD_ACTUATOR_DRIVE_KEY_WIDTH_VALUE,
  SLKBD_ACTUATOR_DRIVE_SQUARE_UNIT,
  SLKBD_ACTUATOR_DRIVE_SQUARE_VALUE,
  SLKBD_ACTUATOR_MOUNTING_BOLTS,
  SLKBD_ACTUATOR_MOUNTING_BOLTS_COUNT,
  SLKBD_ACTUATOR_MOUNTING_PCD_UNIT,
  SLKBD_ACTUATOR_MOUNTING_PCD_VALUE,
  SLKBD_FETCH_BRACKET_HEIGHTS_FAIL,
  SLKBD_FETCH_BRACKET_HEIGHTS_REQUEST,
  SLKBD_FETCH_BRACKET_HEIGHTS_SUCCESS,
  SLKBD_FETCH_PRICE_FAIL,
  SLKBD_FETCH_PRICE_REQUEST,
  SLKBD_FETCH_PRICE_SUCCESS,
  SLKBD_SELECT_ACTUATOR_DRIVE_TYPE,
  SLKBD_SELECT_ACTUATOR_MOUNTING_TYPE,
  SLKBD_SELECT_BRACKET_HEIGHT,
  SLKBD_SELECT_COMPONENTS,
  SLKBD_SELECT_LOCKOUT,
  SLKBD_SELECT_QTY,
  SLKBD_SELECT_VALVE_MOUNTING_HOLES_COUNT,
  SLKBD_SELECT_VALVE_MOUNTING_HOLES_DIA_UNIT,
  SLKBD_SELECT_VALVE_MOUNTING_HOLES_DIA_VALUE,
  SLKBD_SELECT_VALVE_MOUNTING_PCD_UNIT,
  SLKBD_SELECT_VALVE_MOUNTING_PCD_VALUE,
  SLKBD_SELECT_VALVE_MOUNTING_TYPE,
  SLKBD_SELECT_VALVE_STEM_ACROSS_FLATS_UNIT,
  SLKBD_SELECT_VALVE_STEM_ACROSS_FLATS_VALUE,
  SLKBD_SELECT_VALVE_STEM_DIAMETER_UNIT,
  SLKBD_SELECT_VALVE_STEM_DIAMETER_VALUE,
  SLKBD_SELECT_VALVE_STEM_SQUARE_VALUE,
  SLKBD_SELECT_VALVE_STEM_SQUARE_UNIT,
  SLKBD_SELECT_VALVE_STEM_FLATS_HEIGHT_UNIT,
  SLKBD_SELECT_VALVE_STEM_FLATS_HEIGHT_VALUE,
  SLKBD_SELECT_VALVE_STEM_HEIGHT_UNIT,
  SLKBD_SELECT_VALVE_STEM_HEIGHT_VALUE,
  SLKBD_SELECT_VALVE_STEM_TYPE,
  SLKBD_VALVE_CUSTOM_NAME,
  SLKBD_SELECT_VALVE_STEM_KEY_WIDTH_UNIT,
  SLKBD_SELECT_VALVE_STEM_KEY_WIDTH_VALUE,
  SLKBD_SELECT_VALVE_STEM_KEY_HEIGHT_VALUE,
  SLKBD_SELECT_VALVE_STEM_KEY_HEIGHT_UNIT,
  SLKBD_SELECT_VALVE_STEM_KEY_LENGTH_VALUE,
  SLKBD_SELECT_VALVE_STEM_KEY_LENGTH_UNIT,
  SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_X_UNIT,
  SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_X_VALUE,
  SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_Y_VALUE,
  SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_Y_UNIT,
  SLKBD_ACTUATOR_DRIVE_WIDTH_VALUE,
  SLKBD_ACTUATOR_DRIVE_WIDTH_UNIT,
} from "../constants";

const base = {
  valve: {
    stem: {
      type: "doubleD",
      diameter: {
        unit: "inches",
        value: 0.863,
      },
      flats: {
        height: {
          unit: "inches",
          value: 1.25,
        },
        across: {
          unit: "inches",
          value: 0.63,
        },
      },
      height: {
        unit: "inches",
        value: 3.625,
      },
      square: {
        unit: "mm",
        value: 27,
      },
      key: {
        width: {
          unit: "inches",
          value: "0.5",
        },
        height: {
          unit: "inches",
          value: "0.5",
        },
        length: {
          unit: "inches",
          value: "1.125",
        },
      },
    },
    mounting: {
      type: "rectangle",
      pcd: {
        unit: "inches",
        value: 4.92,
      },
      rectangle: {
        x: {
          unit: "inches",
          value: 3.5,
        },
        y: {
          unit: "inches",
          value: 1,
        },
      },
      holes: {
        diameter: {
          unit: "inches",
          value: 0.375,
        },
        count: 4,
      },
    },
    customName: "Custom Valve",
  },
  actuator: {
    drive: {
      type: "doubleD",
      square: {
        value: 0.551,
        unit: "inches",
      },
      diameter: {
        value: 0.875,
        unit: "inches",
      },
      width: {
        value: 0.633,
        unit: "inches",
      },
      depth: {
        value: 1,
        unit: "inches",
      },
      key: {
        width: {
          value: 0.625,
          unit: "inches",
        },
        height: {
          value: 0.625,
          unit: "inches",
        },
      },
    },
    mounting: {
      type: "boltCircle",
      pcd: {
        value: 4.92,
        unit: "inches",
      },
      bolts: "1/2",
      boltCount: 4,
    },
    customName: "Custom Actuator",
  },
  lockout: false,
  components: "Bracket and Coupler",
  bracketHeights: [],
  bracketHeight: 3,
  qty: 1,
  price: null,
  qtyOnePrice: null,
  error: null,
};

export const selectLinkageKitByDimensionsReducer = (state = base, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_LOADING:
        draft.loading = action.payload;
        break;
      case SLKBD_SELECT_VALVE_STEM_TYPE:
        draft.valve.stem.type = action.payload;
        break;
      case SLKBD_SELECT_VALVE_STEM_DIAMETER_VALUE:
        draft.valve.stem.diameter.value = action.payload;
        break;
      case SLKBD_SELECT_VALVE_STEM_DIAMETER_UNIT:
        draft.valve.stem.diameter.unit = action.payload;
        break;
      case SLKBD_SELECT_VALVE_STEM_SQUARE_VALUE:
        draft.valve.stem.square.value = action.payload;
        break;
      case SLKBD_SELECT_VALVE_STEM_SQUARE_UNIT:
        draft.valve.stem.square.unit = action.payload;
        break;
      case SLKBD_SELECT_VALVE_STEM_HEIGHT_VALUE:
        draft.valve.stem.height.value = action.payload;
        break;
      case SLKBD_SELECT_VALVE_STEM_HEIGHT_UNIT:
        draft.valve.stem.height.unit = action.payload;
        break;
      case SLKBD_SELECT_VALVE_STEM_ACROSS_FLATS_VALUE:
        draft.valve.stem.flats.across.value = action.payload;
        break;
      case SLKBD_SELECT_VALVE_STEM_ACROSS_FLATS_UNIT:
        draft.valve.stem.flats.across.unit = action.payload;
        break;
      case SLKBD_SELECT_VALVE_STEM_FLATS_HEIGHT_VALUE:
        draft.valve.stem.flats.height.value = action.payload;
        break;
      case SLKBD_SELECT_VALVE_STEM_FLATS_HEIGHT_UNIT:
        draft.valve.stem.flats.height.unit = action.payload;
        break;
      case SLKBD_SELECT_VALVE_STEM_KEY_WIDTH_VALUE:
        draft.valve.stem.key.width.value = action.payload;
        break;
      case SLKBD_SELECT_VALVE_STEM_KEY_WIDTH_UNIT:
        draft.valve.stem.key.width.unit = action.payload;
        break;
      case SLKBD_SELECT_VALVE_STEM_KEY_HEIGHT_VALUE:
        draft.valve.stem.key.height.value = action.payload;
        break;
      case SLKBD_SELECT_VALVE_STEM_KEY_HEIGHT_UNIT:
        draft.valve.stem.key.height.unit = action.payload;
        break;
      case SLKBD_SELECT_VALVE_STEM_KEY_LENGTH_VALUE:
        draft.valve.stem.key.length.value = action.payload;
        break;
      case SLKBD_SELECT_VALVE_STEM_KEY_LENGTH_UNIT:
        draft.valve.stem.key.length.unit = action.payload;
        break;
      case SLKBD_SELECT_VALVE_MOUNTING_TYPE:
        draft.valve.mounting.type = action.payload;
        break;
      case SLKBD_SELECT_VALVE_MOUNTING_PCD_VALUE:
        draft.valve.mounting.pcd.value = action.payload;
        break;
      case SLKBD_SELECT_VALVE_MOUNTING_PCD_UNIT:
        draft.valve.mounting.pcd.unit = action.payload;
        break;
      case SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_X_VALUE:
        draft.valve.mounting.rectangle.x.value = action.payload;
        break;
      case SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_X_UNIT:
        draft.valve.mounting.rectangle.x.unit = action.payload;
        break;
      case SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_Y_VALUE:
        draft.valve.mounting.rectangle.y.value = action.payload;
        break;
      case SLKBD_SELECT_VALVE_MOUNTING_RECTANGLE_Y_UNIT:
        draft.valve.mounting.rectangle.y.unit = action.payload;
        break;
      case SLKBD_SELECT_VALVE_MOUNTING_HOLES_DIA_VALUE:
        draft.valve.mounting.holes.diameter.value = action.payload;
        break;
      case SLKBD_SELECT_VALVE_MOUNTING_HOLES_DIA_UNIT:
        draft.valve.mounting.holes.diameter.unit = action.payload;
        break;
      case SLKBD_SELECT_VALVE_MOUNTING_HOLES_COUNT:
        draft.valve.mounting.holes.count = action.payload;
        break;
      case SLKBD_VALVE_CUSTOM_NAME:
        draft.valve.customName = action.payload;
        break;
      case SLKBD_SELECT_ACTUATOR_DRIVE_TYPE:
        draft.actuator.drive.type = action.payload;
        break;
      case SLKBD_ACTUATOR_DRIVE_SQUARE_VALUE:
        draft.actuator.drive.square.value = action.payload;
        break;
      case SLKBD_ACTUATOR_DRIVE_SQUARE_UNIT:
        draft.actuator.drive.square.unit = action.payload;
        break;
      case SLKBD_ACTUATOR_DRIVE_DEPTH_VALUE:
        draft.actuator.drive.depth.value = action.payload;
        break;
      case SLKBD_ACTUATOR_DRIVE_DEPTH_UNIT:
        draft.actuator.drive.depth.unit = action.payload;
        break;
      case SLKBD_ACTUATOR_DRIVE_DIAMETER_VALUE:
        draft.actuator.drive.diameter.value = action.payload;
        break;
      case SLKBD_ACTUATOR_DRIVE_DIAMETER_UNIT:
        draft.actuator.drive.diameter.unit = action.payload;
        break;
      case SLKBD_ACTUATOR_DRIVE_WIDTH_VALUE:
        draft.actuator.drive.width.value = action.payload;
        break;
      case SLKBD_ACTUATOR_DRIVE_WIDTH_UNIT:
        draft.actuator.drive.width.unit = action.payload;
        break;
      case SLKBD_ACTUATOR_DRIVE_KEY_WIDTH_VALUE:
        draft.actuator.drive.key.width.value = action.payload;
        break;
      case SLKBD_ACTUATOR_DRIVE_KEY_WIDTH_UNIT:
        draft.actuator.drive.key.width.unit = action.payload;
        break;
      case SLKBD_ACTUATOR_DRIVE_KEY_HEIGHT_VALUE:
        draft.actuator.drive.key.height.value = action.payload;
        break;
      case SLKBD_ACTUATOR_DRIVE_KEY_HEIGHT_UNIT:
        draft.actuator.drive.key.height.unit = action.payload;
        break;
      case SLKBD_SELECT_ACTUATOR_MOUNTING_TYPE:
        draft.actuator.mounting.type = action.payload;
        break;
      case SLKBD_ACTUATOR_MOUNTING_PCD_VALUE:
        draft.actuator.mounting.pcd.value = action.payload;
        break;
      case SLKBD_ACTUATOR_MOUNTING_PCD_UNIT:
        draft.actuator.mounting.pcd.unit = action.payload;
        break;
      case SLKBD_ACTUATOR_MOUNTING_BOLTS:
        draft.actuator.mounting.bolts = action.payload;
        break;
      case SLKBD_ACTUATOR_MOUNTING_BOLTS_COUNT:
        draft.actuator.mounting.boltCount = action.payload;
        break;
      case SLKBD_ACTUATOR_CUSTOM_NAME:
        draft.actuator.customName = action.payload;
        break;
      case SLKBD_FETCH_BRACKET_HEIGHTS_REQUEST:
        draft.loading = true;
        break;
      case SLKBD_FETCH_BRACKET_HEIGHTS_SUCCESS:
        draft.loading = false;
        draft.bracketHeights = action.payload;
        break;
      case SLKBD_SELECT_LOCKOUT:
        draft.lockout = action.payload;
        break;
      case SLKBD_SELECT_COMPONENTS:
        draft.components = action.payload;
        break;
      case SLKBD_SELECT_QTY:
        draft.qty = action.payload;
        break;
      case SLKBD_SELECT_BRACKET_HEIGHT:
        draft.bracketHeight = action.payload;
        break;
      case SLKBD_FETCH_BRACKET_HEIGHTS_FAIL:
        draft.loading = false;
        draft.error = action.payload;
        break;
      case SLKBD_FETCH_PRICE_REQUEST:
        draft.loading = true;
        break;
      case SLKBD_FETCH_PRICE_SUCCESS:
        draft.loading = false;
        draft.price = action.payload.price;
        draft.qtyOnePrice = action.payload.qtyOnePrice;
        break;
      case SLKBD_FETCH_PRICE_FAIL:
        draft.loading = false;
        draft.error = action.payload;
      default:
        return state;
    }
  });
