import { isEmpty } from "lodash";
import React, { useState } from "react";

const ItemDescriptionBlock = ({
  item,
  sku,
  components,
  lockout,
  selectedBracketHeight,
  user,
  admin,
}) => {
  const [activeTab, setActiveTab] = useState(
    item.productType === "mountingKit"
      ? "desc"
      : item.productType === "customMountingKit"
      ? "dimensions"
      : user.isAdmin && "pricing"
  );

  return (
    <>
      <div className="product__tabs product-tabs product-tabs--layout--full">
        <ul className="product-tabs__list">
          <li
            className={`product-tabs__item ${activeTab === "desc" && "product-tabs__item--active"}`}
          >
            <a href="#desc" onClick={() => setActiveTab("desc")}>
              Description
            </a>
          </li>
          <li
            className={`product-tabs__item ${activeTab === "spec" && "product-tabs__item--active"}`}
          >
            <a href="#spec" onClick={() => setActiveTab("spec")}>
              Specification
            </a>
          </li>
          {item.productType === "customMountingKit" && (
            <li
              className={`product-tabs__item ${
                activeTab === "dimensions" && "product-tabs__item--active"
              }`}
            >
              <a href="#dimensions" onClick={() => setActiveTab("dimensions")}>
                Dimensions
              </a>
            </li>
          )}
          {(item.productType === "mountingKit" || item.productType === "customMountingKit") &&
            user &&
            user.isAdmin && (
              <li
                className={`product-tabs__item ${
                  activeTab === "pricing" && "product-tabs__item--active"
                }`}
              >
                <a href="#pricing" onClick={() => setActiveTab("pricing")}>
                  Pricing
                </a>
              </li>
            )}
        </ul>
        <div className="product-tabs__content">
          <div
            className={`product-tabs__pane  ${
              activeTab === "desc" && "product-tabs__pane--active"
            }`}
            id="product-tab-description"
          >
            <div className="typography">
              <p>
                This custom made-to-order{" "}
                {components === "Bracket and Coupler" ? (
                  <>mounting kit</>
                ) : components === "Bracket Only" ? (
                  <>bracket</>
                ) : (
                  components === "Coupler Only" && <>coupler</>
                )}{" "}
                is precision machined to tight tolerances by skilled machinists at our U.S.-based
                manufacturing plant.{" "}
                {components === "Bracket and Coupler" ? (
                  <>Both the bracket and coupler are</>
                ) : components === "Bracket Only" ? (
                  <>The bracket is</>
                ) : (
                  components === "Coupler Only" && <>The coupler is</>
                )}{" "}
                made from 300-series stainless steel for maximum strenth and corrosion-resistance.
              </p>
              <div className="alert alert-xs alert-info">
                <i className="fas fa-info-circle mr-1"></i>Please Note: Fasteners are not included.
              </div>
              {/* <p>NOTE: Fasteners are not included.</p> */}
            </div>
          </div>

          <div
            className={`product-tabs__pane  ${
              activeTab === "spec" && "product-tabs__pane--active"
            }`}
            id="product-tab-specification"
          >
            <div className="spec">
              <div className="spec__section">
                <h4 className="spec__section-title">General</h4>
                {item.productType === "mountingKit" && (
                  <div className="spec__row">
                    <div className="spec__name">SKU</div>
                    <div className="spec__value">{sku}</div>
                  </div>
                )}
                <div className="spec__row">
                  <div className="spec__name">Material</div>
                  <div className="spec__value">Stainless Steel</div>
                </div>
                <div className="spec__row">
                  <div className="spec__name">Country Of Origin</div>
                  <div className="spec__value">United States</div>
                </div>
                <div className="spec__row">
                  <div className="spec__name">Lock-out Kit</div>
                  <div className="spec__value">
                    {lockout === true ? "Yes" : lockout === false && "No"}
                  </div>
                </div>
                <div className="spec__row">
                  <div className="spec__name">Components</div>
                  <div className="spec__value">{components}</div>
                </div>
                <div className="spec__row">
                  <div className="spec__name">Bracket Height</div>
                  <div className="spec__value">{`${selectedBracketHeight}"`}</div>
                </div>
                <div className="spec__row">
                  <div className="spec__name">Fasteners Included</div>
                  <div className="spec__value">No</div>
                </div>
                {/* <div className="spec__row">
                        <div className="spec__name">Weight</div>
                        <div className="spec__value"></div>
                      </div> */}
              </div>
            </div>
          </div>

          {item.valve.type === "Custom Valve" && (
            <div
              className={`product-tabs__pane  ${
                activeTab === "dimensions" && "product-tabs__pane--active"
              }`}
              id="product-tab-specification"
            >
              <div className="spec">
                <div className="spec__section">
                  <h4 className="spec__section-title">Valve</h4>
                  {item.valve.stem.type === "roundWithKeyway" ? (
                    <>
                      <div className="spec__row">
                        <div className="spec__name">Stem Diameter</div>
                        <div className="spec__value">{`${item.valve.stem.diameter.value} ${item.valve.stem.diameter.unit}`}</div>
                      </div>

                      <div className="spec__row">
                        <div className="spec__name">Stem Height</div>
                        <div className="spec__value">{`${item.valve.stem.height.value} ${item.valve.stem.height.unit}`}</div>
                      </div>

                      <div className="spec__row">
                        <div className="spec__name">Stem Key Length</div>
                        <div className="spec__value">{`${item.valve.stem.key.length.value} ${item.valve.stem.key.length.unit}`}</div>
                      </div>

                      <div className="spec__row">
                        <div className="spec__name">Stem Key Width</div>
                        <div className="spec__value">{`${item.valve.stem.key.width.value} ${item.valve.stem.key.width.unit}`}</div>
                      </div>

                      <div className="spec__row">
                        <div className="spec__name">Stem Key Height</div>
                        <div className="spec__value">{`${item.valve.stem.key.height.value} ${item.valve.stem.key.height.unit}`}</div>
                      </div>

                      <div className="spec__row">
                        <div className="spec__name">Stem Key Length</div>
                        <div className="spec__value">{`${item.valve.stem.key.length.value} ${item.valve.stem.key.length.unit}`}</div>
                      </div>
                    </>
                  ) : (
                    item.valve.stem.type === "square" && (
                      <>
                        <div className="spec__row">
                          <div className="spec__name">Stem Square</div>
                          <div className="spec__value">{`${item.valve.stem.square.value} ${item.valve.stem.square.unit}`}</div>
                        </div>

                        <div className="spec__row">
                          <div className="spec__name">Stem Height</div>
                          <div className="spec__value">{`${item.valve.stem.height.value} ${item.valve.stem.height.unit}`}</div>
                        </div>

                        <div className="spec__row">
                          <div className="spec__name">Stem Flats Height</div>
                          <div className="spec__value">{`${item.valve.stem.flats.height.value} ${item.valve.stem.flats.height.unit}`}</div>
                        </div>
                      </>
                    )
                  )}
                  {item.valve.mounting.type === "boltCircle" && (
                    <>
                      <div className="spec__row">
                        <div className="spec__name">Mounting Flange Bolt Circle Diameter</div>
                        <div className="spec__value">{`${item.valve.mounting.pcd.value} ${item.valve.mounting.pcd.unit}`}</div>
                      </div>

                      <div className="spec__row">
                        <div className="spec__name">Mounting Flange Bolts/Holes Diameter</div>
                        <div className="spec__value">{`${item.valve.mounting.holes.diameter.value} ${item.valve.mounting.holes.diameter.unit}`}</div>
                      </div>

                      <div className="spec__row">
                        <div className="spec__name">Mounting Flange Bolts/Holes Count</div>
                        <div className="spec__value">{`${item.valve.mounting.holes.count}`}</div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {item.actuator.type === "Custom Actuator" && (
            <div
              className={`product-tabs__pane  ${
                activeTab === "dimensions" && "product-tabs__pane--active"
              }`}
              id="product-tab-specification"
            >
              <div className="spec">
                <div className="spec__section">
                  <h4 className="spec__section-title">Actuator</h4>
                  {/* <div className="spec__row">
                    <div className="spec__name">Stem Diameter</div>
                    <div className="spec__value">{`${item.valve.stem.diameter.value} ${item.valve.stem.diameter.unit}`}</div>
                  </div> */}
                </div>
              </div>
            </div>
          )}

          <div
            className={`product-tabs__pane  ${
              activeTab === "pricing" && "product-tabs__pane--active"
            }`}
            id="product-tab-specification"
          >
            <div className="spec">
              <div className="spec__section">
                <h4 className="spec__section-title">Pricing</h4>
                <div className="spec__row">
                  <div className="spec__name">Manufacture Rate</div>
                  <div className="spec__value">
                    {admin?.settings?.manufactureRates?.manufactureRate?.multiplier}
                  </div>
                </div>
                <div className="spec__row">
                  <div className="spec__name">Kit Size</div>
                  <div className="spec__value">
                    {admin?.settings?.manufactureRates?.manufactureRate?.name}
                  </div>
                </div>
                <div className="spec__row">
                  <div className="spec__name">Keyway on Valve</div>
                  <div className="spec__value">
                    {admin?.settings?.manufactureRates?.manufactureRate?.valveKeyway === true
                      ? "Yes"
                      : "No"}
                  </div>
                </div>
                <div className="spec__row">
                  <div className="spec__name">Keyway on Actuator</div>
                  <div className="spec__value">
                    {admin?.settings?.manufactureRates?.manufactureRate?.actuatorKeyway === true
                      ? "Yes"
                      : "No"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemDescriptionBlock;
