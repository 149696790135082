import { api } from "../slices/api";

// Define a service using a base URL and expected endpoints
export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getActuatorBrands: builder.query({
      query: () => "v1/actuators/brands",
    }),
    getActuatorSeries: builder.query({
      query: ({ brand }) => `v1/actuators/${brand}/series`,
    }),
    getActuatorModels: builder.query({
      query: ({ brand, series }) => `v1/actuators/${brand}/${series}/models`,
    }),
    getActuatorOptions: builder.query({
      query: ({ brand, series, model }) =>
        `v1/actuators/${brand}/${series}/${model}/options`,
    }),
    getISO5211ActuatorFlanges: builder.query({
      query: () => "v1/actuators/iso5211/isoFlanges",
    }),
    getISO5211ActuatorDriveTypes: builder.query({
      query: ({ isoFlange }) => `v1/actuators/iso5211/${isoFlange}/driveTypes`,
    }),
    getISO5211ActuatorDriveSizes: builder.query({
      query: ({ isoFlange, driveType }) =>
        `v1/actuators/iso5211/${isoFlange}/${driveType}/driveSizes`,
    }),
  }),
});

export const {
  useGetActuatorBrandsQuery,
  useGetActuatorSeriesQuery,
  useGetActuatorModelsQuery,
  useGetActuatorOptionsQuery,
  useGetISO5211ActuatorFlangesQuery,
  useGetISO5211ActuatorDriveTypesQuery,
  useGetISO5211ActuatorDriveSizesQuery,
} = extendedApiSlice;
