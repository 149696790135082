import { api } from "../slices/api";

// Define a service using a base URL and expected endpoints
export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserDetails: builder.query({
      query: () => "v1/users/profile",
    }),
    updateUserProfile: builder.mutation({
      query: (values) => ({
        url: "v1/users/profile",
        method: "PUT",
        body: values,
      }),
    }),
    updatePassword: builder.mutation({
      query: (values) => ({
        url: "v1/users/updatePassword",
        method: "PUT",
        body: values,
      }),
    }),
    getSavedItems: builder.query({
      query: () => "v1/users/profile/savedItems",
    }),
    getAddresses: builder.query({
      query: () => "v1/users/profile/addresses",
    }),
    getAddress: builder.query({
      query: (id) => `v1/users/profile/addresses/${id}`,
    }),
    getDefaultAddress: builder.query({
      query: () => `v1/users/profile/addresses/default`,
    }),
    addAddress: builder.mutation({
      query: (values) => ({
        url: "v1/users/profile/addresses/add",
        method: "POST",
        body: values,
      }),
    }),
    updateAddress: builder.mutation({
      query: ({ id, body }) => ({
        url: `v1/users/profile/addresses/update/${id}`,
        method: "POST",
        body: body,
      }),
    }),
    deleteAddress: builder.mutation({
      query: (id) => ({
        url: `v1/users/profile/addresses/remove/${id}`,
        method: "DELETE",
      }),
    }),
    sendPasswordResetLink: builder.mutation({
      query: (values) => ({
        url: `/v1/users/login/forgot`,
        method: "POST",
        body: values,
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ token, password }) => ({
        url: `v1/users/login/reset/${token}`,
        method: "POST",
        body: { password },
      }),
    }),
    createAccount: builder.mutation({
      query: (values) => ({
        url: `v1/users`,
        method: "POST",
        body: values,
      }),
    }),
    resendConfirmationEmail: builder.mutation({
      query: (values) => ({
        url: `v1/users/confirmation/resend`,
        method: "POST",
        body: values,
      }),
    }),
    confirmEmailAddress: builder.query({
      query: ({ token }) => `v1/users/confirmation/${token}`,
    }),
  }),
});

export const {
  useGetUserDetailsQuery,
  useUpdateUserProfileMutation,
  useUpdatePasswordMutation,
  useGetSavedItemsQuery,
  useGetAddressesQuery,
  useGetAddressQuery,
  useGetDefaultAddressQuery,
  useAddAddressMutation,
  useUpdateAddressMutation,
  useDeleteAddressMutation,
  useSendPasswordResetLinkMutation,
  useResetPasswordMutation,
  useCreateAccountMutation,
  useResendConfirmationEmailMutation,
  useConfirmEmailAddressQuery,
} = extendedApiSlice;
