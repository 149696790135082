import React from "react";
import { vowels } from "../utils/helpers";
import ActuatorDescription from "./ActuatorDescription";
import ValveDescription from "./ValveDescription";

const LinkageKitDescription = ({ item }) => {
  return (
    <>
      <ValveDescription valve={item.valve} />

      {item.actuator?.type === "Custom Actuator" ? (
        <>
          {vowels.includes(item.actuator?.customName?.charAt(0).toLowerCase()) ? " an " : " a "}
          <ActuatorDescription actuator={item.actuator} />
        </>
      ) : item.actuator?.type === "ISO 5211 Standard Actuator" ? (
        <>
          {" an "}
          <ActuatorDescription actuator={item.actuator} />
        </>
      ) : (
        <>
          {vowels.includes(item.actuator?.brand?.charAt(0).toLowerCase()) ? " to an " : " to a "}
          <ActuatorDescription actuator={item.actuator} />
        </>
      )}
    </>
  );
};

export default LinkageKitDescription;
