import * as Yup from "yup";

export const RegisterValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(15, "Must be 15 characters or less")
    .required("Please enter your first name"),
  lastName: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("Please enter your last name"),
  company: Yup.string()
    .max(40, "Must be 40 characters or less")
    .required("Please enter your company name"),
  jobTitle: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("Please enter your job title"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter your email address"),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("Please enter a password"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords do not match")
    .required("Please confirm your password"),
  promotionalEmails: Yup.bool(),
  termsAccepted: Yup.bool().oneOf([true], "Terms and Conditions must be accepted"),
});

export const LoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter your email address"),
  password: Yup.string().required("Please enter your password"),
});

export const CheckoutValidationSchema = Yup.object().shape({
  shippingAddress: Yup.object().shape({
    firstName: Yup.string()
      .max(15, "Must be 15 characters or less")
      .required("Please enter a first name"),
    lastName: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Please enter a last name"),
    company: Yup.string().max(50, "Must be 50 characters or less"),
    phone: Yup.string().min(10, "please enter a valid phone number"),
    street: Yup.string()
      .max(50, "Must be 50 characters or less")
      .required("Please enter a street address"),
    street2: Yup.string().max(50, "Must be 50 characters or less"),
    city: Yup.string().required("Please enter a city"),
    state: Yup.string().required("Please enter a street address"),
    postalCode: Yup.string()
      .min(5, "Must be between 5 and 10 characters")
      .max(10, "Must be between 5 and 10 characters")
      .required("Please enter a postal code"),
    country: Yup.string()
      .notOneOf([""], "Please select a country")
      .required("Please select a country"),
  }),
  billingDetails: Yup.object().shape({
    sameAsShipping: Yup.bool().oneOf([true, false]),
    firstName: Yup.string()
      .max(15, "Must be 15 characters or less")
      .required("Please enter a first name"),
    lastName: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Please enter a last name"),
    company: Yup.string().max(50, "Must be 50 characters or less"),
    phone: Yup.string()
      .min(10, "please enter a valid phone number")
      .required("Please enter a phone number"),
    street: Yup.string()
      .max(50, "Must be 50 characters or less")
      .required("Please enter a street address"),
    street2: Yup.string().max(50, "Must be 50 characters or less"),
    city: Yup.string().required("Please enter a city"),
    state: Yup.string().required("Please enter a street address"),
    postalCode: Yup.string()
      .min(5, "Must be between 5 and 10 characters")
      .max(10, "Must be between 5 and 10 characters")
      .required("Please enter a postal code"),
    country: Yup.string()
      .notOneOf([""], "Please select a country")
      .required("Please select a country"),
  }),
  shippingMethod: Yup.string()
    .oneOf(["upsAccount", "flatRate", "localPickup"], "Please select a shipping method")
    .required("Payment type must be selected"),
  upsAccountNo: Yup.string().when("shippingMethod", {
    is: "upsAccount",
    then: Yup.string().required("Please enter your UPS account number"),
  }),
  // shippingService: Yup.string().when('shippingMethod', {
  //   is: 'upsAccount',
  //   then: Yup.string().oneOf(
  //     ['uspGround', 'ups3DaySelect', 'upsNextDayAir'],
  //     'Please choose a shipping service'
  //   ),
  // }),
  paymentMethod: Yup.string()
    .oneOf(["card", "invoice"], "Please select a payment method")
    .required("Payment type must be selected"),
  cardNumber: Yup.string().when("paymentMethod", {
    is: "card",
    then: Yup.string().required("Please enter your credit card number"),
  }),
  expiryDate: Yup.string().when("paymentMethod", {
    is: "card",
    then: Yup.string().required("Please enter the expiration"),
  }),
  cvc: Yup.string().when("paymentMethod", {
    is: "card",
    then: Yup.string().required("Please enter the security code"),
  }),
  nameOnCard: Yup.string()
    .max(50, "Must be 50 characters or less")
    .when("paymentMethod", {
      is: "card",
      then: Yup.string().required("Please enter the name on the credit card"),
    }),
  customerPO: Yup.string().max(25, "Must be 25 characters or less"),
  orderNotes: Yup.string().max(500, "Must be 500 characters or less"),
  termsAccepted: Yup.bool().oneOf([true], "Terms and Conditions must be accepted"),
});

export const SubmitEmailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter an email address"),
});

export const AddCouponValidationSchema = Yup.object().shape({
  coupon: Yup.string().required("Please enter a coupon code"),
});

export const AddAddressValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(15, "Must be 15 characters or less")
    .required("Please enter a first name"),
  lastName: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("Please enter a last name"),
  company: Yup.string().max(50, "Must be 50 characters or less"),
  phone: Yup.string()
    .min(10, "please enter a valid phone number")
    .required("Please enter a phone number"),
  street: Yup.string()
    .max(50, "Must be 50 characters or less")
    .required("Please enter a street address"),
  street2: Yup.string().max(50, "Must be 50 characters or less"),
  city: Yup.string().max(20, "Must be 20 characters or less").required("Please enter a city"),
  state: Yup.string().notOneOf([""], "Please select a state").required("Please select a state"),
  postalCode: Yup.string()
    .min(5, "Must be between 5 and 10 characters")
    .max(10, "Must be between 5 and 10 characters")
    .required("Please a postal code"),
  country: Yup.string().max(20, "Must be 20 characters or less").required("Please enter a country"),
  setAsDefault: Yup.bool().oneOf([true, false]),
});

export const UpdateAddressValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(15, "Must be 15 characters or less")
    .required("Please enter a first name"),
  lastName: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("Please enter a last name"),
  company: Yup.string().max(50, "Must be 50 characters or less"),
  phone: Yup.string()
    .min(10, "please enter a valid phone number")
    .required("Please enter a phone number"),
  street: Yup.string()
    .max(50, "Must be 50 characters or less")
    .required("Please enter a street address"),
  street2: Yup.string().max(50, "Must be 50 characters or less"),
  city: Yup.string().max(20, "Must be 20 characters or less").required("Please enter a city"),
  state: Yup.string().notOneOf([""], "Please select a state").required("Please select a state"),
  postalCode: Yup.string()
    .min(5, "Must be between 5 and 10 characters")
    .max(10, "Must be between 5 and 10 characters")
    .required("Please a postal code"),
  country: Yup.string().max(20, "Must be 20 characters or less").required("Please enter a country"),
  setAsDefault: Yup.bool().oneOf([true, false]),
});

export const UpdatePasswordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Please enter your current password"),
  newPassword: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("Please enter your new password"),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords do not match")
    .required("Please confirm your new password"),
});

export const ResetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("Please enter your new password"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords do not match")
    .required("Please confirm your password"),
});

export const PasswordValidationSchema = Yup.object().shape({
  password: Yup.string().required("Please enter your password"),
});

export const UpdateUserProfileValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(15, "Must be 15 characters or less")
    .required("Please enter your first name"),
  lastName: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("Please enter your last name"),
  company: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("Please enter your company"),
  jobTitle: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("Please enter your job title"),
  phone: Yup.string(),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter your email address"),
  promotionalEmails: Yup.bool(),
});

export const RequestQuoteValidationSchema = Yup.object().shape({
  name: Yup.string().max(50, "Must be 50 characters or less").required("Please enter your name"),
  company: Yup.string().max(50, "Must be 50 characters or less"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .max(50, "Must be 50 characters or less")
    .required("Please enter an email address"),
  phone: Yup.string().max(10, "Please enter a valid phone number"),
  valve: Yup.string()
    .max(50, "Must be 50 characters or less")
    .required("Please enter the model of your valve"),
  actuator: Yup.string()
    .max(50, "Must be 50 characters or less")
    .required("Please enter the model of your actuator"),
  preferredBracketHeight: Yup.string()
    .max(50, "Must be 50 characters or less")
    .required("Please select your preferred bracket height"),
  lockoutCapabilities: Yup.string()
    .max(50, "Must be 50 characters or less")
    .notOneOf([""], "Please select an option")
    .required("Please indicate if you would like this to be a lockout kit"),
  leadTime: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("Please enter the required lead time"),
  additionalDetails: Yup.string().max(500, "Must be 500 characters or less"),
});

export const CustomerMessageSchema = Yup.object().shape({
  name: Yup.string().max(20, "Must be 20 characters or less").required("Please enter your name"),
  company: Yup.string().max(40, "Must be 40 characters or less"),
  phone: Yup.string().max(10, "Please enter a valid phone number"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter your email address"),
  subject: Yup.string().max(50, "Must be 50 characters or less").required("Please a subject line"),
  message: Yup.string()
    .max(500, "Must be 500 characters or less")
    .required("Please enter a message"),
});

export const CustomLinkageKitSchema = Yup.object().shape({
  valve: Yup.object().shape({
    stem: Yup.object().shape({
      type: Yup.bool().oneOf(
        ["doubleD", "square", "roundWithKeyway"],
        "You must select a valve stem type"
      ),
      diameter: Yup.object().shape({
        value: Yup.number().required("Please enter a number"),
        unit: Yup.bool().oneOf(["inches", "mm"], "You must select a unit value"),
      }),
    }),
  }),
});
