import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AccountNavigation from "../../components/account/AccountNavigation";
import SavedItems from "../../components/SavedItems";
import LoadingWidget from "../../components/LoadingWidget";
import { Helmet } from "react-helmet";
import { variables } from "../../config";
import { useNavigate } from "react-router-dom";
import { useGetSavedItemsQuery } from "../../redux/api/savedItemsApi";
import { setSavedItems } from "../../redux/slices/savedItems";

const AccountSavedItemsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const savedItems = useSelector((state) => state.savedItems);

  const getSavedItems = useGetSavedItemsQuery();

  useEffect(() => {
    if (getSavedItems.isSuccess) {
      dispatch(setSavedItems(getSavedItems?.data));
    }
  }, [getSavedItems]);

  return (
    <>
      <Helmet>
        <title>Account | {variables.WEBSITE_NAME}</title>
        <link rel="canonical" href={`${variables.FULL_URL}/account/saved-items`} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {getSavedItems.isLoading ? (
        <LoadingWidget />
      ) : (
        <>
          <div className="block-space block-space--layout--after-header"></div>
          <div className="block">
            <div className="container container--max--xl">
              <div className="row">
                <div className="col-12 col-lg-3">
                  <AccountNavigation active="savedItems" />
                </div>

                <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                  {getSavedItems?.data?.message && (
                    <div className="alert alert-success">{savedItems?.data?.message}</div>
                  )}

                  <div className="card">
                    <div className="card-header">
                      <h5>Saved Items</h5>
                    </div>
                    <div className="card-divider"></div>

                    {savedItems.length < 1 ? (
                      <div className="card-body profile-card__body">No Saved Items</div>
                    ) : (
                      <SavedItems refetchSavedItems={getSavedItems.refetch} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-space block-space--layout--before-footer"></div>
        </>
      )}
    </>
  );
};

export default AccountSavedItemsScreen;
