import React from "react";

const ValveDescription = ({ valve }) => {
  return (
    <>
      {valve?.type === "Custom Valve?" ? (
        <>{valve?.customName}</>
      ) : (
        <>
          {`${valve?.size} ${valve?.brand} Series ${valve?.series} ${
            valve?.options && valve?.options.length > 0
              ? valve?.options
                  .map((option) => {
                    return `${Object.values(option)[0]} `;
                  })
                  .join(" ")
              : ""
          } ${valve?.type}`}
        </>
      )}
    </>
  );
};

export default ValveDescription;
