import React from "react";
import { useSelector } from "react-redux";
import AddToCartModal from "../modals/AddedToCartModal";
import ItemDescriptionBlock from "./ItemDescriptionBlock";
import { ItemInfoBlock } from "./ItemInfoBlock";
import ItemTitleBlock from "./ItemTitleBlock";

const ItemDetails = ({
  loading,
  item,
  sku,
  price,
  qty,
  qtyOnePrice,
  selectedValve,
  selectedActuator,
  components,
  lockout,
  bracketHeights,
  selectedBracketHeight,
  user,
  admin,
}) => {
  const addedToCartModal = useSelector(
    (state) => state.application.addedToCartModal
  );
  return (
    <>
      <AddToCartModal show={addedToCartModal} item={item} />
      <form className="container">
        <div className="product product--layout--full">
          <div className="product__body">
            <div className="product__card product__card--one"></div>
            <div className="product__card product__card--two"></div>

            <ItemTitleBlock
              item={item}
              sku={sku}
              selectedValve={selectedValve}
              selectedActuator={selectedActuator}
            />

            <ItemInfoBlock
              loading={loading}
              item={item}
              sku={sku}
              price={price}
              qty={qty}
              qtyOnePrice={qtyOnePrice}
              selectedValve={selectedValve}
              selectedActuator={selectedActuator}
              components={components}
              lockout={lockout}
              bracketHeights={bracketHeights}
              selectedBracketHeight={selectedBracketHeight}
            />

            <ItemDescriptionBlock
              item={item}
              sku={sku}
              components={components}
              lockout={lockout}
              selectedBracketHeight={selectedBracketHeight}
              user={user}
              admin={admin}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default ItemDetails;
