import React from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { SLKBD_ACTUATOR_CUSTOM_NAME } from "../../redux/constants";

const ActuatorCustomName = () => {
  const dispatch = useDispatch();

  const actuator = useSelector((state) => state.selectLinkageKitByDimensions.actuator);

  return (
    <div className="slkbd w-100">
      <Form.Group>
        <Form.Label>Enter the name of your actuator</Form.Label>
        <div className="d-flex">
          <Form.Control
            type="text"
            className="input"
            value={actuator.customName}
            onChange={(e) =>
              dispatch({
                type: SLKBD_ACTUATOR_CUSTOM_NAME,
                payload: e.target.value,
              })
            }
            placeholder={"Enter name of actuator"}
          />
        </div>
      </Form.Group>
    </div>
  );
};

export default ActuatorCustomName;
