import { combineReducers } from "redux";
import { selectLinkageKitReducer } from "./selectLinkageKitReducer";
import { selectLinkageKitByDimensionsReducer } from "./selectLinkageKitByDimensionsReducer";
import { adminReducer } from "./adminReducer";
import { applicationReducer } from "./applicationReducer";
import { api } from "../slices/api";
import userSlice from "../slices/user";
import cartSlice from "../slices/cart";
import savedItemsSlice from "../slices/savedItems";

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  userDetails: userSlice,
  cart: cartSlice,
  savedItems: savedItemsSlice,
  admin: adminReducer,
  application: applicationReducer,
  selectLinkageKit: selectLinkageKitReducer,
  selectLinkageKitByDimensions: selectLinkageKitByDimensionsReducer,
});

export default rootReducer;
