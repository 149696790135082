import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CForm,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import LoadingWidget from "../../components/LoadingWidget";
import { Formik } from "formik";
import {
  getAllOrdersByCustomer,
  getPreferredCustomerStatus,
  getSingleUser,
  updateResellerStatus,
} from "../../redux/actions/adminActions";
import axios from "axios";
import { ADMIN_MESSAGE_RESET, ADMIN_UPDATE_RESELLER_STATUS_RESET } from "../../redux/constants";

const AdminCustomerDetails = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const admin = useSelector((state) => state.admin);
  const { loading, userDetails, success, message, error, status } = admin;

  const [showChangeResellerStatusModal, setShowChangeResellerStatusModal] = useState();

  const viewCertHandler = async (id) => {
    const { data } = await axios.get(`/api/v1/contact/resale/${id}`);

    window.open(data);
  };

  useEffect(() => {
    dispatch(getSingleUser(id));
    dispatch(getAllOrdersByCustomer(id));
  }, []);

  useEffect(() => {
    if (userDetails.email) {
      dispatch(getPreferredCustomerStatus(userDetails.email));
    }
  }, [userDetails.email]);

  useEffect(() => {
    if (admin.updateResellerStatus.success) {
      setShowChangeResellerStatusModal(false);
      dispatch(getSingleUser(id));
    }
  }, [admin.updateResellerStatus]);

  return (
    <>
      {loading ? (
        <LoadingWidget />
      ) : (
        <>
          <CModal visible={showChangeResellerStatusModal} size="sm" alignment="center">
            <Formik
              onSubmit={(values) => {
                dispatch({ type: ADMIN_MESSAGE_RESET });
                dispatch({ type: ADMIN_UPDATE_RESELLER_STATUS_RESET });

                dispatch(updateResellerStatus(userDetails._id, values.status));
              }}
              initialValues={{
                status: userDetails.resellerStatus,
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                isSubmitting,
              }) => (
                <CForm noValidate onSubmit={handleSubmit}>
                  <CModalHeader>
                    <CModalTitle>Change Reseller Status</CModalTitle>
                  </CModalHeader>
                  <CModalBody>
                    {admin.changeOrderStatus.error && (
                      <CAlert color="danger" size="sm">
                        {admin.changeOrderStatus.error}
                      </CAlert>
                    )}
                    <CFormSelect name="status" value={values.status} onChange={handleChange}>
                      <option key="Pending" value="Pending">
                        Pending
                      </option>
                      <option key="Unverified" value="Unverifieds">
                        Unverified
                      </option>
                      <option key="Verified" value="Verified">
                        Verified
                      </option>
                      <option key="Denied" value="Denied">
                        Denied
                      </option>
                    </CFormSelect>
                  </CModalBody>
                  <CModalFooter>
                    <CButton
                      type="button"
                      color="light"
                      onClick={() => setShowChangeResellerStatusModal(false)}
                    >
                      Cancel
                    </CButton>
                    <CButton type="submit">Submit</CButton>
                  </CModalFooter>
                </CForm>
              )}
            </Formik>
          </CModal>
          {admin.updateResellerStatus.success && (
            <CAlert color="success">{admin.updateResellerStatus.message}</CAlert>
          )}
          <a href="/admin/customers" className="btn btn-primary mb-3">
            <i className="fas fa-angle-left mr-2"></i>Customers
          </a>
          <CRow>
            <CCol md={8}>
              <CCard className="mb-3">
                <CCardHeader>
                  <span className="fs-4">{`${userDetails.firstName} ${userDetails.lastName}`}</span>
                </CCardHeader>
                <CCardBody>
                  <p>
                    <strong>Company</strong> <br />
                    <span>{userDetails.company}</span>
                  </p>
                  <p>
                    <strong>Job Title</strong> <br />
                    <span>{userDetails.jobTitle}</span>
                  </p>
                  <p>
                    <strong>Email Address</strong> <br />
                    <span>{userDetails.email}</span>
                  </p>
                </CCardBody>
              </CCard>
              <CCard className="mb-3">
                <CCardHeader>
                  <span className="fs-4">Order History</span>
                </CCardHeader>
                <CCardBody>
                  <CTable>
                    <CTableBody>
                      <CTableRow>
                        <CTableHeaderCell>Order Number</CTableHeaderCell>
                        <CTableHeaderCell>Items</CTableHeaderCell>
                        <CTableHeaderCell>Total</CTableHeaderCell>
                        <CTableHeaderCell>Actions</CTableHeaderCell>
                      </CTableRow>
                      {userDetails.orders.length > 0 ? (
                        userDetails.orders.map((order) => (
                          <CTableRow>
                            <CTableDataCell>{order.orderNumber}</CTableDataCell>
                            <CTableDataCell>
                              {order.orderItems.reduce((acc, item) => acc + item.qty, 0)}
                            </CTableDataCell>
                            <CTableDataCell>${order.amounts.total}</CTableDataCell>
                            <CTableDataCell>
                              <CButton
                                color="light"
                                size="sm"
                                className="mx-1"
                                href={`/admin/orders/${order.orderNumber}`}
                              >
                                <i className="fas fa-eye text-primary"></i>
                              </CButton>
                            </CTableDataCell>
                          </CTableRow>
                        ))
                      ) : (
                        <CCardBody>
                          <span className="text-center">No Orders</span>
                        </CCardBody>
                      )}
                    </CTableBody>
                  </CTable>
                </CCardBody>
              </CCard>

              <CCard className="mb-3">
                <CCardHeader>
                  <span className="fs-4">Current Cart</span>
                </CCardHeader>
                <CCardBody>
                  {userDetails.cart.cartItems.length > 0 ? (
                    userDetails.cart.cartItems.map((item) => (
                      <>
                        <CRow>
                          <CCol className="d-flex align-items-start">
                            <div>
                              <strong>{`${item.valve.size} ${item.valve.brand} ${item.valve.series} ${item.valve.type} to ${item.actuator.brand} ${item.actuator.series} ${item.actuator.model}`}</strong>
                              <br />
                              <span className="small">SKU: {item.sku}</span>
                              <br />
                              <span className="small">{item.components}</span>
                              <br />
                              <span className="small">
                                {item.lockout ? "Lockout Kit" : "Non-Lockout Kit"}
                              </span>
                              <br />
                              <span className="small">Bracket Height: {item.bracketHeight}"</span>
                            </div>
                            <div className="ml-auto">
                              <strong>${Number(item.price).toFixed(2)}</strong>
                            </div>
                          </CCol>
                        </CRow>
                        <hr />
                      </>
                    ))
                  ) : (
                    <span>No Items in Cart</span>
                  )}
                </CCardBody>
              </CCard>

              <CCard>
                <CCardHeader>
                  <span className="fs-4">Current Saved Items</span>
                </CCardHeader>
                <CCardBody>
                  {userDetails.savedItems.length > 0 ? (
                    userDetails.savedItems.map((item) => (
                      <>
                        <CRow>
                          <CCol className="d-flex align-items-start">
                            <div>
                              <strong>{`${item.valve.size} ${item.valve.brand} ${item.valve.series} ${item.valve.type} to ${item.actuator.brand} ${item.actuator.series} ${item.actuator.model}`}</strong>
                              <br />
                              <span className="small">SKU: {item.sku}</span>
                              <br />
                              <span className="small">{item.components}</span>
                              <br />
                              <span className="small">
                                {item.lockout ? "Lockout Kit" : "Non-Lockout Kit"}
                              </span>
                              <br />
                              <span className="small">Bracket Height: {item.bracketHeight}"</span>
                            </div>
                            <div className="ml-auto">
                              <strong>${Number(item.price).toFixed(2)}</strong>
                            </div>
                          </CCol>
                        </CRow>
                        <hr />
                      </>
                    ))
                  ) : (
                    <span>No Saved Items</span>
                  )}
                </CCardBody>
              </CCard>
            </CCol>
            <CCol md={4}>
              <CCard className="mb-3">
                <CCardHeader>
                  <span className="fs-4">Account</span>
                </CCardHeader>
                <CCardBody>
                  <div className="d-flex align-items-start">
                    <p>
                      <strong>Reseller Status</strong> <br />
                      <span className="small">{userDetails.resellerStatus}</span>
                    </p>

                    {userDetails.resellerStatus === "Verified" && (
                      <CDropdown className="ml-auto" variant="btn-group" alignment="end">
                        <CButton
                          color="light"
                          size="sm"
                          onClick={() => viewCertHandler(userDetails._id)}
                        >
                          View Certificate
                        </CButton>
                        <CDropdownToggle color="light" split />
                        <CDropdownMenu>
                          <CDropdownItem
                            component="button"
                            size="sm"
                            onClick={() => {
                              setShowChangeResellerStatusModal(true);
                            }}
                          >
                            Change Status
                          </CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    )}
                    {userDetails.resellerStatus === "Unverified" ||
                      (userDetails.resellerStatus === "Pending" && (
                        <CButton
                          color="light"
                          className="ml-auto"
                          size="sm"
                          onClick={() => {
                            setShowChangeResellerStatusModal(true);
                          }}
                        >
                          Change Status
                        </CButton>
                      ))}
                  </div>
                  {userDetails.resellerStatus === "Pending" && (
                    <CRow>
                      <CCol>
                        <CAlert className="alert-xs">
                          Customer has submitted a Cerificate of Resale, and is awaiting approval.
                          <br />
                          <CButton
                            className="mt-2"
                            color="primary"
                            size="sm"
                            onClick={() => viewCertHandler(userDetails._id)}
                          >
                            View Certificate
                          </CButton>
                        </CAlert>
                      </CCol>
                    </CRow>
                  )}
                  <hr />

                  <div className="d-flex align-items-start">
                    <p>
                      <strong>Preferred Customer Status</strong> <br />
                      <span className="small">
                        {userDetails.preferredCustomerStatus.isPreferredCustomer
                          ? "Preferred Customer"
                          : "Not a Preferred Customer"}
                      </span>
                    </p>
                  </div>

                  {/* {userDetails.isPreferredCustomer ? (
                    <CBadge>
                      <i className="fas fa-check mr-2"></i>Preferred Customer
                    </CBadge>
                  ) : (
                    <CBadge>
                      <i className="fas fa-cross mr-2"></i>Not a Preferred
                      Customer
                    </CBadge>
                  )} */}
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </>
      )}
    </>
  );
};

export default AdminCustomerDetails;
