import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  CButton,
  CForm,
  CFormInput,
  CFormFeedback,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { ADMIN_ADD_EARLY_ACCESS_USER_MODAL, ADMIN_MESSAGE_RESET } from "../../../redux/constants";
import { addEarlyAccessUser } from "../../../redux/actions/adminActions";

export const AddEarlyAccessUserModal = () => {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);
  const { loading, message, error, status } = admin;

  return (
    <CModal alignment="center" visible={admin.application.addEarlyAccessUserModal}>
      <CModalHeader onDismiss={() => dispatch({ type: ADMIN_ADD_EARLY_ACCESS_USER_MODAL })}>
        <CModalTitle>Add Early Access User</CModalTitle>
      </CModalHeader>
      <Formik
        validationSchema={Yup.object().shape({
          email: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          dispatch({ type: ADMIN_MESSAGE_RESET });
          dispatch(addEarlyAccessUser(values.email));
        }}
        initialValues={{
          email: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          isSubmitting,
        }) => (
          <CForm noValidate onSubmit={handleSubmit}>
            <CModalBody>
              {error && <div className="alert alert-danger">{error}</div>}
              <CFormLabel htmlFor="email">Email</CFormLabel>
              <CFormInput
                type="text"
                name="email"
                value={values.email}
                onChange={handleChange}
                isInvalid={!!errors.email}
                id="email"
              />
              <CFormFeedback invalid>{errors.email}</CFormFeedback>
            </CModalBody>
            <CModalFooter>
              <CButton
                type="button"
                color="light"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({ type: ADMIN_ADD_EARLY_ACCESS_USER_MODAL });
                  dispatch({ type: ADMIN_MESSAGE_RESET });
                }}
              >
                Close
              </CButton>
              <CButton type="submit" color="primary">
                Save
              </CButton>
            </CModalFooter>
          </CForm>
        )}
      </Formik>
    </CModal>
  );
};
