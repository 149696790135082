import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";

import { getPreferredCustomers } from "../../redux/actions/adminActions";
import { ADMIN_ADD_PREFERRED_CUSTOMER_MODAL, ADMIN_MESSAGE_RESET } from "../../redux/constants";
import { AddPreferredCustomerModal } from "../../components/admin/modals/AddPreferredCustomerModal";

const AdminPreferredCustomers = () => {
  const dispatch = useDispatch();

  const [confirmDeletePreferredCustomerModal, setConfirmDeletePreferredCustomerModal] =
    useState(false);

  const [preferredCustomerDetails, setPreferredCustomerDetails] = useState({});

  const admin = useSelector((state) => state.admin);
  const { loading, success, message, status, error, preferredCustomers } = admin;

  useEffect(() => {
    dispatch(getPreferredCustomers());
  }, []);

  return (
    <>
      {confirmDeletePreferredCustomerModal && (
        <CModal visible={confirmDeletePreferredCustomerModal} centered>
          <CModalHeader>
            <CModalTitle>Delete Preferred Customer?</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <strong>Preferred Customer:</strong> {`${preferredCustomerDetails.email}`}
          </CModalBody>
          <CModalFooter>
            <CButton
              color="light"
              onClick={() => {
                setConfirmDeletePreferredCustomerModal(false);
                setPreferredCustomerDetails({});
              }}
            >
              Cancel
            </CButton>
            <CButton
              color="danger"
              onClick={() => {
                //dispatch(deletePreferredCustomer(preferredCustomer.email));
              }}
            >
              Delete
            </CButton>
          </CModalFooter>
        </CModal>
      )}
      {message === "Preferred Customer added successfully" && (
        <div className="alert alert-success">{message}</div>
      )}
      {message === "Preferred Customer deleted successfully" && (
        <div className="alert alert-success">{message}</div>
      )}
      <AddPreferredCustomerModal />
      <CRow>
        <CCol xs={12} className="mb-4">
          <CButton
            color="primary"
            onClick={() => {
              dispatch({ type: ADMIN_ADD_PREFERRED_CUSTOMER_MODAL });
              dispatch({ type: ADMIN_MESSAGE_RESET });
            }}
          >
            Add Preferred Customer
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={12}></CCol>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Preferred Customers</strong>
            </CCardHeader>
            <CCardBody>
              <CTable hover>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Discount</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {preferredCustomers.map((pc) => (
                    <CTableRow>
                      <CTableDataCell>{pc.email}</CTableDataCell>
                      <CTableDataCell>
                        {pc.discount.type === "percentage" ? (
                          <>{pc.discount.amount}%</>
                        ) : (
                          pc.discount.type === "dollar" && <>${pc.discount.amount}</>
                        )}
                      </CTableDataCell>
                      <CTableDataCell>
                        <CButton
                          color="light"
                          size="sm"
                          className="mx-1"
                          // onClick={() => {
                          //   setCouponDetails(coupon);

                          //   dispatch({
                          //     type: ADMIN_EDIT_COUPON_MODAL,
                          //   });
                          // }}
                        >
                          <i className="fas fa-edit"></i>
                        </CButton>
                        <CButton
                          color="light"
                          size="sm"
                          className="mx-1"
                          // onClick={() => {
                          //   setConfirmDeleteCouponModal(true);
                          //   setCouponDetails(coupon);
                          // }}
                        >
                          <i className="fas fa-trash"></i>
                        </CButton>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default AdminPreferredCustomers;
