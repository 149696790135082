import React, { useEffect, useState } from "react";
import { isEmpty, startCase } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Form, Spinner } from "react-bootstrap";
import Select from "react-select";

// import custom styling for react-select
import { customStyles3 } from "../../styles/selectorStyles";

//import trademark information
import tm from "../../data/trademarks";

// import redux actions
import {
  fetchSelectedActuator,
  setSelectedActuatorBrand,
  setSelectedActuatorSeries,
  setSelectedActuatorModel,
  setSelectedActuatorOptions,
  resetSelectedActuator,
} from "../../redux/actions/actuatorActions";
import {
  ACTUATOR_OPTIONS_RESET,
  SELECTED_ACTUATOR_OPTIONS_RESET,
} from "../../redux/constants";
import {
  useGetActuatorBrandsQuery,
  useGetActuatorModelsQuery,
  useGetActuatorSeriesQuery,
} from "../../redux/api/actuatorApi";

const SelectActuatorModel = () => {
  const dispatch = useDispatch();

  // bring linkage kits selections in from redux state
  const linkageKitSelections = useSelector((state) => state.selectLinkageKit);
  const {
    loading,
    actuatorOptions,
    selectedActuatorBrand,
    selectedActuatorSeries,
    selectedActuatorModel,
    selectedActuatorOptions,
    selectedActuator,
  } = linkageKitSelections;

  const getActuatorBrandsQuery = useGetActuatorBrandsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  const getActuatorSeriesQuery = useGetActuatorSeriesQuery(
    { brand: selectedActuatorBrand },
    { skip: !selectedActuatorBrand }
  );

  const getActuatorModelsQuery = useGetActuatorModelsQuery(
    { brand: selectedActuatorBrand, series: selectedActuatorSeries },
    { skip: !selectedActuatorBrand || !selectedActuatorSeries }
  );

  const brands = getActuatorBrandsQuery?.data?.map((x) => ({
    value: x,
    label: tm[x] === "r" ? `${x}\u00AE` : tm[x] === "tm" ? `${x}\u2122` : x,
  }));

  const series = getActuatorSeriesQuery?.data?.map((x) => ({
    value: x,
    label: tm[x] === "r" ? `${x}\u00AE` : tm[x] === "tm" ? `${x}\u2122` : x,
  }));

  const models = getActuatorModelsQuery?.data?.map((x) => ({
    value: x,
    label: x,
  }));

  // when a brand is selected, dispatch action, set new state in redux, and reset series and model selections
  const selectActuatorBrandHandler = (value) => {
    dispatch(setSelectedActuatorBrand(value));
    dispatch(setSelectedActuatorSeries(""));
    dispatch(setSelectedActuatorModel(""));
    dispatch({ type: SELECTED_ACTUATOR_OPTIONS_RESET });
    dispatch({ type: ACTUATOR_OPTIONS_RESET });

    if (!isEmpty(selectedActuator)) {
      dispatch(resetSelectedActuator());
    }
  };

  // when a series is selected, dispatch action, set new state in redux, and reset model selections
  const selectActuatorSeriesHandler = (value) => {
    dispatch(setSelectedActuatorSeries(value));
    dispatch(setSelectedActuatorModel(""));
    dispatch({ type: SELECTED_ACTUATOR_OPTIONS_RESET });
    dispatch({ type: ACTUATOR_OPTIONS_RESET });

    if (!isEmpty(selectedActuator)) {
      dispatch(resetSelectedActuator());
    }
  };

  // when a model is selected, dispatch action, and set new state in redux
  const selectActuatorModelHandler = (value) => {
    dispatch(setSelectedActuatorModel(value));
    dispatch({ type: SELECTED_ACTUATOR_OPTIONS_RESET });
    dispatch({ type: ACTUATOR_OPTIONS_RESET });

    if (!isEmpty(selectedActuator)) {
      dispatch(resetSelectedActuator());
    }
  };

  const selectActuatorOptionsHandler = (key, value) => {
    dispatch(setSelectedActuatorOptions(key, value));

    if (!isEmpty(selectedActuator)) {
      dispatch(resetSelectedActuator());
    }
  };

  // when a model is selected, fetch a list of options
  useEffect(() => {
    if (isEmpty(selectedActuator)) {
      if (
        selectedActuatorModel !== "" &&
        selectedActuatorModel !== "Select Model"
      ) {
        dispatch(
          fetchSelectedActuator(
            selectedActuatorBrand,
            selectedActuatorSeries,
            selectedActuatorModel,
            selectedActuatorOptions
          )
        );
      }
    }
  }, [
    dispatch,
    selectedActuatorModel,
    selectedActuatorSeries,
    selectedActuatorModel,
    selectedActuatorOptions,
  ]);

  return (
    <div className="w-100">
      {/* <ul className="payment-methods__list">
        <li
          className={
            actuatorSelectionType === "iso"
              ? "payment-methods__item payment-methods__item--active"
              : "payment-methods__item"
          }
        >
          <label className="payment-methods__item-header">
            <span className="payment-methods__item-radio input-radio">
              <span className="input-radio__body">
                <input
                  className="input-radio__input"
                  name="selectActuatorType"
                  value="selectByISO"
                  type="radio"
                  checked={actuatorSelectionType === "iso"}
                  onChange={() => dispatch(setActuatorSelectionType("iso"))}
                />
                <span className="input-radio__circle"></span>
              </span>
            </span>
            <span className="payment-methods__item-title">Select By Standard ISO 5211</span>
          </label>
          <div
            className="payment-methods__item-container"
            style={actuatorSelectionType === "iso" ? { overflow: "visible" } : {}}
          ></div>
        </li>
        <li
          className={
            actuatorSelectionType === "model"
              ? "payment-methods__item payment-methods__item--active"
              : "payment-methods__item"
          }
        >
          <label className="payment-methods__item-header">
            <span className="payment-methods__item-radio input-radio">
              <span className="input-radio__body">
                <input
                  className="input-radio__input"
                  name="selectActuatorType"
                  value="selectByModel"
                  type="radio"
                  checked={actuatorSelectionType === "model"}
                  onChange={() => dispatch(setActuatorSelectionType("model"))}
                />
                <span className="input-radio__circle"></span>
              </span>
            </span>
            <span className="payment-methods__item-title">Select by Brand/Series/Model</span>
          </label>
          <div
            className="payment-methods__item-container"
            style={actuatorSelectionType === "model" ? { overflow: "visible" } : {}}
          ></div>
        </li>
      </ul> */}

      <div className="build-kit-dropdowns-container">
        <>
          <div className="flex-fill">
            <Form.Group>
              <Form.Label
                id="aria-label-actuator-brand"
                htmlFor="aria-input-actuator-brand"
              >
                Brand
              </Form.Label>
              <Select
                inputId="aria-input-actuator-brand"
                aria-labelledby="aria-label-actuator-brand"
                value={brands?.filter(
                  ({ value }) => selectedActuatorBrand === value
                )}
                options={brands}
                onChange={(value) => selectActuatorBrandHandler(value.value)}
                styles={customStyles3}
                placeholder={
                  loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="ml-2"
                    />
                  ) : (
                    "Select Brand"
                  )
                }
              />
            </Form.Group>
          </div>
          <div className="flex-fill">
            <Form.Group>
              <Form.Label
                id="aria-label-actuator-series"
                htmlFor="aria-input-actuator-series"
              >
                Series
              </Form.Label>
              <Select
                inputId="aria-input-actuator-series"
                aria-labelledby="aria-label-actuator-series"
                value={series?.filter(
                  ({ value }) => selectedActuatorSeries === value
                )}
                options={series}
                onChange={(value) => selectActuatorSeriesHandler(value.value)}
                styles={customStyles3}
                isDisabled={selectedActuatorBrand === ""}
                placeholder={
                  loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="ml-2"
                    />
                  ) : (
                    "Select Series"
                  )
                }
              />
            </Form.Group>
          </div>

          <div className="flex-fill">
            <Form.Group>
              <Form.Label
                id="aria-label-actuator-model"
                htmlFor="aria-input-actuator-model"
              >
                Model
              </Form.Label>
              <Select
                inputId="aria-input-actuator-model"
                aria-labelledby="aria-label-actuator-model"
                value={models?.filter(
                  ({ value }) => selectedActuatorModel === value
                )}
                options={models}
                onChange={(value) => selectActuatorModelHandler(value.value)}
                styles={customStyles3}
                isDisabled={selectedActuatorSeries === ""}
                placeholder={
                  loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="ml-2"
                    />
                  ) : (
                    "Select Model"
                  )
                }
              />
            </Form.Group>
          </div>

          {!isEmpty(actuatorOptions) &&
            actuatorOptions.map((option, index) => {
              const options = Object.values(option)[0].map((x) => ({
                value: x,
                label: x,
              }));

              const optionName = Object.keys(option)[0];

              const label = startCase(Object.keys(option)[0]);

              const disabled = index > selectedActuatorOptions.length;

              return (
                <React.Fragment key={Object.keys(option)[0]}>
                  <div className="flex-fill">
                    <Form.Group>
                      <Form.Label
                        id={`aria-label-actuator-${optionName}`}
                        htmlFor={`aria-input-actuator-${optionName}`}
                      >
                        {label}
                      </Form.Label>
                      <Select
                        value={options.filter(({ value }) =>
                          selectedActuatorOptions.find(
                            (option) => Object.values(option)[0] === value
                          )
                        )}
                        options={options}
                        onChange={(value) =>
                          selectActuatorOptionsHandler(
                            Object.keys(option)[0],
                            value.value
                          )
                        }
                        styles={customStyles3}
                        isDisabled={disabled}
                        placeholder={
                          loading ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="ml-2"
                            />
                          ) : (
                            // `Select ${label}`
                            "Select an Option"
                          )
                        }
                      />
                    </Form.Group>
                  </div>
                </React.Fragment>
              );
            })}
        </>
      </div>
    </div>
  );
};

export default SelectActuatorModel;
