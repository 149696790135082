import { api } from "../slices/api";

// Define a service using a base URL and expected endpoints
export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    submitRFQ: builder.mutation({
      query: (values) => ({
        url: "v1/contact/rfq",
        method: "POST",
        body: values,
      }),
    }),
    sendMessage: builder.mutation({
      query: (values) => ({
        url: "v1/contact/message",
        method: "POST",
        body: values,
      }),
    }),
    submitResellerCertificate: builder.mutation({
      query: (values) => ({
        url: "v1/contact/resale",
        method: "POST",
        body: values,
      }),
    }),
    signupForEmail: builder.mutation({
      query: (values) => ({
        url: "v1/email",
        method: "POST",
        body: values,
      }),
    }),
  }),
});

export const {
  useSubmitRFQMutation,
  useSendMessageMutation,
  useSubmitResellerCertificateMutation,
  useSignupForEmailMutation,
} = extendedApiSlice;
