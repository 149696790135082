import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { getQuoteRequestDetails } from "../../redux/actions/adminActions";
import LoadingWidget from "../../components/LoadingWidget";
import { isEmpty } from "lodash";

const AdminQuoteRequestDetails = () => {
  const dispatch = useDispatch();

  const { rfqNumber } = useParams();

  const admin = useSelector((state) => state.admin);
  const { loading, message, error, status } = admin;

  const quoteRequests = useSelector((state) => state.admin.quoteRequests);
  const { quoteRequestDetails } = quoteRequests;

  useEffect(() => {
    dispatch(getQuoteRequestDetails(rfqNumber));
  }, []);

  return (
    <>
      {loading ? (
        <LoadingWidget />
      ) : (
        !isEmpty(quoteRequestDetails) && (
          <>
            <CButton href="/admin/quote-requests" color="primary" className="mb-3">
              <i className="fas fa-angle-left mr-2"></i>Quote Requests
            </CButton>
            <CRow>
              <CCol md={8}>
                <CCard className="mb-4 p-2">
                  <CCardBody>
                    <p className="fs-2">#{quoteRequestDetails.rfqNumber}</p>
                    <p>{new Date(quoteRequestDetails.createdAt).toLocaleDateString()}</p>
                    <hr />
                    <p className="fs-5">Details</p>
                    <CTable>
                      <CTableBody>
                        <CTableRow>
                          <CTableHeaderCell scope="row">Name</CTableHeaderCell>
                          <CTableDataCell>{quoteRequestDetails.name}</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                          <CTableHeaderCell scope="row">Company</CTableHeaderCell>
                          <CTableDataCell>{quoteRequestDetails.company}</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                          <CTableHeaderCell scope="row">Email</CTableHeaderCell>
                          <CTableDataCell>{quoteRequestDetails.email}</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                          <CTableHeaderCell scope="row">Phone</CTableHeaderCell>
                          <CTableDataCell>{quoteRequestDetails.phone}</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                          <CTableHeaderCell scope="row">Valve</CTableHeaderCell>
                          <CTableDataCell>{quoteRequestDetails.valve}</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                          <CTableHeaderCell scope="row">Actuator</CTableHeaderCell>
                          <CTableDataCell>{quoteRequestDetails.actuator}</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                          <CTableHeaderCell scope="row">Preferred Bracket Height</CTableHeaderCell>
                          <CTableDataCell>
                            {quoteRequestDetails.preferredBracketHeight}
                          </CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                          <CTableHeaderCell scope="row">Lead Time</CTableHeaderCell>
                          <CTableDataCell>{quoteRequestDetails.leadTime}</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                          <CTableHeaderCell scope="row">Customer Note</CTableHeaderCell>
                          <CTableDataCell>{quoteRequestDetails.additionalDetails}</CTableDataCell>
                        </CTableRow>
                      </CTableBody>
                    </CTable>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </>
        )
      )}
    </>
  );
};

export default AdminQuoteRequestDetails;
