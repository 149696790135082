import React, { useEffect, useState } from "react";
import { isEmpty, startCase } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Form, Spinner } from "react-bootstrap";
import Select from "react-select";

// import custom styling for react-select
import { customStyles3 } from "../../styles/selectorStyles";

//import trademark information
import tm from "../../data/trademarks";

// import redux actions
import {
  resetSelectedActuator,
  setSelectedActuatorISO5211Flange,
  setSelectedActuatorISO5211DriveType,
  setSelectedActuatorISO5211DriveSize,
  fetchSelectedISO5211Actuator,
} from "../../redux/actions/actuatorActions";
import {
  useGetISO5211ActuatorFlangesQuery,
  useGetISO5211ActuatorDriveTypesQuery,
  useGetISO5211ActuatorDriveSizesQuery,
} from "../../redux/api/actuatorApi";

const SelectActuatorISO5211 = () => {
  const dispatch = useDispatch();

  // bring linkage kits selections in from redux state
  const linkageKitSelections = useSelector((state) => state.selectLinkageKit);
  const {
    loading,
    selectedActuatorISO5211Flange,
    selectedActuatorISO5211DriveType,
    selectedActuatorISO5211DriveSize,
    selectedActuator,
  } = linkageKitSelections;

  const getISO5211ActuatorFlangesQuery = useGetISO5211ActuatorFlangesQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  const GetISO5211ActuatorDriveTypesQuery =
    useGetISO5211ActuatorDriveTypesQuery(
      { isoFlange: selectedActuatorISO5211Flange },
      { skip: !selectedActuatorISO5211Flange }
    );

  const getISO5211ActuatorDriveSizesQuery =
    useGetISO5211ActuatorDriveSizesQuery(
      {
        isoFlange: selectedActuatorISO5211Flange,
        driveType: selectedActuatorISO5211DriveType,
      },
      {
        skip:
          !selectedActuatorISO5211Flange || !selectedActuatorISO5211DriveType,
      }
    );

  // convert arrays of brands, series, models fetched from DB to arrays of objects containing values and labels. (For use with React-Select)
  // ie; { value: 'XOMOX', label: 'XOMOX' }
  const iso5211Flanges = getISO5211ActuatorFlangesQuery?.data?.map((x) => ({
    value: x,
    label: tm[x] === "r" ? `${x}\u00AE` : tm[x] === "tm" ? `${x}\u2122` : x,
  }));

  const iso5211DriveTypes = GetISO5211ActuatorDriveTypesQuery?.data?.map(
    (x) => ({
      value: x,
      label: tm[x] === "r" ? `${x}\u00AE` : tm[x] === "tm" ? `${x}\u2122` : x,
    })
  );

  const iso5211DriveSizes = getISO5211ActuatorDriveSizesQuery?.data?.map(
    (x) => ({
      value: x,
      label: tm[x] === "r" ? `${x}\u00AE` : tm[x] === "tm" ? `${x}\u2122` : x,
    })
  );

  // when a iso flange is selected, dispatch action, set new state in redux, and reset iso drive type and drive size selections
  const selectActuatorISO5211FlangeHandler = (value) => {
    dispatch(setSelectedActuatorISO5211Flange(value));
    dispatch(setSelectedActuatorISO5211DriveType(""));
    dispatch(setSelectedActuatorISO5211DriveSize(""));

    if (!isEmpty(selectedActuator)) {
      dispatch(resetSelectedActuator());
    }
  };

  // when a iso drive type is selected, dispatch action, set new state in redux, and reset iso drive size selection
  const selectActuatorISO5211DriveTypeHandler = (value) => {
    dispatch(setSelectedActuatorISO5211DriveType(value));
    dispatch(setSelectedActuatorISO5211DriveSize(""));

    if (!isEmpty(selectedActuator)) {
      dispatch(resetSelectedActuator());
    }
  };

  // when a iso drive size is selected, dispatch action, set new state in redux
  const selectActuatorISO5211DriveSizeHandler = (value) => {
    dispatch(setSelectedActuatorISO5211DriveSize(value));

    if (!isEmpty(selectedActuator)) {
      dispatch(resetSelectedActuator());
    }
  };

  // fetch ISO 5211 actuator details when drive size is selected
  useEffect(() => {
    if (
      isEmpty(selectedActuator) &&
      selectedActuatorISO5211DriveSize !== "" &&
      selectedActuatorISO5211DriveSize !== "Select Drive Size"
    ) {
      dispatch(
        fetchSelectedISO5211Actuator(
          selectedActuatorISO5211Flange,
          selectedActuatorISO5211DriveType,
          selectedActuatorISO5211DriveSize
        )
      );
    }
  }, [dispatch, selectedActuatorISO5211DriveSize]);

  return (
    <div className="w-100">
      <div className="build-kit-dropdowns-container mt-3">
        <>
          <div className="flex-fill">
            <Form.Group>
              <Form.Label>ISO Flange</Form.Label>
              <Select
                value={iso5211Flanges?.filter(
                  ({ value }) => selectedActuatorISO5211Flange === value
                )}
                options={iso5211Flanges}
                onChange={(value) =>
                  selectActuatorISO5211FlangeHandler(value.value)
                }
                styles={customStyles3}
                placeholder={
                  loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="ml-2"
                    />
                  ) : (
                    "Select ISO Flange"
                  )
                }
              />
            </Form.Group>
          </div>
          <div className="flex-fill">
            <Form.Group>
              <Form.Label>Drive Type</Form.Label>
              <Select
                value={iso5211DriveTypes?.filter(
                  ({ value }) => selectedActuatorISO5211DriveType === value
                )}
                options={iso5211DriveTypes}
                onChange={(value) =>
                  selectActuatorISO5211DriveTypeHandler(value.value)
                }
                styles={customStyles3}
                isDisabled={selectedActuatorISO5211Flange === ""}
                placeholder={
                  loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="ml-2"
                    />
                  ) : (
                    "Select Drive Type"
                  )
                }
              />
            </Form.Group>
          </div>

          <div className="flex-fill">
            <Form.Group>
              <Form.Label>Drive Size</Form.Label>
              <Select
                value={iso5211DriveSizes?.filter(
                  ({ value }) => selectedActuatorISO5211DriveSize === value
                )}
                options={iso5211DriveSizes}
                onChange={(value) =>
                  selectActuatorISO5211DriveSizeHandler(value.value)
                }
                styles={customStyles3}
                isDisabled={selectedActuatorISO5211DriveType === ""}
                placeholder={
                  loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="ml-2"
                    />
                  ) : (
                    "Select Drive Size"
                  )
                }
              />
            </Form.Group>
          </div>
        </>
      </div>
    </div>
  );
};

export default SelectActuatorISO5211;
