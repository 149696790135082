import axios from "axios";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import {
  CART_ADD_ITEM_REQUEST,
  CART_ADD_ITEM_SUCCESS,
  CART_ADD_ITEM_FAIL,
  CART_REMOVE_ITEM_REQUEST,
  CART_REMOVE_ITEM_SUCCESS,
  CART_REMOVE_ITEM_FAIL,
  CART_UPDATE_ITEM_QTY_REQUEST,
  CART_UPDATE_ITEM_QTY_SUCCESS,
  CART_UPDATE_ITEM_QTY_FAIL,
  CART_ADD_COUPON_REQUEST,
  CART_ADD_COUPON_SUCCESS,
  CART_ADD_COUPON_FAIL,
  CART_REMOVE_COUPON_REQUEST,
  CART_REMOVE_COUPON_SUCCESS,
  CART_REMOVE_COUPON_FAIL,
  CART_MERGE_REQUEST,
  CART_MERGE_SUCCESS,
  CART_MERGE_FAIL,
  VALIDATE_COUPON_REQUEST,
  VALIDATE_COUPON_SUCCESS,
  VALIDATE_COUPON_FAIL,
  VALIDATE_PC_REQUEST,
  VALIDATE_PC_SUCCESS,
  VALIDATE_PC_FAIL,
  USER_CART_REQUEST,
  USER_CART_SUCCESS,
  USER_CART_FAIL,
  MOVE_TO_SAVED_ITEMS_REQUEST,
  MOVE_TO_SAVED_ITEMS_SUCCESS,
  MOVE_TO_SAVED_ITEMS_FAIL,
  GET_SHIPPING_PRICE_REQUEST,
  GET_SHIPPING_PRICE_SUCCESS,
  GET_SHIPPING_PRICE_FAIL,
  CART_UPDATE_REQUEST,
  CART_UPDATE_SUCCESS,
  CART_UPDATE_FAIL,
  TAX_RATES_REQUEST,
  TAX_RATES_SUCCESS,
  TAX_RATES_FAIL,
  GET_ITEMS_WEIGHT_REQUEST,
  GET_ITEMS_WEIGHT_SUCCESS,
  GET_ITEMS_WEIGHT_FAIL,
  IS_TAX_EXEMPT,
  UPS_SHIPPING_RATES_REQUEST,
  UPS_SHIPPING_RATES_SUCCESS,
  UPS_SHIPPING_RATES_FAIL,
} from "../constants";

export const getUserCart = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_CART_REQUEST,
    });

    const res = await axios.get("/api/v1/cart");

    dispatch({
      type: USER_CART_SUCCESS,
      payload: res,
    });

    localStorage.setItem("cartItems", JSON.stringify(res.data.cartItems));

    if (res.data.coupon && res.data.coupon.code) {
      dispatch(
        addCoupon(
          res.data.coupon.code,
          res.data.cartItems
            .reduce((acc, item) => acc + item.qty * item.price, 0)
            .toFixed(2),
          res.data.cartItems.reduce((acc, item) => acc + item.qty, 0),
          true
        )
      );

      localStorage.setItem("couponInfo", JSON.stringify(res.data.coupon));
    }
  } catch (error) {
    dispatch({
      type: USER_CART_FAIL,
      payload: error,
    });
  }
};

export const addToCart = (item, isLoggedIn) => async (dispatch, getState) => {
  const state = getState();

  dispatch({ type: CART_ADD_ITEM_REQUEST });

  if (isLoggedIn === true) {
    try {
      const res = await axios.post(
        "/api/v1/cart/add",
        {
          productType: item.productType,
          ...(item.productType === "mountingKit" ? { sku: item.sku } : null),
          ...(item.productType === "customMountingKit"
            ? {
                valve: item.valve,
                actuator: item.actuator,
                components: item.components,
                lockout: item.lockout,
                bracketHeight: item.bracketHeight,
              }
            : null),
          qty: item.qty,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      localStorage.setItem("cartItems", JSON.stringify(res.data));

      dispatch({ type: CART_ADD_ITEM_SUCCESS, payload: res, item: item });
    } catch (error) {
      dispatch({ type: CART_ADD_ITEM_FAIL, payload: error });
    }
  } else if (isLoggedIn === false) {
    const match = state.cart.cartItems.find((x) => x.sku === item.sku);

    if (match) {
      const matchIndex = state.cart.cartItems.findIndex(
        (x) => x.sku === item.sku
      );

      const updatedQty = Number(item.qty) + Number(match.qty);

      const updatedPrice = await axios.post("/api/v1/calculate/price/", {
        sku: item.sku,
        qty: updatedQty,
      });

      await dispatch({
        type: CART_ADD_ITEM_SUCCESS,
        payload: {
          data: [
            ...state.cart.cartItems,
            (state.cart.cartItems[matchIndex] = {
              productType: item.productType,
              sku: item.sku,
              valve: item.valve,
              actuator: item.actuator,
              lockout: item.lockout,
              bracketHeight: item.bracketHeight,
              components: item.components,
              qty: updatedQty,
              price: updatedPrice.data.price,
            }),
          ],
        },
      });

      localStorage.setItem("cartItems", JSON.stringify(state.cart.cartItems));
    } else {
      state.cart.cartItems.push(item);
    }

    await dispatch({
      type: CART_ADD_ITEM_SUCCESS,
      payload: { data: state.cart.cartItems },
    });

    localStorage.setItem("cartItems", JSON.stringify(state.cart.cartItems));
  }
};

export const updateCartItemQty =
  (item, isLoggedIn) => async (dispatch, getState) => {
    // when using a number input field in the cart screen, use this code block to split the sku and qty
    //const qty = Number(updatedQtyInput.split("?qty=")[1]);

    const state = getState();

    dispatch({ type: CART_UPDATE_ITEM_QTY_REQUEST });

    if (isLoggedIn === true) {
      try {
        const res = await axios.post(
          "/api/v1/cart/update-qty",
          {
            productType: item.productType,
            ...(item.productType === "mountingKit" && { sku: item.sku }),
            ...(item.productType === "customMountingKit" && {
              valve: item.valve,
              actuator: item.actuator,
              components: item.components,
              lockout: item.lockout,
              bracketHeight: item.bracketHeight,
              _id: item._id,
            }),
            qty: item.qty,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({ type: CART_UPDATE_ITEM_QTY_SUCCESS, payload: res });

        localStorage.setItem("cartItems", JSON.stringify(res.data));
      } catch (error) {
        dispatch({ type: CART_UPDATE_ITEM_QTY_FAIL, payload: error });
      }
    } else if (isLoggedIn === false) {
      const matchIndex = state.cart.cartItems.findIndex(
        (x) => x.sku === item.sku
      );

      const priceRequest = await axios.post(
        "/api/v1/calculate/price",
        {
          productType: item.productType,
          sku: item.sku,
          qty: item.qty,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      state.cart.cartItems[matchIndex] = {
        ...state.cart.cartItems[matchIndex],
        qty: item.qty,
        price: priceRequest.data.price,
        qtyOnePrice: priceRequest.data.qtyOnePrice,
      };

      localStorage.setItem("cartItems", JSON.stringify(state.cart.cartItems));

      dispatch({
        type: CART_UPDATE_ITEM_QTY_SUCCESS,
        payload: { data: state.cart.cartItems },
      });
    }
  };

export const removeFromCart =
  (item, isLoggedIn) => async (dispatch, getState) => {
    const state = getState();

    dispatch({ type: CART_REMOVE_ITEM_REQUEST });

    if (isLoggedIn === true) {
      try {
        const res = await axios.post(
          "/api/v1/cart/remove",
          {
            productType: item.productType,
            ...(item.productType === "mountingKit" && {
              sku: item.sku,
            }),
            ...(item.productType === "customMountingKit" && {
              _id: item._id,
            }),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({ type: CART_REMOVE_ITEM_SUCCESS, payload: res });

        localStorage.setItem("cartItems", JSON.stringify(res.data));
      } catch (error) {
        dispatch({ type: CART_REMOVE_ITEM_FAIL, payload: error });
      }
    } else if (isLoggedIn === false) {
      try {
        const updated = state.cart.cartItems.filter((x) => x.sku !== item.sku);

        localStorage.setItem("cartItems", JSON.stringify(updated));

        dispatch({
          type: CART_REMOVE_ITEM_SUCCESS,
          payload: { data: updated },
        });
      } catch (error) {
        dispatch({
          type: CART_REMOVE_ITEM_FAIL,
          payload: error,
        });
      }
    }
  };

export const validateCoupon =
  (couponCode, subtotal, totalCartItems) => async (dispatch) => {
    try {
      dispatch({
        type: VALIDATE_COUPON_REQUEST,
      });

      const { executeRecaptcha } = useGoogleReCaptcha();
      const token = await executeRecaptcha();

      const res = await axios.post(
        `/api/v1/coupons/validate`,
        {
          couponCode: couponCode,
          cartSubtotal: subtotal,
          totalCartItems: totalCartItems,
          reCaptchaToken: token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: VALIDATE_COUPON_SUCCESS,
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: VALIDATE_COUPON_FAIL,
        payload: error,
      });
    }
  };

// export const addCoupon =
//   (couponCode, subtotal, totalCartItems, isLoggedIn) => async (dispatch) => {
//     if (isLoggedIn === true) {
//       try {
//         dispatch({ type: CART_ADD_COUPON_REQUEST });

//         const res = await axios.post(
//           `/api/v1/cart/coupon/add`,
//           {
//             couponCode: couponCode,
//             cartSubtotal: subtotal,
//             totalCartItems: totalCartItems,
//           },
//           {
//             headers: {
//               'Content-Type': 'application/json',
//             },
//           }
//         );

//         dispatch({
//           type: CART_ADD_COUPON_SUCCESS,
//           payload: {
//             isValid: res.data.isValid,
//             couponCode: res.data.couponCode,
//             discount: res.data.discount,
//             message: res.data.message,
//             status: res.status,
//           },
//         });

//         localStorage.setItem(
//           'couponInfo',
//           JSON.stringify({
//             isValid: res.data.isValid,
//             couponCode: res.data.couponCode,
//             discount: res.data.discount,
//           })
//         );
//       } catch (error) {
//         dispatch({ type: CART_ADD_COUPON_FAIL, payload: error });
//       }
//     } else if (isLoggedIn === false) {
//       try {
//         dispatch({ type: CART_ADD_COUPON_REQUEST });

//         const res = await axios.post(
//           `/api/v1/coupons/validate`,
//           {
//             couponCode: couponCode,
//             cartSubtotal: subtotal,
//             totalCartItems: totalCartItems,
//           },
//           {
//             headers: {
//               'Content-Type': 'application/json',
//             },
//           }
//         );

//         dispatch({
//           type: CART_ADD_COUPON_SUCCESS,
//           payload: {
//             isValid: res.data.isValid,
//             couponCode: res.data.couponCode,
//             discount: res.data.discount,
//             message: res.data.message,
//             status: res.status,
//           },
//         });

//         localStorage.setItem(
//           'couponInfo',
//           JSON.stringify({
//             isValid: res.data.isValid,
//             couponCode: res.data.couponCode,
//             discount: res.data.discount,
//           })
//         );
//       } catch (error) {
//         dispatch({
//           type: CART_ADD_COUPON_FAIL,
//           payload: error,
//         });
//       }
//     }
//   };

export const addCoupon =
  (couponCode, subtotal, totalCartItems) => async (dispatch) => {
    try {
      dispatch({ type: CART_ADD_COUPON_REQUEST });

      // const { executeRecaptcha } = useGoogleReCaptcha();
      // const token = await executeRecaptcha();

      const res = await axios.post(
        `/api/v1/cart/coupon/add`,
        {
          couponCode: couponCode,
          cartSubtotal: subtotal,
          totalCartItems: totalCartItems,
          //reCaptchaToken: token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      localStorage.setItem(
        "couponInfo",
        JSON.stringify({
          isValid: res.data.isValid,
          couponCode: res.data.couponCode,
          discount: res.data.discount,
        })
      );

      dispatch({
        type: CART_ADD_COUPON_SUCCESS,
        payload: res,
      });
    } catch (error) {
      dispatch({ type: CART_ADD_COUPON_FAIL, payload: error });
    }
  };

export const removeCoupon = (isLoggedOn) => async (dispatch) => {
  dispatch({ type: CART_REMOVE_COUPON_REQUEST });

  if (isLoggedOn === true) {
    try {
      const res = await axios.delete("/api/v1/cart/coupon/remove");

      dispatch({ type: CART_REMOVE_COUPON_SUCCESS, payload: res });

      localStorage.removeItem("couponInfo");
    } catch (error) {
      dispatch({ type: CART_REMOVE_COUPON_FAIL, payload: error });
    }
  } else if (isLoggedOn === false) {
    localStorage.removeItem("couponInfo");

    dispatch({
      type: CART_REMOVE_COUPON_SUCCESS,
      payload: {
        success: true,
        message: "Coupon successfully removed.",
      },
    });
  }
};

export const validatePreferredCustomer = () => async (dispatch) => {
  try {
    dispatch({
      type: VALIDATE_PC_REQUEST,
    });

    const res = await axios.get(`/api/v1/preferredCustomers/validate`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: VALIDATE_PC_SUCCESS,
      payload: res,
    });

    localStorage.setItem("preferredCustomer", JSON.stringify(res.data));
  } catch (error) {
    dispatch({
      type: VALIDATE_PC_FAIL,
      payload: error,
    });
  }
};

export const getUpsShippingRates = (values) => async (dispatch) => {
  try {
    dispatch({ type: UPS_SHIPPING_RATES_REQUEST });

    const res = await axios.post("/api/v1/cart/shipping/rates", values, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({ type: UPS_SHIPPING_RATES_SUCCESS, payload: res });
  } catch (error) {
    dispatch({ type: UPS_SHIPPING_RATES_FAIL, payload: error });
  }
};

export const getShippingPrice = (subtotal) => async (dispatch) => {
  try {
    dispatch({ type: GET_SHIPPING_PRICE_REQUEST });

    const res = await axios.post(
      "/api/v1/calculate/shipping",
      { subtotal: subtotal },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({ type: GET_SHIPPING_PRICE_SUCCESS, payload: res });
  } catch (error) {
    dispatch({ type: GET_SHIPPING_PRICE_FAIL, payload: error });
  }
};

export const getTaxRates = () => async (dispatch) => {
  try {
    dispatch({ type: TAX_RATES_REQUEST });

    const res = await axios.get("/api/v1/taxRates");

    dispatch({ type: TAX_RATES_SUCCESS, payload: res });
  } catch (error) {
    dispatch({ type: TAX_RATES_FAIL, payload: error });
  }
};

export const isTaxExempt = () => (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: IS_TAX_EXEMPT,
    payload: state.userDetails.user.isTaxExempt,
  });
};

export const moveToSavedItems = (item) => async (dispatch) => {
  try {
    dispatch({ type: MOVE_TO_SAVED_ITEMS_REQUEST });

    const res = await axios.post("api/v1/cart/moveToSavedItems", item, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({ type: MOVE_TO_SAVED_ITEMS_SUCCESS, payload: res });

    localStorage.setItem("cartItems", JSON.stringify(res.data.cartItems));
    localStorage.setItem("savedItems", JSON.stringify(res.data.savedItems));
  } catch (error) {
    dispatch({ type: MOVE_TO_SAVED_ITEMS_FAIL, payload: error });
  }
};

export const mergeCart = (localCart) => async (dispatch) => {
  try {
    dispatch({ type: CART_MERGE_REQUEST });

    const res = await axios.post("/api/v1/cart/merge", localCart, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.data.coupon) {
      dispatch(
        addCoupon(
          res.data.coupon.code,
          res.data.cart.cartItems
            .reduce((acc, item) => acc + item.qty * item.price, 0)
            .toFixed(2),
          res.data.cart.cartItems.reduce((acc, item) => acc + item.qty, 0)
        )
      );
    }

    dispatch({ type: CART_MERGE_SUCCESS, payload: res });

    localStorage.setItem("cartItems", JSON.stringify(res.data.cartItems));
    localStorage.setItem("savedItems", JSON.stringify(res.data.savedItems));
  } catch (error) {
    dispatch({ type: CART_MERGE_FAIL, payload: error });
  }
};

export const updateCart = (cartItems) => async (dispatch, getState) => {
  const state = getState();
  try {
    dispatch({ type: CART_UPDATE_REQUEST });

    // const { executeRecaptcha } = useGoogleReCaptcha();
    // const token = await executeRecaptcha();

    const res = await axios.post(
      "/api/v1/cart/update",
      {
        cartItems: cartItems,
        // reCaptchaToken: token
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (state.cart.coupon.couponCode) {
      dispatch(
        addCoupon(
          state.cart.coupon.couponCode,
          state.cart.totals.subtotal,
          state.cart.cartItemsCount
        )
      );
    }

    dispatch({ type: CART_UPDATE_SUCCESS, payload: res });

    // Set updated cart to local storage
    localStorage.setItem("cartItems", JSON.stringify(res.data.cartItems));
  } catch (error) {
    dispatch({ type: CART_UPDATE_FAIL, payload: error });
  }
};
