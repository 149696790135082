const tm = {
  XOMOX: 'r',
  Apollo: 'r',
  Jamesbury: 'tm',
  Sharpe: 'r',
  SVF: 'r',
  Keystone: 'r',
  Hytork: 'tm',
  'Air Torque': 'tm',
  Bettis: 'tm',
  WKM: 'r',
};

export default tm;
