import React from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { customStyles5 } from "../../styles/selectorStyles";
import {
  SLKBD_ACTUATOR_DRIVE_DEPTH_UNIT,
  SLKBD_ACTUATOR_DRIVE_DEPTH_VALUE,
  SLKBD_ACTUATOR_DRIVE_DIAMETER_UNIT,
  SLKBD_ACTUATOR_DRIVE_DIAMETER_VALUE,
  SLKBD_ACTUATOR_DRIVE_KEY_HEIGHT_UNIT,
  SLKBD_ACTUATOR_DRIVE_KEY_HEIGHT_VALUE,
  SLKBD_ACTUATOR_DRIVE_KEY_WIDTH_UNIT,
  SLKBD_ACTUATOR_DRIVE_KEY_WIDTH_VALUE,
  SLKBD_ACTUATOR_DRIVE_SQUARE_UNIT,
  SLKBD_ACTUATOR_DRIVE_SQUARE_VALUE,
  SLKBD_ACTUATOR_DRIVE_WIDTH_UNIT,
  SLKBD_ACTUATOR_DRIVE_WIDTH_VALUE,
} from "../../redux/constants";
import { unitOptions } from "../../utils/helpers";

const ActuatorDriveDimensions = () => {
  const dispatch = useDispatch();

  // bring linkage kit selections in from redux state
  const actuator = useSelector((state) => state.selectLinkageKitByDimensions.actuator);

  return (
    <>
      <div className="slkbd p-4">
        <div className="d-flex flex-column">
          {actuator.drive.type === "parallelSquare" || actuator.drive.type === "diagonalSquare" ? (
            <>
              <Form.Group className="ml-auto">
                <div className="d-flex">
                  <Form.Label>Square Width</Form.Label>
                  <Form.Control
                    type="text"
                    className="split-input"
                    value={actuator.drive.square.value}
                    onChange={(e) =>
                      dispatch({
                        type: SLKBD_ACTUATOR_DRIVE_SQUARE_VALUE,
                        payload: e.target.value,
                      })
                    }
                    placeholder={"Enter Mounting PCD"}
                  />
                  <Select
                    options={unitOptions}
                    isSearchable={false}
                    styles={customStyles5}
                    placeholder={actuator.drive.square.unit}
                    value={actuator.drive.square.unit}
                    onChange={(value) =>
                      dispatch({
                        type: SLKBD_ACTUATOR_DRIVE_SQUARE_UNIT,
                        payload: value.value,
                      })
                    }
                  />
                </div>
              </Form.Group>

              <Form.Group className="ml-auto">
                <div className="d-flex">
                  <Form.Label>Drive Depth</Form.Label>
                  <Form.Control
                    type="text"
                    className="split-input"
                    value={actuator.drive.depth.value}
                    onChange={(e) =>
                      dispatch({
                        type: SLKBD_ACTUATOR_DRIVE_DEPTH_VALUE,
                        payload: e.target.value,
                      })
                    }
                    placeholder={"Enter Mounting Holes Diameter"}
                  />
                  <Select
                    options={unitOptions}
                    isSearchable={false}
                    styles={customStyles5}
                    placeholder={actuator.drive.depth.unit}
                    value={actuator.drive.depth.unit}
                    onChange={(value) =>
                      dispatch({
                        type: SLKBD_ACTUATOR_DRIVE_DEPTH_UNIT,
                        payload: value.value,
                      })
                    }
                  />
                </div>
              </Form.Group>
            </>
          ) : actuator.drive.type === "doubleD" ? (
            <>
              <Form.Group className="ml-auto">
                <div className="d-flex">
                  <Form.Label>Drive Diameter</Form.Label>
                  <Form.Control
                    type="text"
                    className="split-input"
                    value={actuator.drive.diameter.value}
                    onChange={(e) =>
                      dispatch({
                        type: SLKBD_ACTUATOR_DRIVE_DIAMETER_VALUE,
                        payload: e.target.value,
                      })
                    }
                  />
                  <Select
                    options={unitOptions}
                    isSearchable={false}
                    styles={customStyles5}
                    placeholder={actuator.drive.diameter.unit}
                    value={actuator.drive.diameter.unit}
                    onChange={(value) =>
                      dispatch({
                        type: SLKBD_ACTUATOR_DRIVE_DIAMETER_UNIT,
                        payload: value.value,
                      })
                    }
                  />
                </div>
              </Form.Group>

              <Form.Group className="ml-auto">
                <div className="d-flex">
                  <Form.Label>Drive Width</Form.Label>
                  <Form.Control
                    type="text"
                    className="split-input"
                    value={actuator.drive.width.value}
                    onChange={(e) =>
                      dispatch({
                        type: SLKBD_ACTUATOR_DRIVE_WIDTH_VALUE,
                        payload: e.target.value,
                      })
                    }
                  />
                  <Select
                    options={unitOptions}
                    isSearchable={false}
                    styles={customStyles5}
                    placeholder={actuator.drive.width.unit}
                    value={actuator.drive.width.unit}
                    onChange={(value) =>
                      dispatch({
                        type: SLKBD_ACTUATOR_DRIVE_WIDTH_UNIT,
                        payload: value.value,
                      })
                    }
                  />
                </div>
              </Form.Group>

              <Form.Group className="ml-auto">
                <div className="d-flex">
                  <Form.Label>Drive Depth</Form.Label>
                  <Form.Control
                    type="text"
                    className="split-input"
                    value={actuator.drive.depth.value}
                    onChange={(e) =>
                      dispatch({
                        type: SLKBD_ACTUATOR_DRIVE_DEPTH_VALUE,
                        payload: e.target.value,
                      })
                    }
                    placeholder={"Enter Mounting Holes Diameter"}
                  />
                  <Select
                    options={unitOptions}
                    isSearchable={false}
                    styles={customStyles5}
                    placeholder={actuator.drive.depth.unit}
                    value={actuator.drive.depth.unit}
                    onChange={(value) =>
                      dispatch({
                        type: SLKBD_ACTUATOR_DRIVE_DEPTH_UNIT,
                        payload: value.value,
                      })
                    }
                  />
                </div>
              </Form.Group>
            </>
          ) : (
            actuator.drive.type === "roundWithKeyway" && (
              <>
                <Form.Group className="ml-auto">
                  <div className="d-flex">
                    <Form.Label>Diameter</Form.Label>
                    <Form.Control
                      type="text"
                      className="split-input"
                      value={actuator.drive.diameter.value}
                      onChange={(e) =>
                        dispatch({
                          type: SLKBD_ACTUATOR_DRIVE_DIAMETER_VALUE,
                          payload: e.target.value,
                        })
                      }
                    />
                    <Select
                      options={unitOptions}
                      isSearchable={false}
                      styles={customStyles5}
                      placeholder={actuator.drive.diameter.unit}
                      value={actuator.drive.diameter.unit}
                      onChange={(value) =>
                        dispatch({
                          type: SLKBD_ACTUATOR_DRIVE_DIAMETER_UNIT,
                          payload: value.value,
                        })
                      }
                    />
                  </div>
                </Form.Group>

                <Form.Group className="ml-auto">
                  <div className="d-flex">
                    <Form.Label>Depth</Form.Label>
                    <Form.Control
                      type="text"
                      className="split-input"
                      value={actuator.drive.depth.value}
                      onChange={(e) =>
                        dispatch({
                          type: SLKBD_ACTUATOR_DRIVE_DEPTH_VALUE,
                          payload: e.target.value,
                        })
                      }
                    />
                    <Select
                      options={unitOptions}
                      isSearchable={false}
                      styles={customStyles5}
                      placeholder={actuator.drive.depth.unit}
                      value={actuator.drive.depth.unit}
                      onChange={(value) =>
                        dispatch({
                          type: SLKBD_ACTUATOR_DRIVE_DEPTH_UNIT,
                          payload: value.value,
                        })
                      }
                    />
                  </div>
                </Form.Group>

                <Form.Group className="ml-auto">
                  <div className="d-flex">
                    <Form.Label>Key Width</Form.Label>
                    <Form.Control
                      type="text"
                      className="split-input"
                      value={actuator.drive.key.width.value}
                      onChange={(e) =>
                        dispatch({
                          type: SLKBD_ACTUATOR_DRIVE_KEY_WIDTH_VALUE,
                          payload: e.target.value,
                        })
                      }
                    />
                    <Select
                      options={unitOptions}
                      isSearchable={false}
                      styles={customStyles5}
                      placeholder={actuator.drive.key.width.unit}
                      value={actuator.drive.key.width.unit}
                      onChange={(value) =>
                        dispatch({
                          type: SLKBD_ACTUATOR_DRIVE_KEY_WIDTH_UNIT,
                          payload: value.value,
                        })
                      }
                    />
                  </div>
                </Form.Group>

                <Form.Group className="ml-auto">
                  <div className="d-flex">
                    <Form.Label>Key Height</Form.Label>
                    <Form.Control
                      type="text"
                      className="split-input"
                      value={actuator.drive.key.height.value}
                      onChange={(e) =>
                        dispatch({
                          type: SLKBD_ACTUATOR_DRIVE_KEY_HEIGHT_VALUE,
                          payload: e.target.value,
                        })
                      }
                    />
                    <Select
                      options={unitOptions}
                      isSearchable={false}
                      styles={customStyles5}
                      placeholder={actuator.drive.key.height.unit}
                      value={actuator.drive.key.height.unit}
                      onChange={(value) =>
                        dispatch({
                          type: SLKBD_ACTUATOR_DRIVE_KEY_HEIGHT_UNIT,
                          payload: value.value,
                        })
                      }
                    />
                  </div>
                </Form.Group>
              </>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default ActuatorDriveDimensions;
