import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilSpeedometer,
  cilSettings,
  cilInbox,
  cilPeople,
  cilTags,
  cilCheck,
} from "@coreui/icons";
import { CNavGroup, CNavItem } from "@coreui/react";

const _nav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/admin/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Kit Builder",
    to: "/admin/build-kit",
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "Orders",
    to: "/admin/orders",
    icon: <CIcon icon={cilInbox} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Orders",
        to: "/admin/orders",
      },
      {
        component: CNavItem,
        name: "Abandoned Carts",
        to: "/admin/abandoned-carts",
      },
    ],
  },
  {
    component: CNavItem,
    name: "Inbox",
    to: "/admin/customer-messages",
    icon: <CIcon icon={cilInbox} customClassName="nav-icon" />,
    // badge: {
    //   color: "info",
    //   text: "NEW",
    // },
  },
  {
    component: CNavItem,
    name: "Quote Requests",
    to: "/admin/quote-requests",
    icon: <CIcon icon={cilInbox} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Customers",
    to: "/admin/customers",
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Preferred Customers",
    to: "/admin/preferred-customers",
    icon: <CIcon icon={cilCheck} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Early Access Users",
    to: "/admin/early-access-users",
    icon: <CIcon icon={cilCheck} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Coupons",
    to: "/admin/coupons",
    icon: <CIcon icon={cilTags} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "Database",
    to: "/admin/database/",
    icon: <CIcon icon={cilInbox} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Valves",
        to: "/admin/database/valves",
      },
      {
        component: CNavItem,
        name: "Actuators",
        to: "/admin/database/actuators",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Settings",
    to: "/admin/settings/",
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Settings",
        to: "/admin/settings",
      },
      {
        component: CNavItem,
        name: "Manufacture Rates",
        to: "/admin/manufacture-rates",
      },
      {
        component: CNavItem,
        name: "Material Pricing",
        to: "/admin/material-pricing",
      },
    ],
  },
];

export default _nav;
