import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  getShippingAddresses,
  getUserAuthStatus,
  softLogout,
} from "../../redux/actions/userActions";

import AccountNavigation from "../../components/account/AccountNavigation";
import LoadingWidget from "../../components/LoadingWidget";
import VerifyAccount from "../../components/VerifyAccount";
import { variables } from "../../config";
import { useGetAddressesQuery, useGetUserDetailsQuery } from "../../redux/api/userApi";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AccountAddressesScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const query = useQuery();

  const user = useGetUserDetailsQuery();

  const addresses = useGetAddressesQuery({}, { refetchOnMountOrArgChange: true });

  return (
    <>
      <Helmet>
        <title>Account | {variables.WEBSITE_NAME}</title>
        <link rel="canonical" href={`${variables.FULL_URL}/account/addresses`} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {user.isLoading || addresses.isLoading ? (
        <LoadingWidget />
      ) : (
        <>
          <div className="block-space block-space--layout--after-header"></div>
          <div className="block">
            <div className="container container--max--xl">
              {user.isSuccess && user?.data?.isVerified === false ? (
                <div className="row">
                  <div className="col">
                    <VerifyAccount />
                  </div>
                </div>
              ) : null}
              <div className="row">
                <div className="col-12 col-lg-3">
                  <AccountNavigation active="addresses" />
                </div>
                {addresses?.data ? (
                  <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                    {query.get("msg") === "add-address-success" ? (
                      <div className="alert alert-success">Address added successfully.</div>
                    ) : query.get("msg") === "delete-address-success" ? (
                      <div className="alert alert-success">Address deleted successfully.</div>
                    ) : query.get("msg") === "update-address-success" ? (
                      <div className="alert alert-success">Address updated successfully.</div>
                    ) : (
                      query.get("msg") === "address-not-found" && (
                        <div className="alert alert-info">Address not found.</div>
                      )
                    )}
                    <div className="addresses-list">
                      <Link
                        to="/account/addresses/add"
                        className="addresses-list__item addresses-list__item--new"
                      >
                        <div className="addresses-list__plus"></div>
                        <div className="btn btn-secondary btn-sm">Add New</div>
                      </Link>
                      <div className="addresses-list__divider"></div>
                      {addresses?.data?.map((address) => (
                        <>
                          <div className="addresses-list__item card address-card">
                            {address.default && (
                              <div className="address-card__badge tag-badge tag-badge--theme">
                                Default
                              </div>
                            )}
                            <div className="address-card__body">
                              <div className="address-card__name">{`${address.firstName} ${address.lastName}`}</div>
                              <div className="address-card__row">
                                {address.company && (
                                  <>
                                    {address.company} <br />
                                  </>
                                )}
                                {address.street2 ? (
                                  <>
                                    {`${address.street} ${address.street2}`} <br />
                                  </>
                                ) : (
                                  <>
                                    {address.street} <br />
                                  </>
                                )}
                                {`${address.city}, ${address.state}, ${address.postalCode}`}
                                <br />
                                {address.country}
                              </div>
                              <div className="address-card__row">
                                <div className="address-card__row-title">Phone Number</div>
                                <div className="address-card__row-content">{address.phone}</div>
                              </div>
                              <div className="address-card__footer">
                                <Link
                                  className="btn btn-link btn-sm p-0"
                                  to={`/account/addresses/edit/${address._id}`}
                                >
                                  Edit Address
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="addresses-list__divider"></div>
                        </>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="block-space block-space--layout--before-footer"></div>
        </>
      )}
    </>
  );
};

export default AccountAddressesScreen;
