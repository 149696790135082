import React, { useEffect } from "react";
import { Form, Button, Alert, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import LoadingWidget from "../../components/LoadingWidget";
import { getUserDetails, updateUserProfile } from "../../redux/actions/userActions";

import AccountNavigation from "../../components/account/AccountNavigation";
import { Helmet } from "react-helmet";
import { variables } from "../../config";
import { UpdateUserProfileValidationSchema } from "../../validation";
import VerifyAccount from "../../components/VerifyAccount";
import { useNavigate } from "react-router-dom";
import { useGetUserDetailsQuery, useUpdateUserProfileMutation } from "../../redux/api/userApi";

const AccountProfileScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useGetUserDetailsQuery();
  const [updateUser, updateUserResult] = useUpdateUserProfileMutation();

  useEffect(() => {
    if (updateUserResult.isSuccess) {
      user.refetch();
    }
  }, [updateUserResult]);

  return (
    <>
      <Helmet>
        <title>Account | {variables.WEBSITE_NAME}</title>
        <link rel="canonical" href={`${variables.FULL_URL}/account/profile`} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {user.isLoading ? (
        <LoadingWidget />
      ) : user.isSuccess ? (
        <>
          <div className="block-space block-space--layout--after-header"></div>
          <div className="block">
            <div className="container container--max--xl">
              {user?.data?.isVerified === false ? (
                <div className="row">
                  <div className="col">
                    <VerifyAccount />
                  </div>
                </div>
              ) : null}
              <div className="row">
                <div className="col-12 col-lg-3">
                  <AccountNavigation active="profile" />
                </div>
                <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                  <div className="card">
                    <div className="card-header">
                      <h5>Edit Profile</h5>
                    </div>
                    <div className="card-divider"></div>
                    <div className="card-body card-body--padding--2">
                      <div className="row no-gutters">
                        <div className="col-12 col-lg-7 col-xl-6">
                          {updateUserResult.isError ? (
                            <Alert variant="danger">{updateUserResult.error.data.message}</Alert>
                          ) : null}
                          {updateUserResult.isSuccess ? (
                            <Alert variant="success">{updateUserResult?.data?.message}</Alert>
                          ) : null}
                          <Formik
                            validationSchema={UpdateUserProfileValidationSchema}
                            onSubmit={async (values) => {
                              await updateUser({
                                firstName: values.firstName,
                                lastName: values.lastName,
                                company: values.company,
                                jobTitle: values.jobTitle,
                                phone: values.phone,
                                email: user?.data?.email,
                                promotionalEmails: values.promotionalEmails,
                              });
                            }}
                            initialValues={{
                              firstName: user?.data?.user?.firstName
                                ? user?.data?.user?.firstName
                                : "",
                              lastName: user?.data?.user?.lastName
                                ? user?.data?.user?.lastName
                                : "",
                              company: user?.data?.user?.company ? user?.data?.user?.company : "",
                              jobTitle: user?.data?.user?.jobTitle
                                ? user?.data?.user?.jobTitle
                                : "",
                              phone: user?.data?.user?.phone ? user?.data?.user?.phone : "",
                              email: user?.data?.user?.email ? user?.data?.user?.email : "",
                              promotionalEmails: user?.data?.user?.emailPreferences
                                ?.promotionalEmails
                                ? user?.data?.user?.emailPreferences.promotionalEmails
                                : false,
                            }}
                          >
                            {({
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              values,
                              touched,
                              isValid,
                              errors,
                              isSubmitting,
                            }) => (
                              <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group controlId="first-name">
                                  <Form.Label>First Name</Form.Label>
                                  <Form.Control
                                    name="firstName"
                                    type="text"
                                    placeholder="Enter first name"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    isInvalid={!!errors.firstName && touched.firstName}
                                    disabled={updateUserResult.isLoading}
                                  ></Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    {errors.firstName}
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="last-name">
                                  <Form.Label>Last Name</Form.Label>
                                  <Form.Control
                                    name="lastName"
                                    type="text"
                                    placeholder="Enter last name"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    isInvalid={!!errors.lastName && touched.lastName}
                                    disabled={updateUserResult.isLoading}
                                  ></Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    {errors.lastName}
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="company">
                                  <Form.Label>Company</Form.Label>
                                  <Form.Control
                                    name="company"
                                    type="text"
                                    placeholder="Enter company"
                                    value={values.company}
                                    onChange={handleChange}
                                    isInvalid={!!errors.company && touched.company}
                                    disabled={updateUserResult.isLoading}
                                  ></Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    {errors.company}
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="job-title">
                                  <Form.Label>Job Title</Form.Label>
                                  <Form.Control
                                    name="jobTitle"
                                    type="text"
                                    placeholder="Enter job title"
                                    value={values.jobTitle}
                                    onChange={handleChange}
                                    isInvalid={!!errors.jobTitle && touched.jobTitle}
                                    disabled={updateUserResult.isLoading}
                                  ></Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    {errors.jobTitle}
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="phone">
                                  <Form.Label>Phone</Form.Label>
                                  <Form.Control
                                    name="phone"
                                    type="phone"
                                    placeholder="Enter phone number"
                                    value={values.phone}
                                    onChange={handleChange}
                                    isInvalid={!!errors.phone && touched.phone}
                                    disabled={updateUserResult.isLoading}
                                  ></Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    {errors.phone}
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="email">
                                  <Form.Label>Email Address</Form.Label>
                                  <Form.Control
                                    name="email"
                                    type="email"
                                    placeholder="Enter email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email && touched.email}
                                    disabled
                                  ></Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                  <div className="form-check">
                                    <span className="input-check form-check-input">
                                      <span className="input-check__body">
                                        <input
                                          className="input-check__input"
                                          type="checkbox"
                                          id="user-promotionals"
                                          name="promotionalEmails"
                                          checked={values.promotionalEmails}
                                          onChange={handleChange}
                                          isInvalid={
                                            !!errors.promotionalEmails && touched.promotionalEmails
                                          }
                                          disabled={updateUserResult.isLoading}
                                        />
                                        <span className="input-check__box"></span>
                                        <span className="input-check__icon">
                                          <svg width="9px" height="7px">
                                            <path d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z" />
                                          </svg>
                                        </span>
                                      </span>
                                    </span>
                                    <label className="form-check-label" htmlFor="user-promotionals">
                                      Send me occasional promotional emails.
                                    </label>
                                    {errors && (
                                      <div
                                        className={
                                          "invalid-feedback " +
                                          (errors.promotionalEmails &&
                                            touched.promotionalEmails &&
                                            "d-block")
                                        }
                                      >
                                        {errors.promotionalEmails}
                                      </div>
                                    )}
                                  </div>
                                </Form.Group>
                                <Form.Group className="mb-0">
                                  <Button
                                    type="submit"
                                    className="mt-3"
                                    disabled={updateUserResult.isLoading}
                                  >
                                    {updateUserResult.isLoading ? (
                                      <>
                                        Update
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                          className="ml-2"
                                        />
                                      </>
                                    ) : (
                                      <>Update</>
                                    )}
                                  </Button>
                                </Form.Group>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-space block-space--layout--before-footer"></div>
        </>
      ) : null}
    </>
  );
};

export default AccountProfileScreen;
