import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../../redux/api/authApi";
import { clearCart } from "../../redux/slices/cart";
import { clearUserDetails, setAuthStatus } from "../../redux/slices/user";

import { LogoutOutlined } from "@ant-design/icons";

const AccountNavigation = ({ active }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [logout, logoutResult] = useLogoutMutation();

  const onClickLogoutHandler = async () => {
    await logout();
    dispatch(clearCart());
    dispatch(clearUserDetails());
  };

  useEffect(() => {
    if (logoutResult.isSuccess) {
      dispatch(setAuthStatus(false));

      // Clear state of user details
      dispatch(clearUserDetails());

      // Clear state of cart items
      dispatch(clearCart());

      navigate("/");
    }
  }, [logoutResult]);

  return (
    <>
      <div className="account-nav flex-grow-1">
        <h4 className="account-nav__title">Account</h4>
        <ul className="account-nav__list">
          <li
            className={`account-nav__item ${
              active === "dashboard" && "account-nav__item--active"
            }`}
          >
            <Link to="/account/dashboard">Dashboard</Link>
          </li>
          <li
            className={`account-nav__item ${
              active === "profile" && "account-nav__item--active"
            }`}
          >
            <Link to="/account/profile">Edit Profile</Link>
          </li>
          <li
            className={`account-nav__item ${
              (active === "orderHistory" || active === "orderDetails") &&
              "account-nav__item--active"
            }`}
          >
            <Link to="/account/order-history">Order History</Link>
          </li>
          <li
            className={`account-nav__item ${
              active === "savedItems" && "account-nav__item--active"
            }`}
          >
            <Link to="/account/saved-items">Saved Items</Link>
          </li>
          {/* <li
            className={`account-nav__item ${
              active === "orderDetails" && "account-nav__item--active"
            }`}
          >
            <Link to="/account/order-details">Order Details</Link>
          </li> */}
          <li
            className={`account-nav__item ${
              active === "addresses" && "account-nav__item--active"
            }`}
          >
            <Link to="/account/addresses">Addresses</Link>
          </li>
          {/* {active === "edit-address" && (
            <li
              className={`account-nav__item ${
                active === "edit-address" && "account-nav__item--active"
              }`}
            >
              <Link to="/account/edit-address">Edit Address</Link>
            </li>
          )} */}
          <li
            className={`account-nav__item ${
              active === "password" && "account-nav__item--active"
            }`}
          >
            <Link to="/account/password">Password</Link>
          </li>
          <li
            className={`account-nav__item ${
              active === "reseller" && "account-nav__item--active"
            }`}
          >
            <Link to="/account/reseller">Reseller Status</Link>
          </li>
          <li className="account-nav__divider" role="presentation"></li>
          <li className="account-nav__item">
            <button
              onClick={onClickLogoutHandler}
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "6px",
              }}
            >
              <LogoutOutlined /> Sign Out
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default AccountNavigation;
