import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { Form, Spinner } from "react-bootstrap";
import LoadingWidget from "../../components/LoadingWidget";

import { states } from "../../arrays/states";

import AccountNavigation from "../../components/account/AccountNavigation";
import { addShippingAddress } from "../../redux/actions/userActions";

// Import Validation Schemas
import { AddAddressValidationSchema } from "../../validation";
import { Helmet } from "react-helmet";
import { variables } from "../../config";
import { Link, useNavigate } from "react-router-dom";
import { useAddAddressMutation, useGetAddressesQuery } from "../../redux/api/userApi";

const AccountAddAddressScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addresses = useGetAddressesQuery();

  const [addAddress, addAddressResult] = useAddAddressMutation();

  useEffect(() => {
    if (addAddressResult.isSuccess) {
      navigate("/account/addresses?msg=add-address-success");
    }
  }, [addAddressResult]);

  return (
    <>
      <Helmet>
        <title>Account | {variables.WEBSITE_NAME}</title>
        <link rel="canonical" href={`${variables.FULL_URL}/account/addresses/add`} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      {addresses.isLoading ? (
        <LoadingWidget />
      ) : (
        <>
          <div className="block-space block-space--layout--after-header"></div>
          <div className="block">
            <div className="container container--max--xl">
              <div className="row">
                <div className="col-12 col-lg-3">
                  <AccountNavigation active="addresses" />
                </div>
                <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                  <div className="card">
                    <div className="card-header">
                      <h5>Add a New Address</h5>
                    </div>
                    <div className="card-divider"></div>
                    <div className="card-body card-body--padding--2">
                      <div className="row no-gutters">
                        <div className="col-12 col-lg-10 col-xl-8">
                          <Formik
                            validationSchema={AddAddressValidationSchema}
                            onSubmit={async (values) => {
                              await addAddress({
                                firstName: values.firstName,
                                lastName: values.lastName,
                                company: values.company,
                                phone: values.phone,
                                street: values.street,
                                street2: values.street2,
                                city: values.city,
                                state: values.state,
                                postalCode: values.postalCode,
                                country: values.country,
                                default: values.setAsDefault,
                              });
                            }}
                            initialValues={{
                              firstName: "",
                              lastName: "",
                              company: "",
                              phone: "",
                              street: "",
                              street2: "",
                              city: "",
                              state: "",
                              postalCode: "",
                              country: "",
                              setAsDefault: addresses?.data?.length < 1 ? true : false,
                            }}
                          >
                            {({
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              values,
                              setValues,
                              setFieldValue,
                              touched,
                              isValid,
                              errors,
                              isSubmitting,
                            }) => (
                              <>
                                <Form noValidate onSubmit={handleSubmit}>
                                  <div className="form-row">
                                    <div className="col-md-6">
                                      <Form.Group controlId="first-name">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                          name="firstName"
                                          type="text"
                                          placeholder="Enter first name"
                                          value={values.firstName}
                                          onChange={handleChange}
                                          isInvalid={!!errors.firstName && touched.firstName}
                                          disabled={addAddressResult.isLoading}
                                        ></Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                          {errors.firstName}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                      <Form.Group controlId="last-name">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                          name="lastName"
                                          type="text"
                                          placeholder="Enter last name"
                                          value={values.lastName}
                                          onChange={handleChange}
                                          isInvalid={!!errors.lastName && touched.lastName}
                                          disabled={addAddressResult.isLoading}
                                        ></Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                          {errors.lastName}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </div>
                                  </div>
                                  <Form.Group controlId="company">
                                    <Form.Label>
                                      Company Name <span className="text-muted">(Optional)</span>
                                    </Form.Label>
                                    <Form.Control
                                      name="company"
                                      type="text"
                                      placeholder="Enter a company name"
                                      value={values.company}
                                      onChange={handleChange}
                                      isInvalid={!!errors.company && touched.company}
                                      disabled={addAddressResult.isLoading}
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.company}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group controlId="phone">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control
                                      name="phone"
                                      type="phone"
                                      placeholder="Enter phone number"
                                      value={values.phone}
                                      onChange={handleChange}
                                      isInvalid={!!errors.phone && touched.phone}
                                      disabled={addAddressResult.isLoading}
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.phone}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group controlId="street">
                                    <Form.Label>Street Address</Form.Label>
                                    <Form.Control
                                      name="street"
                                      type="text"
                                      placeholder="Enter a street address"
                                      value={values.street}
                                      onChange={handleChange}
                                      isInvalid={!!errors.street && touched.street}
                                      disabled={addAddressResult.isLoading}
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.street}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group controlId="street-2">
                                    <Form.Label>
                                      Apartment, suite, unit etc.{" "}
                                      <span className="text-muted">(Optional)</span>
                                    </Form.Label>
                                    <Form.Control
                                      name="street2"
                                      type="text"
                                      placeholder="Enter an apartment, suite, unit etc."
                                      value={values.street2}
                                      onChange={handleChange}
                                      isInvalid={!!errors.street2 && touched.street2}
                                      disabled={addAddressResult.isLoading}
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.street2}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group controlId="city">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                      name="city"
                                      type="text"
                                      placeholder="Enter a city"
                                      value={values.city}
                                      onChange={handleChange}
                                      isInvalid={!!errors.city && touched.city}
                                      disabled={addAddressResult.isLoading}
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.city}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group controlId="state">
                                    <Form.Label>State</Form.Label>
                                    <Form.Control
                                      as="select"
                                      name="state"
                                      value={values.state}
                                      onChange={handleChange}
                                      isInvalid={!!errors.state && touched.state}
                                      style={
                                        values.state === ""
                                          ? { color: "#6c757d" }
                                          : { color: "#262626" }
                                      }
                                      disabled={addAddressResult.isLoading}
                                    >
                                      <option value="" key="" disabled>
                                        Please Select a State
                                      </option>
                                      {states.map((x) => (
                                        <option value={x} key={x} style={{ color: "#262626" }}>
                                          {x}
                                        </option>
                                      ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.state}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group controlId="postalCode">
                                    <Form.Label>Postal Code</Form.Label>
                                    <Form.Control
                                      name="postalCode"
                                      type="text"
                                      placeholder="Enter a five-digit postal code"
                                      value={values.postalCode}
                                      onChange={handleChange}
                                      isInvalid={!!errors.postalCode && touched.postalCode}
                                      disabled={addAddressResult.isLoading}
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.postalCode}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group controlId="country">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control
                                      as="select"
                                      placeholder="Enter a country"
                                      value={values.country}
                                      onChange={handleChange}
                                      isInvalid={!!errors.country && touched.country}
                                      style={
                                        values.state === ""
                                          ? { color: "#6c757d" }
                                          : { color: "#262626" }
                                      }
                                      disabled={addAddressResult.isLoading}
                                    >
                                      <option value="" key="" disabled>
                                        Please Select a Country
                                      </option>
                                      <option value="United States" style={{ color: "#262626" }}>
                                        United States
                                      </option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.country}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <div className="form-group mt-3">
                                    <div className="form-check">
                                      <span className="input-check form-check-input">
                                        <span className="input-check__body">
                                          <input
                                            className="input-check__input"
                                            id="default-address"
                                            type="checkbox"
                                            name="setAsDefault"
                                            checked={values.setAsDefault}
                                            onChange={handleChange}
                                            disabled={addresses?.data?.length < 1}
                                          />
                                          <span className="input-check__box"></span>
                                          <span className="input-check__icon">
                                            <svg width="9px" height="7px">
                                              <path d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z" />
                                            </svg>
                                          </span>
                                        </span>
                                      </span>
                                      <label className="form-check-label" for="default-address">
                                        Set as my default address
                                      </label>
                                    </div>
                                  </div>
                                  <div className="form-group mb-0 pt-3 mt-3 d-flex flex-row-reverse">
                                    <button
                                      type="submit"
                                      className="btn btn-primary ml-2"
                                      disabled={addAddressResult.isLoading}
                                    >
                                      {addAddressResult.isLoading ? (
                                        <>
                                          Save
                                          <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="ml-2"
                                          />
                                        </>
                                      ) : (
                                        <>Save</>
                                      )}
                                    </button>
                                    <Link to="/account/addresses" className="btn btn-secondary">
                                      Cancel
                                    </Link>
                                  </div>
                                </Form>
                              </>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-space block-space--layout--before-footer"></div>
        </>
      )}
    </>
  );
};

export default AccountAddAddressScreen;
