import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { SLKBD_SELECT_ACTUATOR_DRIVE_TYPE } from "../../redux/constants";

const ActuatorDriveTypes = () => {
  const dispatch = useDispatch();

  const actuator = useSelector((state) => state.selectLinkageKitByDimensions.actuator);

  const actuatorDriveTypes = [
    {
      value: "parallelSquare",
      label: `Parallel Square`,
    },
    {
      value: "star",
      label: "Star",
    },
    {
      value: "diagonalSquare",
      label: "Diagonal Square",
    },
    {
      value: "doubleD",
      label: "Double D",
    },
    {
      value: "roundWithKeyway",
      label: "Round With Key Way",
    },
  ];

  return (
    <div className="slkbd w-100">
      <div className="product-form__row">
        <div className="product-form__control">
          <div className="input-radio-label">
            <div className="input-radio-label__list d-flex flex-col" style={{ columnGap: "1rem" }}>
              {actuatorDriveTypes.map((x) => (
                <label
                  key={x.value}
                  className="input-radio-label__item"
                  style={{ flexGrow: 1, flexBasis: 1 }}
                >
                  {`${x.label}`}
                  <input
                    type="radio"
                    name={x.label}
                    value={x.value}
                    onClick={(e) =>
                      dispatch({
                        type: SLKBD_SELECT_ACTUATOR_DRIVE_TYPE,
                        payload: e.target.value,
                      })
                    }
                    checked={actuator.drive.type === x.value}
                    className="input-radio-label__input"
                  />
                  <span
                    className="input-radio-label__title"
                    style={{
                      // background: `url(${doubleDThumbnail})`,
                      // backgroundSize: "calc(100% - 20px)",
                      // border: "none",
                      // width: "calc(100%)",
                      // height: "auto",
                      // paddingBottom: "100%",
                      // backgroundPositionX: "10px",
                      // backgroundPositionY: "10px",
                      // backgroundRepeat: "no-repeat",
                      textAlign: "center",
                    }}
                  >
                    {x.label}
                  </span>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActuatorDriveTypes;
