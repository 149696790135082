import {
  CART_DROPDOWN_OPEN,
  CART_DROPDOWN_CLOSE,
  ACCOUNT_DROPDOWN_OPEN,
  ACCOUNT_DROPDOWN_CLOSE,
  MOBILE_MENU_OPEN,
  MOBILE_MENU_CLOSE,
  CHECKOUT_EDIT_ADDRESS_MODAL_CLOSE,
  CHECKOUT_EDIT_ADDRESS_MODAL_OPEN,
  CHECKOUT_ADD_ADDRESS_MODAL_OPEN,
  CHECKOUT_ADD_ADDRESS_MODAL_CLOSE,
  ADDED_TO_CART_MODAL_OPEN,
  ADDED_TO_CART_MODAL_CLOSE,
  EMAIL_SIGNUP_MODAL_OPEN,
  EMAIL_SIGNUP_MODAL_CLOSE,
  CHANGE_SHIPPING_ADDRESS_SHOW,
  CHANGE_SHIPPING_ADDRESS_HIDE,
} from "../constants";

export const applicationReducer = (
  state = {
    accountDropdownOpen: false,
    cartDropdownOpen: false,
    mobileMenuOpen: false,
    checkoutEditShippingAddressModal: false,
    checkoutAddShippingAddressModal: false,
    addedToCartModal: false,
    emailSignupModal: false,
    changeShippingAddress: false,
  },
  action
) => {
  switch (action.type) {
    case ACCOUNT_DROPDOWN_OPEN:
      return {
        ...state,
        accountDropdownOpen: true,
      };
    case ACCOUNT_DROPDOWN_CLOSE:
      return {
        ...state,
        accountDropdownOpen: false,
      };
    case CART_DROPDOWN_OPEN:
      return {
        ...state,
        cartDropdownOpen: true,
      };
    case CART_DROPDOWN_CLOSE:
      return {
        ...state,
        cartDropdownOpen: false,
      };
    case MOBILE_MENU_OPEN:
      return {
        ...state,
        mobileMenuOpen: true,
      };
    case MOBILE_MENU_CLOSE:
      return {
        ...state,
        mobileMenuOpen: false,
      };
    case CHECKOUT_EDIT_ADDRESS_MODAL_OPEN:
      return {
        ...state,
        checkoutEditShippingAddressModal: true,
      };
    case CHECKOUT_EDIT_ADDRESS_MODAL_CLOSE:
      return {
        ...state,
        checkoutEditShippingAddressModal: false,
      };
    case CHECKOUT_ADD_ADDRESS_MODAL_OPEN:
      return {
        ...state,
        checkoutAddShippingAddressModal: true,
      };
    case CHECKOUT_ADD_ADDRESS_MODAL_CLOSE:
      return {
        ...state,
        checkoutAddShippingAddressModal: false,
      };
    case ADDED_TO_CART_MODAL_OPEN:
      return {
        ...state,
        addedToCartModal: true,
      };
    case ADDED_TO_CART_MODAL_CLOSE:
      return {
        ...state,
        addedToCartModal: false,
      };
    case EMAIL_SIGNUP_MODAL_OPEN:
      return {
        ...state,
        emailSignupModal: true,
      };
    case EMAIL_SIGNUP_MODAL_CLOSE:
      return {
        ...state,
        emailSignupModal: false,
      };
    case CHANGE_SHIPPING_ADDRESS_SHOW:
      return {
        ...state,
        changeShippingAddress: true,
      };
    case CHANGE_SHIPPING_ADDRESS_HIDE:
      return {
        ...state,
        changeShippingAddress: false,
      };
    default:
      return state;
  }
};
