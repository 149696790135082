import axios from "axios";
import {
  OPTIONS_LOCKOUT,
  OPTIONS_BRACKET_HEIGHT,
  OPTIONS_COMPONENTS,
  OPTIONS_QUANTITY,
  LINKAGE_KIT_PRICE_REQUEST,
  LINKAGE_KIT_PRICE_SUCCESS,
  LINKAGE_KIT_PRICE_FAIL,
  SELECTED_KIT_SKU,
  BRACKET_HEIGHTS_REQUEST,
  BRACKET_HEIGHTS_SUCCESS,
  BRACKET_HEIGHTS_FAIL,
  GET_KIT_WEIGHT_REQUEST,
  GET_KIT_WEIGHT_SUCCESS,
  GET_KIT_WEIGHT_FAIL,
  OPTIONS_USE_CUSTOM_DATA_SHEETS,
} from "../constants";

export const selectLockout = (value) => (dispatch) => {
  dispatch({
    type: OPTIONS_LOCKOUT,
    payload: value,
  });
};

export const selectBracketHeight = (bracketHeight) => (dispatch) => {
  dispatch({
    type: OPTIONS_BRACKET_HEIGHT,
    payload: bracketHeight,
  });
};

export const selectComponents = (component) => (dispatch) => {
  dispatch({
    type: OPTIONS_COMPONENTS,
    payload: component,
  });
};

export const selectUseCustomDataSheet = (value) => (dispatch) => {
  dispatch({
    type: OPTIONS_USE_CUSTOM_DATA_SHEETS,
    payload: value,
  });
};

export const selectQuantity = (qtyInput) => (dispatch) => {
  dispatch({
    type: OPTIONS_QUANTITY,
    payload: JSON.parse(qtyInput),
  });
};

export const setSku =
  (
    selectedValve,
    selectedActuator,
    lockout,
    components,
    selectedBracketHeight
  ) =>
  (dispatch) => {
    // generate an item SKU
    let sku = `${selectedValve.sku}-${selectedActuator.sku}`;

    if (components === "Bracket and Coupler") {
      if (lockout === true) {
        sku = `${sku}-K-L-${selectedBracketHeight}`;
      } else {
        sku = `${sku}-K-S-${selectedBracketHeight}`;
      }
    } else if (components === "Bracket Only") {
      if (lockout === true) {
        sku = `${sku}-B-L-${selectedBracketHeight}`;
      } else {
        sku = `${sku}-B-S-${selectedBracketHeight}`;
      }
    } else if (components === "Coupler Only") {
      if (lockout === true) {
        sku = `${sku}-C-L-${selectedBracketHeight}`;
      } else {
        sku = `${sku}-C-S-${selectedBracketHeight}`;
      }
    }

    dispatch({
      type: SELECTED_KIT_SKU,
      payload: sku,
    });
  };

export const fetchBracketHeights = (item) => async (dispatch) => {
  try {
    dispatch({ type: BRACKET_HEIGHTS_REQUEST });

    const { data } = await axios.post(
      "/api/v1/calculate/bracketHeights",
      {
        valve: item.valve,
        actuator: item.actuator,
        lockout: item.lockout,
        components: item.components,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({ type: BRACKET_HEIGHTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: BRACKET_HEIGHTS_FAIL, payload: error });
  }
};

export const fetchPrice = (productType, sku, qty) => async (dispatch) => {
  try {
    dispatch({
      type: LINKAGE_KIT_PRICE_REQUEST,
    });

    const { data } = await axios.post(
      "/api/v1/calculate/price",
      {
        productType: productType,
        sku: sku,
        qty: qty,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: LINKAGE_KIT_PRICE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LINKAGE_KIT_PRICE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchPriceByDimensions = (item, qty) => async (dispatch) => {
  try {
    dispatch({ type: LINKAGE_KIT_PRICE_REQUEST });

    const { data } = await axios.post(
      "/api/v1/calculate/price/byDimensions",
      {
        valve: item.valve,
        actuator: item.actuator,
        lockout: item.lockout,
        components: item.components,
        bracketHeight: item.bracketHeight,
        qty: qty,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({ type: LINKAGE_KIT_PRICE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: LINKAGE_KIT_PRICE_FAIL, payload: error });
  }
};

export const getKitWeight = (kit) => async (dispatch) => {
  try {
    dispatch({ type: GET_KIT_WEIGHT_REQUEST });

    const { data } = await axios.post("/api/v1/calculate/weight", kit, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({ type: GET_KIT_WEIGHT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_KIT_WEIGHT_FAIL, payload: error });
  }
};
