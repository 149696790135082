import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  CButton,
  CCol,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import DrawingPreview from "../../components/admin/DrawingPreview";
import LinkageKitSelector from "../../components/LinkageKitSelector";

const AdminLinkageKitSelector = () => {
  const linkageKitSelections = useSelector((state) => state.selectLinkageKit);
  const { drawingPreview } = linkageKitSelections;

  const [showDrawingPreview, setShowDrawingPreview] = useState(false);

  return (
    <>
      <CModal
        visible={showDrawingPreview}
        onDismiss={() => setShowDrawingPreview(false)}
        fullscreen
      >
        <CModalHeader>
          <CModalTitle>Drawing Preview</CModalTitle>
          <CButton
            color="close"
            onClick={() => setShowDrawingPreview(false)}
          ></CButton>
        </CModalHeader>
        <CModalBody style={{ background: "#000" }}>
          <DrawingPreview drawing={drawingPreview} />
        </CModalBody>
      </CModal>
      <CRow>
        <CCol xs={12}>
          <LinkageKitSelector />
        </CCol>
      </CRow>
    </>
  );
};

export default AdminLinkageKitSelector;
