import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Accordion, Card, Spinner } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";

import SelectValveModel from "./linkage_kit_selector/SelectValveModel";
import SelectionSummary from "../components/linkage_kit_selector/SelectionSummary";
import {
  LINKAGE_KIT_SELECTIONS_RESET,
  SET_LINKAGE_KIT_SELECTOR_STEP,
  SET_LINKAGE_KIT_SELECTOR_STEPS,
} from "../redux/constants";
import SearchBox from "./SearchBox";
import SkuSearchResults from "./linkage_kit_selector/SkuSearchResults";
import AdminButtons from "./linkage_kit_selector/AdminButtons";
import SelectValveChoices from "./linkage_kit_selector/SelectValveChoices";
import SelectActuatorModel from "./linkage_kit_selector/SelectActuatorModel";
import SelectActuatorChoices from "./linkage_kit_selector/SelectActuatorChoices";
import SelectActuatorISO5211 from "./linkage_kit_selector/SelectActuatorISO5211";
import ValveStemType from "./linkage_kit_selector/ValveStemType";
import ValveStemDimensions from "./linkage_kit_selector/ValveStemDimensions";
import ValveMountingType from "./linkage_kit_selector/ValveMountingType";
import ValveMountingDimensions from "./linkage_kit_selector/ValveMountingDimensions";
import ValveCustomName from "./linkage_kit_selector/ValveCustomName";
import ActuatorCustomName from "./linkage_kit_selector/ActuatorCustomName";
import ActuatorDriveTypes from "./linkage_kit_selector/ActuatorDriveTypes";
import ActuatorMountingTypes from "./linkage_kit_selector/ActuatorMountingTypes";
import ActuatorDriveDimensions from "./linkage_kit_selector/ActuatorDriveDimensions";
import ActuatorMountingDimensions from "./linkage_kit_selector/ActuatorMountingDimensions";
import LoadingWidget from "./LoadingWidget";
import { useGetLinkageKitBySkuQuery } from "../redux/api/linkageKitApi";

const LinkageKitSelector = ({ style }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  const [searchError, setSearchError] = useState(null);

  const [linkageKitDetails, setLinkageKitDetails] = useState({});

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  // const userLogout = useSelector((state) => state.userLogout);

  const [sku, setSku] = useState(null);
  const [queryString, setQueryString] = useState(
    query.get("sku") ? query.get("sku") : null
  );

  const qty =
    query.get("qty") && !isNaN(query.get("qty")) && query.get("qty") <= 100
      ? query.get("qty")
      : 1;

  // Trigger fetching linkage kit by sku if sku is the URL
  const getLinkageKitBySku = useGetLinkageKitBySkuQuery(queryString, {
    skip: !queryString,
    refetchOnMountOrArgChange: true,
  });

  // Sets tab to sku search results if the ural contains an sku
  const [activeTab, setActiveTab] = useState(
    sku ? "searchByPartNumber" : "searchByComponents"
  );

  const linkageKitSelections = useSelector((state) => state.selectLinkageKit);
  const {
    step,
    steps,
    valveSelectionType,
    actuatorSelectionType,
    selectedValve,
    selectedActuator,
  } = linkageKitSelections;

  const resetHandler = () => {
    dispatch({
      type: LINKAGE_KIT_SELECTIONS_RESET,
      payload: {
        step:
          !isEmpty(user) && user.isAdmin === true
            ? {
                value: "chooseValveSelectionType",
                label: "How Would You Like To Select Your Valve?",
              }
            : undefined,
        steps:
          !isEmpty(user) && user.isAdmin === true
            ? [
                {
                  value: "chooseValveSelectionType",
                  label: "How Would You Like To Select Your Valve?",
                },
                {
                  value: "selectValveByModel",
                  label: "Select Valve By Model",
                },
                {
                  value: "chooseActuatorSelectionType",
                  label: "How Would You Like To Select Your Actuator?",
                },
              ]
            : undefined,
      },
    });
  };

  const nextButtonHandler = () => {
    const curr = steps.findIndex((x) => x.value === step.value);
    dispatch({ type: SET_LINKAGE_KIT_SELECTOR_STEP, payload: steps[curr + 1] });
  };

  const backButtonHandler = () => {
    const curr = steps.findIndex((x) => x.value === step.value);
    dispatch({ type: SET_LINKAGE_KIT_SELECTOR_STEP, payload: steps[curr - 1] });
  };

  const resetButtonHandler = () => {
    resetHandler();
  };

  let disabled;

  if (step.value === "selectValveByModel") {
    if (isEmpty(selectedValve)) {
      disabled = true;
    } else {
      disabled = false;
    }
  } else if (
    step.value === "selectActuatorByModel" ||
    step.value === "selectActuatorByISO5211"
  ) {
    if (isEmpty(selectedActuator)) {
      disabled = true;
    } else {
      disabled = false;
    }
  } else if (
    step.value === "chooseValveSelectionType" ||
    step.value === "chooseActuatorSelectionType"
  ) {
    disabled = true;
  }

  useEffect(() => {
    resetHandler();
  }, []);

  useEffect(() => {
    if (queryString) {
      setActiveTab("searchByPartNumber");
    }
  }, [queryString]);

  useEffect(() => {
    if (getLinkageKitBySku.isSuccess) {
      if (getLinkageKitBySku?.data?.isValidSku) {
        navigate(`/build?sku=${encodeURIComponent(queryString.trim())}`);
        setSku(getLinkageKitBySku?.data?.kitDetails.sku);
        setLinkageKitDetails(getLinkageKitBySku?.data?.kitDetails);
      }
    }
  }, [getLinkageKitBySku]);

  return (
    <>
      <Accordion defaultActiveKey={activeTab} activeKey={activeTab}>
        <div
          style={{
            zIndex: "1",
            position: "relative",
            top: "-60px",

            boxShadow: "5px 5px 20px rgba(0,0,0,0.25)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              background: "rgb(255,255,255)",
              background:
                "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 50%)",
            }}
          >
            <Accordion.Toggle
              variant="light"
              eventKey="searchByComponents"
              onClick={() => {
                // changes current tab to 'Search by Valve and Actuator'
                setActiveTab("searchByComponents");
                // set step to valve
                dispatch({
                  type: SET_LINKAGE_KIT_SELECTOR_STEP,
                  payload: steps[0],
                });

                resetButtonHandler();

                // removes sku from URL`
                navigate("/build");
              }}
              style={{
                width: "calc(100% / 2)",
                background:
                  activeTab === "searchByComponents" ? "#fff" : "#f1f1f1",
                borderRadius:
                  activeTab === "searchByPartNumber"
                    ? "4px 4px 4px 0"
                    : "4px 4px 0 0",
                border: "none",
                borderRight:
                  activeTab !== "searchByComponents"
                    ? "1px solid #ddd"
                    : activeTab === "searchByComponents" && "none",
                borderBottom:
                  activeTab !== "searchByComponents"
                    ? "1px solid #ddd"
                    : activeTab === "searchByComponents" && "none",
                boxShadow:
                  activeTab !== "searchByComponents"
                    ? "inset -4px -4px 4px rgba(0,155,255,0.03)"
                    : "none",
              }}
            >
              <Card.Header
                className="text-center"
                style={{
                  padding: "1rem",
                  fontSize: ".95rem",
                  color:
                    activeTab === "searchByComponents" ? "#000" : "#7b7b7b",
                  fontWeight: "500",
                  lineHeight: "1.2rem",
                }}
              >
                <span>Search by Valve and Actuator</span>
              </Card.Header>
            </Accordion.Toggle>
            <Accordion.Toggle
              variant="light"
              eventKey="searchByPartNumber"
              style={{
                width: "calc(100% / 2)",
                background:
                  activeTab === "searchByPartNumber" ? "#fff" : "#f1f1f1",
                borderRadius:
                  activeTab === "searchByComponents"
                    ? "4px 4px 0 4px"
                    : activeTab === "inputDimensions"
                    ? "4px 4px 4px 0"
                    : "4px 4px 0 0",
                border: "none",
                borderRight:
                  activeTab === "inputDimensions" ? "1px solid #ddd" : "none",
                borderLeft:
                  activeTab === "searchByComponents"
                    ? "1px solid #ddd"
                    : "none",
                borderBottom:
                  activeTab !== "searchByPartNumber"
                    ? "1px solid #ddd"
                    : activeTab === "searchByPartNumber" && "none",
                boxShadow:
                  activeTab !== "searchByPartNumber"
                    ? "inset 3px -3px 6px rgba(0,0,0,0.03)"
                    : "none",
              }}
              onClick={() => setActiveTab("searchByPartNumber")}
            >
              <Card.Header
                className="text-center"
                style={{
                  padding: "1rem",
                  fontSize: ".95rem",
                  color:
                    activeTab === "searchByPartNumber" ? "#000" : "#7b7b7b",
                  fontWeight: "500",
                  lineHeight: "1.2rem",
                }}
              >
                <span>Search by Mounting Kit SKU</span>
              </Card.Header>
            </Accordion.Toggle>
          </div>

          <Card
            style={{
              boxShadow: "none",
            }}
          >
            <Accordion.Collapse eventKey="searchByPartNumber">
              <>
                <div className="my-2">
                  <Card.Body>
                    <SearchBox
                      refetch={getLinkageKitBySku.refetch}
                      queryString={queryString}
                      setQueryString={setQueryString}
                    />

                    <>
                      {getLinkageKitBySku.isLoading ||
                      getLinkageKitBySku.isFetching ? (
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                          style={{
                            marginTop: "40px",
                            marginBottom: "40px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block",
                          }}
                        ></Spinner>
                      ) : getLinkageKitBySku?.data?.isValidSku === false ? (
                        <div className="alert alert-danger mb-0">
                          Invalid Part Number
                        </div>
                      ) : !isEmpty(linkageKitDetails) ? (
                        <>
                          {/* <div className="alert alert-success alert mb-0">
                              Search Results for{" "}
                              <strong>
                                <i>{sku}</i>
                              </strong>
                            </div> */}
                          <div className="mt-4">
                            <SkuSearchResults
                              linkagekit={{ ...linkageKitDetails, qty: qty }}
                            />
                          </div>
                        </>
                      ) : null}
                    </>
                  </Card.Body>
                  {!isEmpty(user) && user.isAdmin === true && (
                    <Card.Footer>
                      <AdminButtons />
                    </Card.Footer>
                  )}
                </div>
              </>
            </Accordion.Collapse>
          </Card>
          <Card
            style={{
              boxShadow: "none",
            }}
          >
            <Accordion.Collapse eventKey="searchByComponents">
              <>
                <Card.Body>
                  <h4
                    className="py-3 mb-0"
                    style={{ fontWeight: "800" }}
                    id="progressbar-label"
                  >
                    {step.label}
                  </h4>
                  {isEmpty(user) ||
                    (isEmpty(user) && user.isAdmin === "false" && (
                      <div className="progress my-3">
                        <div
                          role="progressbar"
                          className="progress-bar"
                          style={{
                            width: `calc(100% / ${steps.length} * ${
                              steps.findIndex((x) => x.value === step) + 1
                            })`,
                          }}
                          aria-labelledby="progressbar-label"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          aria-valuenow={
                            (100 / steps.length) *
                              steps.findIndex((x) => x.value === step) +
                            1
                          }
                        />
                      </div>
                    ))}
                  <div>
                    <div
                      style={{
                        display: "flex",
                        position: "relative",
                        top: 0,
                        // paddingBottom: "100%",
                        minHeight: "150px",
                        height: "100%",
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <CSSTransition
                        in={step.value === "chooseValveSelectionType"}
                        timeout={{ appear: 300, enter: 300, exit: 0 }}
                        classNames="transition"
                        unmountOnExit
                      >
                        <SelectValveChoices steps={steps} step={step} />
                      </CSSTransition>
                      <CSSTransition
                        in={step.value === "selectValveByModel"}
                        timeout={{ appear: 300, enter: 300, exit: 0 }}
                        classNames="transition"
                        unmountOnExit
                      >
                        <SelectValveModel />
                      </CSSTransition>
                      <CSSTransition
                        in={step.value === "valveStemType"}
                        timeout={{ appear: 1000, enter: 1000, exit: 0 }}
                        classNames="transition"
                        unmountOnExit
                      >
                        <ValveStemType />
                      </CSSTransition>
                      <CSSTransition
                        in={step.value === "valveStemDimensions"}
                        timeout={{ appear: 300, enter: 300, exit: 0 }}
                        classNames="transition"
                        unmountOnExit
                      >
                        <ValveStemDimensions />
                      </CSSTransition>
                      <CSSTransition
                        in={step.value === "valveMountingType"}
                        timeout={{ appear: 300, enter: 300, exit: 0 }}
                        classNames="transition"
                        unmountOnExit
                      >
                        <ValveMountingType />
                      </CSSTransition>
                      <CSSTransition
                        in={step.value === "valveMountingDimensions"}
                        timeout={{ appear: 300, enter: 300, exit: 0 }}
                        classNames="transition"
                        unmountOnExit
                      >
                        <ValveMountingDimensions />
                      </CSSTransition>
                      <CSSTransition
                        in={step.value === "valveCustomName"}
                        timeout={{ appear: 300, enter: 300, exit: 0 }}
                        classNames="transition"
                        unmountOnExit
                      >
                        <ValveCustomName />
                      </CSSTransition>
                      <CSSTransition
                        in={step.value === "chooseActuatorSelectionType"}
                        timeout={{ appear: 300, enter: 300, exit: 0 }}
                        classNames="transition"
                        unmountOnExit
                      >
                        <SelectActuatorChoices steps={steps} step={step} />
                      </CSSTransition>
                      <CSSTransition
                        in={step.value === "selectActuatorByModel"}
                        timeout={{ appear: 300, enter: 300, exit: 0 }}
                        classNames="transition"
                        unmountOnExit
                      >
                        <SelectActuatorModel />
                      </CSSTransition>
                      <CSSTransition
                        in={step.value === "actuatorDriveType"}
                        timeout={{ appear: 300, enter: 300, exit: 0 }}
                        classNames="transition"
                        unmountOnExit
                      >
                        <ActuatorDriveTypes />
                      </CSSTransition>
                      <CSSTransition
                        in={step.value === "actuatorDriveDimensions"}
                        timeout={{ appear: 300, enter: 300, exit: 0 }}
                        classNames="transition"
                        unmountOnExit
                      >
                        <ActuatorDriveDimensions />
                      </CSSTransition>
                      <CSSTransition
                        in={step.value === "actuatorMountingType"}
                        timeout={{ appear: 300, enter: 300, exit: 0 }}
                        classNames="transition"
                        unmountOnExit
                      >
                        <ActuatorMountingTypes />
                      </CSSTransition>
                      <CSSTransition
                        in={step.value === "actuatorMountingDimensions"}
                        timeout={{ appear: 300, enter: 300, exit: 0 }}
                        classNames="transition"
                        unmountOnExit
                      >
                        <ActuatorMountingDimensions />
                      </CSSTransition>
                      <CSSTransition
                        in={step.value === "selectActuatorByISO5211"}
                        timeout={{ appear: 300, enter: 300, exit: 0 }}
                        classNames="transition"
                        unmountOnExit
                      >
                        <SelectActuatorISO5211 />
                      </CSSTransition>
                      <CSSTransition
                        in={step.value === "actuatorCustomName"}
                        timeout={{ appear: 300, enter: 300, exit: 0 }}
                        classNames="transition"
                        unmountOnExit
                      >
                        <ActuatorCustomName />
                      </CSSTransition>
                      <CSSTransition
                        in={step.value === "summary"}
                        timeout={{ appear: 300, enter: 300, exit: 0 }}
                        classNames="transition"
                        unmountOnExit
                      >
                        <SelectionSummary />
                      </CSSTransition>
                    </div>
                  </div>
                </Card.Body>
                <Card.Footer className="p-4" style={{ background: "#fafafa" }}>
                  <div className="build-kit-buttons-container mt-0">
                    {(step === "selectValveByModel" ||
                      step === "selectActuatorByModel") && (
                      <div className="mr-auto request-quote-block">
                        <span className="small mb-1">
                          <i className="fas fa-info-circle mr-1"></i>
                          <span>Don't see what you're looking for?</span>{" "}
                          <a
                            href="/request-quote"
                            target="_blank"
                            className="ml-1 bg-transparent alert-link"
                          >
                            Request a Quote
                          </a>
                        </span>
                      </div>
                    )}

                    {!isEmpty(user) &&
                      user.isAdmin === true &&
                      step.value === "summary" && <AdminButtons />}

                    {step.value !== steps[0].value && (
                      <button
                        className="btn btn-secondary reset"
                        onClick={() => resetButtonHandler()}
                      >
                        Reset
                      </button>
                    )}

                    {step.value !== steps[0].value && (
                      <button
                        className="btn btn-secondary back"
                        onClick={() => backButtonHandler()}
                      >
                        Back
                      </button>
                    )}

                    {step.value !== "summary" && (
                      <button
                        className="btn btn-primary next"
                        disabled={disabled}
                        onClick={() => nextButtonHandler()}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </Card.Footer>
              </>
            </Accordion.Collapse>
          </Card>
        </div>
      </Accordion>
    </>
  );
};

export default LinkageKitSelector;
