import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CButton,
  CForm,
  CFormCheck,
  CFormInput,
  CFormFeedback,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import { ADMIN_ADD_PREFERRED_CUSTOMER_MODAL } from "../../../redux/constants";
import {
  addPreferredCustomer,
  getPreferredCustomers,
} from "../../../redux/actions/adminActions.js";

export const AddPreferredCustomerModal = () => {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);
  const { loading, message, success, error, status } = admin;

  useEffect(() => {
    if (success) {
      dispatch(getPreferredCustomers());
      dispatch({ type: ADMIN_ADD_PREFERRED_CUSTOMER_MODAL });
    }
  }, [success]);

  return (
    <CModal alignment="center" visible={admin.application.addPreferredCustomerModal}>
      <CModalHeader onDismiss={() => dispatch({ type: ADMIN_ADD_PREFERRED_CUSTOMER_MODAL })}>
        <CModalTitle>Add Preferred Customer</CModalTitle>
      </CModalHeader>
      <Formik
        validationSchema={Yup.object().shape({
          email: Yup.string().required("Required"),
          discountType: Yup.string().required("Required"),
          discountAmount: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          dispatch(addPreferredCustomer(values));
        }}
        initialValues={{
          email: "",
          discountType: "percentage",
          discountAmount: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          isSubmitting,
        }) => (
          <CForm noValidate onSubmit={handleSubmit}>
            <CModalBody>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="form-row mb-3">
                <div className="col-md-6">
                  <CFormLabel htmlFor="email">Email</CFormLabel>
                  <CFormInput
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                    id="email"
                  />
                  <CFormFeedback invalid>{errors.email}</CFormFeedback>
                </div>
                <div className="col-md-6">
                  <CFormLabel htmlFor="discountAmount">Discount Amount</CFormLabel>
                  <CFormInput
                    type="text"
                    name="discountAmount"
                    value={values.discountAmount}
                    onChange={handleChange}
                    id="discountAmount"
                  />
                  <CFormFeedback invalid>{errors.discountAmount}</CFormFeedback>
                </div>
              </div>
              <CFormLabel htmlFor="discountType">Discount Type</CFormLabel>
              <CFormCheck
                type="radio"
                name="discountType"
                value="percentage"
                onChange={handleChange}
                id="discountTypePercentage"
                label="Percentage"
                checked={values.discountType === "percentage"}
              />
              <CFormCheck
                type="radio"
                name="discountType"
                value="dollar"
                onChange={handleChange}
                id="discountTypeDollar"
                label="Dollar"
                checked={values.discountType === "dollar"}
              />
            </CModalBody>
            <CModalFooter>
              <CButton
                type="button"
                color="light"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({ type: ADMIN_ADD_PREFERRED_CUSTOMER_MODAL });
                }}
              >
                Close
              </CButton>
              <CButton type="submit" color="primary">
                Save
              </CButton>
            </CModalFooter>
          </CForm>
        )}
      </Formik>
    </CModal>
  );
};
