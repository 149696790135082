import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { UAParser } from "ua-parser-js";

import Admin from "./Admin";
import LoadingWidget from "./components/LoadingWidget";
import Main from "./Main";

import { useAdminStatusQuery, useAuthStatusQuery } from "./redux/api/authApi";
import { useGetCartByTokenQuery } from "./redux/api/cartApi";
import { useGetUserDetailsQuery } from "./redux/api/userApi";
import { clearCart, setCart, setCartLoading } from "./redux/slices/cart";
import {
  clearUserDetails,
  setAuthStatus,
  setUserDetails,
  setUserLoading,
} from "./redux/slices/user";

const client = new UAParser();
const browser = client.getBrowser().name;

function CheckAuth({ children }) {
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => state.userDetails);
  const cart = useSelector((state) => state.cart);

  const getCartByToken = useGetCartByTokenQuery(
    { id: cart.token },
    { skip: !cart.token || isAuthenticated }
  );

  const authStatus = useAuthStatusQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const getUserDetails = useGetUserDetailsQuery({}, { skip: !isAuthenticated });

  const handleAuthCheckError = () => {
    localStorage.removeItem("userDetails");
    localStorage.removeItem("savedItems");
    dispatch(clearUserDetails());
    dispatch(setAuthStatus(false));
  };

  useEffect(() => {
    if (authStatus.isSuccess) {
      if (authStatus?.currentData?.isAuthenticated === true) {
        dispatch(setAuthStatus(true));
      } else if (authStatus?.currentData?.isAuthenticated === false) {
        dispatch(setAuthStatus(false));
      } else {
        handleAuthCheckError();
      }
    }
  }, [authStatus]);

  useEffect(() => {
    if (getUserDetails.isSuccess) {
      dispatch(setUserDetails(getUserDetails.data?.user));

      if (getUserDetails.data?.cart) {
        dispatch(setCart(getUserDetails.data?.cart));
      }
    }
  }, [getUserDetails]);

  useEffect(() => {
    if (getCartByToken.isLoading) {
      dispatch(setCartLoading(true));
    }

    if (getCartByToken.isError) {
      dispatch(clearCart());
    }

    if (getCartByToken.isSuccess) {
      dispatch(setCart(getCartByToken.data));
    }

    dispatch(setCartLoading(false));
  }, [getCartByToken]);

  return <Routes>{children}</Routes>;
}

function RequireAdmin({ children }) {
  const location = useLocation();
  const { isAuthenticated } = useSelector((state) => state.userDetails);
  const adminStatus = useAdminStatusQuery();

  return adminStatus.isLoading || adminStatus.isUninitialized ? (
    <>
      <LoadingWidget />
    </>
  ) : (
    <>
      {isAuthenticated === true && adminStatus?.data === true ? (
        <Routes>{children}</Routes>
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      )}
    </>
  );
}

const App = () => {
  // Clear old data saved to localstorage
  useEffect(() => {
    localStorage.removeItem("userLogin");
    localStorage.removeItem("cartItems");
    localStorage.removeItem("savedItems");
    localStorage.removeItem("unavailableCartItems");
    localStorage.removeItem("preferredCustomer");
    localStorage.removeItem("couponInfo");
  }, []);

  return (
    <>
      {browser === "IE" ? (
        <div className="d-block w-100 pt-5 mt-3 px-3 text-center">
          <h1 className="pb-3">
            You are using a browser that is no longer supported by
            Microsoft&reg;
          </h1>
          <h2>
            To continue to LinkageKits.com, please switch to a modern browser
            like Chrome, Firefox, Safari, or Edge.
          </h2>
        </div>
      ) : (
        <Routes>
          <Route
            path="*"
            element={
              <CheckAuth>
                <>
                  <Route
                    path="/admin/*"
                    element={
                      <RequireAdmin>
                        <Route path="*" element={<Admin />} />
                      </RequireAdmin>
                    }
                  />
                  <Route path="/*" element={<Main />} />
                </>
              </CheckAuth>
            }
          />
        </Routes>
      )}
    </>
  );
};

export default App;
