import React, { Suspense, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Switch, Redirect, Navigate, useNavigate, Routes } from "react-router-dom";
import { CContainer, CSpinner } from "@coreui/react";
import { AppSidebar, AppFooter, AppHeader } from "./components/admin/index";

import { ToastContainer } from "react-toastify";

import "../node_modules/react-toastify/dist/ReactToastify.css";

// Import coreui styles
import "./admin.scss";

// Redux Actions
import { checkAdminStatus } from "./redux/actions/authActions";
import { softLogout } from "./redux/actions/userActions";

// Import views
import AdminDashboard from "./screens/admin/AdminDashboard";
import AdminOrders from "./screens/admin/AdminOrders";
import AdminOrderDetails from "./screens/admin/AdminOrderDetails";
import AdminCustomers from "./screens/admin/AdminCustomers";
import AdminPreferredCustomers from "./screens/admin/AdminPreferredCustomers";
import AdminEarlyAccessUsers from "./screens/admin/AdminEarlyAccessUsers";
import AdminCoupons from "./screens/admin/AdminCoupons";
import AdminValves from "./screens/admin/AdminValves";
import AdminActuators from "./screens/admin/AdminActuators";
import AdminQuoteRequests from "./screens/admin/AdminQuoteRequests";
import AdminQuoteRequestDetails from "./screens/admin/AdminQuoteRequestDetails";
import AdminCustomerMessages from "./screens/admin/AdminCustomerMessages";
import AdminManufactureRates from "./screens/admin/AdminManufactureRates";
import AdminMaterialPricing from "./screens/admin/AdminMaterialPricing";
import AdminLinkageKitSelector from "./screens/admin/AdminLinkageKitSelector";
import AdminCustomerMessageDetails from "./screens/admin/AdminCustomerMessageDetails";
import AdminCustomerDetails from "./screens/admin/AdminCustomerDetails";
import AdminSettings from "./screens/admin/AdminSettings";

const Admin = () => {
  useEffect(() => {
    const body = document.getElementsByTagName("BODY")[0];

    body.removeAttribute("id", "main");
    body.setAttribute("id", "admin");
  }, []);

  return (
    <>
      <>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <AppHeader />
          <div className="body flex-grow-1 px-3">
            <CContainer lg>
              <Suspense fallback={<CSpinner color="primary" />}>
                <Routes>
                  <Route path="dashboard" element={<AdminDashboard />} />
                  <Route path="build-kit" element={<AdminLinkageKitSelector />} />
                  <Route path="customers" element={<AdminCustomers />} />
                  <Route path="customers/:id" element={<AdminCustomerDetails />} />
                  <Route path="quote-requests" element={<AdminQuoteRequests />} />
                  <Route path="quote-requests/:id" element={<AdminQuoteRequestDetails />} />
                  <Route path="customer-messages" element={<AdminCustomerMessages />} />
                  <Route path="customer-messages/:id" element={<AdminCustomerMessageDetails />} />
                  <Route path="preferred-customers" element={<AdminPreferredCustomers />} />
                  <Route path="early-access-users" element={<AdminEarlyAccessUsers />} />
                  <Route path="coupons" element={<AdminCoupons />} />
                  <Route path="orders" element={<AdminOrders />} />
                  <Route path="orders/:id" element={<AdminOrderDetails />} />
                  <Route path="database/valves" element={<AdminValves />} />
                  <Route path="database/actuators" element={<AdminActuators />} />
                  <Route path="settings" element={<AdminSettings />} />
                  <Route path="manufacture-rates" element={<AdminManufactureRates />} />
                  <Route path="material-pricing" element={<AdminMaterialPricing />} />
                  <Route path="*" element={<AdminDashboard />} />
                </Routes>
              </Suspense>
            </CContainer>
          </div>
          <AppFooter />
        </div>
      </>

      <ToastContainer />
    </>
  );
};

export default Admin;
