import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormFeedback,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { getAllManufactureRates, updateManufactureRate } from "../../redux/actions/adminActions";
import LoadingWidget from "../../components/LoadingWidget";
import { Formik } from "formik";
import * as Yup from "yup";
import { ADMIN_MESSAGE_RESET } from "../../redux/constants";
import { components } from "react-select";

const AdminManufactureRates = () => {
  const dispatch = useDispatch();

  const admin = useSelector((state) => state.admin);
  const { loading, success, message, error, status } = admin;

  const allManufactureRates = useSelector(
    (state) => state.admin.settings.manufactureRates.allManufactureRates
  );

  const [showEditRateModal, setShowEditRateModal] = useState(false);
  const [rateDetails, setRateDetails] = useState({});

  useEffect(() => {
    dispatch(getAllManufactureRates());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      setRateDetails({});
      setShowEditRateModal(false);
      dispatch(getAllManufactureRates());
    }
  }, [dispatch, success]);

  return (
    <>
      {loading ? (
        <LoadingWidget />
      ) : (
        <>
          {showEditRateModal && (
            <CModal visible={showEditRateModal} alignment="center">
              <Formik
                validationSchema={Yup.object().shape({
                  multiplier: Yup.string().required("Please enter a multiplier"),
                })}
                onSubmit={(values) => {
                  dispatch(
                    updateManufactureRate(rateDetails._id, {
                      multiplier: values.multiplier,
                    })
                  );
                }}
                initialValues={{
                  multiplier: rateDetails.multiplier,
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  setValues,
                  setFieldValue,
                  touched,
                  isValid,
                  errors,
                  isSubmitting,
                }) => (
                  <CForm noValidate onSubmit={handleSubmit}>
                    <CModalHeader>
                      <CModalTitle>Update Manufacture Rate</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                      {error && <div className="alert alert-danger">{error}</div>}
                      <CFormLabel>Multiplier</CFormLabel>
                      <CFormInput
                        type="text"
                        name="multiplier"
                        value={values.multiplier}
                        onChange={handleChange}
                        isInvalid={!!errors.multiplier && touched.multiplier}
                      />
                      <CFormFeedback>{errors.multiplier}</CFormFeedback>
                    </CModalBody>
                    <CModalFooter>
                      <CButton
                        type="button"
                        color="light"
                        onClick={() => {
                          setRateDetails({});
                          setShowEditRateModal(false);
                        }}
                      >
                        Cancel
                      </CButton>
                      <CButton
                        type="submit"
                        color="primary"
                        disabled={rateDetails.multiplier === values.multiplier}
                      >
                        Update
                      </CButton>
                    </CModalFooter>
                  </CForm>
                )}
              </Formik>
            </CModal>
          )}
          {success && message && <div className="alert alert-success">{message}</div>}
          <CRow>
            <CCol xs={12}>
              <CCard className="mb-4">
                <CCardHeader>
                  <strong>Manufacture Rates</strong>
                </CCardHeader>
                <CCardBody>
                  <CTable>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell scope="col">Kit Size</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Components</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Additional Options</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Multiplier</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {allManufactureRates.map((rate) => (
                        <>
                          {rate.components === "Coupler Only" && (
                            <CTableRow>
                              <CTableDataCell>{rate.name}</CTableDataCell>
                              <CTableDataCell>{rate.components}</CTableDataCell>
                              <CTableDataCell>
                                {rate.actuatorKeyway && !rate.valveKeyway
                                  ? "Keyway on Actuator"
                                  : !rate.actuatorKeyway && rate.valveKeyway
                                  ? "Keyway on Valve"
                                  : rate.actuatorKeyway &&
                                    rate.valveKeyway &&
                                    "Keyway on Valve and Actuator"}
                              </CTableDataCell>
                              <CTableDataCell>{rate.multiplier} </CTableDataCell>
                              <CTableDataCell>
                                <CButton
                                  type="button"
                                  color="light"
                                  size="sm"
                                  onClick={() => {
                                    dispatch({ type: ADMIN_MESSAGE_RESET });
                                    setRateDetails(rate);
                                    setShowEditRateModal(true);
                                  }}
                                >
                                  <i className="fas fa-edit"></i>
                                </CButton>
                              </CTableDataCell>
                            </CTableRow>
                          )}
                        </>
                      ))}
                    </CTableBody>
                  </CTable>
                </CCardBody>
              </CCard>

              <CCard className="mb-4">
                <CCardHeader>
                  <strong>Manufacture Rates</strong>
                </CCardHeader>
                <CCardBody>
                  <CTable>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell scope="col">Kit Size</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Components</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Multiplier</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {allManufactureRates.map((rate) => (
                        <>
                          {rate.components === "Bracket Only" && (
                            <CTableRow>
                              <CTableDataCell>{rate.name}</CTableDataCell>
                              <CTableDataCell>{rate.components}</CTableDataCell>
                              <CTableDataCell>{rate.multiplier} </CTableDataCell>
                              <CTableDataCell>
                                <CButton
                                  type="button"
                                  color="light"
                                  size="sm"
                                  onClick={() => {
                                    dispatch({ type: ADMIN_MESSAGE_RESET });
                                    setRateDetails(rate);
                                    setShowEditRateModal(true);
                                  }}
                                >
                                  <i className="fas fa-edit"></i>
                                </CButton>
                              </CTableDataCell>
                            </CTableRow>
                          )}
                        </>
                      ))}
                    </CTableBody>
                  </CTable>
                </CCardBody>
              </CCard>

              <CCard className="mb-4">
                <CCardHeader>
                  <strong>Manufacture Rates</strong>
                </CCardHeader>
                <CCardBody>
                  <CTable>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell scope="col">Kit Size</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Components</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Additional Options</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Multiplier</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {allManufactureRates.map((rate) => (
                        <>
                          {rate.components === "Bracket and Coupler" && (
                            <CTableRow>
                              <CTableDataCell>{rate.name}</CTableDataCell>
                              <CTableDataCell>{rate.components}</CTableDataCell>
                              <CTableDataCell>
                                {rate.actuatorKeyway && !rate.valveKeyway
                                  ? "Keyway on Actuator"
                                  : !rate.actuatorKeyway && rate.valveKeyway
                                  ? "Keyway on Valve"
                                  : rate.actuatorKeyway &&
                                    rate.valveKeyway &&
                                    "Keyway on Valve and Actuator"}
                              </CTableDataCell>
                              <CTableDataCell>{rate.multiplier} </CTableDataCell>
                              <CTableDataCell>
                                <CButton
                                  type="button"
                                  color="light"
                                  size="sm"
                                  onClick={() => {
                                    dispatch({ type: ADMIN_MESSAGE_RESET });
                                    setRateDetails(rate);
                                    setShowEditRateModal(true);
                                  }}
                                >
                                  <i className="fas fa-edit"></i>
                                </CButton>
                              </CTableDataCell>
                            </CTableRow>
                          )}
                        </>
                      ))}
                    </CTableBody>
                  </CTable>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </>
      )}
    </>
  );
};

export default AdminManufactureRates;
