import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetSavedItemsQuery,
  useMoveToCartMutation,
  useRemoveFromSavedItemsMutation,
} from "../redux/api/savedItemsApi.js";
import * as Antd from "antd";
import { setCart } from "../redux/slices/cart.js";
import { setSavedItems } from "../redux/slices/savedItems.js";
import ActuatorDescription from "./ActuatorDescription.js";
import LinkageKitDescription from "./LinkageKitDescription.js";
import ValveDescription from "./ValveDescription.js";
import { CheckCircleTwoTone, PaperClipOutlined } from "@ant-design/icons";

const SavedItems = ({ refetchSavedItems }) => {
  const dispatch = useDispatch();

  const savedItems = useSelector((state) => state.savedItems.items);

  const [removeFromSavedItems, removeFromSavedItemsResult] =
    useRemoveFromSavedItemsMutation();
  const [moveToCart, moveToCartResult] = useMoveToCartMutation();

  const moveToCartHandler = async (item) => {
    moveToCart({ id: item._id })
      .unwrap()
      .then((data) => {
        dispatch(setCart(data));
      });
  };

  const removeSavedItemHandler = async (sku) => {
    await removeFromSavedItems({ sku: sku });
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    if (moveToCartResult.isSuccess) {
      if (refetchSavedItems) refetchSavedItems();
    }
  }, [moveToCartResult]);

  return (
    <>
      {savedItems?.map((item) => (
        <React.Fragment key={item.sku}>
          <div style={{ borderBottom: "1px solid #ebebeb" }}>
            <div className="d-flex pt-4 pr-4 pb-2 pl-4 flex-wrap flex-sm-nowrap">
              <div className="">
                <span
                  className="d-block mb-2"
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "500",
                    lineHeight: "1.3rem",
                  }}
                >
                  {item.product.productType === "mountingKit"
                    ? "Stainless Steel Mounting Kit: "
                    : null}
                  <LinkageKitDescription item={item.product} />
                </span>
                <Antd.Descriptions
                  layout="horizontal"
                  column={1}
                  size="small"
                  bordered={true}
                  style={{ maxWidth: "480px" }}
                  labelStyle={{ padding: "6px", lineHeight: "15px" }}
                  contentStyle={{ padding: "6px", lineHeight: "15px" }}
                >
                  <Antd.Descriptions.Item label="SKU">
                    {item.product.sku}
                  </Antd.Descriptions.Item>
                  <Antd.Descriptions.Item label="Custom Datasheet(s)">
                    {item.useCustomDataSheets === true ? "Yes" : "No"}
                  </Antd.Descriptions.Item>
                  <Antd.Descriptions.Item label="Components">
                    {item.product.components}
                  </Antd.Descriptions.Item>
                  <Antd.Descriptions.Item label="Lockout Kit">
                    {item.product.lockout === false
                      ? "No"
                      : item.product.lockout === true && "Yes"}
                  </Antd.Descriptions.Item>
                  <Antd.Descriptions.Item label="Bracket Height">
                    {item.product.bracketHeight}"
                  </Antd.Descriptions.Item>
                </Antd.Descriptions>
              </div>
              <div
                className="ml-auto"
                style={{
                  textAlign: "right",
                  fontSize: "1.1rem",
                  fontWeight: "500",
                  lineHeight: "1.3rem",
                  paddingLeft: "4rem",
                }}
              >
                {/* {item.qty > 1 && (
                    <span className="text-muted small">
                      <s>
                        $
                        {Number(item.qtyOnePrice).toFixed(
                          2
                        )}
                        ea.
                      </s>
                    </span>
                  )}<br /> */}
                {`$${Number(item.price).toFixed(2)}\u00a0ea.`}
                {/* <br />
                {item.qty > 1 && (
                  <span
                    style={{
                      color: '#225021',
                      fontSize: '.8rem',
                      fontWeight: '500',
                    }}
                  >{`Qty\u00A0Discount\u00A0$${Number(
                    item.qtyOnePrice - item.price
                  ).toFixed(2)}`}</span>
                )} */}
              </div>
            </div>
            {item.useCustomDataSheets === true ? (
              <Antd.Card
                style={{
                  margin: "0 24px 12px 24px",
                  border: "none",
                  boxShadow: "none",
                }}
                bodyStyle={{ padding: 0 }}
              >
                <Antd.Row gutter={[8, 8]}>
                  {item.customValveDataSheet ? (
                    <Antd.Col md={12} span={24}>
                      <Antd.Card
                        className="h-100"
                        bodyStyle={{ padding: "18px" }}
                      >
                        <>
                          <div>
                            <div className="font-weight-bold mb-2">
                              <span className="mr-2">
                                Customer-Supplied Valve Datasheet
                              </span>
                              <CheckCircleTwoTone />
                            </div>
                            <div>
                              <Antd.Typography.Paragraph ellipsis={{ rows: 1 }}>
                                <a
                                  href={item.customValveDataSheet?.url}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="link"
                                >
                                  <PaperClipOutlined />{" "}
                                  {item.customValveDataSheet?.name}
                                </a>
                              </Antd.Typography.Paragraph>
                            </div>
                          </div>
                        </>
                      </Antd.Card>
                    </Antd.Col>
                  ) : null}
                  {item.customActuatorDataSheet ? (
                    <Antd.Col md={12} span={24}>
                      <Antd.Card
                        className="h-100"
                        bodyStyle={{ padding: "18px" }}
                      >
                        <>
                          <div>
                            <div className="font-weight-bold mb-2">
                              <span className="mr-2">
                                Customer-Supplied Actuator Datasheet
                              </span>
                              <CheckCircleTwoTone />
                            </div>
                          </div>
                          <div>
                            <Antd.Typography.Paragraph ellipsis={{ rows: 1 }}>
                              <a
                                href={item.customActuatorDataSheet?.url}
                                target="_blank"
                                rel="noreferrer"
                                className="link"
                              >
                                <PaperClipOutlined />{" "}
                                {item.customActuatorDataSheet?.name}
                              </a>
                            </Antd.Typography.Paragraph>
                          </div>
                        </>
                      </Antd.Card>
                    </Antd.Col>
                  ) : null}
                </Antd.Row>
              </Antd.Card>
            ) : null}
            <div className="d-block w-100 pb-4 pr-4 pl-4">
              <div className="d-inline-block pt-1">
                <span
                  className="mr-3"
                  style={{
                    fontSize: ".95rem",
                    lineHeight: "2rem",
                    fontWeight: "500",
                  }}
                >{`Qty: ${item.quantity}`}</span>
                <button
                  className="btn btn-xs btn-link pl-0 ml-0"
                  onClick={() => moveToCartHandler(item)}
                  disabled={removeFromSavedItemsResult.isLoading}
                >
                  Move To Cart
                </button>
                <div
                  className="d-inline-block"
                  style={{
                    width: "1px",
                    height: "1rem",
                    backgroundColor: "#ddd",
                    verticalAlign: "middle",
                  }}
                ></div>
                <button
                  type="button"
                  className="btn btn-xs btn-link"
                  onClick={() => removeSavedItemHandler(item.sku)}
                  disabled={removeFromSavedItemsResult.isLoading}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

export default SavedItems;
