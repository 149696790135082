import React from 'react';

const BillingAddressCard = ({ billingDetails }) => {
  return (
    <>
      <div className="card address-card h-100">
        <div className="order-header pl-4">
          <h5 className="mb-0">Billing Details</h5>
        </div>
        <div className="card-divider"></div>
        <div className="address-card__body">
          <div className="address-card__name">{`${billingDetails.firstName} ${billingDetails.lastName}`}</div>
          <div className="address-card__row">
            {billingDetails.company && (
              <>
                {billingDetails.company} <br />
              </>
            )}
            {billingDetails.street2 ? (
              <>
                {`${billingDetails.street} ${billingDetails.street2}`} <br />
              </>
            ) : (
              <>
                {billingDetails.street} <br />
              </>
            )}
            <>
              {`${billingDetails.city}, ${billingDetails.state} ${billingDetails.postalCode} `}
              <br />
              {billingDetails.country}
            </>
          </div>
          <div className="address-card__row">
            <div className="address-card__row-title">Phone Number</div>
            <div className="address-card__row-content">
              {billingDetails.phone}
            </div>
          </div>
          <div className="address-card__row">
            <div className="address-card__row-title">Email Address</div>
            <div className="address-card__row-content">
              {billingDetails.email}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingAddressCard;
