import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_LINKAGE_KIT_SELECTOR_STEP,
  SET_LINKAGE_KIT_SELECTOR_STEPS,
  SET_VALVE_SELECTION_TYPE,
} from "../../redux/constants";

const SelectValveChoices = () => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="valve-selection-type-btns-container d-block p-1">
        <button
          className="btn btn-lg btn-dark"
          onClick={() => {
            dispatch({ type: SET_VALVE_SELECTION_TYPE, payload: "byModel" });
            dispatch({
              type: SET_LINKAGE_KIT_SELECTOR_STEPS,
              payload: [
                {
                  value: "chooseValveSelectionType",
                  label: "How Would You Like To Select Your Valve?",
                },
                {
                  value: "selectValveByModel",
                  label: "Select Valve By Model",
                },
                {
                  value: "chooseActuatorSelectionType",
                  label: "How Would You Like To Select Your Actuator?",
                },
              ],
            });
            dispatch({
              type: SET_LINKAGE_KIT_SELECTOR_STEP,
              payload: {
                value: "selectValveByModel",
                label: "Select Valve By Model",
              },
            });
          }}
        >
          By Model
        </button>
        <button
          className="btn btn-lg btn-dark"
          onClick={() => {
            dispatch({ type: SET_VALVE_SELECTION_TYPE, payload: "byDimensions" });
            dispatch({
              type: SET_LINKAGE_KIT_SELECTOR_STEPS,
              payload: [
                {
                  value: "chooseValveSelectionType",
                  label: "How Would You Like To Select Your Valve?",
                },
                { value: "valveStemType", label: "Select Valve Stem Type" },
                { value: "valveStemDimensions", label: "Enter Valve Stem Dimensions" },
                { value: "valveMountingType", label: "Enter Valve Mounting Type" },
                { value: "valveMountingDimensions", label: "Enter Valve Mounting Dimensions" },
                { value: "valveCustomName", label: "Name Your Valve" },
                {
                  value: "chooseActuatorSelectionType",
                  label: "How Would You Like To Select Your Actuator?",
                },
              ],
            });
            dispatch({
              type: SET_LINKAGE_KIT_SELECTOR_STEP,
              payload: { value: "valveStemType", label: "Select Valve Stem Type" },
            });
          }}
        >
          <i className="fas fa-lock mr-2"></i> By Dimensions
        </button>
      </div>
    </>
  );
};

export default SelectValveChoices;
