import { api } from "../slices/api";

// Define a service using a base URL and expected endpoints
export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getSavedItems: builder.query({
      query: () => "v1/users/profile/savedItems",
    }),
    addToSavedItems: builder.mutation({
      query: (values) => ({
        url: "v1/users/profile/savedItems/add",
        method: "POST",
        body: values,
      }),
    }),
    removeFromSavedItems: builder.mutation({
      query: (values) => ({
        url: "v1/users/profile/savedItems/remove",
        method: "POST",
        body: values,
      }),
    }),
    moveToCart: builder.mutation({
      query: ({ id }) => ({
        url: `v1/users/profile/saved-items/${id}/move-to-cart`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetSavedItemsQuery,
  useAddToSavedItemsMutation,
  useRemoveFromSavedItemsMutation,
  useMoveToCartMutation,
} = extendedApiSlice;
