import axios from 'axios';
import {
  GET_SAVED_ITEMS_REQUEST,
  GET_SAVED_ITEMS_SUCCESS,
  GET_SAVED_ITEMS_FAIL,
  ADD_TO_SAVED_ITEMS_REQUEST,
  ADD_TO_SAVED_ITEMS_SUCCESS,
  ADD_TO_SAVED_ITEMS_FAIL,
  REMOVE_FROM_SAVED_ITEMS_REQUEST,
  REMOVE_FROM_SAVED_ITEMS_SUCCESS,
  REMOVE_FROM_SAVED_ITEMS_FAIL,
  MOVE_TO_CART_REQUEST,
  MOVE_TO_CART_SUCCESS,
  MOVE_TO_CART_FAIL,
} from '../constants';

export const getSavedItems = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_SAVED_ITEMS_REQUEST,
    });

    const res = await axios.get('/api/v1/users/profile/savedItems');

    dispatch({
      type: GET_SAVED_ITEMS_SUCCESS,
      payload: res,
    });

    localStorage.setItem('savedItems', JSON.stringify(res.data));
  } catch (error) {
    dispatch({
      type: GET_SAVED_ITEMS_FAIL,
      payload: error,
    });
  }
};

export const addToSavedItems = (item) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_TO_SAVED_ITEMS_REQUEST,
    });

    const res = await axios.post('/api/v1/users/profile/savedItems/add', item, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    dispatch({
      type: ADD_TO_SAVED_ITEMS_SUCCESS,
      payload: res,
    });

    localStorage.setItem('savedItems', JSON.stringify(res.data.items));
  } catch (error) {
    dispatch({
      type: ADD_TO_SAVED_ITEMS_FAIL,
      payload: error,
    });
  }
};

export const removeFromSavedItems = (sku) => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_FROM_SAVED_ITEMS_REQUEST,
    });

    const res = await axios.post(
      '/api/v1/users/profile/savedItems/remove',
      { sku },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    dispatch({
      type: REMOVE_FROM_SAVED_ITEMS_SUCCESS,
      payload: res,
    });

    localStorage.setItem('savedItems', JSON.stringify(res.data.items));
  } catch (error) {
    dispatch({
      type: REMOVE_FROM_SAVED_ITEMS_FAIL,
      payload: error,
    });
  }
};

export const moveToCart = (item) => async (dispatch) => {
  try {
    dispatch({ type: MOVE_TO_CART_REQUEST });

    const res = await axios.post(
      '/api/v1/users/profile/savedItems/moveToCart',
      item,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    dispatch({ type: MOVE_TO_CART_SUCCESS, payload: res });

    localStorage.setItem('cartItems', JSON.stringify(res.data.cartItems));
    localStorage.setItem('savedItems', JSON.stringify(res.data.savedItems));
  } catch (error) {
    dispatch({ type: MOVE_TO_CART_FAIL, payload: error });
  }
};
