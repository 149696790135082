import { api } from "../slices/api";

// Define a service using a base URL and expected endpoints
export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: () => `v1/users/profile/orders`,
    }),
    getOrderDetails: builder.query({
      query: (id) => `v1/users/profile/order/${id}`,
    }),
    reOrderItem: builder.mutation({
      query: ({ order, item }) => ({
        url: `v1/users/profile/order/${order}/item/${item}/reorder`,
        method: "POST",
      }),
      method: "POST",
    }),
    createOrder: builder.mutation({
      query: (values) => ({
        url: "v1/orders",
        method: "POST",
        body: values,
      }),
    }),
    createOrder: builder.mutation({
      query: (values) => ({
        url: "v1/orders",
        method: "POST",
        body: values,
      }),
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useGetOrderDetailsQuery,
  useReOrderItemMutation,
  useCreateOrderMutation,
} = extendedApiSlice;
