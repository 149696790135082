import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getShippingAddresses,
  getUserOrders,
  softLogout,
} from "../../redux/actions/userActions";

import { capitalize, numberWithCommas } from "../../utils/helpers";

import AccountNavigation from "../../components/account/AccountNavigation";
import LoadingWidget from "../../components/LoadingWidget";
import { Helmet } from "react-helmet";
import { variables } from "../../config";
import VerifyAccount from "../../components/VerifyAccount";
import { Link, useNavigate } from "react-router-dom";
import { useGetOrdersQuery } from "../../redux/api/orderApi";
import {
  useGetAddressesQuery,
  useGetUserDetailsQuery,
} from "../../redux/api/userApi";

const AccountDashboardScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useGetUserDetailsQuery();
  const addresses = useGetAddressesQuery();
  const orders = useGetOrdersQuery();

  const defaultAddress = addresses.isSuccess
    ? addresses?.data?.find((address) => address.default === true)
    : null;

  return (
    <>
      {user.isLoading || addresses.isLoading || orders.isLoading ? (
        <LoadingWidget />
      ) : (
        <>
          <Helmet>
            <title>Account | {variables.WEBSITE_NAME}</title>
            <link
              rel="canonical"
              href={`${variables.FULL_URL}/account/dashboard`}
            />
            <meta name="robots" content="noindex, nofollow" />
          </Helmet>
          <div className="block-space block-space--layout--after-header"></div>
          <div className="block">
            <div className="container container--max--xl">
              {user?.data?.isVerified === false ? (
                <div className="row">
                  <div className="col">
                    <VerifyAccount />
                  </div>
                </div>
              ) : null}
              <div className="row">
                <div className="col-12 col-lg-3">
                  <AccountNavigation active="dashboard" />
                </div>
                <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                  <div className="dashboard">
                    <div className="dashboard__profile card profile-card">
                      <div className="card-body profile-card__body">
                        <div className="profile-card__name">{`${user?.data?.user?.firstName} ${user?.data?.user?.lastName}`}</div>
                        <div className="profile-card__email">
                          {user?.data?.user?.email}
                        </div>
                        <div className="profile-card__edit">
                          <Link
                            to="/account/profile"
                            className="btn btn-secondary btn-sm"
                          >
                            Edit Profile
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="dashboard__address card address-card address-card--featured">
                      {!defaultAddress ? (
                        <div className="card-body profile-card__body">
                          <div className="profile-card__email">
                            No Shipping Address
                          </div>
                          <div className="profile-card__edit">
                            <Link
                              to="/account/addresses/add"
                              className="btn btn-secondary btn-sm"
                            >
                              Add Shipping Address
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="address-card__badge tag-badge tag-badge--theme">
                            Default Address
                          </div>
                          <div className="address-card__body">
                            <div className="address-card__name">
                              {`${defaultAddress.firstName} ${defaultAddress.lastName}`}
                            </div>
                            <div className="address-card__row">
                              {defaultAddress.company && (
                                <>
                                  {defaultAddress.company} <br />
                                </>
                              )}
                              {defaultAddress.street2 ? (
                                <>
                                  {`${defaultAddress.street} ${defaultAddress.street2}`}{" "}
                                  <br />
                                </>
                              ) : (
                                <>
                                  {defaultAddress.street} <br />
                                </>
                              )}
                              {`${defaultAddress.city}, ${defaultAddress.state}, ${defaultAddress.postalCode}`}
                              <br />
                              {defaultAddress.country}
                            </div>
                            <div className="address-card__row">
                              <div className="address-card__row-title">
                                Phone Number
                              </div>
                              <div className="address-card__row-content">
                                {defaultAddress.phone}
                              </div>
                            </div>
                            <div className="address-card__footer">
                              <Link
                                className="btn btn-link btn-sm p-0"
                                to={`/account/addresses/edit/${defaultAddress._id}`}
                              >
                                Edit Address
                              </Link>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="dashboard__orders card">
                      <div className="card-header">
                        <h5>Recent Orders</h5>
                      </div>
                      <div className="card-divider"></div>
                      <div className="card-table">
                        {orders?.data?.length < 1 ? (
                          <div className="card-body profile-card__body">
                            No Recent Orders
                          </div>
                        ) : (
                          <div className="table-responsive-sm">
                            <table>
                              <thead>
                                <tr>
                                  <th>Order</th>
                                  <th>Date</th>
                                  <th>Status</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orders?.data?.slice(0, 3).map((order) => (
                                  <tr key={order._id}>
                                    <td>
                                      <Link
                                        to={`/account/order-details/${order._id}`}
                                      >
                                        #{order.orderNumber}
                                      </Link>
                                    </td>
                                    <td>
                                      {new Date(
                                        order.submittedAt
                                      ).toLocaleDateString()}
                                    </td>
                                    <td>{capitalize(order.status)}</td>
                                    <td>${numberWithCommas(order.total)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-space block-space--layout--before-footer"></div>
        </>
      )}
    </>
  );
};

export default AccountDashboardScreen;
