import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CBadge,
} from "@coreui/react";

import { getAllValves, deleteValve } from "../../redux/actions/adminActions";
import {
  ADMIN_ADD_VALVES_FROM_CSV_STRING_MODAL,
  ADMIN_ADD_VALVE_MODAL,
  ADMIN_EDIT_VALVE_MODAL,
} from "../../redux/constants";
import { AddValveModal } from "../../components/admin/modals/AddValveModal";
import { EditValveModal } from "../../components/admin/modals/EditValveModal";
import { AddValvesFromCsvStringModal } from "../../components/admin/modals/AddValvesFromCsvStringModal";

const AdminValves = () => {
  const dispatch = useDispatch();

  const [valveDetails, setValveDetails] = useState({});
  const [confirmDeleteValveModal, setConfirmDeleteValveModal] = useState(false);

  const admin = useSelector((state) => state.admin);
  const { loading, message, error, status } = admin;

  const valves = useSelector((state) => state.admin.database.valves);
  const { allValves } = valves;

  const addValvesFromCsvString = useSelector(
    (state) => state.admin.database.valves.addValvesFromCsvString
  );

  useEffect(() => {
    dispatch(getAllValves());
  }, [dispatch]);

  useEffect(() => {
    if (message === "Valve deleted successfully") {
      setConfirmDeleteValveModal(false);
      setValveDetails({});

      dispatch(getAllValves());
    }
  }, [dispatch, message]);

  useEffect(() => {
    if (addValvesFromCsvString.success) {
      dispatch({ type: ADMIN_ADD_VALVES_FROM_CSV_STRING_MODAL });
      dispatch(getAllValves());
      toast.success(addValvesFromCsvString.message);
    }
  }, [addValvesFromCsvString.success]);

  return (
    <>
      {confirmDeleteValveModal && (
        <CModal visible={confirmDeleteValveModal} centered>
          <CModalHeader>
            <CModalTitle>Delete Valve?</CModalTitle>
          </CModalHeader>
          <CModalBody>{`${valveDetails.size} Class ${valveDetails.asmeClass} ${valveDetails.brand} ${valveDetails.series}`}</CModalBody>
          <CModalFooter>
            <CButton
              color="light"
              onClick={() => {
                setConfirmDeleteValveModal(false);
                setValveDetails({});
              }}
            >
              Cancel
            </CButton>
            <CButton
              color="danger"
              onClick={() => {
                dispatch(deleteValve(valveDetails.sku));
              }}
            >
              Delete
            </CButton>
          </CModalFooter>
        </CModal>
      )}
      <AddValveModal />
      <AddValvesFromCsvStringModal />
      <EditValveModal valve={valveDetails} />
      {message && message === "Valve deleted successfully" && (
        <div className="alert alert-success">{message}</div>
      )}
      <CRow>
        <CCol xs={12} className="mb-4">
          <CButton
            className="mr-2"
            color="primary"
            onClick={() => dispatch({ type: ADMIN_ADD_VALVE_MODAL })}
          >
            Add Valve
          </CButton>
          <CButton
            color="primary"
            onClick={() => dispatch({ type: ADMIN_ADD_VALVES_FROM_CSV_STRING_MODAL })}
          >
            Paste CSV Text
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={12}></CCol>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Valves</strong>
            </CCardHeader>
            <CCardBody>
              <CTable hover>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col">Public</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Brand</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Series</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Size</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Options</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {allValves.map((valve) => (
                    <CTableRow>
                      <CTableDataCell>
                        {valve.allow === true ? "Yes" : valve.allow === false && "No"}
                      </CTableDataCell>
                      <CTableDataCell>{valve.brand}</CTableDataCell>
                      <CTableDataCell>{valve.series}</CTableDataCell>
                      <CTableDataCell>{valve.size}</CTableDataCell>
                      <CTableDataCell>
                        {valve.options.map((option) => {
                          return `${Object.values(option)[0]}, `;
                        })}
                      </CTableDataCell>
                      <CTableDataCell>
                        <CButton
                          color="light"
                          size="sm"
                          className="mx-1"
                          onClick={() => {
                            setValveDetails(valve);
                            dispatch({
                              type: ADMIN_EDIT_VALVE_MODAL,
                            });
                          }}
                        >
                          <i className="fas fa-edit"></i>
                        </CButton>
                        <CButton
                          color="light"
                          size="sm"
                          className="mx-1"
                          onClick={() => {
                            setValveDetails(valve);
                            setConfirmDeleteValveModal(true);
                          }}
                        >
                          <i className="fas fa-trash"></i>
                        </CButton>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default AdminValves;
