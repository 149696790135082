import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormFeedback,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormSelect,
} from "@coreui/react";
import LoadingWidget from "../../components/LoadingWidget";
import { Formik } from "formik";
import * as Yup from "yup";
import { getAllMaterialPricing, updateMaterialPricing } from "../../redux/actions/adminActions";
import { ADMIN_MESSAGE_RESET } from "../../redux/constants";
import dayjs from "dayjs";

const AdminMaterialPricing = () => {
  const dispatch = useDispatch();

  const admin = useSelector((state) => state.admin);
  const { loading, success, message, error, status } = admin;

  const allMaterialPricing = useSelector(
    (state) => state.admin.settings.materialPricing.allMaterialPricing
  );

  const [showEditPriceModal, setShowEditPriceModal] = useState(false);
  const [materialDetails, setMaterialDetails] = useState({});

  useEffect(() => {
    dispatch(getAllMaterialPricing());
  }, [dispatch]);

  useEffect(() => {
    if (message === "Material updated successfully") {
      setMaterialDetails({});
      setShowEditPriceModal(false);
      dispatch(getAllMaterialPricing());
    }
  }, [dispatch, message]);

  return (
    <>
      {loading ? (
        <LoadingWidget />
      ) : (
        <>
          {showEditPriceModal && (
            <CModal visible={showEditPriceModal} alignment="center">
              <Formik
                validationSchema={Yup.object().shape({
                  price: Yup.string().required("Please enter a price"),
                  length:
                    (materialDetails.type === "Channel" ||
                      materialDetails.type === "Round Stock") &&
                    Yup.string().required("Please enter a length"),
                  vendor: Yup.string().required("Please select a vendor"),
                })}
                onSubmit={(values) => {
                  dispatch(
                    updateMaterialPricing(materialDetails._id, {
                      length:
                        materialDetails.type === "Channel" || materialDetails.type === "Round Stock"
                          ? values.length
                          : "",
                      price: values.price,
                      vendor: values.vendor,
                    })
                  );
                }}
                initialValues={{
                  length:
                    materialDetails.type === "Channel" || materialDetails.type === "Round Stock"
                      ? materialDetails.length
                      : "",
                  price: materialDetails.price,
                  vendor: "Great Lakes Specialty Metals",
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  setValues,
                  setFieldValue,
                  touched,
                  isValid,
                  errors,
                  isSubmitting,
                }) => (
                  <CForm noValidate onSubmit={handleSubmit}>
                    <CModalHeader>
                      <CModalTitle>Update Price</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                      {error && <div className="alert alert-danger">{error}</div>}
                      {materialDetails.type === "Channel" ||
                      materialDetails.type === "Round Stock" ? (
                        <p>{`${materialDetails.size} ${materialDetails.material} ${materialDetails.type}`}</p>
                      ) : (
                        (materialDetails.type === "Standard Lockout Pin" ||
                          materialDetails.type === "Custom Lockout Pin") && (
                          <p>{`${materialDetails.length} x ${materialDetails.diameter} Lockout Pin`}</p>
                        )
                      )}

                      <CRow className="mb-2">
                        {(materialDetails.type === "Channel" ||
                          materialDetails.type === "Round Stock") && (
                          <CCol>
                            <CFormLabel>Length (ft.)</CFormLabel>
                            <CFormInput
                              type="text"
                              name="length"
                              value={values.length}
                              onChange={handleChange}
                              isInvalid={!!errors.length && touched.length}
                            />
                            <CFormFeedback>{errors.length}</CFormFeedback>
                          </CCol>
                        )}
                        <CCol>
                          <CFormLabel>Price ($)</CFormLabel>
                          <CFormInput
                            type="text"
                            name="price"
                            value={values.price}
                            onChange={handleChange}
                            isInvalid={!!errors.price && touched.price}
                          />
                          <CFormFeedback>{errors.price}</CFormFeedback>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <CFormLabel>Vendor</CFormLabel>
                          <CFormSelect
                            name="vendor"
                            value={values.vendor}
                            onChange={handleChange}
                            isInvalid={!!errors.vendor && touched.vendor}
                            placeholder="Select a vendor"
                          >
                            <option value="Great Lakes Specialty Metals">
                              Great Lakes Specialty Metals
                            </option>
                            <option value="Alro Steel">Alro Steel</option>
                            <option value="Liebovich Steel and Aluminum">
                              Liebovich Steel and Aluminum
                            </option>
                            <option value="TW Metals">TW Metals</option>
                            <option value="Ryerson">Ryerson</option>
                          </CFormSelect>
                          <CFormFeedback>{errors.vendor}</CFormFeedback>
                        </CCol>
                      </CRow>
                    </CModalBody>
                    <CModalFooter>
                      <CButton
                        type="button"
                        color="light"
                        onClick={() => {
                          setMaterialDetails({});
                          setShowEditPriceModal(false);
                        }}
                      >
                        Cancel
                      </CButton>
                      <CButton
                        type="submit"
                        color="primary"
                        disabled={
                          materialDetails.length === values.length &&
                          materialDetails.price === values.price
                        }
                      >
                        Update
                      </CButton>
                    </CModalFooter>
                  </CForm>
                )}
              </Formik>
            </CModal>
          )}
          {success && message && <div className="alert alert-success">{message}</div>}
          <CRow>
            <CCol xs={12}>
              <CCard className="mb-4">
                <CCardHeader>
                  <strong>Channel</strong>
                </CCardHeader>
                <CCardBody>
                  <CTable>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell scope="col">Size</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Gage</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Material</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Length (ft.)</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Weight</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Price</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Updated</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Vendor</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {allMaterialPricing.map((x) => {
                        const type = "Channel";
                        const material =
                          x.material === "stainlessSteel"
                            ? "Stainless Steel"
                            : x.x === "carbonSteel"
                            ? "Carbon Steel"
                            : x.x === "aluminum" && "Aluminum";
                        const size =
                          x.shape === "channel" && x.dimensions.width.in && x.dimensions.height.in
                            ? `${x.dimensions.height.in}" x ${x.dimensions.width.in}"`
                            : x.shape === "channel" &&
                              !x.dimensions.width.in &&
                              !x.dimensions.height.in &&
                              `${x.dimensions.width.mm}mm x ${x.dimensions.width.mm}mm`;

                        return (
                          x.shape === "channel" && (
                            <>
                              <CTableRow>
                                <CTableDataCell>{size}</CTableDataCell>
                                <CTableDataCell>{x.gage && x.gage}</CTableDataCell>
                                <CTableDataCell>{material}</CTableDataCell>
                                <CTableDataCell>
                                  {x.priceHistory.sort((a, b) => a.date > b.date).at(-1).length}'
                                </CTableDataCell>
                                <CTableDataCell>
                                  {x.weight?.ozPerInch && `${x.weight.ozPerInch} oz./Inch`}
                                </CTableDataCell>
                                <CTableDataCell>
                                  $
                                  {Number(
                                    x.priceHistory.sort((a, b) => a.date > b.date).at(-1).price
                                  ).toFixed(2)}
                                </CTableDataCell>
                                <CTableDataCell>
                                  {dayjs(
                                    x.priceHistory.sort((a, b) => a.date > b.date).at(-1).date
                                  ).format("MM/DD/YY")}
                                </CTableDataCell>
                                <CTableDataCell>
                                  {x.priceHistory.sort((a, b) => a.date > b.date).at(-1).vendor}
                                </CTableDataCell>
                                <CTableDataCell>
                                  <CButton
                                    type="button"
                                    color="light"
                                    size="sm"
                                    onClick={() => {
                                      dispatch({ type: ADMIN_MESSAGE_RESET });
                                      setMaterialDetails({
                                        _id: x._id,
                                        size: size,
                                        material: material,
                                        type: type,
                                        length: x.length,
                                        price: x.price,
                                      });
                                      setShowEditPriceModal(true);
                                    }}
                                  >
                                    <i className="fas fa-edit"></i>
                                  </CButton>
                                </CTableDataCell>
                              </CTableRow>
                            </>
                          )
                        );
                      })}
                    </CTableBody>
                  </CTable>
                </CCardBody>
              </CCard>
              <CCard className="mb-4">
                <CCardHeader>
                  <strong>Round Stock</strong>
                </CCardHeader>
                <CCardBody>
                  <CTable>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell scope="col">Size</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Material</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Length (ft.)</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Weight</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Price</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Updated</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Vendor</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {allMaterialPricing.map((x) => {
                        const type = "Round Stock";
                        const material =
                          x.material === "stainlessSteel"
                            ? "Stainless Steel"
                            : x.material === "carbonSteel"
                            ? "Carbon Steel"
                            : x.material === "aluminum" && "Aluminum";
                        const size =
                          x.shape === "roundStock" && x.dimensions.diameter.in
                            ? `${x.dimensions.diameter.in}"`
                            : x.shape === "roundStock" &&
                              !x.dimensions.diameter.in &&
                              `${x.dimensions.diameter.mm}mm`;

                        return (
                          x.shape === "roundStock" && (
                            <>
                              <CTableRow>
                                <CTableDataCell>{size}</CTableDataCell>
                                <CTableDataCell>{type}</CTableDataCell>
                                <CTableDataCell>
                                  {x.priceHistory.sort((a, b) => a.date > b.date).at(-1).length}'
                                </CTableDataCell>
                                <CTableDataCell>
                                  {x.weight?.ozPerInch && `${x.weight.ozPerInch} oz./Inch`}
                                </CTableDataCell>
                                <CTableDataCell>
                                  $
                                  {Number(
                                    x.priceHistory.sort((a, b) => a.date > b.date).at(-1).price
                                  ).toFixed(2)}
                                </CTableDataCell>
                                <CTableDataCell>
                                  {dayjs(
                                    x.priceHistory.sort((a, b) => a.date > b.date).at(-1).date
                                  ).format("MM/DD/YY")}
                                </CTableDataCell>
                                <CTableDataCell>
                                  {x.priceHistory.sort((a, b) => a.date > b.date).at(-1).vendor}
                                </CTableDataCell>
                                <CTableDataCell>
                                  <CButton
                                    type="button"
                                    color="light"
                                    size="sm"
                                    onClick={() => {
                                      dispatch({ type: ADMIN_MESSAGE_RESET });
                                      setMaterialDetails({
                                        _id: x._id,
                                        size: size,
                                        material: material,
                                        type: type,
                                        length: x.length,
                                        price: x.price,
                                      });
                                      setShowEditPriceModal(true);
                                    }}
                                  >
                                    <i className="fas fa-edit"></i>
                                  </CButton>
                                </CTableDataCell>
                              </CTableRow>
                            </>
                          )
                        );
                      })}
                    </CTableBody>
                  </CTable>
                </CCardBody>
              </CCard>
              <CCard className="mb-4">
                <CCardHeader>
                  <strong>Standard Lockout Pins</strong>
                </CCardHeader>
                <CCardBody>
                  <CTable>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell scope="col">Length</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Diameter</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Price</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {allMaterialPricing.map((x) => {
                        const type = "Standard Lockout Pin";
                        const length =
                          x.length === "fourInch"
                            ? '4"'
                            : x.length === "fiveAndThreeQuarterInch" && '5.75"';
                        const diameter =
                          x.diameter === "threeEighthsInch"
                            ? '0.375"'
                            : x.diameter === "oneHalfInch" && '0.5"';

                        return (
                          x.type === "standardLockoutPin" && (
                            <>
                              <CTableRow>
                                <CTableDataCell>{length}</CTableDataCell>
                                <CTableDataCell>{diameter}</CTableDataCell>
                                <CTableDataCell>${Number(x.price).toFixed(2)}</CTableDataCell>
                                <CTableDataCell>
                                  <CButton
                                    type="button"
                                    color="light"
                                    size="sm"
                                    onClick={() => {
                                      dispatch({ type: ADMIN_MESSAGE_RESET });
                                      setMaterialDetails({
                                        _id: x._id,
                                        type: type,
                                        diameter: diameter,
                                        length: length,
                                        price: x.price,
                                      });
                                      setShowEditPriceModal(true);
                                    }}
                                  >
                                    <i className="fas fa-edit"></i>
                                  </CButton>
                                </CTableDataCell>
                              </CTableRow>
                            </>
                          )
                        );
                      })}
                    </CTableBody>
                  </CTable>
                </CCardBody>
              </CCard>
              <CCard className="mb-4">
                <CCardHeader>
                  <strong>Custom Lockout Pins</strong>
                </CCardHeader>
                <CCardBody>
                  <CTable>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell scope="col">Length</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Diameter</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Price</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {allMaterialPricing.map((x) => {
                        const type = "Custom Lockout Pin";
                        const length = "Any";
                        const diameter = "Any";

                        return (
                          x.type === "customLockoutPin" && (
                            <>
                              <CTableRow>
                                <CTableDataCell>{length}</CTableDataCell>
                                <CTableDataCell>{diameter}</CTableDataCell>
                                <CTableDataCell>${Number(x.price).toFixed(2)}</CTableDataCell>
                                <CTableDataCell>
                                  <CButton
                                    type="button"
                                    color="light"
                                    size="sm"
                                    onClick={() => {
                                      dispatch({ type: ADMIN_MESSAGE_RESET });
                                      setMaterialDetails({
                                        _id: x._id,
                                        type: type,
                                        diameter: diameter,
                                        length: length,
                                        price: x.price,
                                      });
                                      setShowEditPriceModal(true);
                                    }}
                                  >
                                    <i className="fas fa-edit"></i>
                                  </CButton>
                                </CTableDataCell>
                              </CTableRow>
                            </>
                          )
                        );
                      })}
                    </CTableBody>
                  </CTable>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </>
      )}
    </>
  );
};

export default AdminMaterialPricing;
