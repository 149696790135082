import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";

import { getAllUsers } from "../../redux/actions/adminActions";

const AdminCustomers = () => {
  const dispatch = useDispatch();

  const allUsers = useSelector((state) => state.admin.allUsers);

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  return (
    <CRow>
      <CCol xs={12}></CCol>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Customers</strong>
          </CCardHeader>
          <CCardBody>
            <CTable hover>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Company</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {allUsers.map((user) => (
                  <CTableRow>
                    <CTableDataCell>{user.name}</CTableDataCell>
                    <CTableDataCell>{user.email}</CTableDataCell>
                    <CTableDataCell>{user.company}</CTableDataCell>
                    <CTableDataCell>
                      <CButton
                        color="light"
                        size="sm"
                        className="mx-1"
                        href={`/admin/customers/${user._id}`}
                      >
                        <i className="fas fa-eye text-primary"></i>
                      </CButton>
                    </CTableDataCell>
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default AdminCustomers;
