import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import { Form, Spinner } from "react-bootstrap";

import { states } from "../../arrays/states";

import AccountNavigation from "../../components/account/AccountNavigation";

import LoadingWidget from "../../components/LoadingWidget";
import { Helmet } from "react-helmet";
import { variables } from "../../config";
import { UpdateAddressValidationSchema } from "../../validation";
import {
  useDeleteAddressMutation,
  useGetAddressesQuery,
  useGetAddressQuery,
  useUpdateAddressMutation,
} from "../../redux/api/userApi";

const AccountEditAddressScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const addresses = useGetAddressesQuery(id);
  const address = useGetAddressQuery(id);

  const [updateAddress, updateAddressResult] = useUpdateAddressMutation();
  const [deleteAddress, deleteAddressResult] = useDeleteAddressMutation();

  useEffect(() => {
    if (address.isError && address.error.status === 404) {
      navigate("/account/addresses?msg=address-not-found");
    }
  }, [address]);

  useEffect(() => {
    if (updateAddressResult.isSuccess) {
      navigate("/account/addresses?msg=update-address-success");
    }

    if (deleteAddressResult.isSuccess) {
      navigate("/account/addresses?msg=delete-address-success");
    }
  }, [dispatch, updateAddressResult, deleteAddressResult]);

  return (
    <>
      <Helmet>
        <title>Account | {variables.WEBSITE_NAME}</title>
        <link rel="canonical" href={`${variables.FULL_URL}/account/addresses/edit`} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {address.isLoading ? (
        <LoadingWidget />
      ) : (
        <>
          <div className="block-space block-space--layout--after-header"></div>
          <div className="block">
            <div className="container container--max--xl">
              <div className="row">
                <div className="col-12 col-lg-3">
                  <AccountNavigation active="addresses" />
                </div>
                <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                  <div className="card">
                    <div className="card-header">
                      <h5>Edit Address</h5>
                    </div>
                    <div className="card-divider"></div>
                    <div className="card-body card-body--padding--2">
                      <div className="row no-gutters">
                        <div className="col-12 col-lg-10 col-xl-8">
                          {address?.data && (
                            <Formik
                              validationSchema={UpdateAddressValidationSchema}
                              onSubmit={async (values) => {
                                await updateAddress({
                                  id: id,
                                  body: {
                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                    email: values.email,
                                    company: values.company,
                                    phone: values.phone,
                                    street: values.street,
                                    street2: values.street2,
                                    city: values.city,
                                    state: values.state,
                                    postalCode: values.postalCode,
                                    country: values.country,
                                    default: values.setAsDefault,
                                  },
                                });
                              }}
                              initialValues={{
                                firstName: address?.data?.firstName,
                                lastName: address?.data?.lastName,
                                company: address?.data?.company ? address?.data?.company : "",
                                phone: address?.data?.phone,
                                street: address?.data?.street,
                                street2: address?.data?.street2 ? address?.data?.street2 : "",
                                city: address?.data?.city,
                                state: address?.data?.state,
                                postalCode: address?.data?.postalCode,
                                country: address?.data?.country,
                                setAsDefault: address?.data?.default
                                  ? address?.data?.default
                                  : false,
                              }}
                            >
                              {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                setValues,
                                setFieldValue,
                                touched,
                                isValid,
                                errors,
                                isSubmitting,
                              }) => (
                                <>
                                  <Form noValidate onSubmit={handleSubmit}>
                                    <div className="form-row">
                                      <div className="col-md-6">
                                        <Form.Group controlId="first-name">
                                          <Form.Label>First Name</Form.Label>
                                          <Form.Control
                                            name="firstName"
                                            type="text"
                                            placeholder="Enter first name"
                                            value={values.firstName}
                                            onChange={handleChange}
                                            isInvalid={!!errors.firstName && touched.firstName}
                                          ></Form.Control>
                                          <Form.Control.Feedback type="invalid">
                                            {errors.firstName}
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                      </div>
                                      <div className="col-md-6">
                                        <Form.Group controlId="last-name">
                                          <Form.Label>Last Name</Form.Label>
                                          <Form.Control
                                            name="lastName"
                                            type="text"
                                            placeholder="Enter last name"
                                            value={values.lastName}
                                            onChange={handleChange}
                                            isInvalid={!!errors.lastName && touched.lastName}
                                          ></Form.Control>
                                          <Form.Control.Feedback type="invalid">
                                            {errors.lastName}
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                      </div>
                                    </div>
                                    <Form.Group controlId="company">
                                      <Form.Label>
                                        Company Name <span className="text-muted">(Optional)</span>
                                      </Form.Label>
                                      <Form.Control
                                        name="company"
                                        type="text"
                                        placeholder="Enter a company name"
                                        value={values.company}
                                        onChange={handleChange}
                                        isInvalid={!!errors.company && touched.company}
                                      ></Form.Control>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.company}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="phone">
                                      <Form.Label>Phone</Form.Label>
                                      <Form.Control
                                        name="phone"
                                        type="phone"
                                        placeholder="Enter phone number"
                                        value={values.phone}
                                        onChange={handleChange}
                                        isInvalid={!!errors.phone && touched.phone}
                                      ></Form.Control>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.phone}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="street">
                                      <Form.Label>Street Address</Form.Label>
                                      <Form.Control
                                        name="street"
                                        type="text"
                                        placeholder="Enter a street address"
                                        value={values.street}
                                        onChange={handleChange}
                                        isInvalid={!!errors.street && touched.street}
                                      ></Form.Control>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.street}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="street-2">
                                      <Form.Label>
                                        Apartment, suite, unit etc.{" "}
                                        <span className="text-muted">(Optional)</span>
                                      </Form.Label>
                                      <Form.Control
                                        name="street2"
                                        type="text"
                                        placeholder="Enter an apartment, suite, unit etc."
                                        value={values.street2}
                                        onChange={handleChange}
                                        isInvalid={!!errors.street2 && touched.street2}
                                      ></Form.Control>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.street2}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="city">
                                      <Form.Label>City</Form.Label>
                                      <Form.Control
                                        name="city"
                                        type="text"
                                        placeholder="Enter a city"
                                        value={values.city}
                                        onChange={handleChange}
                                        isInvalid={!!errors.city && touched.city}
                                      ></Form.Control>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.city}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="state">
                                      <Form.Label>State</Form.Label>
                                      <Form.Control
                                        as="select"
                                        name="state"
                                        value={values.state}
                                        onChange={handleChange}
                                        isInvalid={!!errors.state && touched.state}
                                        style={
                                          values.state === ""
                                            ? { color: "#6c757d" }
                                            : { color: "#262626" }
                                        }
                                      >
                                        <option value="" key="" disabled>
                                          Please Select a State
                                        </option>
                                        {states.map((x) => (
                                          <option value={x} key={x} style={{ color: "#262626" }}>
                                            {x}
                                          </option>
                                        ))}
                                      </Form.Control>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.state}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="postalCode">
                                      <Form.Label>Postal Code</Form.Label>
                                      <Form.Control
                                        name="postalCode"
                                        type="text"
                                        placeholder="Enter a five-digit postal code"
                                        value={values.postalCode}
                                        onChange={handleChange}
                                        isInvalid={!!errors.postalCode && touched.postalCode}
                                      ></Form.Control>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.postalCode}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="country">
                                      <Form.Label>Country</Form.Label>
                                      <Form.Control
                                        name="country"
                                        type="text"
                                        placeholder="Enter a country"
                                        value={values.country}
                                        onChange={handleChange}
                                        isInvalid={!!errors.country && touched.country}
                                      ></Form.Control>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.country}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <div className="form-group mt-3">
                                      <div className="form-check">
                                        <span className="input-check form-check-input">
                                          <span className="input-check__body">
                                            <input
                                              className="input-check__input"
                                              id="default-address"
                                              type="checkbox"
                                              name="setAsDefault"
                                              checked={values.setAsDefault}
                                              onChange={handleChange}
                                              disabled={
                                                addresses?.data?.length === 1 ||
                                                address?.data?.default
                                              }
                                            />
                                            <span className="input-check__box"></span>
                                            <span className="input-check__icon">
                                              <svg width="9px" height="7px">
                                                <path d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z" />
                                              </svg>
                                            </span>
                                          </span>
                                        </span>
                                        <label className="form-check-label" for="default-address">
                                          {address?.default ? (
                                            <>Default address</>
                                          ) : (
                                            <>Set as my default address</>
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group pt-3 mt-3">
                                      <button
                                        className="btn btn-primary"
                                        disabled={updateAddressResult.isLoading}
                                      >
                                        {updateAddressResult.isLoading ? (
                                          <>
                                            Save
                                            <Spinner
                                              as="span"
                                              animation="border"
                                              size="sm"
                                              role="status"
                                              aria-hidden="true"
                                              className="ml-2"
                                            />
                                          </>
                                        ) : (
                                          <>Save</>
                                        )}
                                      </button>
                                    </div>
                                  </Form>
                                </>
                              )}
                            </Formik>
                          )}
                          <div className="form-group">
                            {showConfirmDelete ? (
                              <>
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => setShowConfirmDelete(false)}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn btn-danger"
                                  onClick={async () => await deleteAddress(id)}
                                  disabled={deleteAddressResult.isLoading}
                                >
                                  {deleteAddressResult.isLoading ? (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                      className="mr-2"
                                    />
                                  ) : null}
                                  Confirm Remove Address
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="btn btn-link p-0"
                                  onClick={() => setShowConfirmDelete(true)}
                                >
                                  Remove Address
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-space block-space--layout--before-footer"></div>
        </>
      )}
    </>
  );
};

export default AccountEditAddressScreen;
